import { ProjectType } from 'lib/Types';
import api from 'lib/api';
import { deleteProject, upsertProjects } from 'lib/redux/slices/projects';
import {
  deleteProjectTranscriptCharacters,
  upsertTranscriptCharacters,
} from 'lib/redux/slices/transcriptCharacters';
import {
  deleteProjectTranscriptLines,
  upsertTranscriptLines,
} from 'lib/redux/slices/transcriptLines';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  projects: ProjectType[];
};

export const usePingProjects = ({ projects }: Props) => {
  const dispatch = useDispatch();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const loadingProjectIds = useMemo(
    () =>
      projects
        .filter((project) => project.build_status === 'BUILDING')
        .map((project) => project.id),
    [projects],
  );

  useEffect(() => {
    const pingStatusEndpoint = async () => {
      if (loadingProjectIds.length > 0) {
        try {
          const { data } = await api.projects.status({
            project_ids: loadingProjectIds,
          });
          // Clear old project data before upserting new project
          data.projects.forEach((proj: ProjectType) => {
            dispatch(deleteProject(proj.id));
            dispatch(deleteProjectTranscriptLines(proj.id));
            dispatch(deleteProjectTranscriptCharacters(proj.id));
          });

          dispatch(upsertProjects(data.projects));
          dispatch(upsertTranscriptLines(data.transcript_lines));
          dispatch(upsertTranscriptCharacters(data.transcript_characters));
        } catch (error) {
          console.error('Error pinging status endpoint:', error);
        }
      }
    };

    if (loadingProjectIds.length) {
      intervalRef.current = setInterval(pingStatusEndpoint, 3000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [loadingProjectIds]);
};
