import { ProjectType } from 'lib/Types';
import api from 'lib/api';
import { getUserMe } from 'lib/redux/selectors/users';
import { deleteProjectUserByProjectId } from 'lib/redux/slices/projectUsers';
import { deleteProject, upsertProjects } from 'lib/redux/slices/projects';
import { renameEditableInput } from 'lib/utils/utils';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  project: ProjectType;
  children?: React.ReactNode;
};

export const ProjectContextMenu = ({ project, children }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserMe);

  const handleDelete = async () => {
    try {
      dispatch(deleteProject(project.id));
      dispatch(deleteProjectUserByProjectId(project.id));
      await api.projects.delete(project.id);
    } catch (e) {
      alert('error soft deleting project');
    }
  };

  const handleHardDelete = async () => {
    try {
      dispatch(deleteProject(project.id));
      dispatch(deleteProjectUserByProjectId(project.id));
      await api.projects.hardDelete(project.id);
    } catch (e) {
      alert('error hard deleting project');
    }
  };

  const handleRebuild = async () => {
    try {
      dispatch(upsertProjects([{ id: project.id, build_status: 'BUILDING' }]));
      const { data } = await api.projects.rebuild(project.id);
      dispatch(upsertProjects([data.project]));
    } catch (e) {
      console.error('error rebuilding video project', e);
      alert(`error rebuilding video project ${e}`);
    }
  };

  if (!project) return null;

  return (
    <ContextMenu>
      <ContextMenuTrigger>{children}</ContextMenuTrigger>
      <ContextMenuContent onClick={(e) => e.stopPropagation()}>
        <ContextMenuItem
          title="Rename"
          icon="edit"
          onSelect={() => {
            renameEditableInput(`#project-row-${project.id}`);
          }}
        />
        <a
          href={project.video_src}
          style={{ all: 'unset' }}
          target="_blank"
          rel="noreferrer"
        >
          <ContextMenuItem title="View Source" icon="open_in_new" />
        </a>

        <ContextMenuItem title="Delete" icon="delete" onSelect={handleDelete} />
        {user?.role === 'ADMIN' ? (
          <>
            <ContextMenuLabel>Admin</ContextMenuLabel>
            <ContextMenuItem
              title="Copy Demo URL"
              icon="link"
              onSelect={() => {
                navigator.clipboard.writeText(
                  `https://site.deeptune.ai/demos/${project.demo_id}`,
                );
              }}
              disabled={!project.demo_id}
            />
            <ContextMenuItem
              title="Rebuild "
              icon="restart_alt"
              onSelect={handleRebuild}
            />
            <ContextMenuItem
              title="Hard Delete"
              icon="delete_forever"
              onSelect={handleHardDelete}
            />
            <ContextMenuItem
              title="Copy Project UUID"
              icon="content_copy"
              onSelect={() => {
                navigator.clipboard.writeText(project.id);
              }}
            />
            <ContextMenuItem
              title="Copy Video S3 URL"
              icon="content_copy"
              onSelect={() => {
                navigator.clipboard.writeText(project.video_src);
              }}
            />
          </>
        ) : null}
      </ContextMenuContent>
    </ContextMenu>
  );
};
