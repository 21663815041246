import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClipType } from 'lib/Types';

export type ClipsReduxType = { [id: string]: ClipType };
const initialState = {} as ClipsReduxType;

export const clipsSlice = createSlice({
  name: 'clips',
  initialState,
  reducers: {
    upsertClips: (
      state: ClipsReduxType,
      action: PayloadAction<({ id: string } & Partial<ClipType>)[]>,
    ) => {
      action.payload.forEach((clip) => {
        state[clip.id] = { ...(state[clip.id] || {}), ...clip };
      });
      return state;
    },
    deleteClip: (state: ClipsReduxType, action: PayloadAction<string>) => {
      delete state[action.payload];
      return state;
    },
    deleteClipsByTrack: (
      state: ClipsReduxType,
      action: PayloadAction<string>,
    ) => {
      Object.keys(state).forEach((clipId) => {
        if (state[clipId].clip_track_id === action.payload) {
          delete state[clipId];
        }
      });
      return state;
    },
    resetClips: () => initialState,
  },
});

export const { upsertClips, deleteClip, deleteClipsByTrack, resetClips } =
  clipsSlice.actions;
