import { ToastProvider } from '@radix-ui/react-toast';
import api from 'lib/api';
import { useProjectDubParam } from 'lib/hooks/use-id-param';
import { getToast } from 'lib/redux/selectors/toast';
import { upsertCaptions } from 'lib/redux/slices/captions';
import { upsertCharacters } from 'lib/redux/slices/characters';
import { upsertClipTracks } from 'lib/redux/slices/clipTracks';
import { upsertClips } from 'lib/redux/slices/clips';
import { resetEditor } from 'lib/redux/slices/editor';
import { upsertExportJobs } from 'lib/redux/slices/exportJobs';
import { upsertLines } from 'lib/redux/slices/lines';
import { upsertProjectDubs } from 'lib/redux/slices/projectDubs';
import { upsertProjects } from 'lib/redux/slices/projects';
import { ProjectSettingsModal } from 'modules/editor/components/project-settings-modal';
import { Toolbar } from 'modules/editor/components/toolbar';
import {
  ToastDescription,
  ToastRoot,
  ToastTitle,
  ToastViewport,
} from 'modules/radix/Toast';
import { Loading } from 'modules/shared/loading';
import { ProjectBuilding } from 'modules/shared/screens/project-building';
import { ProjectFailed } from 'modules/shared/screens/project-failed';
import { ProjectNotFound } from 'modules/shared/screens/project-not-found';
import { FlexRow } from 'modules/shared/ui';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CharacterModal } from './components/character-modal';
import EditorDetail from './components/editor-detail';
import { HistoryModal } from './components/history-modal';

const Editor = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { project, projectDub } = useProjectDubParam();
  const [isLoading, setLoading] = useState(true);
  const toast = useSelector(getToast);

  useEffect(() => {
    const handleLoad = async () => {
      if (!id) return;
      try {
        setLoading(true);
        const { data } = await api.projectDubs.getById(id);
        dispatch(resetEditor());
        dispatch(upsertProjects([data.project]));
        dispatch(upsertProjectDubs(data.project_dubs));
        dispatch(upsertCharacters(data.characters));
        dispatch(upsertClips(data.clips));
        dispatch(upsertClipTracks(data.clip_tracks));
        dispatch(upsertLines(data.lines));
        dispatch(upsertExportJobs(data.export_jobs));
        if (data.caption) dispatch(upsertCaptions([data.caption]));
      } catch (e) {
        console.error('Error loading project.', e);
      } finally {
        setLoading(false);
      }
    };

    handleLoad();
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }

  if (!projectDub || !project) {
    return <ProjectNotFound />;
  }

  if (project.build_status === 'BUILDING') {
    return <ProjectBuilding />;
  }

  if (project.build_status === 'FAILED') {
    return <ProjectFailed project={project} />;
  }

  return (
    <>
      <Container>
        <Toolbar project={project} projectDub={projectDub} />
        <EditorDetail project={project} projectDub={projectDub} />
      </Container>
      <HistoryModal />
      <ProjectSettingsModal project={project} projectDub={projectDub} />
      <CharacterModal />
      <ToastProvider swipeDirection="right">
        {toast.map((t) => (
          <ToastRoot open={true} key={t.id}>
            <FlexRow style={{ gap: 8 }}>
              {t.isLoading ? (
                <TailSpin width={16} height={16} color="var(--text-primary)" />
              ) : null}
              <div>
                <ToastTitle>{t.title}</ToastTitle>
                {t.description ? (
                  <ToastDescription>{t.description}</ToastDescription>
                ) : null}
              </div>
            </FlexRow>
          </ToastRoot>
        ))}
        {toast.length > 0 && <ToastViewport />}
      </ToastProvider>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
  height: calc(100%);
  font-size: 12px;
  background: var(--background);
  height: 100%;
`;

export default Editor;
