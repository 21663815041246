export const WEBSERVER_URL = process.env.REACT_APP_API_URL || '';

export const VALLEX_INFERENCE_URL =
  process.env.VALLEX_INFERENCE_URL ||
  'http://a4d68fdb0ad2243cfb621c2d81a70dd0-069d76260a5e16f2.elb.us-east-1.amazonaws.com/vallex-prod';

export const TOPBAR_HEIGHT = 50;

export enum FeatureFlags {
  CAPTIONS = '2024-06-12-captions-editor',
}
