import { FontWeightEnum } from 'lib/Types';

export enum WebsafeFontsEnum {
  ARIAL = 'Arial',
  BOOK_ANTIQUA = 'Book Antiqua',
  BRUSH_SCRIPT_MT = 'Brush Script MT',
  COMIC_SANS_MS = 'Comic Sans MS',
  COURIER_NEW = 'Courier New',
  GARAMOND = 'Garamond',
  GENEVA = 'Geneva',
  GEORGIA = 'Georgia',
  HELVETICA = 'Helvetica',
  IMPACT = 'Impact',
  LUCIDA_CONSOLE = 'Lucida Console',
  MONACO = 'Monaco',
  PALATINO_LINOTYPE = 'Palatino Linotype',
  TAHOMA = 'Tahoma',
  TIMES_NEW_ROMAN = 'Times New Roman',
  TREBUCHET_MS = 'Trebuchet MS',
  VERDANA = 'Verdana',
}

export const getWeightName = (weight: FontWeightEnum): string => {
  switch (weight) {
    case FontWeightEnum.LIGHT:
      return 'Light';
    case FontWeightEnum.NORMAL:
      return 'Normal';
    case FontWeightEnum.MEDIUM:
      return 'Medium';
    case FontWeightEnum.BOLD:
      return 'Bold';
    default:
      return 'Unknown';
  }
};

export interface FontInfo {
  availableWeights: FontWeightEnum[];
  supportsItalic: boolean;
}

export const WebsafeFontProperties: Record<string, FontInfo> = {
  [WebsafeFontsEnum.ARIAL]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: true,
  },
  [WebsafeFontsEnum.BOOK_ANTIQUA]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: true,
  },
  [WebsafeFontsEnum.BRUSH_SCRIPT_MT]: {
    availableWeights: [FontWeightEnum.NORMAL],
    supportsItalic: false,
  },
  [WebsafeFontsEnum.COMIC_SANS_MS]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: false,
  },
  [WebsafeFontsEnum.COURIER_NEW]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: true,
  },
  [WebsafeFontsEnum.GARAMOND]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: true,
  },
  [WebsafeFontsEnum.GENEVA]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: false,
  },
  [WebsafeFontsEnum.GEORGIA]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: true,
  },
  [WebsafeFontsEnum.HELVETICA]: {
    availableWeights: [
      FontWeightEnum.LIGHT,
      FontWeightEnum.NORMAL,
      FontWeightEnum.BOLD,
    ],
    supportsItalic: true,
  },
  [WebsafeFontsEnum.IMPACT]: {
    availableWeights: [FontWeightEnum.NORMAL],
    supportsItalic: false,
  },
  [WebsafeFontsEnum.LUCIDA_CONSOLE]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: false,
  },
  [WebsafeFontsEnum.MONACO]: {
    availableWeights: [FontWeightEnum.NORMAL],
    supportsItalic: false,
  },
  [WebsafeFontsEnum.PALATINO_LINOTYPE]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: true,
  },
  [WebsafeFontsEnum.TAHOMA]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: false,
  },
  [WebsafeFontsEnum.TIMES_NEW_ROMAN]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: true,
  },
  [WebsafeFontsEnum.TREBUCHET_MS]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: true,
  },
  [WebsafeFontsEnum.VERDANA]: {
    availableWeights: [FontWeightEnum.NORMAL, FontWeightEnum.BOLD],
    supportsItalic: true,
  },
};
