import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import { FontWeightEnum } from 'lib/Types';
import {
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { FlexRow } from 'modules/shared/ui';
import { getWeightName } from '../../../lib/fonts';
import { UseFontProperties } from '../../../lib/hooks/use-font-properties';

type Props = {
  font: string;
  fontWeight: FontWeightEnum;
  fontStyle: string;
  onValueChange: (v: string) => void;
};

export const StylePicker = ({
  font,
  fontWeight,
  fontStyle,
  onValueChange,
}: Props) => {
  const { fontProperties } = UseFontProperties();
  const value = `${fontWeight} ${fontStyle}`;
  const selectItems = fontProperties[font].availableWeights.flatMap(
    (weight) => {
      const styles = fontProperties[font].supportsItalic
        ? ['normal', 'italic']
        : ['normal'];
      const weightName = getWeightName(weight);

      return styles.map((style) => {
        const italicSuffix = style === 'italic' ? ' Italic' : '';
        return {
          value: `${weight} ${style}`,
          label: `${weightName}${italicSuffix}`,
          style: {
            fontFamily: font,
            fontSize: 13,
            fontWeight: weight,
            fontStyle: style,
          },
        };
      });
    },
  );

  return (
    <SelectRoot value={value} onValueChange={onValueChange}>
      <SelectTrigger
        style={{
          fontFamily: font,
          fontSize: 13,
          width: 100,
          fontWeight,
          fontStyle,
        }}
      >
        <FlexRow style={{ justifyContent: 'space-between', width: '100%' }}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <SelectValue />
          </div>
          <SelectIcon>
            <IconChevronDown width={13} height={13} />
          </SelectIcon>
        </FlexRow>
      </SelectTrigger>
      <SelectContent position="popper" style={{ marginTop: '4px' }}>
        <SelectViewport>
          {selectItems.map((item) => (
            <SelectItem key={item.value} value={item.value} style={item.style}>
              <SelectItemText>{item.label}</SelectItemText>
              <SelectItemIndicator>
                <IconCheck width={16} height={16} />
              </SelectItemIndicator>
            </SelectItem>
          ))}
        </SelectViewport>
      </SelectContent>
    </SelectRoot>
  );
};
