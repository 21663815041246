import { TimelineEngine } from 'lib/engine/engine';
import { setEditorDuration, setEditorSeekTime } from 'lib/redux/slices/editor';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';

type Props = {
  timelineEngine: MutableRefObject<TimelineEngine>;
};

export const useTimelinePlayer = ({ timelineEngine }: Props) => {
  const dispatch = useDispatch();
  const playerRef = useRef<ReactPlayer>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!timelineEngine.current) return;
    const playHandler = timelineEngine.current.on('play', () => {
      setIsPlaying(true);
    });
    const pauseHandler = timelineEngine.current.on('paused', () => {
      setIsPlaying(false);
    });
    const setTimeHandler = timelineEngine.current.on(
      'afterSetTime',
      ({ time }) => {
        if (playerRef.current) playerRef.current.seekTo(time, 'seconds');
        dispatch(setEditorSeekTime(time));
      },
    );
    return () => {
      timelineEngine.current.off(playHandler);
      timelineEngine.current.off(pauseHandler);
      timelineEngine.current.off(setTimeHandler);
    };
  }, [timelineEngine.current]);

  const onDuration = (duration: number) => {
    dispatch(setEditorDuration(duration));
  };

  return { isPlaying, playerRef, onDuration };
};
