import { blackA, violet } from '@radix-ui/colors';
import * as SelectPrimitive from '@radix-ui/react-select';
import styled from 'styled-components';

const StyledTrigger = styled(SelectPrimitive.SelectTrigger)`
  all: unset;
  color: var(--text-primary);
  background-color: var(--background);
  border: 1px solid var(--border);
  border-radius: 4px;
  padding: 0px 12px;
  /* box-shadow: 0 2px 10px ${blackA.blackA7}; */
  font-size: 12px;
  height: 32px;
  gap: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: var(--hover);
  }
  // '&:focus': { boxShadow: '0 0 0 2px black' },
  ::placeholder {
    color: ${violet.violet9};
  }
`;

const StyledIcon = styled(SelectPrimitive.SelectIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
`;

const StyledContent = styled(SelectPrimitive.Content)`
  overflow: hidden;
  background-color: var(--background);
  border: 1px solid var(--border);
  border-radius: 4px;
  box-shadow: 0 2px 10px ${blackA.blackA7};
`;

const StyledViewport = styled(SelectPrimitive.Viewport)`
  padding: 5px;
`;

function Content({ children, ...props }: any) {
  return (
    <SelectPrimitive.Portal>
      <StyledContent {...props}>{children}</StyledContent>
    </SelectPrimitive.Portal>
  );
}

const StyledItem = styled(SelectPrimitive.Item)`
  all: unset;
  font-size: 12px;
  height: 24px;
  padding: 0 36px 0 24px;
  color: var(--text-primary);
  border-radius: 2px;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;

  :hover {
    background-color: var(--hover);
  }

  :disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &[data-disabled] {
    pointer-events: none;
    opacity: 0.3;
  }
`;

const StyledLabel = styled(SelectPrimitive.Label)`
  padding: 0 24px;
  font-size: 12px;
  line-height: 24px;
  color: var(--text-secondary);
`;

const StyledSeparator = styled(SelectPrimitive.Separator)`
  height: 1px;
  background-color: ${violet.violet6};
  margin: 5px;
`;

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator)`
  position: absolute;
  left: 0px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const StyledScrollUpButton = styled(SelectPrimitive.ScrollUpButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: ${violet.violet11};
  cursor: default;
`;

const StyledScrollDownButton = styled(SelectPrimitive.ScrollDownButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: ${violet.violet11};
  cursor: default;
`;

// Exports
export const SelectRoot = SelectPrimitive.Root;
export const SelectTrigger = StyledTrigger;
export const SelectValue = SelectPrimitive.Value;
export const SelectIcon = StyledIcon;
export const SelectContent = Content;
export const SelectViewport = StyledViewport;
export const SelectGroup = SelectPrimitive.Group;
export const SelectItem = StyledItem;
export const SelectItemText = SelectPrimitive.ItemText;
export const SelectItemIndicator = StyledItemIndicator;
export const SelectLabel = StyledLabel;
export const SelectSeparator = StyledSeparator;
export const SelectScrollUpButton = StyledScrollUpButton;
export const SelectScrollDownButton = StyledScrollDownButton;
