import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getUserMe } from 'lib/redux/selectors/users';
import { ButtonSecondary } from 'modules/shared/ui';
import { Container, Description, Input, Label, Title } from '../ui';

export const Profile = () => {
  const user = useSelector(getUserMe);

  return (
    <Container>
      <Title>Profile</Title>
      <div>
        <FlexRow>
          <div style={{ flex: 1 }}>
            <Label>Name</Label>
            <Input value={user?.name} readOnly />
          </div>
          {/* <div style={{ flex: 1 }}>
            <Label>Last Name</Label>
            <Input value={user?.last_name} readOnly />
          </div> */}
        </FlexRow>
        <Description> Your preferred name as it will be displayed </Description>
      </div>
      <div style={{ marginTop: 16 }}>
        <FlexRow
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <div>
            <div>Email</div>
            <Description style={{ marginLeft: 0 }}>{user?.email}</Description>
          </div>
          <ButtonSecondary
            onClick={() => {
              alert(
                'Contact support at support@deeptune.com to change your email.',
              );
            }}
          >
            Change email
          </ButtonSecondary>
        </FlexRow>
      </div>
    </Container>
  );
};

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
