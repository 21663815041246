import { ProjectDubType, ProjectType } from 'lib/Types';
import {
  FlexRow,
  Label,
  ToggleButton,
  WarningMessage,
} from 'modules/shared/ui';
import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { IconAlertTriangle } from '@tabler/icons-react';
import { ExportModeEnum } from './utils';
import { ExportAudio } from './components/export-audio';
import { ExportSubtitles } from './components/export-subtitles';
import { ExportTranscript } from './components/export-transcript';
import { ExportTimeline } from './components/export-timeline';
import { ExportVideo } from './components/export-video';

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
  numLinesLoading: number;
  setTab: (tab: 'export' | 'history' | 'publish') => void;
  setCaptionsExportProgress: Dispatch<SetStateAction<number | null>>;
};

export const ExportTab = ({
  project,
  projectDub,
  numLinesLoading,
  setTab,
  setCaptionsExportProgress,
}: Props) => {
  const [mode, setMode] = useState<ExportModeEnum>(ExportModeEnum.VIDEO);

  const renderBody = () => {
    switch (mode) {
      case ExportModeEnum.VIDEO:
        return (
          <ExportVideo
            project={project}
            projectDub={projectDub}
            setTab={setTab}
            setCaptionsExportProgress={setCaptionsExportProgress}
          />
        );
      case ExportModeEnum.AUDIO:
        return (
          <ExportAudio
            project={project}
            projectDub={projectDub}
            setTab={setTab}
          />
        );
      case ExportModeEnum.TIMELINE:
        return <ExportTimeline project={project} projectDub={projectDub} />;
      case ExportModeEnum.TRANSCRIPT:
        return <ExportTranscript project={project} projectDub={projectDub} />;
      case ExportModeEnum.SUBTITLES:
        return <ExportSubtitles project={project} projectDub={projectDub} />;
      case ExportModeEnum.ASSETS:
        return 'assets';
      default:
        return null;
    }
  };

  return (
    <Container>
      <FlexCol>
        <Label>Export mode</Label>
        <ToggleRow>
          <ExportToggle
            isActive={mode === ExportModeEnum.VIDEO}
            onClick={() => setMode(ExportModeEnum.VIDEO)}
          >
            Video
          </ExportToggle>
          <ExportToggle
            isActive={mode === ExportModeEnum.AUDIO}
            onClick={() => setMode(ExportModeEnum.AUDIO)}
          >
            Audio
          </ExportToggle>
          <ExportToggle
            isActive={mode === ExportModeEnum.TIMELINE}
            onClick={() => setMode(ExportModeEnum.TIMELINE)}
          >
            Timeline
          </ExportToggle>
          <ExportToggle
            isActive={mode === ExportModeEnum.TRANSCRIPT}
            onClick={() => setMode(ExportModeEnum.TRANSCRIPT)}
          >
            Transcript
          </ExportToggle>
          <ExportToggle
            isActive={mode === ExportModeEnum.SUBTITLES}
            onClick={() => setMode(ExportModeEnum.SUBTITLES)}
          >
            Subtitles
          </ExportToggle>
          {project.asset_zip_src && (
            <ExportToggle
              isActive={mode === ExportModeEnum.ASSETS}
              onClick={() => setMode(ExportModeEnum.ASSETS)}
            >
              Assets
            </ExportToggle>
          )}
        </ToggleRow>
      </FlexCol>
      {renderBody()}
      {numLinesLoading === 0 ? null : (
        <WarningMessage style={{ marginTop: 32 }}>
          <IconAlertTriangle style={{ width: 13, height: 13 }} />
          You still have {numLinesLoading} lines loading. Are you sure you want
          to export?
        </WarningMessage>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ToggleRow = styled(FlexRow)`
  gap: 8px;
`;

const ExportToggle = styled(ToggleButton)<{ isActive: boolean }>`
  color: var(--text-primary);
  border: 1px solid
    ${({ isActive }) => (isActive ? 'var(--text-secondary)' : 'var(--border)')};
  font-size: 12px;
  padding: 8px 8px;
  background: ${({ isActive }) =>
    isActive ? 'var(--hover)' : 'var(--background)'};
`;
