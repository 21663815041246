import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import React from 'react';
import { TranscriptCharacterType } from 'lib/Types';
import { renameEditableInput } from 'lib/utils/utils';
import { useTranscriptCharacterApi } from 'lib/hooks/api/use-transcript-character-api';

type Props = {
  transcriptCharacter: TranscriptCharacterType;
  entrypoint: 'sidebar' | 'modal';
  children?: React.ReactNode;
};

export const TranscriptCharacterContextMenu = ({
  transcriptCharacter,
  entrypoint,
  children,
}: Props) => {
  const { handleDeleteTranscriptCharacter } = useTranscriptCharacterApi();

  return (
    <ContextMenu>
      <ContextMenuTrigger>{children}</ContextMenuTrigger>
      <ContextMenuContent onClick={(e) => e.stopPropagation()}>
        <ContextMenuItem
          title="Rename"
          icon="edit"
          onSelect={() => {
            renameEditableInput(
              `#transcript-character-row-${transcriptCharacter.id}-${entrypoint}`,
            );
          }}
        />
        <ContextMenuItem
          title="Delete"
          icon="delete"
          onSelect={() => handleDeleteTranscriptCharacter(transcriptCharacter)}
        />
      </ContextMenuContent>
    </ContextMenu>
  );
};
