import { IconCheck } from '@tabler/icons-react';
import { TimelineEngine } from 'lib/engine/engine';
import { TranscriptLineType } from 'lib/Types';
import api from 'lib/api';
import { useTranscriptLineApi } from 'lib/hooks/api/use-transcript-line-api';
import { useQueryParams } from 'lib/hooks/use-query-params';
import { getProjectTranscriptCharacters } from 'lib/redux/selectors/transcriptCharacters';
import { setEditorSeekTime } from 'lib/redux/slices/editor';
import { upsertTranscriptLines } from 'lib/redux/slices/transcriptLines';
import { secondsToHumanReadable } from 'lib/utils/audio';
import { debounce } from 'lodash';
import {
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { TranscriptLineContextMenu } from 'modules/shared/context-menu/transcript-line-context-menu';
import { EditableDiv } from 'modules/shared/editable-components/editable-div';
import { FlexRow } from 'modules/shared/ui';
import { MutableRefObject } from 'react';
import { RootState } from 'lib/redux';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

type Props = {
  transcriptLine: TranscriptLineType;
  focusTimeline: () => void;
  timelineEngine: MutableRefObject<TimelineEngine>;
};

export const TranscriptLineRow = ({
  transcriptLine,
  focusTimeline,
  timelineEngine,
}: Props) => {
  const dispatch = useDispatch();
  const { updateQueryParams, getQueryParams } = useQueryParams();
  const transcriptCharacters = useSelector((state: RootState) =>
    getProjectTranscriptCharacters(transcriptLine.project_id)(state),
  );
  const {
    handleUpdateTranscriptLine,
    handleUnapproveTranscriptLine,
    handleApproveTranscriptLine,
    handleSplitTranscriptLine,
  } = useTranscriptLineApi();
  const { activeId } = getQueryParams(['activeId']);

  const handleClick = (editable: boolean) => {
    updateQueryParams({ activeId: transcriptLine.id });
    if (!editable) {
      focusTimeline();
      timelineEngine.current?.setTime(transcriptLine.start);
      dispatch(setEditorSeekTime(transcriptLine.start));
    }
  };

  const debouncedHandleChange = debounce((e: string) => {
    api.transcriptLines
      .update(transcriptLine.id, { text: e })
      .catch(() => alert('error saving'));
  }, 300);

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      // Flush any updates to the text
      debouncedHandleChange.flush();
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const cursorPosition = range.startOffset;
        handleSplitTranscriptLine(transcriptLine.id, cursorPosition);
      }
    }
  };

  const handleChange = async (text: string) => {
    dispatch(upsertTranscriptLines([{ ...transcriptLine, text }]));
    debouncedHandleChange(text); // Debounced server-side update
  };

  const handleApprovalChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (transcriptLine.approved_at) {
      handleUnapproveTranscriptLine(transcriptLine.id);
    } else {
      handleApproveTranscriptLine(transcriptLine.id);
    }
  };

  return (
    <TranscriptLineContextMenu transcriptLine={transcriptLine}>
      <Container
        id={`transcript-line-row-${transcriptLine.id}`}
        style={{
          background:
            activeId === transcriptLine.id ? 'var(--hover)' : undefined,
        }}
      >
        <FlexRow
          style={{ gap: 8, alignItems: 'flex-start' }}
          onClick={() => handleClick(false)}
        >
          <Button
            disabled={!transcriptLine.transcript_character_id}
            onClick={handleApprovalChange}
          >
            <IconCheck
              color={transcriptLine.approved_at ? 'var(--green)' : undefined}
            />
          </Button>
          <SelectRoot
            value={transcriptLine.transcript_character_id}
            onValueChange={(e: any) => {
              handleUpdateTranscriptLine({
                id: transcriptLine.id,
                transcript_character_id: e,
              });
            }}
            disabled={!!transcriptLine.approved_at}
          >
            <SelectTrigger
              style={{
                width: 64,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
              <SelectViewport>
                {transcriptCharacters.map((c) => (
                  <SelectItem value={c.id} key={c.id}>
                    <SelectItemText>{c.name}</SelectItemText>
                    <SelectItemIndicator>
                      <IconCheck />
                    </SelectItemIndicator>
                  </SelectItem>
                ))}
              </SelectViewport>
            </SelectContent>
          </SelectRoot>
        </FlexRow>
        <div
          style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 4 }}
        >
          <OriginalText onClick={() => handleClick(false)}>
            {secondsToHumanReadable(transcriptLine.start)} -{' '}
            {secondsToHumanReadable(transcriptLine.end)}
          </OriginalText>
          {transcriptLine.uiLoading ? (
            <StaticText>{transcriptLine.text}</StaticText>
          ) : (
            <EditableDiv
              value={transcriptLine.text}
              onChange={handleChange}
              disabled={!!transcriptLine.approved_at}
              onClick={() => handleClick(true)}
              onKeyPress={handleEnter}
            />
          )}
        </div>
      </Container>
    </TranscriptLineContextMenu>
  );
};

const StaticText = styled.div`
  color: var(--text-secondary);
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 13px;
  padding: 8px;
  gap: 16px;
  box-sizing: border-box;
`;

const OriginalText = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
  cursor: pointer;
`;

const Button = styled.button`
  all: unset;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid var(--border);
  font-size: 13px;
  color: var(--text-primary);
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background: var(--hover);
  }
  :disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;
