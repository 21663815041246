import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

export const getProjectDubsDict = (state: RootState) => state.projectDubs;
export const getProjectDubs = createSelector(
  getProjectDubsDict,
  (projectDubs) => Object.values(projectDubs),
);
export const getProjectDubById = (id: string) =>
  createSelector(getProjectDubsDict, (dubs) => dubs[id]);
