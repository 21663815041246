import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserType } from 'lib/Types';

export type UserReduxType = {
  user: UserType | null;
  users: { [id: string]: UserType };
};
const initialState = { user: null, users: {} } as UserReduxType;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    upsertUsers: (
      state: UserReduxType,
      action: PayloadAction<{ id: string & Partial<UserType> }[]>,
    ) => {
      action.payload.forEach((user) => {
        state.users[user.id] = {
          ...(state.users[user.id] || {}),
          ...user,
        };
      });

      return state;
    },
    upsertUser: (
      state: UserReduxType,
      action: PayloadAction<Partial<UserType>>,
    ) => {
      state.user = { ...(state.user || {}), ...action.payload } as UserType;
      return state;
    },
    deleteUser: (state, action: PayloadAction<string>) => {
      delete state.users[action.payload];
      return state;
    },
    resetUsers: () => initialState,
  },
});

export const { upsertUsers, upsertUser, deleteUser, resetUsers } =
  userSlice.actions;
