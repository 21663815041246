import styled from 'styled-components';
import { WorkspaceType } from 'lib/Types';
import { Container, Description, Input, Label, Title } from '../ui';

type Props = {
  workspace: WorkspaceType;
};

export const WorkspaceSettings = ({ workspace }: Props) => (
  <Container>
    <Title>Workspace settings</Title>
    <div>
      <FlexRow>
        <div style={{ flex: 1 }}>
          <Label>Name</Label>
          <Input value={workspace?.name} readOnly />
        </div>
      </FlexRow>
      <Description>You can use your organization or company name</Description>
    </div>
  </Container>
);

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
