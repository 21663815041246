import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VoiceType } from 'lib/Types';

export type VoiceReduxType = { [id: string]: VoiceType };
const initialState = {} as VoiceReduxType;

export const voicesSlice = createSlice({
  name: 'voices',
  initialState,
  reducers: {
    upsertVoices: (
      state: VoiceReduxType,
      action: PayloadAction<({ id: string } & Partial<VoiceType>)[]>,
    ) => {
      action.payload.forEach((voice) => {
        state[voice.id] = {
          ...(state[voice.id] || {}),
          ...voice,
        };
      });
      return state;
    },
    deleteVoice: (state: VoiceReduxType, action: PayloadAction<string>) => {
      delete state[action.payload];
      return state;
    },
    resetVoices: () => initialState,
  },
});

export const { upsertVoices, deleteVoice, resetVoices } = voicesSlice.actions;
