import { IconCheck } from '@tabler/icons-react';
import { LanguageNames, ProjectDubType, ProjectType } from 'lib/Types';
import { getProjectDubs } from 'lib/redux/selectors/projectDubs';
import {
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { CreateProjectDubModal } from 'modules/shared/create-project-dub-modal';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  project: ProjectType;
  projectDub?: ProjectDubType;
};

export const ProjectSelect = ({ project, projectDub }: Props) => {
  const navigate = useNavigate();
  const projectDubs = useSelector(getProjectDubs).filter(
    (p) => p.project_id === project.id,
  );
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <SelectRoot
        value={projectDub?.id || 'transcript'}
        onValueChange={(value) => {
          if (value === 'transcript') {
            navigate(`/transcript/${project.id}`);
          } else {
            navigate(`/editor/${value}`);
          }
        }}
      >
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent position="popper">
          <SelectViewport>
            <SelectItem value="transcript">
              <SelectItemText>Transcript</SelectItemText>
              <SelectItemIndicator>
                <IconCheck />
              </SelectItemIndicator>
            </SelectItem>
            {projectDubs
              .sort((a, b) =>
                LanguageNames[a.lang].localeCompare(LanguageNames[b.lang]),
              )
              .map((p) => (
                <SelectItem value={p.id} key={p.id}>
                  <SelectItemText>{LanguageNames[p.lang]}</SelectItemText>
                  <SelectItemIndicator>
                    <IconCheck />
                  </SelectItemIndicator>
                </SelectItem>
              ))}
            <AddLineTrackButton onClick={() => setModalOpen(true)}>
              Add Language
            </AddLineTrackButton>
          </SelectViewport>
        </SelectContent>
      </SelectRoot>
      <CreateProjectDubModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        project={project}
      />
    </>
  );
};

// TODO this is a lil sus maybe condense with SelectItem?
const AddLineTrackButton = styled.div`
  all: unset;
  font-size: 12px;
  height: 24px;
  padding: 0 36px 0 24px;
  color: var(--text-light);
  border-radius: 2px;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;

  :hover {
    background-color: var(--hover);
  }
`;
