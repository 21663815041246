import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import React from 'react';
import { CharacterType } from 'lib/Types';
import { useCharacterApi } from 'lib/hooks/api/use-character-api';
import { renameEditableInput } from 'lib/utils/utils';

type Props = {
  character: CharacterType;
  children?: React.ReactNode;
};

export const CharacterContextMenu = ({ character, children }: Props) => {
  const { handleDeleteCharacter } = useCharacterApi();

  return (
    <ContextMenu>
      <ContextMenuTrigger>{children}</ContextMenuTrigger>
      <ContextMenuContent onClick={(e) => e.stopPropagation()}>
        <ContextMenuItem
          title="Rename"
          icon="edit"
          onSelect={() => {
            renameEditableInput(`#character-row-${character.id}`);
          }}
        />
        <ContextMenuItem
          title="Delete"
          icon="delete"
          onSelect={() => handleDeleteCharacter(character)}
        />
      </ContextMenuContent>
    </ContextMenu>
  );
};
