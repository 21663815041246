import {
  IconAdjustmentsHorizontal,
  IconPlus,
  IconX,
} from '@tabler/icons-react';
import { CharacterType } from 'lib/Types';
import {
  getWorkspaceVoices,
  getPresetVoices,
} from 'lib/redux/selectors/voices';
import _ from 'lodash';
import { Dot, FlexCol, FlexRow, Label, Tag, Title } from 'modules/shared/ui';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { VoiceContextMenu } from 'modules/shared/context-menu/voice-context-menu';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { VoiceRow } from './voice-row';

type Props = {
  character: CharacterType;
  setMode: (mode: 'create') => void;
  setEditVoiceId: (id: string) => void;
};

export const Voices = ({ character, setMode, setEditVoiceId }: Props) => {
  const presetVoices = useSelector(getPresetVoices);
  const workspaceVoices = useSelector(getWorkspaceVoices);
  const [activeTags, setActiveTags] = useState<string[]>([]);

  const presetTags = useMemo<string[]>(
    () =>
      Array.from(
        new Set(
          presetVoices
            .flatMap((v) => v.tags)
            .filter((tag): tag is string => !!tag),
        ),
      ),
    [presetVoices],
  );

  const sortedTags = useMemo(
    () =>
      [...presetTags].sort((a, b) => {
        const aIsActive = activeTags.includes(a);
        const bIsActive = activeTags.includes(b);
        return aIsActive === bIsActive ? 0 : aIsActive ? -1 : 1;
      }),
    [presetTags, activeTags],
  );

  const filteredVoices = useMemo(
    () =>
      _.filter(
        presetVoices,
        (v) =>
          _.isEmpty(activeTags) ||
          _.every(activeTags, (tag) => _.includes(v.tags, tag)),
      ).sort((a, b) => {
        const aIsActive = character.voice_data_id === a.voice_data_id;
        const bIsActive = character.voice_data_id === b.voice_data_id;
        return aIsActive === bIsActive ? 0 : aIsActive ? -1 : 1;
      }),
    [presetVoices, activeTags],
  );

  return (
    <Container>
      <FlexRow>
        <Dot
          style={{
            background: character.color,
            marginRight: '10px',
            flexShrink: 0,
          }}
        />
        <Title>{character.name}</Title>
      </FlexRow>
      <FlexCol style={{ gap: 16 }}>
        <div>
          <Title>Cloned Voice</Title>
          <Label style={{ marginTop: 4 }}>
            Clone the voice from the original video
          </Label>
        </div>
        <VoiceRow
          title="Cloned from Original"
          description="Cloned voice from the original video"
          tags={[]}
          isActive={character.voice_data_id === character.clone_voice_data_id}
          character={character}
          voice_data_id={character.clone_voice_data_id || ''}
          rightChildren={
            <HoverIcon>
              <IconAdjustmentsHorizontal
                onClick={() => {
                  setEditVoiceId(character.clone_voice_data_id || '');
                }}
              />
            </HoverIcon>
          }
        />
      </FlexCol>
      <FlexCol style={{ gap: 16 }}>
        <div>
          <Title>Workspace Voices</Title>
          <Label style={{ marginTop: 4 }}>
            Create a custom voice with your own sample audio
          </Label>
        </div>
        <VoiceContainer>
          <CardContainer onClick={() => setMode('create')}>
            <LabelHeader>Upload a new voice</LabelHeader>
            <StyledPlusCircledIcon style={{ width: 16, height: 16 }} />
          </CardContainer>
          {workspaceVoices.map((voice) => (
            <VoiceContextMenu voice={voice} key={voice.id}>
              <VoiceRow
                key={voice.id}
                character={character}
                voice_data_id={voice.voice_data_id}
                title={voice.name}
                description={voice.description}
                tags={voice.tags}
                previewUrl={voice.preview_url}
                isActive={character.voice_data_id === voice.voice_data_id}
                rightChildren={
                  <HoverIcon>
                    <IconAdjustmentsHorizontal
                      onClick={() => {
                        setEditVoiceId(voice.voice_data_id || '');
                      }}
                    />
                  </HoverIcon>
                }
              />
            </VoiceContextMenu>
          ))}
        </VoiceContainer>
      </FlexCol>
      <FlexCol style={{ gap: 16 }}>
        <div>
          <Title>Preset voices</Title>
          <Label style={{ marginTop: 4 }}>Select a preset voice to apply</Label>
        </div>
        <FlexRow style={{ gap: 8, flexWrap: 'wrap' }}>
          {activeTags.length ? (
            <Tag onClick={() => setActiveTags([])}>
              <IconX style={{ width: 11, height: 11 }} /> Clear all filters
            </Tag>
          ) : null}
          {sortedTags.map((tag) => (
            <Tag
              onClick={() => {
                if (activeTags.includes(tag)) {
                  setActiveTags(activeTags.filter((t) => t !== tag));
                } else {
                  setActiveTags([...activeTags, tag]);
                }
              }}
              isActive={activeTags.includes(tag)}
              key={tag}
            >
              {tag}
            </Tag>
          ))}
        </FlexRow>
        <VoiceContainer>
          {filteredVoices.map((voice) => (
            <VoiceRow
              key={voice.id}
              character={character}
              voice_data_id={voice.voice_data_id}
              title={voice.name}
              description={voice.description}
              tags={voice.tags}
              previewUrl={voice.preview_url}
              isActive={character.voice_data_id === voice.voice_data_id}
            />
          ))}
        </VoiceContainer>
      </FlexCol>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  gap: 32px;
  overflow: auto;
`;

const VoiceContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* This will automatically adjust the number of columns based on the container width */
  gap: 8px; /* Spacing between grid items */
`;

const StyledPlusCircledIcon = styled(IconPlus)`
  width: 24px;
  height: 24px;
`;

const CardContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px;
  background: ${({ isActive }) =>
    isActive ? 'var(--background-secondary)' : 'transparent'};
  border: ${({ isActive }) =>
    isActive ? '1px solid var(--text-light)' : '1px solid var(--border)'};
  border-radius: 4px;
  font-size: 12px;
  justify-content: space-between;
  gap: 8px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background: var(--hover);
  }
`;

const LabelHeader = styled.div`
  font-size: 13px;
  color: var(--text-primary);
`;
