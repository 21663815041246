import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActivityType } from 'lib/Types';

export type ActivityReduxType = { [id: string]: ActivityType };
const initialState = {} as ActivityReduxType;

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    upsertActivities: (
      state: ActivityReduxType,
      action: PayloadAction<({ id: string } & Partial<ActivityType>)[]>,
    ) => {
      action.payload.forEach((activity) => {
        state[activity.id] = {
          ...(state[activity.id] || {}),
          ...activity,
        };
      });
      return state;
    },
    resetActivities: () => initialState,
  },
});

export const { upsertActivities, resetActivities } = activitiesSlice.actions;
