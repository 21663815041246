import { Container } from 'modules/admin/ui';
import { FlexCol, FlexRow, Subtitle, Title } from 'modules/shared/ui';
import { TailSpin } from 'react-loader-spinner';
import { BackToolbar } from './back-toolbar';

export const ProjectBuilding = () => (
  <div style={{ height: '100%' }}>
    <BackToolbar />
    <Container style={{ height: 'calc(100% - 48px)' }}>
      <FlexCol style={{ gap: 8, textAlign: 'center' }}>
        <FlexRow style={{ gap: 8, justifyContent: 'center' }}>
          <TailSpin width={13} height={13} color="var(--text-primary)" />
          <Title>Building Project</Title>
        </FlexRow>
        <Subtitle style={{ maxWidth: 340 }}>
          Preparing your project, this may take a few minutes...
        </Subtitle>
      </FlexCol>
    </Container>
  </div>
);
