import $ from 'jquery';
import { ProjectType } from 'lib/Types';
import { TimelineEngine, TimelineRow } from 'lib/engine/engine';
import { useTranscriptLineApi } from 'lib/hooks/api/use-transcript-line-api';
import { useQueryParams } from 'lib/hooks/use-query-params';
import { getEditorSeekTime } from 'lib/redux/selectors/editor';
import { getTranscriptLinesDict } from 'lib/redux/selectors/transcriptLines';
import { getComputedColor } from 'lib/utils/utils';
import { OVERSCAN_PADDING, SCROLLBAR_SIZE } from 'modules/editor/constants';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import { TranscriptLineMenuContent } from 'modules/shared/context-menu/transcript-line-context-menu';
import { useWorkerPool } from 'modules/shared/konva/hooks/use-worker-pool';
import { KonvaMarkers } from 'modules/shared/konva/konva-markers';
import { StemAction } from 'modules/shared/konva/stem-action';
import { LineAction } from 'modules/transcript-editor/components/timeline-panel/components/line-action';
import { useActiveTranscriptLine } from 'modules/transcript-editor/hooks/use-active-transcript-line';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Group, Layer, Line, Rect, Stage } from 'react-konva';
import { useSelector } from 'react-redux';
import { useGesture } from 'react-use-gesture';

type Props = {
  project: ProjectType;
  data: TimelineRow[];
  timelineEngine: React.MutableRefObject<TimelineEngine>;
  rowHeight: number;
  dimensions: { width: number; height: number };
  sidebarRef: React.MutableRefObject<HTMLDivElement | null>;
  stageY: number;
  setStageY: (y: number) => void;
};

export const KonvaTimeline = ({
  project,
  data,
  timelineEngine,
  rowHeight,
  dimensions,
  sidebarRef,
  stageY,
  setStageY,
}: Props) => {
  const lastOffset = useRef(0);
  const lineTopPaddingPx = 2;
  const defaultPxPerSec = 10;
  const stageRef = useRef<any>(null);
  const [stageScale, setStageScale] = useState(1);
  const [stageX, setStageX] = useState(0);
  const [currTime, setCurrTime] = useState(0);
  const seekTime = useSelector(getEditorSeekTime);
  const { updateQueryParams } = useQueryParams();
  const transcriptLineDict = useSelector(getTranscriptLinesDict);
  const [contextMenuId, setContextMenuId] = useState<string | null>(null);
  const contextMenuLine = transcriptLineDict[contextMenuId || ''];
  const { handleCreateTranscriptLine } = useTranscriptLineApi();
  const { activeTranscriptLine } = useActiveTranscriptLine();
  const scrollHeight = rowHeight * (data.length + 1) + SCROLLBAR_SIZE;
  const enqueueTask = useWorkerPool(4);

  const checkFocused = () => {
    const dialog = $('[role="dialog"]');
    return !dialog.length;
  };

  const handleZoom = (newScale: number) => {
    setStageScale(newScale);
    const newStageX =
      -seekTime * newScale * defaultPxPerSec + dimensions.width / 2;
    setStageX(Math.min(0, newStageX));
  };

  useHotkeys(
    'mod+=,mod+NumpadAdd',
    (e) => {
      if (!checkFocused()) return;
      handleZoom(stageScale * 1.5);
      e.preventDefault();
      e.stopPropagation();
    },
    { enableOnFormTags: ['INPUT'], enableOnContentEditable: true },
    [checkFocused, stageScale],
  );

  useHotkeys(
    'mod+minus,mod+NumpadSubtract',
    (e) => {
      if (!checkFocused()) return;
      handleZoom(stageScale * 0.5);
      e.preventDefault();
      e.stopPropagation();
    },
    { enableOnFormTags: ['INPUT'], enableOnContentEditable: true },
    [checkFocused, stageScale],
  );

  useEffect(() => {
    setCurrTime(seekTime);
  }, [seekTime]);

  // Center the cursor when the active line changes
  useEffect(() => {
    if (!activeTranscriptLine) return;
    const time = activeTranscriptLine?.start;

    // Check if the cursor is not visible
    const cursorPosition = time * stageScale * defaultPxPerSec;
    if (
      cursorPosition < -stageX ||
      cursorPosition > -stageX + dimensions.width
    ) {
      // Calculate the new stage x-position to center the cursor
      const newStageX =
        -time * stageScale * defaultPxPerSec + dimensions.width / 2;
      setStageX(Math.min(0, newStageX));
    }
  }, [activeTranscriptLine?.id]);

  useEffect(() => {
    const newY = Math.min(
      0,
      Math.max(stageY, dimensions.height - scrollHeight),
    );
    setStageY(newY);
  }, [dimensions.height]);

  useEffect(() => {
    // Update playhead while the video plays
    const setTickTimeHandler = timelineEngine.current.on(
      'setTimeByTick',
      ({ time }) => {
        setCurrTime(time);
      },
    );
    // Update playhead on pause
    const setTimeHandler = timelineEngine.current.on(
      'afterSetTime',
      ({ time }) => setCurrTime(time),
    );
    return () => {
      timelineEngine.current.off(setTickTimeHandler);
      timelineEngine.current.off(setTimeHandler);
    };
  }, [timelineEngine.current]);

  useGesture(
    {
      onPinch: (state) => {
        const {
          offset: [d],
        } = state;
        const scaleBy = 1.07;
        const stage = stageRef.current as any;
        const pointer = stage.getPointerPosition();
        const oldScale = stageScale;
        const mousePointTo = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };

        const newScale =
          d > lastOffset.current ? oldScale * scaleBy : oldScale / scaleBy;
        setStageScale(newScale);
        const newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };
        setStageX(Math.min(newPos.x, 0));
        lastOffset.current = d;
      },
      onWheel: ({ delta: [dx, dy], event }) => {
        event.preventDefault();
        // Panning speed, adjust as necessary
        const speed = 2;
        const newX = stageX - dx * speed;
        setStageX(Math.min(0, newX));

        let newY = stageY - dy * speed;
        newY = Math.min(0, Math.max(newY, dimensions.height - scrollHeight));
        setStageY(newY);
        sidebarRef.current?.scrollTo({
          top: -newY,
        });
      },
    },
    {
      domTarget: stageRef, // Use the stageRef as the target
      eventOptions: { passive: false }, // Ensure the event is not passive
    },
  );

  const handleMouseDownVertical = (e: any) => {
    const stage = e.target.getStage();
    const initialMousePos = stage.getPointerPosition().y;
    const initialStageY = stageY;

    const handleMouseMove = () => {
      const currentMousePos = stage.getPointerPosition().y;
      const mouseDelta = currentMousePos - initialMousePos;
      const newStageY = Math.min(
        0,
        Math.max(
          initialStageY - (mouseDelta / dimensions.height) * scrollHeight,
          dimensions.height - scrollHeight,
        ),
      );
      setStageY(newStageY);
      sidebarRef.current?.scrollTo({
        top: -newStageY,
      });
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDownHorizontal = (e: any) => {
    const stage = e.target.getStage();
    const initialMousePos = stage.getPointerPosition().x;
    const initialStageX = stageX;

    const handleMouseMove = () => {
      const currentMousePos = stage.getPointerPosition().x;
      const mouseDelta = currentMousePos - initialMousePos;
      const newStageX = Math.min(
        0,
        initialStageX -
          (mouseDelta / (dimensions.width - SCROLLBAR_SIZE)) * totalPixelWidth,
      );
      setStageX(newStageX);
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const scrollbarHeight = dimensions.height - rowHeight - SCROLLBAR_SIZE;

  const scrollbarYHeight = Math.min(
    scrollbarHeight,
    scrollbarHeight / scrollHeight,
  );

  const visibleTime = dimensions.width / (stageScale * defaultPxPerSec);
  const percentVisible = visibleTime / (project.duration || 1);
  const scrollbarWidth =
    Math.max(0.05, Math.min(1, percentVisible)) *
    (dimensions.width - SCROLLBAR_SIZE);

  // also calculate percentage that stageX is from total possible width
  const totalPixelWidth =
    (project.duration || 1) * defaultPxPerSec * stageScale;
  const percentX = -stageX / totalPixelWidth;

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <Stage
          ref={stageRef}
          width={dimensions.width}
          height={dimensions.height}
          x={stageX}
          onContextMenu={(e) => {
            const actionId = e.target.parent?.attrs?.id;
            const line = transcriptLineDict[actionId || ''];
            if (!actionId || !line) {
              setContextMenuId(null);
              return;
            }
            setContextMenuId(actionId);
            updateQueryParams({ activeId: actionId });
          }}
        >
          <Layer scaleY={1}>
            {data.map((row, idx) => {
              const rowTop = (idx + 1) * rowHeight;
              const rowBottom = rowHeight;
              return (
                <Group
                  id={row.id}
                  key={row.id}
                  y={rowTop + stageY}
                  height={rowHeight}
                  onDblClick={(e) => {
                    if (row.type !== 'character') return;
                    const stage = e.target.getStage();
                    if (!stage) return;
                    const pointerPosition = stage.getPointerPosition();
                    if (!pointerPosition) return;
                    const time =
                      (pointerPosition.x - stageX) /
                      (stageScale * defaultPxPerSec);
                    handleCreateTranscriptLine({
                      text: 'Placeholder',
                      transcript_character_id: row.id,
                      start: time,
                      end: time + 1,
                      project_id: project.id,
                    });
                  }}
                >
                  <Rect
                    x={-stageX}
                    y={0}
                    width={dimensions.width + -stageX}
                    height={rowHeight}
                    fill="transparent"
                  />
                  <Line
                    points={[
                      0,
                      rowBottom,
                      dimensions.width + -stageX,
                      rowBottom,
                    ]}
                    stroke={getComputedColor('var(--border)')}
                    strokeWidth={1}
                  />
                  {row.actions.map((action) => {
                    // Check if the clip is within the visible area of the stage
                    const actionWidth =
                      (action.end - action.start) *
                      defaultPxPerSec *
                      stageScale;
                    const clipRect = {
                      x: action.start * defaultPxPerSec * stageScale,
                      y: rowTop,
                      width: actionWidth,
                      height: rowHeight,
                    };
                    const isVisible =
                      clipRect.x + stageX <=
                        dimensions.width + OVERSCAN_PADDING &&
                      clipRect.x + clipRect.width + stageX >=
                        0 - OVERSCAN_PADDING;

                    if (!isVisible) return null;

                    if (action.type === 'stem') {
                      return (
                        <StemAction
                          key={action.id}
                          action={action}
                          stageScale={stageScale}
                          defaultPxPerSec={defaultPxPerSec}
                          rowHeight={rowHeight}
                          rowTop={rowTop}
                          lineTopPaddingPx={lineTopPaddingPx}
                          stageX={stageX}
                          stageY={stageY}
                          dimensions={dimensions}
                          enqueueTask={enqueueTask}
                        />
                      );
                    }

                    // TODO: add clip
                    return (
                      <LineAction
                        key={action.id}
                        action={action}
                        stageScale={stageScale}
                        defaultPxPerSec={defaultPxPerSec}
                        rowHeight={rowHeight}
                        rowTop={rowTop}
                        lineTopPaddingPx={lineTopPaddingPx}
                        stageX={stageX}
                        stageY={stageY}
                        dimensions={dimensions}
                        enqueueTask={enqueueTask}
                      />
                    );
                  })}
                </Group>
              );
            })}
            <KonvaMarkers
              timelineEngine={timelineEngine}
              stageX={stageX}
              stageScale={stageScale}
              width={dimensions.width}
              defaultPxPerSec={defaultPxPerSec}
              rowHeight={rowHeight}
              scaleTimesteps={5}
            />
            <Group x={currTime * stageScale * defaultPxPerSec} y={rowHeight}>
              <Line
                points={[0, 0, 0, dimensions.height]}
                stroke="#5297FE"
                strokeWidth={1}
              />
              <Rect
                x={-8 / 2}
                y={-12}
                width={8}
                height={12}
                fill="#5297FE"
                cornerRadius={2}
              />
            </Group>
            <Group
              x={-stageX + dimensions.width - SCROLLBAR_SIZE}
              y={rowHeight}
              width={SCROLLBAR_SIZE}
              height={scrollbarHeight}
            >
              <Rect
                x={0}
                width={SCROLLBAR_SIZE}
                height={scrollbarHeight}
                stroke={getComputedColor('--border')}
                fill={getComputedColor('--background')}
              />
              <Rect
                x={0}
                y={Math.abs(stageY / scrollHeight) * scrollbarHeight}
                width={SCROLLBAR_SIZE}
                height={scrollbarYHeight * dimensions.height}
                fill={getComputedColor('--border')}
                onMouseDown={handleMouseDownVertical}
              />
            </Group>
            <Group
              x={-stageX}
              y={dimensions.height - SCROLLBAR_SIZE}
              width={dimensions.width - SCROLLBAR_SIZE}
              height={SCROLLBAR_SIZE}
            >
              <Rect
                width={dimensions.width - SCROLLBAR_SIZE}
                height={SCROLLBAR_SIZE}
                stroke={getComputedColor('--border')}
                fill={getComputedColor('--background')}
              />
              <Rect
                x={Math.max(
                  0,
                  Math.min(
                    dimensions.width - SCROLLBAR_SIZE - scrollbarWidth,
                    percentX * (dimensions.width - SCROLLBAR_SIZE),
                  ),
                )}
                width={scrollbarWidth}
                height={SCROLLBAR_SIZE}
                fill={getComputedColor('--border')}
                onMouseDown={handleMouseDownHorizontal}
              />
            </Group>
          </Layer>
        </Stage>
      </ContextMenuTrigger>
      {contextMenuLine ? (
        <TranscriptLineMenuContent transcriptLine={contextMenuLine} />
      ) : (
        <ContextMenuContent>
          <ContextMenuItem
            title="Zoom in"
            icon="zoom_in"
            onSelect={() => handleZoom(stageScale * 1.5)}
          />
          <ContextMenuItem
            title="Zoom out"
            icon="zoom_out"
            onSelect={() => handleZoom(stageScale * 0.5)}
          />
        </ContextMenuContent>
      )}
    </ContextMenu>
  );
};
