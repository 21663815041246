import {
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
} from '@tabler/icons-react';
import { useWaveform } from 'lib/hooks/useWaveform';
import { HoverIcon } from 'modules/radix/HoverIcon';
import React, { useEffect, useRef } from 'react';

type Props = {
  audioUrl: string;
  style?: any;
  waveformHeight?: number;
};

export function Waveform({ audioUrl, style, waveformHeight }: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const wavesurfer = useWaveform({
    height: waveformHeight ?? 120,
    audioUrl,
    containerRef,
  });
  const [isPlaying, setIsPlaying] = React.useState(false);

  useEffect(() => {
    if (wavesurfer) {
      const handleFinish = () => {
        setIsPlaying(false);
      };
      wavesurfer.on('finish', handleFinish);
      return () => {
        wavesurfer.un('finish', handleFinish);
      };
    }
  }, [wavesurfer]);

  const togglePlay = () => {
    wavesurfer?.playPause();
    setIsPlaying(!isPlaying);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div
        style={{
          width: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <HoverIcon onClick={togglePlay}>
          {isPlaying ? <IconPlayerPauseFilled /> : <IconPlayerPlayFilled />}
        </HoverIcon>
      </div>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <div style={{ ...{ height: '100%' }, ...style }} ref={containerRef} />
      </div>
    </div>
  );
}
