import { CharacterType } from 'lib/Types';
import api from 'lib/api';
import { deleteCharacter, upsertCharacters } from 'lib/redux/slices/characters';
import { upsertLines } from 'lib/redux/slices/lines';
import { useDispatch } from 'react-redux';

export const useCharacterApi = () => {
  const dispatch = useDispatch();

  const handleCreateCharacter = async (d: { project_dub_id: string }) => {
    try {
      const { data } = await api.characters.create(d);
      dispatch(upsertCharacters([{ ...data.character }]));
    } catch (err) {
      alert('Error creating character');
    }
  };

  const handleUpdateCharacter = async (
    character: { id: string } & Partial<CharacterType>,
  ) => {
    try {
      dispatch(upsertCharacters([{ ...character }]));
      await api.characters.update(character.id, character);
    } catch (err) {
      alert('Error updating character');
    }
  };

  const handleDeleteCharacter = async (character: { id: string }) => {
    try {
      await api.characters.delete(character.id);
      dispatch(deleteCharacter(character.id));
    } catch (err: any) {
      // If a user tries to delete a char with lines, present an informative error
      if (err.response && err.response.data && err.response.data.detail) {
        alert(`Error deleting character: ${err.response.data.detail}`);
      } else {
        alert('Error deleting character');
      }
    }
  };

  const handleUpdateVoice = async (
    id: string,
    params: { voice_data_id: string },
  ) => {
    try {
      dispatch(upsertCharacters([{ id, isLoading: true }]));
      const { data } = await api.characters.updateElVoice(id, params);
      dispatch(upsertCharacters([data.character]));
      dispatch(upsertLines(data.lines));
    } catch (err) {
      alert('Error updating voice');
    } finally {
      dispatch(upsertCharacters([{ id, isLoading: false }]));
    }
  };

  return {
    handleCreateCharacter,
    handleUpdateCharacter,
    handleUpdateVoice,
    handleDeleteCharacter,
  };
};
