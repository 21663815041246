import { signOut } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { auth } from 'lib/utils/firebase';
import { resetProjectUsers } from 'lib/redux/slices/projectUsers';
import { resetProjects } from 'lib/redux/slices/projects';
import { resetSettings } from 'lib/redux/slices/settings';
import { resetUsers } from 'lib/redux/slices/user';
import { resetFonts } from 'lib/redux/slices/fonts';
import { resetWorkspaces } from 'lib/redux/slices/workspaces';
import { resetWorkspaceUsers } from 'lib/redux/slices/workspaceUsers';
import { resetActivities } from 'lib/redux/slices/activities';
import { resetCaptions } from 'lib/redux/slices/captions';
import { resetCharacters } from 'lib/redux/slices/characters';
import { resetClips } from 'lib/redux/slices/clips';
import { resetClipTracks } from 'lib/redux/slices/clipTracks';
import { resetEditor } from 'lib/redux/slices/editor';
import { resetExportJobs } from 'lib/redux/slices/exportJobs';
import { resetLines } from 'lib/redux/slices/lines';
import { resetProjectDubs } from 'lib/redux/slices/projectDubs';
import { resetTranscriptCharacters } from 'lib/redux/slices/transcriptCharacters';
import { resetTranscriptLines } from 'lib/redux/slices/transcriptLines';
import { resetToast } from 'lib/redux/slices/toast';
import { resetVoices } from 'lib/redux/slices/voices';

export const useLogout = () => {
  const dispatch = useDispatch();
  const handleLogout = async () => {
    await signOut(auth);
    dispatch(resetActivities());
    dispatch(resetCaptions());
    dispatch(resetCharacters());
    dispatch(resetClips());
    dispatch(resetClipTracks());
    dispatch(resetEditor());
    dispatch(resetExportJobs());
    dispatch(resetFonts());
    dispatch(resetLines());
    dispatch(resetProjects());
    dispatch(resetProjectDubs());
    dispatch(resetProjectUsers());
    dispatch(resetSettings());
    dispatch(resetTranscriptCharacters());
    dispatch(resetTranscriptLines());
    dispatch(resetToast());
    dispatch(resetUsers());
    dispatch(resetVoices());
    dispatch(resetWorkspaces());
    dispatch(resetWorkspaceUsers());
  };
  return { handleLogout };
};
