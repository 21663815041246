import { IconGridDots, IconHome, IconWorld } from '@tabler/icons-react';
import { getUserMe } from 'lib/redux/selectors/users';
import { NavRoutes } from 'lib/routes';
import { CreateProjectModal } from 'modules/projects/components/create-project-modal';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CreateProjectButton } from './components/create-project-button';
import { Profile } from './components/profile';
import { SidebarRow } from './components/sidebar-row';
import { WorkspaceSelect } from './components/workspace-select';

const Sidebar = () => {
  const user = useSelector(getUserMe);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  return (
    <Container>
      <FlexColumn>
        <FlexRow>
          <WorkspaceSelect />
          <Profile />
        </FlexRow>
        <TightFlexColumn>
          <CreateProjectButton />
          <div>
            <Label>WORKSPACE</Label>
            <SidebarRow
              title="Projects"
              icon={<IconHome />}
              isActive={pathname === NavRoutes.Projects}
              onClick={() => navigate(NavRoutes.Projects)}
            />
            <SidebarRow
              title="Voices"
              icon={<IconGridDots />}
              isActive={pathname === NavRoutes.Voices}
              onClick={() => navigate(NavRoutes.Voices)}
            />
          </div>
          {user?.role === 'ADMIN' ? (
            <div>
              <Label>ADMIN</Label>
              <SidebarRow
                title="All Projects"
                icon={<IconWorld />}
                isActive={pathname === NavRoutes.AdminProjects}
                onClick={() => navigate(NavRoutes.AdminProjects)}
              />
            </div>
          ) : null}
          <CreateProjectModal
            isOpen={isCreateModalOpen}
            setOpen={setCreateModalOpen}
          />
        </TightFlexColumn>
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
  height: 100%;
  box-sizing: border-box;
  padding: 12px 8px;
  background: var(--background-secondary);
  color: var(--text-secondary);
  font-size: 13px;
  font-weight: 400;
  font-family: Inter;
  border-right: 1px solid var(--border);
  box-sizing: border-box;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TightFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Label = styled.div`
  color: var(--text-light);
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 8px;
  margin-left: 4px;
`;

export default Sidebar;
