import { ClipType } from 'lib/Types';
import { AxiosProgressEvent } from 'axios';
import axios from './axios';

export const Clips = {
  create: ({
    file,
    project_id,
    project_dub_id,
    onUploadProgress,
  }: {
    file: File;
    project_id: string;
    project_dub_id: string;
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('project_id', project_id);
    formData.append('project_dub_id', project_dub_id);
    return axios.post('/clips', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  },
  update: (id: string, data: Partial<ClipType>) =>
    axios.put(`/clips/${id}`, data),
  delete: (id: string) => axios.delete(`/clips/${id}`),
  split: (clipId: string, splitTimelineTime: number) =>
    axios.post(`clips/${clipId}/split`, {
      split_timeline_time: splitTimelineTime,
    }),
};
