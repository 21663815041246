import { IconPlus } from '@tabler/icons-react';
import { getWorkspaceVoices } from 'lib/redux/selectors/voices';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Label, Title } from 'modules/shared/ui';
import { useState } from 'react';
import { CreateVoice } from 'modules/editor/components/character-modal/components/create-voice';
import { Dialog, DialogContent } from 'modules/radix/Dialog';
import { VoiceRow } from './voice-row';

const Voices = () => {
  const voices = useSelector(getWorkspaceVoices);
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  return (
    <>
      <Container>
        <div>
          <Title>Workspace Voices</Title>
          <Label style={{ marginTop: 4 }}>
            Create a custom voice with your own sample audio
          </Label>
        </div>
        <VoiceGrid>
          <CardContainer onClick={() => setIsCreateOpen(true)}>
            <LabelHeader>Upload a new voice</LabelHeader>
            <StyledPlusCircledIcon style={{ width: 16, height: 16 }} />
          </CardContainer>
          {voices.map((v) => (
            <VoiceRow voice={v} key={v.id} />
          ))}
        </VoiceGrid>
      </Container>
      <Dialog open={isCreateOpen} onOpenChange={setIsCreateOpen}>
        <DialogContent
          style={{
            padding: 0,
            maxHeight: 700,
            height: '100%',
            background: 'var(--background)',
          }}
        >
          <CreateVoice onClose={() => setIsCreateOpen(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px;
`;

const VoiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* This will automatically adjust the number of columns based on the container width */
  gap: 8px; /* Spacing between grid items */
`;

const StyledPlusCircledIcon = styled(IconPlus)`
  width: 24px;
  height: 24px;
`;

const CardContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px;
  background: ${({ isActive }) =>
    isActive ? 'var(--background-secondary)' : 'transparent'};
  border: ${({ isActive }) =>
    isActive ? '1px solid var(--text-light)' : '1px solid var(--border)'};
  border-radius: 4px;
  font-size: 12px;
  justify-content: space-between;
  gap: 8px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  color: var(--text-primary);
  &:hover {
    background: var(--hover);
  }
`;

const LabelHeader = styled.div`
  font-size: 13px;
  color: var(--text-primary);
`;

export default Voices;
