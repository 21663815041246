import { ProjectType } from 'lib/Types';
import api from 'lib/api';
import { upsertProjectDubs } from 'lib/redux/slices/projectDubs';
import { useDispatch } from 'react-redux';

export const useProjectApi = () => {
  const dispatch = useDispatch();

  const handleUpdateProject = async (
    project: { id: string } & Partial<ProjectType>,
  ) => {
    try {
      const { data } = await api.projects.update(project.id, project);
      dispatch(upsertProjectDubs([data.project]));
    } catch (error) {
      alert('Error saving project dub');
    }
  };

  return {
    handleUpdateProject,
  };
};
