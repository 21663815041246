import { LanguageNames, ProjectType } from 'lib/Types';
import { getProjectDubs } from 'lib/redux/selectors/projectDubs';
import { Subtitle } from 'modules/projects/components/ui';
import { useSelector } from 'react-redux';

type Props = {
  project: ProjectType;
};

export const ProjectLanguage = ({ project }: Props) => {
  const projectDubs = useSelector(getProjectDubs).filter(
    (pd) => pd.project_id === project.id,
  );

  return (
    <Subtitle>
      {projectDubs
        .map((pd) => LanguageNames[pd.lang])
        .sort()
        .join(', ')}
    </Subtitle>
  );
};
