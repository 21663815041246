export const secondsToHumanReadable = (seconds: number): string => {
  // Convert to int to remove decimal points
  seconds = Math.floor(seconds);

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(seconds / 3600);
  const remainder = seconds % 3600;
  const minutes = Math.floor(remainder / 60);
  seconds = remainder % 60;

  // Return the formatted string
  return `${hours.toString().padStart(1, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

// Define the function signature with input and return types
export const getAudioFormatExt = (mediaType: string): string => {
  // Check if the mediaType is one of the simple string formats
  if (['wav', 'aac', 'mp3', 'ogg', 'flac', 'opus'].includes(mediaType)) {
    return mediaType;
  }

  // Check against known MIME types
  switch (mediaType) {
    case 'audio/aac':
      return 'aac';
    case 'audio/mpeg':
      return 'mp3';
    case 'audio/wave':
    case 'audio/wav':
      return 'wav';
    case 'audio/ogg':
      return 'ogg';
    case 'audio/flac':
      return 'flac';
    case 'audio/opus':
      return 'opus';
    default:
      throw new Error(`Unsupported content type ${mediaType}`);
  }
};
