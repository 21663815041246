import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogClose,
} from 'modules/radix/Dialog';
import styled from 'styled-components';
import {
  LanguageEnum,
  LanguageNames,
  ProjectType,
  VoiceEngineType,
} from 'lib/Types';
import { TailSpin } from 'react-loader-spinner';
import { getUserMe } from 'lib/redux/selectors/users';
import {
  SelectRoot,
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { getProjectDubs } from 'lib/redux/selectors/projectDubs';
import api from 'lib/api';
import { upsertProjectDubs } from 'lib/redux/slices/projectDubs';
import { useNavigate } from 'react-router-dom';
import { upsertLines } from 'lib/redux/slices/lines';
import { Button, ErrorMessage } from 'modules/shared/ui';
import { upsertCharacters } from 'lib/redux/slices/characters';
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react';

type Props = {
  project: ProjectType;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
};

export const CreateProjectDubModal = ({ project, isOpen, setOpen }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUserMe);
  const [isLoading, setLoading] = useState(false);
  const [lang, setLang] = useState<LanguageEnum | null>(null);
  const [pivotDubId, setPivotDubId] = useState<string | 'none'>('none');
  const [voiceEngine, setVoiceEngine] = useState<VoiceEngineType>(
    VoiceEngineType.ELEVENLABS,
  );

  const [error, setError] = useState<string | null>(null);
  const projectDubs = useSelector(getProjectDubs).filter(
    (p) => p.project_id === project.id,
  );
  const projectLangs = projectDubs.map((p) => p.lang);

  const handleCreate = async () => {
    if (!lang) return;
    setLoading(true);
    try {
      const { data } = await api.projectDubs.create({
        project_id: project.id,
        lang,
        pivot_project_dub_id: pivotDubId === 'none' ? undefined : pivotDubId,
        voice_engine: voiceEngine,
      });
      dispatch(upsertProjectDubs([data.project_dub]));
      dispatch(upsertLines(data.lines));
      dispatch(upsertCharacters(data.characters));
      navigate(`/editor/${data.project_dub.id}`);
      setOpen(false);
      setError(null);
    } catch (err: any) {
      console.error(err);
      setError(err?.response?.data?.detail || 'Error creating project');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent>
        <Container>
          <DialogTitle>Create Project</DialogTitle>
          <div>
            <Label>To Language</Label>
            <SelectRoot
              value={lang || undefined}
              onValueChange={(v) => setLang(v as any)}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectViewport>
                  {Object.entries(LanguageEnum)
                    .sort((a, b) =>
                      LanguageNames[a[1]].localeCompare(LanguageNames[b[1]]),
                    )
                    .map(([k, v]) => (
                      <SelectItem
                        value={v}
                        disabled={projectLangs.includes(v)}
                        key={k}
                      >
                        <SelectItemText>{LanguageNames[v]}</SelectItemText>
                        <SelectItemIndicator>
                          <IconCheck />
                        </SelectItemIndicator>
                      </SelectItem>
                    ))}
                </SelectViewport>
              </SelectContent>
            </SelectRoot>
          </div>
          <div
            style={
              !projectDubs.length ? { opacity: 0.3, pointerEvents: 'none' } : {}
            }
          >
            <Label>Pivot Language</Label>
            <SelectRoot
              value={pivotDubId}
              onValueChange={(v) => setPivotDubId(v as any)}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectViewport>
                  <SelectItem value="none">
                    <SelectItemText>No pivot langauge</SelectItemText>
                    <SelectItemIndicator>
                      <IconCheck />
                    </SelectItemIndicator>
                  </SelectItem>
                  {Object.values(projectDubs)
                    .sort((a, b) => a.lang.localeCompare(b.lang))
                    .map((v) => (
                      <SelectItem value={v.id} key={v.id}>
                        <SelectItemText>{v.lang}</SelectItemText>
                        <SelectItemIndicator>
                          <IconCheck />
                        </SelectItemIndicator>
                      </SelectItem>
                    ))}
                </SelectViewport>
              </SelectContent>
            </SelectRoot>
          </div>
          <div>
            {user?.role === 'ADMIN' && (
              <>
                <Label>Voice Engine</Label>
                <SelectRoot
                  value={voiceEngine}
                  onValueChange={(v) => {
                    setVoiceEngine(v as any);
                  }}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectViewport>
                      <SelectItem value="DEEPTUNE">
                        <SelectItemText>Deeptune</SelectItemText>
                        <SelectItemIndicator>
                          <IconCheck />
                        </SelectItemIndicator>
                      </SelectItem>
                      <SelectItem value="ELEVENLABS">
                        <SelectItemText>EL</SelectItemText>
                        <SelectItemIndicator>
                          <IconCheck />
                        </SelectItemIndicator>
                      </SelectItem>
                    </SelectViewport>
                  </SelectContent>
                </SelectRoot>
              </>
            )}
          </div>

          <Button onClick={handleCreate} disabled={!lang || !pivotDubId}>
            {isLoading ? (
              <TailSpin width={14} height={14} color="white" />
            ) : (
              'Create Project'
            )}
          </Button>
          {error ? (
            <ErrorMessage>
              <IconAlertTriangle style={{ width: 13, height: 13 }} />
              {error}
            </ErrorMessage>
          ) : null}
          <DialogClose asChild />
        </Container>
      </DialogContent>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Label = styled.div`
  font-size: 12px;
  color: var(--text-secondary);
`;
