import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogClose } from 'modules/radix/Dialog';
import styled from 'styled-components';
import { ProjectType } from 'lib/Types';
import { Button, FlexRow, Label, Title } from 'modules/shared/ui';
import { EditableDnd } from 'modules/shared/editable-components/editable-dnd';
import api from 'lib/api';
import { TailSpin } from 'react-loader-spinner';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { IconChevronLeft } from '@tabler/icons-react';
import { AlertDialog } from 'modules/radix/AlertDialog';
import { useDispatch } from 'react-redux';
import {
  deleteProjectTranscriptLines,
  upsertTranscriptLines,
} from 'lib/redux/slices/transcriptLines';
import { red } from '@radix-ui/colors';
import { secondsToHumanReadable } from 'lib/utils/audio';
import {
  deleteProjectTranscriptCharacters,
  upsertTranscriptCharacters,
} from 'lib/redux/slices/transcriptCharacters';

type Props = {
  project: ProjectType;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
};

export const ImportTranscriptModal = ({ project, isOpen, setOpen }: Props) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [parsedLines, setParsedLines] = useState<
    | {
        start: number;
        end: number;
        original_text: string;
        translated_text: string;
        speaker: string;
      }[]
    | null
  >(null);

  const handlePreviewTranscript = async () => {
    if (!file) return;
    try {
      setLoading(true);
      const { data } = await api.projects.previewTranscript({
        id: project.id,
        file,
      });
      setParsedLines(data.parsed_lines);
    } catch (e) {
      setParsedLines(null);
      alert(e);
    } finally {
      setLoading(false);
    }
  };

  const handleImportTranscript = async () => {
    if (!file || !parsedLines?.length) return;
    try {
      setIsImportLoading(true);
      const { data } = await api.projects.importTranscript({
        id: project.id,
        parsed_lines: parsedLines,
        file,
      });
      dispatch(deleteProjectTranscriptCharacters(project.id));
      dispatch(deleteProjectTranscriptLines(project.id));
      dispatch(upsertTranscriptCharacters(data.transcript_characters));
      dispatch(upsertTranscriptLines(data.transcript_lines));
      setOpen(false);
    } catch (e) {
      alert(e);
    } finally {
      setIsImportLoading(false);
    }
  };

  const reset = () => {
    setFile(null);
    setParsedLines(null);
    setLoading(false);
    setIsImportLoading(false);
    setIsConfirmOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  const getBody = () => {
    if (parsedLines) {
      return (
        <>
          <FlexRow style={{ gap: 8 }}>
            <HoverIcon onClick={() => reset()}>
              <IconChevronLeft />
            </HoverIcon>
            <Title>Review Transcript</Title>
          </FlexRow>
          <div
            style={{
              flex: 1,
              overflow: 'auto',
              border: '1px solid var(--border)',
              background: 'var(--background-secondary)',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                padding: 16,
              }}
            >
              {parsedLines.length === 0 ? <div>No lines found</div> : null}
              {parsedLines.map((line) => (
                <div>
                  <Label>
                    {secondsToHumanReadable(line.start)} -{' '}
                    {secondsToHumanReadable(line.end)}
                  </Label>
                  <div>
                    {line.speaker ? `${line.speaker}: ` : ''}
                    {line.original_text}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <AlertDialog
            title="Are you sure you want to import this transcript?"
            description="Importing this transcript with overwrite and permanently erase all current transcript lines and speakers (even ones you've approved)."
            isLoading={isImportLoading}
            onConfirm={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleImportTranscript();
            }}
            open={isConfirmOpen}
            onOpenChange={setIsConfirmOpen}
            confirmTitle={
              isImportLoading ? (
                <TailSpin width={12} height={12} color={red.red11} />
              ) : (
                'Confirm'
              )
            }
          >
            <Button disabled={!parsedLines.length}>Import Transcript</Button>
          </AlertDialog>
        </>
      );
    }
    return (
      <>
        <Title>Import Transcript</Title>
        <div>
          <Label style={{ marginBottom: 4 }}>Upload transcript</Label>
          <EditableDnd file={file} onDropFile={setFile} />
        </div>
        <Button
          onClick={() => {
            setParsedLines(null);
            handlePreviewTranscript();
          }}
        >
          {isLoading ? (
            <TailSpin width={16} height={16} color="white" />
          ) : (
            'Next'
          )}
        </Button>
      </>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent style={{ maxWidth: 800, maxHeight: 700, height: '100%' }}>
        <Container>{getBody()}</Container>
        <DialogClose asChild />
      </DialogContent>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;
`;
