import { Dialog, DialogContent, DialogTitle } from 'modules/radix/Dialog';
import { FlexCol } from 'modules/shared/ui';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';

type Props = {
  captionsExportProgress: number | null;
};

export const CaptionExportDialog = ({ captionsExportProgress }: Props) => (
  <Dialog open={captionsExportProgress !== null}>
    <DialogContent style={{ width: '400px' }}>
      <Container>
        <TailSpin color="white" />
        <DialogTitle>Preparing export...</DialogTitle>
        <FlexCol
          style={{ alignItems: 'center', color: 'var(--text-secondary)' }}
        >
          <div>Closing this page will cancel your export.</div>
        </FlexCol>
        <ProgressBar>
          <ProgressBarIndicator
            style={{ width: `${captionsExportProgress}%` }}
          />
        </ProgressBar>
      </Container>
    </DialogContent>
  </Dialog>
);

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const ProgressBar = styled.div`
  height: 8px;
  border: 1px solid var(--border);
  border-radius: 4px;
  width: 100%;
`;

const ProgressBarIndicator = styled.div`
  height: 100%;
  background: var(--text-primary);
  border-radius: 4px;
  transition: width 1.5s ease;
`;
