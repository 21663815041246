import {
  IconColorSwatch,
  IconSettings,
  IconUserCircle,
  IconUsers,
} from '@tabler/icons-react';
import { getSettingsOpen } from 'lib/redux/selectors/settings';
import { getUserMe } from 'lib/redux/selectors/users';
import { setSettingsOpen } from 'lib/redux/slices/settings';
import { Dialog, DialogContent } from 'modules/radix/Dialog';
import {
  SidebarContainer,
  SidebarLabel,
} from 'modules/shared/sidebar-modal/ui';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getWorkspacesDict } from 'lib/redux/selectors/workspaces';
import { SidebarRow } from '../sidebar-modal/sidebar-row';
import { Experience } from './components/experience';
import { Members } from './components/members';
import { Profile } from './components/profile';
import { WorkspaceSettings } from './components/workspace-settings';
import { WorkspaceMembers } from './components/workspace-members';

export const SettingsModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getSettingsOpen);
  const setOpen = (open: boolean) => dispatch(setSettingsOpen(open));
  const [tab, setTab] = useState<
    | 'profile'
    | 'experience'
    | 'members'
    | 'workspace-settings'
    | 'workspace-members'
  >('profile');
  const user = useSelector(getUserMe);
  const activeWorkspace =
    useSelector(getWorkspacesDict)[user?.active_workspace_id || ''];

  useHotkeys(
    'mod+comma',
    (e) => {
      dispatch(setSettingsOpen(!isOpen));
      e.preventDefault();
    },
    { enableOnFormTags: ['INPUT'], enableOnContentEditable: true },
    [isOpen],
  );

  const getBody = () => {
    switch (tab) {
      case 'profile':
        return <Profile />;
      case 'experience':
        return <Experience />;
      case 'members':
        return <Members />;
      case 'workspace-settings':
        return activeWorkspace ? (
          <WorkspaceSettings workspace={activeWorkspace} />
        ) : null;
      case 'workspace-members':
        return activeWorkspace ? (
          <WorkspaceMembers workspace={activeWorkspace} />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent
        style={{
          maxWidth: 1100,
          padding: 0,
          maxHeight: 700,
          height: '100%',
          background: 'var(--background)',
        }}
      >
        <Container>
          <SidebarContainer>
            <div>
              <SidebarLabel style={{ marginTop: 8 }}>User</SidebarLabel>
              <SidebarRow
                title="My Account"
                icon={<IconUserCircle />}
                onClick={() => setTab('profile')}
                isActive={tab === 'profile'}
              />
              <SidebarRow
                title="Experience"
                icon={<IconColorSwatch />}
                onClick={() => setTab('experience')}
                isActive={tab === 'experience'}
              />
            </div>
            {activeWorkspace ? (
              <div>
                <SidebarLabel style={{ marginTop: 8 }}>Workspace</SidebarLabel>
                <SidebarRow
                  title="Settings"
                  icon={<IconSettings />}
                  onClick={() => setTab('workspace-settings')}
                  isActive={tab === 'workspace-settings'}
                />
                <SidebarRow
                  title="Members"
                  icon={<IconUsers />}
                  onClick={() => setTab('workspace-members')}
                  isActive={tab === 'workspace-members'}
                />
              </div>
            ) : null}
            {user?.role === 'ADMIN' ? (
              <div>
                <SidebarLabel style={{ marginTop: 8 }}>Admin</SidebarLabel>
                <SidebarRow
                  title="Members"
                  icon={<IconUsers />}
                  onClick={() => setTab('members')}
                  isActive={tab === 'members'}
                />
              </div>
            ) : null}
          </SidebarContainer>
          <Content>{getBody()}</Content>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  padding: 48px;
`;
