import * as TooltipRadix from '@radix-ui/react-tooltip';
import React from 'react';
import { styled, keyframes } from '@stitches/react';

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
};

export const Tooltip = ({ children, content }: Props) => (
  <TooltipRadix.Provider>
    <TooltipRadix.Root>
      <TooltipRadix.Trigger asChild>{children}</TooltipRadix.Trigger>
      <TooltipRadix.Portal>
        <TooltipContent sideOffset={5}>
          {content}
          {/* <TooltipArrow /> */}
        </TooltipContent>
      </TooltipRadix.Portal>
    </TooltipRadix.Root>
  </TooltipRadix.Provider>
);

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

export const TooltipContent = styled(TooltipRadix.Content, {
  borderRadius: 4,
  border: '1px solid var(--border)',
  padding: '8px 12px',
  fontSize: 12,
  lineHeight: 1,
  color: 'var(--text-secondary)',
  backgroundColor: 'var(--background)',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  userSelect: 'none',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="delayed-open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
  maxWidth: 240,
});

export const TooltipArrow = styled(TooltipRadix.Arrow, {
  fill: 'var(--background)',
  height: 8,
});
