import { HTMLProps } from 'react';

type Props = HTMLProps<HTMLDivElement> & {
  icon: string;
};

export const MaterialIcon = ({ icon, style, ...props }: Props) => (
  <div
    className="material-symbols-outlined"
    style={{ fontSize: 16, cursor: 'default', ...style }}
    {...props}
  >
    {icon}
  </div>
);
