import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

export const getUsersDict = (state: RootState) => state.user.users;
export const getUsers = createSelector(getUsersDict, (users) =>
  Object.values(users),
);
export const getUserById = (userId: string) =>
  createSelector([getUsersDict], (users) => users[userId]);
export const getUserMe = (state: RootState) => state.user.user;
