import { useQueryParams } from 'lib/hooks/use-query-params';
import { RootState } from 'lib/redux';
import { getProjectTranscriptLines } from 'lib/redux/selectors/transcriptLines';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useActiveTranscriptLineIndex } from './use-active-transcript-line';

export const useTranscriptLineNav = () => {
  const { updateQueryParams } = useQueryParams();
  const { id } = useParams();
  const transcriptLines = useSelector((state: RootState) =>
    getProjectTranscriptLines(id || '')(state),
  );
  const { activeIndex } = useActiveTranscriptLineIndex({
    projectId: id || '',
  });

  // Memoize the map of line IDs to indexes for quick lookup
  const transcriptLineIndexMap = useMemo(
    () => new Map(transcriptLines.map((line, index) => [line.id, index])),
    [transcriptLines],
  );

  const handleNextTranscriptLine = (transcriptLineId?: string) => {
    // Use activeIndex if no lineId is provided
    const currentIndex = transcriptLineId
      ? transcriptLineIndexMap.get(transcriptLineId)
      : activeIndex;
    if (currentIndex === undefined) return;

    const newId =
      transcriptLines[Math.min(transcriptLines.length - 1, currentIndex + 1)]
        ?.id;
    updateQueryParams({ activeId: newId || null });
  };

  const handlePrevTranscriptLine = (transcriptLineId?: string) => {
    // Use activeIndex if no lineId is provided
    const currentIndex = transcriptLineId
      ? transcriptLineIndexMap.get(transcriptLineId)
      : activeIndex;
    if (currentIndex === undefined) return;

    const newId = transcriptLines[Math.max(0, currentIndex - 1)]?.id;
    updateQueryParams({ activeId: newId || null });
  };

  return { handleNextTranscriptLine, handlePrevTranscriptLine };
};
