import { NavRoutes } from 'lib/routes';
import AdminProjects from 'modules/admin/all-projects';
import Auth from 'modules/auth';
import { CommandMenu } from 'modules/command-menu';
import Editor from 'modules/editor';
import Projects from 'modules/projects';
import { Initialize } from 'modules/shared/initialize';
import { Loading } from 'modules/shared/loading';
import { PrivateRoute } from 'modules/shared/private-route';
import { SettingsModal } from 'modules/shared/settings-modal';
import { SidebarRoute } from 'modules/shared/sidebar-route';
import TranscriptEditor from 'modules/transcript-editor';
import Voices from 'modules/voices';
import 'react-circular-progressbar/dist/styles.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { store } from './lib/redux';

function App() {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <Initialize>
          <Routes>
            <Route path="/" element={<Navigate to={NavRoutes.Projects} />} />
            <Route path={NavRoutes.Auth} element={<Auth />} />
            <Route path={NavRoutes.Callback} element={<Loading />} />
            <Route
              path={NavRoutes.AdminProjects}
              element={
                <PrivateRoute>
                  <SidebarRoute>
                    <AdminProjects />
                  </SidebarRoute>
                </PrivateRoute>
              }
            />
            <Route
              path={NavRoutes.Editor}
              element={
                <PrivateRoute>
                  <Editor />
                </PrivateRoute>
              }
            />
            <Route
              path={NavRoutes.Projects}
              element={
                <PrivateRoute>
                  <SidebarRoute>
                    <Projects />
                  </SidebarRoute>
                </PrivateRoute>
              }
            />
            <Route
              path={NavRoutes.TranscriptEditor}
              element={
                <PrivateRoute>
                  <TranscriptEditor />
                </PrivateRoute>
              }
            />
            <Route
              path={NavRoutes.Voices}
              element={
                <PrivateRoute>
                  <SidebarRoute>
                    <Voices />
                  </SidebarRoute>
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to={NavRoutes.Projects} />} />
          </Routes>
          <SettingsModal />
          <CommandMenu />
        </Initialize>
      </DndProvider>
    </Provider>
  );
}

export default App;
