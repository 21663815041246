import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SettingsReduxType = {
  isOpen: boolean;
};

const initialState = {
  isOpen: false,
} as SettingsReduxType;

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingsOpen: (
      state: SettingsReduxType,
      action: PayloadAction<boolean>,
    ) => {
      state.isOpen = action.payload;
      return state;
    },
    resetSettings: () => initialState,
  },
});

export const { setSettingsOpen, resetSettings } = settingsSlice.actions;
