import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { getUserMe } from './users';

export const getVoicesDict = (state: RootState) => state.voices;
export const getVoices = createSelector(getVoicesDict, (voices) =>
  Object.values(voices),
);
export const getPresetVoices = createSelector(getVoices, (voices) =>
  voices.filter((voice) => !voice.user_id),
);
export const getWorkspaceVoices = createSelector(
  [getVoices, getUserMe],
  (voices, user) =>
    voices.filter((voice) => voice.workspace_id === user?.active_workspace_id),
);
