import { LineType, VoiceEngineType } from 'lib/Types';
import api from 'lib/api';
import { getEditorSeekTime } from 'lib/redux/selectors/editor';
import { deleteLine, upsertLines } from 'lib/redux/slices/lines';
import { useLineNav } from 'modules/editor/hooks/use-line-nav';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams } from '../use-query-params';

export const useLineApi = () => {
  const dispatch = useDispatch();
  const { handleNextLine } = useLineNav();
  const { updateQueryParams } = useQueryParams();
  const seekTime = useSelector(getEditorSeekTime);

  const handleCreateLine = async (line: {
    orig_text: string;
    start: number;
    end: number;
    project_id: string;
    project_dub_id: string;
    character_id?: string;
  }) => {
    try {
      const { data } = await api.lines.create(line);
      dispatch(upsertLines([data.line]));
      updateQueryParams({ activeId: data.line.id });
    } catch (error) {
      alert(`Error creating line ${error}`);
    }
  };

  // TODO: Sus to pass in do_warp as part of LineType
  const handleUpdateLine = async (
    line: { id: string; do_warp?: boolean } & Partial<LineType>,
  ) => {
    try {
      dispatch(upsertLines([{ ...line, uiLoading: true }]));
      const { data } = await api.lines.update(line.id, line);
      dispatch(upsertLines([data.line]));
    } catch (error) {
      alert(`Error updating line ${error}`);
    } finally {
      dispatch(upsertLines([{ id: line.id, uiLoading: false }]));
    }
  };

  const handleDeleteLine = async (line: { id: string } & Partial<LineType>) => {
    try {
      dispatch(deleteLine(line.id));
      await api.lines.delete(line.id);
    } catch (error) {
      alert(`Error delete line ${error}`);
    }
  };

  const handleApproveLine = async (id: string) => {
    try {
      const { data } = await api.lines.approve(id);
      handleNextLine(id);
      dispatch(upsertLines([data.line]));
    } catch (e) {
      alert('Error approving line');
    }
  };

  const handleUnapproveLine = async (id: string) => {
    try {
      const { data } = await api.lines.unapprove(id);
      dispatch(upsertLines([data.line]));
    } catch (e) {
      alert('Error unapproving line');
    }
  };

  const handleUnwarpLine = async (id: string) => {
    try {
      dispatch(upsertLines([{ id, uiLoading: true }]));
      const { data } = await api.lines.unwarp(id);
      dispatch(upsertLines([data.line]));
    } catch (e) {
      alert('Error unwarping line');
    } finally {
      dispatch(upsertLines([{ id, uiLoading: false }]));
    }
  };

  const handleInference = async (
    line: LineType,
    voiceEngine: VoiceEngineType,
  ) => {
    try {
      dispatch(upsertLines([{ id: line.id, uiLoading: true }]));
      const { data } =
        voiceEngine === VoiceEngineType.ELEVENLABS
          ? await api.lines.inference(line.id, { text: line.text })
          : await api.lines.inferenceDeeptune(line.id, { text: line.text });
      dispatch(upsertLines([data.line]));
    } catch (e) {
      alert(`Error line inference ${e}`);
    } finally {
      dispatch(upsertLines([{ id: line.id, uiLoading: false }]));
    }
  };

  const handleMergeLine = async (id: string, direction: 'next' | 'prev') => {
    try {
      dispatch(upsertLines([{ id, uiLoading: true }]));
      const { data } = await api.lines.merge(id, { direction });
      dispatch(upsertLines([data.line]));
      dispatch(deleteLine(data.merged_line_id));
      updateQueryParams({ activeId: data.line.id });
    } catch (e: any) {
      alert(e?.response?.data?.detail || 'Error merging line');
    } finally {
      dispatch(upsertLines([{ id, uiLoading: false }]));
    }
  };

  const handleSplitLineByText = async (id: string, pos: number) => {
    try {
      dispatch(upsertLines([{ id, uiLoading: true }]));
      const { data } = await api.lines.split(id, { pos });
      dispatch(upsertLines([data.line]));
      if (data.new_line) {
        dispatch(upsertLines([data.new_line]));
        updateQueryParams({ activeId: data.new_line.id });
      }
    } catch (e: any) {
      alert('Error splitting line');
    } finally {
      dispatch(upsertLines([{ id, uiLoading: false }]));
    }
  };

  const handleSplitLineAtPlayhead = async (line: LineType) => {
    if (seekTime < line.start || seekTime > line.end) {
      return;
    }

    try {
      dispatch(upsertLines([{ id: line.id, uiLoading: true }]));
      const { data } = await api.lines.splitAtPlayhead(line.id, {
        seek_time: seekTime,
      });
      dispatch(upsertLines([data.line, data.new_line]));
      updateQueryParams({ activeId: data.new_line.id });
    } catch (e: any) {
      alert('Error splitting line');
    } finally {
      dispatch(upsertLines([{ id: line.id, uiLoading: false }]));
    }
  };

  const handleRetranslateLine = async (id: string) => {
    try {
      dispatch(upsertLines([{ id, uiLoading: true }]));
      const { data } = await api.lines.retranslate(id);
      dispatch(upsertLines([data.line]));
    } catch (e) {
      alert('Error retranslating line');
    } finally {
      dispatch(upsertLines([{ id, uiLoading: false }]));
    }
  };

  return {
    handleInference,
    handleCreateLine,
    handleUpdateLine,
    handleDeleteLine,
    handleApproveLine,
    handleUnapproveLine,
    handleUnwarpLine,
    handleMergeLine,
    handleSplitLineByText,
    handleSplitLineAtPlayhead,
    handleRetranslateLine,
  };
};
