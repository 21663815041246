import { getEditorSpinnerRotation } from 'lib/redux/selectors/editor';
import { Arc } from 'react-konva';
import { useSelector } from 'react-redux';
import { blendColorsWithBg } from './lib/opacity';

type Props = {
  x: number;
  y: number;
  maxRadius?: number;
};

export const KonvaSpinner = ({ x, y, maxRadius }: Props) => {
  const rotation = useSelector(getEditorSpinnerRotation);
  const outerRadius = Math.max(Math.min(maxRadius || 9, 9), 4);
  const innerRadius = outerRadius - 1;

  const disabledColor = blendColorsWithBg('var(--text-secondary)', 0.3);

  return (
    <Arc
      x={x}
      y={y}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      angle={300}
      fill={disabledColor}
      stroke={disabledColor}
      strokeWidth={0.5}
      rotation={rotation - 30}
      lineCap="round"
    />
  );
};
