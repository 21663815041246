import { createSelector } from '@reduxjs/toolkit';
import { getProjects, getProjectsDict } from './projects';
import { getUserMe } from './users';
import { getProjectUsers } from './projectUsers';

export const getMyProjects = createSelector(
  [getProjectsDict, getUserMe, getProjectUsers],
  (projects, user, projectUsers) => {
    if (!user) return [];
    const myProjectUsers = projectUsers.filter(
      (projectUser) => projectUser.user_id === user.id,
    );
    const myProjects = myProjectUsers
      .map((projectUser) => projects[projectUser.project_id])
      .filter((p) => !!p);
    return myProjects;
  },
);

export const getWorkspaceProjects = createSelector(
  [getUserMe, getProjects],
  (user, projects) => {
    if (!user) return [];
    return projects.filter((p) => p.workspace_id === user.active_workspace_id);
  },
);
