import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from '..';

export const getCharactersDict = (state: RootState) => state.characters;
export const getCharacters = createSelector(getCharactersDict, (characters) =>
  Object.values(characters),
);

export const getProjectDubCharacters = memoize((projectDubId: string) =>
  createSelector(getCharacters, (characters) =>
    characters
      .filter((c) => c.project_dub_id === projectDubId)
      .sort((a, b) => {
        if (a.index_number !== b.index_number) {
          return a.index_number - b.index_number;
        }
        if (a.createdAt.localeCompare(b.createdAt) === 0) {
          return a.name.localeCompare(b.name);
        }
        return a.createdAt.localeCompare(b.createdAt);
      }),
  ),
);
