import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 800px;
  gap: 24px;
  margin-top: 20px;
  margin-bottom: 60px;
`;

export const Label = styled.div`
  font-size: 12px;
  color: var(--text-secondary);
`;
