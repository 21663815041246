import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastType } from 'lib/Types';

export type ToastReduxType = {
  toast: { [id: string]: ToastType };
};
const initialState = {
  toast: {},
} as ToastReduxType;

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    upsertToast: (
      state: ToastReduxType,
      action: PayloadAction<{ id: string } & Partial<ToastType>>,
    ) => {
      state.toast[action.payload.id] = {
        ...(state.toast[action.payload.id] || {}),
        ...action.payload,
      };
      return state;
    },
    deleteToast: (state, action: PayloadAction<string>) => {
      delete state.toast[action.payload];
      return state;
    },
    resetToast: () => initialState,
  },
});

export const { deleteToast, upsertToast, resetToast } = toastSlice.actions;
