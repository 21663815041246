import { IconAlertTriangle } from '@tabler/icons-react';
import { ProjectType } from 'lib/Types';
import { Tooltip } from 'modules/radix/Tooltip';
import { TailSpin } from 'react-loader-spinner';

type Props = {
  project: ProjectType;
};

export const ProjectStatus = ({ project }: Props) => {
  if (!project) return <div>Missing post</div>;

  if (project.build_status === 'BUILDING') {
    return <TailSpin width={16} height={16} color="var(--text-primary)" />;
  }
  if (project.build_status === 'FAILED') {
    return (
      <Tooltip
        content={project.failed_reason || 'Invalid file, please try again'}
      >
        <IconAlertTriangle width={16} height={16} color="var(--red)" />
      </Tooltip>
    );
  }
  return null;
};
