import { TimelineRow } from 'lib/engine/engine';
import { ProjectType } from 'lib/Types';
import { RootState } from 'lib/redux';
import { getProjectTranscriptCharacters } from 'lib/redux/selectors/transcriptCharacters';
import { getProjectTranscriptLines } from 'lib/redux/selectors/transcriptLines';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  project: ProjectType;
};

export const useTranscriptEditorData = ({ project }: Props) => {
  const transcriptCharacters = useSelector((state: RootState) =>
    getProjectTranscriptCharacters(project.id)(state),
  );
  const transcriptLines = useSelector((state: RootState) =>
    getProjectTranscriptLines(project.id)(state),
  );

  const data: TimelineRow[] = useMemo(() => {
    const temp: TimelineRow[] = [];
    // Add row for original audio
    temp.push({
      id: 'original',
      name: 'Original',
      type: 'default',
      volume: 1,
      actions: [
        {
          id: 'original',
          start: 0,
          end: project?.duration || 10,
          effectId: 'effect1',
          flexible: false,
          src: project.audio_src,
          hls_src: project.hls_audio_src,
          type: 'stem',
          disable: false,
        },
      ],
    } as TimelineRow);

    const characterData: TimelineRow[] = transcriptCharacters.map((c) => ({
      id: c.id,
      volume: 1,
      name: c.name,
      type: 'character',
      color: c.color,
      disabled: false,
      actions: transcriptLines
        .filter((l) => l.transcript_character_id === c.id)
        .map((line) => ({
          id: line.id,
          start: line.start,
          end: line.end,
          effectId: 'effect1',
          flexible: !line.approved_at && !line.uiLoading,
          movable: !line.approved_at && !line.uiLoading,
          type: 'line',
          disable: line.uiLoading || line.is_disabled,
          color: line.approved_at ? 'var(--green)' : c.color,
        })),
    }));
    temp.push(...(characterData as any[]));
    return temp;
  }, [project, transcriptLines, transcriptCharacters]);

  return { data };
};
