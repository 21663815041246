import { useEffect, useState } from 'react';
import { getComputedColor } from 'lib/utils/utils';
import WaveSurfer from 'wavesurfer.js';
// import PlayheadPlugin from 'wavesurfer.js/dist/plugins/playhead';
// import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions';
// import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
// import RegionsPlugin from 'wavesurfer.js/src/plugin/regions';

type Props = {
  height: number;
  audioUrl: string;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const useWaveform = ({ height, audioUrl, containerRef }: Props) => {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.load(
        audioUrl.replace(
          'https://deeptune-editor.s3.amazonaws.com',
          'https://d3k0hwsi4cp4qn.cloudfront.net',
        ),
      );
    }
  }, [wavesurfer, audioUrl]);

  useEffect(() => {
    const ws = WaveSurfer.create({
      barRadius: 3,
      container: containerRef.current as HTMLElement,
      waveColor: '#1A70CE',
      progressColor: '#1A70CE',
      cursorWidth: 1,
      cursorColor: getComputedColor('--text-primary'),
      height,
      interact: true,
      plugins: [
        //   TimelinePlugin.create({
        //     container: '#waveform-timeline',
        //     primaryColor: getComputedColor('--text-primary'),
        //     primaryFontColor: getComputedColor('--text-primary'),
        //     secondaryColor: getComputedColor('--text-primary'),
        //     secondaryFontColor: getComputedColor('--text-primary'),
        //   }),
        // WaveSurfer.playhead.create({
        //   returnOnPause: true,
        //   moveOnSeek: true,
        //   draw: false,
        // }),
        //   RegionsPlugin.create({
        //     dragSelection: true,
        //     slop: 5,
        //     maxRegions: 1,
        //   }),
      ],
    });

    setWavesurfer(ws);

    // don't allow per channel highlighting
    // ws.on('region-created', (region: any) => {
    //   if (region.regionHeight !== '100%') {
    //     region.regionHeight = '100%';
    //     region.marginTop = '0px';
    //     region.style(region.element, {
    //       height: region.regionHeight,
    //       top: region.marginTop,
    //     });
    //   }
    // });

    // ws.on('region-update-end', (region: any) => {
    //   region.handleStyle.right = 'rgba(0, 0, 255, 1)';
    //   ws.seekTo(region.start, 'seconds');
    // });

    // ws.on('region-out', () => {
    //   ws.pause();
    // });

    // ws.container.addEventListener('mousedown', clearRegions);
    return () => {
      ws.stop();
      // ws.container.removeEventListener('mousedown', clearRegions);
      if (!containerRef.current) return;
      (containerRef.current as any).innerHTML = '';
    };
  }, []);

  return wavesurfer;
};
