import {
  LanguageEnum,
  LanguageNames,
  TranscriptLanguageEnum,
  TranscriptOnlyEnum,
} from 'lib/Types';
import api from 'lib/api';
import { Dialog, DialogContent, DialogTitle } from 'modules/radix/Dialog';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { upsertProjects } from 'lib/redux/slices/projects';
import { upsertProjectUsers } from 'lib/redux/slices/projectUsers';
import {
  Button,
  ErrorMessage,
  Input,
  Label,
  ToggleButton,
  WarningMessage,
} from 'modules/shared/ui';
import { EditableDnd } from 'modules/shared/editable-components/editable-dnd';
import { getUserMe } from 'lib/redux/selectors/users';
import {
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react';

type Props = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

export const CreateProjectModal = ({ isOpen, setOpen }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserMe);
  const [isLoading, setLoading] = useState(false);
  const [src, setSrc] = useState('');
  const [name, setName] = useState('');
  const [fromLang, setFromLang] = useState<TranscriptLanguageEnum | undefined>(
    undefined,
  );
  const [trimStart, setTrimStart] = useState<number | null>(null);
  const [trimEnd, setTrimEnd] = useState<number | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [sourceType, setSourceType] = useState<'url' | 'file'>('file');
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const isValid = (src.length || file) && name.length && fromLang !== undefined;
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    // Match youtube.com and youtu.be URLs
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)(\/.*)?$/;
    setShowWarning(youtubeRegex.test(src));
  }, [src]);

  const handleCreate = async () => {
    if (!isValid) {
      alert('todo validation');
      return;
    }
    if (!user?.active_workspace_id) return;
    setLoading(true);
    try {
      const { data } = await api.projects.create({
        name,
        workspace_id: user.active_workspace_id,
        file,
        src,
        orig_lang: fromLang.valueOf(),
        trim_start: trimStart ?? undefined,
        trim_end: trimEnd ?? undefined,
        onUploadProgress: (progressEvent) => {
          if (!progressEvent.total) return;
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          if (progress === 100) {
            setUploadProgress(null);
          } else {
            setUploadProgress(progress);
          }
        },
      });
      dispatch(upsertProjects([data.project]));
      dispatch(upsertProjectUsers([data.project_user]));
      setName('');
      setSrc('');
      setFromLang(LanguageEnum.ENGLISH);
      setTrimStart(null);
      setTrimEnd(null);
      setError(null);
      handleClose(false);
    } catch (err: any) {
      console.error(err);
      setError(err?.response?.data?.detail || 'Error creating project');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (open: boolean) => {
    if (open) return;
    setOpen(false);
    setSrc('');
    setName('');
    setSourceType('file');
    setFile(null);
    setFromLang(LanguageEnum.ENGLISH);
    setTrimStart(null);
    setTrimEnd(null);
    setError(null);
  };

  const renderSourceUpload = () => {
    switch (sourceType) {
      case 'url':
        return (
          <Input
            value={src}
            onChange={(e) => {
              setSrc(e.target.value);
            }}
            placeholder="Paste URL here..."
          />
        );
      case 'file':
        return (
          <EditableDnd
            onDropFile={(f) => {
              if (f && !name) setName(f.name);
              setFile(f);
            }}
            file={file}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <Container>
        <StyledDialogContent>
          <DialogTitle>Create Project</DialogTitle>
          <Row>
            <Label>Name</Label>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </Row>
          <Row>
            <Label>Source</Label>
            <div
              style={{
                border: '1px solid var(--border)',
                background: 'var(--background-secondary)',
                minHeight: 80,
              }}
            >
              <FlexRow
                style={{
                  padding: '0px 8px',
                }}
              >
                <SourceToggleButton
                  onClick={() => setSourceType('file')}
                  isActive={sourceType === 'file'}
                >
                  File
                </SourceToggleButton>
                <SourceToggleButton
                  onClick={() => setSourceType('url')}
                  isActive={sourceType === 'url'}
                >
                  URL
                </SourceToggleButton>
              </FlexRow>
              <div style={{ padding: 8 }}>{renderSourceUpload()}</div>
            </div>
          </Row>
          {showWarning && (
            <Row>
              <WarningMessage>
                <IconAlertTriangle style={{ width: 13, height: 13 }} />
                YouTube&apos;s API is experiencing intermittent issues. YouTube
                URLs may not work. Try uploading a file instead?
              </WarningMessage>
            </Row>
          )}
          <Row>
            <Label>Source Language</Label>
            <div>
              <SelectRoot value={fromLang} onValueChange={setFromLang as any}>
                <SelectTrigger>
                  <SelectValue
                    placeholder={
                      <div style={{ color: 'var(--text-secondary)' }}>
                        Select Language
                      </div>
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectViewport>
                    {[
                      ...Object.entries(LanguageEnum),
                      ...Object.entries(TranscriptOnlyEnum),
                    ]
                      .sort((a, b) =>
                        LanguageNames[a[1]].localeCompare(LanguageNames[b[1]]),
                      )
                      .map(([k, v]) => (
                        <SelectItem value={v} key={k}>
                          <SelectItemText>{LanguageNames[v]}</SelectItemText>
                          <SelectItemIndicator>
                            <IconCheck />
                          </SelectItemIndicator>
                        </SelectItem>
                      ))}
                  </SelectViewport>
                </SelectContent>
              </SelectRoot>
            </div>
          </Row>
          <FlexRow style={{ gap: 16 }}>
            <Row
              style={{
                opacity: trimStart ? 1 : 0.3,
                transition: 'all 0.2s ease',
              }}
            >
              <Label>Trim Start Seconds (optional)</Label>
              <Input
                value={trimStart || ''}
                onChange={(e) => setTrimStart(Number(e.target.value))}
                type="number"
                step="any"
              />
            </Row>
            <Row
              style={{
                opacity: trimEnd ? 1 : 0.3,
                transition: 'all 0.2s ease',
              }}
            >
              <Label>Trim End Seconds (optional)</Label>
              <Input
                value={trimEnd || ''}
                onChange={(e) => setTrimEnd(Number(e.target.value))}
                type="number"
                step="any"
              />
            </Row>
          </FlexRow>
          <Row>
            <Button onClick={handleCreate} disabled={!isValid}>
              {isLoading ? (
                <>
                  <TailSpin width={12} height={12} color="white" />
                  {uploadProgress ? `${Math.round(uploadProgress)}%` : null}
                </>
              ) : (
                'Create Project'
              )}
            </Button>
          </Row>
          {error ? (
            <Row>
              <ErrorMessage>
                <IconAlertTriangle style={{ width: 13, height: 13 }} />
                {error}
              </ErrorMessage>
            </Row>
          ) : null}
        </StyledDialogContent>
      </Container>
    </Dialog>
  );
};

const StyledDialogContent = styled(DialogContent)`
  max-width: 1000px;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 8px;
`;

const Row = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled(Row)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
`;

const SourceToggleButton = styled(ToggleButton)<{ isActive: boolean }>`
  background: ${({ isActive }) =>
    isActive ? 'var(--text-primary)' : 'var(--background-secondary)'};
  color: ${({ isActive }) => (isActive ? 'black' : 'var(--text-primary)')};
`;
