import { offset, shift, useFloating } from '@floating-ui/react-dom';
import { IconPencil, IconReload } from '@tabler/icons-react';
import api from 'lib/api';
import { useLineApi } from 'lib/hooks/api/use-line-api';
import { getPromptLineId } from 'lib/redux/selectors/editor';
import { getLinesDict } from 'lib/redux/selectors/lines';
import { upsertLines } from 'lib/redux/slices/lines';
import { ButtonIcon, FlexRow, Input } from 'modules/shared/ui';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway, useKey } from 'react-use';
import styled from 'styled-components';

interface Props {
  position: {
    x: number;
    y: number;
  };
  dimensions: {
    width: number;
    height: number;
  };
  onClose: () => void;
}

export const WaveformPopover = ({ position, dimensions, onClose }: Props) => {
  const dispatch = useDispatch();
  const { x, y, refs, strategy, update } = useFloating<HTMLDivElement>({
    placement: 'top',
    middleware: [offset(30), shift({ padding: 10 })],
  });
  const [promptText, setPromptText] = useState('');
  const promptLineId = useSelector(getPromptLineId);
  const promptLine = promptLineId
    ? useSelector(getLinesDict)[promptLineId]
    : null;
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleUnapproveLine } = useLineApi();

  useLayoutEffect(update, [update, position, dimensions]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleClose = () => {
    setPromptText('');
    onClose();
  };

  const handlePrompt = async () => {
    if (!promptLine) return;
    try {
      dispatch(upsertLines([{ id: promptLine.id, uiLoading: true }]));
      const { data } = await api.lines.prompt(promptLine.id, {
        prompt: promptText,
      });
      dispatch(upsertLines([data.line]));
      handleClose();
    } catch (err) {
      alert('Error prompting line');
    } finally {
      dispatch(upsertLines([{ id: promptLine.id, uiLoading: false }]));
    }
  };

  // Close the popover on click away and escape
  useClickAway(refs.floating, handleClose);
  useKey('Escape', handleClose);

  if (!promptLine) return null;

  return (
    <>
      <div
        ref={refs.setReference}
        style={{
          position: 'absolute',
          left: position.x,
          top: position.y,
          width: dimensions.width,
          height: dimensions.height,
        }}
      />
      <div
        ref={refs.setFloating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
      >
        <PopoverContainer>
          <FlexRow style={{ gap: 8 }}>
            <Input
              ref={inputRef}
              placeholder={
                promptLine.approved_at
                  ? 'Cannot prompt while line is approved'
                  : 'Prompt line...'
              }
              style={{ textAlign: 'left' }}
              value={promptText}
              disabled={promptLine.uiLoading || !!promptLine.approved_at}
              onChange={(e) => setPromptText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && (e.metaKey || !e.metaKey)) {
                  e.preventDefault();
                  handlePrompt();
                }
              }}
            />
            {promptLine.approved_at ? (
              <ButtonIcon
                onClick={() => handleUnapproveLine(promptLine.id)}
                style={{ width: 40 }}
              >
                <IconPencil />
              </ButtonIcon>
            ) : (
              <ButtonIcon
                onClick={handlePrompt}
                disabled={
                  promptLine.uiLoading ||
                  !!promptLine.approved_at ||
                  !promptText?.length
                }
                style={{ width: 40 }}
              >
                {promptLine.uiLoading ? (
                  <TailSpin
                    width={12}
                    height={12}
                    color="var(--text-primary)"
                  />
                ) : (
                  <IconReload />
                )}
              </ButtonIcon>
            )}
          </FlexRow>
        </PopoverContainer>
      </div>
    </>
  );
};

const PopoverContainer = styled.div`
  padding: 8px;
  font-size: 12px;
  text-align: center;
  background: var(--background);
  bottom: calc(100% + 8px);
  border-radius: 4px;
  min-width: 360px;
  color: var(--text-primary);
  border: 1px solid var(--border);
`;
