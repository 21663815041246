import { TimelineRow } from 'lib/engine/engine';
import React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

interface Props {
  row: TimelineRow;
  index: number;
  children: React.ReactNode;
}

export const DraggableComponent = ({ row, index, children }: Props) => (
  <Draggable key={row.id} draggableId={row.id} index={index}>
    {(provided: DraggableProvided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{ ...provided.draggableProps.style }}
      >
        {children}
      </div>
    )}
  </Draggable>
);
