import { IconCaretDown, IconCaretUp } from '@tabler/icons-react';
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ProjectType } from 'lib/Types';
import { getProjectDubs } from 'lib/redux/selectors/projectDubs';
import { secondsToHumanReadable } from 'lib/utils/audio';
import { ProjectContextMenu } from 'modules/shared/context-menu/project-context-menu';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { EditableStringInput } from 'modules/shared/editable-components/editable-string-input';
import { useProjectApi } from 'lib/hooks/api/use-project-api';
import { usePingProjects } from 'lib/hooks/use-ping-projects';
import { Subtitle } from '../ui';
import { ProjectLanguage } from './components/project-language';
import { ProjectStatus } from './components/project-status';
import { ProjectOwner } from './components/project-owner';

type Props = {
  projects: ProjectType[];
};

export const ProjectTable = ({ projects }: Props) => {
  const navigate = useNavigate();
  const [data, setData] = React.useState(() => [...projects]);
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: 'created', desc: true },
  ]);
  const projectDubs = useSelector(getProjectDubs);
  const { handleUpdateProject } = useProjectApi();
  usePingProjects({ projects });

  // TODO make this less laggy
  useEffect(() => {
    setData(projects);
  }, [projects]);

  const handleClick = (id: string) => {
    const projectDub = projectDubs.find((pd) => pd.project_id === id);
    if (projectDub) navigate(`/editor/${projectDub.id}`);
    else navigate(`/transcript/${id}`);
  };

  const columnHelper = createColumnHelper<ProjectType>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        id: 'paddingleft',
        header: () => null,
        cell: () => null,
        footer: (info) => info.column.id,
        maxSize: 40,
      }),
      columnHelper.accessor('id', {
        id: 'status',
        header: () => null,
        cell: (info) => (
          <div
            style={{
              marginRight: 16,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <ProjectStatus project={info.row.original} />
          </div>
        ),
        footer: (info) => info.column.id,
        maxSize: 30,
      }),
      columnHelper.accessor('id', {
        id: 'id',
        header: () => <div style={{ textAlign: 'left' }}>Name</div>,
        cell: (info) => (
          <EditableStringInput
            id={`project-row-${info.row.original.id}`}
            value={info.row.original.name}
            handleDoneEditing={(v) => {
              handleUpdateProject({ id: info.row.original.id, name: v });
            }}
          />
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('id', {
        id: 'languages',
        header: 'Languages',
        cell: (info) => <ProjectLanguage project={info.row.original} />,
        footer: (info) => info.column.id,
        maxSize: 200,
      }),
      columnHelper.accessor('id', {
        id: 'owner',
        header: 'Owner',
        cell: (info) => <ProjectOwner project={info.row.original} />,
        footer: (info) => info.column.id,
        maxSize: 120,
      }),
      columnHelper.accessor('createdAt', {
        id: 'created',
        header: 'Created',
        cell: (info) => (
          <Subtitle>
            {new Date(info.getValue()).toLocaleDateString('en-US', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            })}
          </Subtitle>
        ),
        footer: (info) => info.column.id,
        maxSize: 80,
      }),
      columnHelper.accessor('duration', {
        id: 'duration',
        header: () => 'Duration',
        cell: (info) => {
          const value = info.getValue();
          return (
            <Subtitle>{value ? secondsToHumanReadable(value) : null}</Subtitle>
          );
        },
        footer: (info) => info.column.id,
        maxSize: 60,
      }),
      columnHelper.accessor('id', {
        id: 'paddingright',
        header: () => null,
        cell: () => null,
        footer: (info) => info.column.id,
        maxSize: 40,
      }),
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
    debugTable: true,
  });

  return (
    <div style={{ width: '100%' }}>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width:
                      header.getSize() === Number.MAX_SAFE_INTEGER
                        ? 'auto'
                        : header.getSize(),
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: <IconCaretUp />,
                        desc: <IconCaretDown />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </TableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} onClick={() => handleClick(row.original.id)}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{
                    width:
                      cell.column.getSize() === Number.MAX_SAFE_INTEGER
                        ? 'auto'
                        : cell.column.getSize(),
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <ProjectContextMenu project={row.original}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </ProjectContextMenu>
                </td>
              ))}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-spacing: 0px;
  font-size: 13px;
  font-weight: 500;
  font-family: Inter;
  /* th,
  td {
    border: 1px solid #000;
  } */
`;

const TableRow = styled.tr`
  height: 36px;
  :hover {
    background: var(--hover);
  }
`;

const TableHeader = styled.th`
  text-align: left;
`;
