import { IconCheck } from '@tabler/icons-react';
import { ProjectDubType, ProjectType } from 'lib/Types';
import api from 'lib/api';
import { upsertExportJobs } from 'lib/redux/slices/exportJobs';
import {
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { Button, FlexCol, FlexRow, Input, Label } from 'modules/shared/ui';
import { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
  setTab: (tab: 'export' | 'history' | 'publish') => void;
};

const ExportAudioFormats = {
  mp3: 'MP3',
  wav: 'WAV',
};

export const ExportAudio = ({ project, projectDub, setTab }: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [format, setFormat] = useState<'mp3' | 'wav'>('mp3');
  const [trimStart, setTrimStart] = useState<number | null>(null);
  const [trimEnd, setTrimEnd] = useState<number | null>(null);

  const handleExport = async () => {
    if (!project?.id) {
      alert('No project id found');
      return;
    }
    if (isLoading) return;

    try {
      setLoading(true);

      const { data } = await api.projectDubs.exportAudio(projectDub.id, {
        format,
        trim_start: trimStart || undefined,
        trim_end: trimEnd || undefined,
      });
      dispatch(upsertExportJobs([data.export_job]));
      setTab('history');
    } catch (e) {
      alert(`Error exporting ${format}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FlexCol style={{ gap: 16 }}>
      <div>
        <Label>Format</Label>
        <SelectRoot
          value={format}
          onValueChange={(e: any) => {
            setFormat(e);
          }}
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectViewport>
              {Object.entries(ExportAudioFormats).map(([k, v]) => (
                <SelectItem value={k} key={k}>
                  <SelectItemText>{v}</SelectItemText>
                  <SelectItemIndicator>
                    <IconCheck />
                  </SelectItemIndicator>
                </SelectItem>
              ))}
            </SelectViewport>
          </SelectContent>
        </SelectRoot>
      </div>
      <FlexRow style={{ gap: 16 }}>
        <FlexCol
          style={{
            opacity: trimStart ? 1 : 0.4,
            transition: 'all 0.2s ease',
          }}
        >
          <Label>Trim Start Seconds (optional)</Label>
          <Input
            value={trimStart || ''}
            onChange={(e) => setTrimStart(Number(e.target.value))}
            type="number"
            step="any"
          />
        </FlexCol>
        <FlexCol
          style={{
            opacity: trimEnd ? 1 : 0.4,
            transition: 'all 0.2s ease',
          }}
        >
          <Label>Trim End Seconds (optional)</Label>
          <Input
            value={trimEnd || ''}
            onChange={(e) => setTrimEnd(Number(e.target.value))}
            type="number"
            step="any"
          />
        </FlexCol>
      </FlexRow>
      <Button onClick={handleExport}>
        {isLoading ? (
          <TailSpin width={13} height={13} color="white" />
        ) : (
          <div>Export</div>
        )}
      </Button>
    </FlexCol>
  );
};
