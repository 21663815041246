import { ProjectType } from 'lib/Types';
import { TimelineAction, TimelineEngine } from 'lib/engine/engine';
import { Player } from 'modules/editor/components/player';
import { useRef } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import styled from 'styled-components';
import { useTranscriptEditorHotkeys } from '../hooks/use-transcript-editor-hotkeys';
import { TimelinePanel } from './timeline-panel';
import { TranscriptLineEditor } from './transcript-line-editor';

export interface CustomTimelineAction extends TimelineAction {
  data: {
    src: string;
    name: string;
  };
}

type Props = {
  project: ProjectType;
};

const TranscriptEditorDetail = ({ project }: Props) => {
  const timelineEngine = useRef<TimelineEngine>(new TimelineEngine());
  useTranscriptEditorHotkeys({ timelineEngine });
  const lineEditorRef = useRef<HTMLDivElement>(null);
  const timelinePanelRef = useRef<HTMLDivElement>(null);

  const focusTimeline = () => {
    timelinePanelRef.current?.focus();
  };

  return (
    <Container>
      <PanelGroup direction="horizontal">
        <Panel>
          <FocusDiv ref={lineEditorRef} tabIndex={-1}>
            <TranscriptLineEditor
              project={project}
              focusTimeline={focusTimeline}
              timelineEngine={timelineEngine}
            />
          </FocusDiv>
        </Panel>
        <PanelResizeHandle
          style={{
            width: 10,
            background: 'var(--background-secondary)',
            borderRight: '1px solid var(--border)',
          }}
        />
        <Panel defaultSize={50}>
          <PanelGroup direction="vertical">
            <Panel>
              <PlayerContainer>
                <Player project={project} timelineEngine={timelineEngine} />
              </PlayerContainer>
            </Panel>
            <PanelResizeHandle
              style={{
                height: 10,
                background: 'var(--background)',
                borderTop: '1px solid var(--border)',
              }}
            />
            <Panel defaultSize={50} minSize={30}>
              <TimelinePanel
                project={project}
                timelineEngine={timelineEngine}
              />
            </Panel>
          </PanelGroup>
        </Panel>
      </PanelGroup>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
  flex: 1;
  overflow: auto;
  font-size: 12px;
  background: var(--background-secondary);
`;

const FocusDiv = styled.div`
  outline: none;
  height: 100%;
  width: 100%;
`;

const PlayerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: black;
  padding: 16px;
  box-sizing: border-box;
`;

export default TranscriptEditorDetail;
