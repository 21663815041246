import { getCharactersDict } from 'lib/redux/selectors/characters';
import { getCharacterModalId } from 'lib/redux/selectors/editor';
import { setCharacterModalId } from 'lib/redux/slices/editor';
import { Dialog, DialogContent } from 'modules/radix/Dialog';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CreateVoice } from './components/create-voice';
import { Voices } from './components/voices';
import { CloneVoiceSettings } from './components/clone-voice-settings';
import { CustomVoiceSettings } from './components/custom-voice-settings';

export const CharacterModal = () => {
  const dispatch = useDispatch();
  const characterModalId = useSelector(getCharacterModalId);
  const character = useSelector(getCharactersDict)[characterModalId || ''];
  const [mode, setMode] = useState<'create' | 'voices'>('voices');
  const [editVoiceId, setEditVoiceId] = useState<string | null>(null);

  const getBody = () => {
    if (!character) return null;

    if (editVoiceId === character.clone_voice_data_id) {
      return (
        <CloneVoiceSettings
          character={character}
          onClose={() => {
            setEditVoiceId(null);
            setMode('voices');
          }}
        />
      );
    }

    if (editVoiceId) {
      return (
        <CustomVoiceSettings
          voiceDataId={editVoiceId}
          onClose={() => {
            setEditVoiceId(null);
            setMode('voices');
          }}
        />
      );
    }

    if (mode === 'create') {
      return <CreateVoice onClose={() => setMode('voices')} />;
    }
    return (
      <Voices
        character={character}
        setMode={setMode}
        setEditVoiceId={setEditVoiceId}
      />
    );
  };

  const onOpenChange = () => {
    dispatch(setCharacterModalId(null));
    setMode('voices');
    setEditVoiceId(null);
  };

  return (
    <Dialog open={!!character} onOpenChange={onOpenChange}>
      <DialogContent
        style={{
          maxWidth: 1100,
          padding: 0,
          maxHeight: 700,
          height: '100%',
          background: 'var(--background)',
        }}
      >
        <Container>
          {/* {character ? <EditCharacter character={character} /> : null} */}
          {getBody()}
        </Container>
      </DialogContent>
    </Dialog>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;
