import $ from 'jquery';
import fileDownload from 'js-file-download';
import tinycolor from 'tinycolor2';

export const getComputedColor = (v: string) => {
  // Check if the input is a CSS custom property
  if (v.startsWith('var(')) {
    const customPropValue = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(v.slice(4, -1))
      .trim();

    return tinycolor(customPropValue).toHexString();
  }

  // Check if the input is already a valid color format
  const colorTest = tinycolor(v);
  if (colorTest.isValid()) {
    return colorTest.toHexString();
  }

  // Default to computing the color from the document's computed style
  const computedStyleValue = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(v)
    .trim();

  return tinycolor(computedStyleValue).toHexString();
};

export const blobToDataURL = (blob: Blob) =>
  new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });

export const openUriInNewTab = (uri: string) => {
  const link = document.createElement('a');
  link.href = uri;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadUri = (uri: string, name?: string) => {
  const fileName = decodeURIComponent(uri.split('/').pop() || '');
  fetch(uri)
    .then((response) => response.blob())
    .then((blob) => fileDownload(blob, name || fileName));
};

export const isValidEmail = (email: string) => {
  // Regular expression pattern for email validation
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the pattern and return the result
  return emailPattern.test(email);
};

export const formatMoney = (amount: number) =>
  amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

export const renameEditableInput = (id: string) => {
  // enable textarea and move cursor to the end
  setTimeout(() => {
    const input = $(id);
    if (!input.length) return;
    input.attr('disabled', false as any);
    const tmp = input.val() as string;
    input.focus();
    (input[0] as HTMLInputElement).setSelectionRange(0, tmp.length);
  }, 10);
};
