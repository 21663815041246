import { ClipTrackType } from 'lib/Types';
import axios from './axios';

export const ClipTracks = {
  create: (data: { project_dub_id: string }) =>
    axios.post('/clip_tracks', data),
  createDX: (data: { project_dub_id: string }) =>
    axios.post('/clip_tracks/dx', data),
  update: (id: string, data: Partial<ClipTrackType>) =>
    axios.put(`/clip_tracks/${id}`, data),
  duplicate: (id: string) => axios.post(`/clip_tracks/${id}/duplicate`),
  delete: (id: string) => axios.delete(`/clip_tracks/${id}`),
  updateVoice: ({
    clip_id,
    voice_model_id,
  }: {
    clip_id: string;
    voice_model_id?: string;
  }) => axios.post(`/clip_tracks/${clip_id}/voice`, { voice_model_id }),
};
