import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkspaceUserType } from 'lib/Types';

export type WorkspaceUsersReduxType = { [id: string]: WorkspaceUserType };
const initialState = {} as WorkspaceUsersReduxType;

export const workspaceUsersSlice = createSlice({
  name: 'workspaceUsers',
  initialState,
  reducers: {
    upsertWorkspaceUsers: (
      state: WorkspaceUsersReduxType,
      action: PayloadAction<({ id: string } & Partial<WorkspaceUserType>)[]>,
    ) => {
      action.payload.forEach((workspaceUser) => {
        state[workspaceUser.id] = {
          ...(state[workspaceUser.id] || {}),
          ...workspaceUser,
        };
      });
      return state;
    },
    deleteWorkspaceUser: (
      state: WorkspaceUsersReduxType,
      action: PayloadAction<string>,
    ) => {
      delete state[action.payload];
      return state;
    },
    resetWorkspaceUsers: () => initialState,
  },
});

export const {
  upsertWorkspaceUsers,
  deleteWorkspaceUser,
  resetWorkspaceUsers,
} = workspaceUsersSlice.actions;
