import { useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
  id: string;
  value: string;
  handleDoneEditing: (newValue: string) => void;
};

export const EditableStringInput = ({
  id,
  value,
  handleDoneEditing,
}: Props) => {
  const [isDisabled, setDisabled] = useState(true);
  const [currValue, setCurrValue] = useState<string>(value);

  // Listen for changes to the disabled attribute from jquery
  // TODO this might be better handled by tracking disabled state in redux
  useEffect(() => {
    const targetNode = document.getElementById(id);
    if (!targetNode) return; // Ensure targetNode is not null
    const config: MutationObserverInit = {
      attributes: true,
      childList: false,
      subtree: false,
    };
    const callback: MutationCallback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'disabled'
        ) {
          setDisabled((mutation.target as HTMLButtonElement).disabled);
        }
      });
    };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    setCurrValue(value);
  }, [value]);

  const handleEdit = () => {
    setDisabled(true);
    handleDoneEditing(currValue);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') (document.activeElement as any)?.blur();
  };

  return (
    <InputRow
      id={id}
      value={currValue}
      onChange={(e) => setCurrValue(e.target.value)}
      onBlur={handleEdit}
      onKeyDown={handleKeyPress}
      disabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
    />
  );
};

const InputRow = styled.input`
  all: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
  flex: 2 0 auto;
  width: 100%;
`;
