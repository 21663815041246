import api from 'lib/api';
import { getProjects } from 'lib/redux/selectors/projects';
import { getUserMe } from 'lib/redux/selectors/users';
import { upsertProjectDubs } from 'lib/redux/slices/projectDubs';
import { upsertProjectUsers } from 'lib/redux/slices/projectUsers';
import { upsertProjects } from 'lib/redux/slices/projects';
import { ProjectTable } from 'modules/projects/components/project-table';
import { Loading } from 'modules/shared/loading';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const AdminProjects = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUserMe);
  const projects = useSelector(getProjects);
  const [isLoading, setLoading] = useState(true);

  const handleLoad = async () => {
    if (user?.role !== 'ADMIN') return;
    setLoading(true);
    try {
      const { data } = await api.projects.getAll();
      dispatch(upsertProjects(data.projects));
      dispatch(upsertProjectDubs(data.project_dubs));
      dispatch(upsertProjectUsers(data.project_users));
    } catch (e) {
      alert('Error fetching projects');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleLoad();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <div>protected</div>;
  }

  return (
    <Container>
      <Body>
        <ProjectTable projects={projects} />
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
`;

const Body = styled.div`
  padding: 40px 0px;
`;

export default AdminProjects;
