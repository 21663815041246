import { combineReducers } from 'redux';
import { activitiesSlice } from './activities';
import { charactersSlice } from './characters';
import { editorSlice } from './editor';
import { linesSlice } from './lines';
import { projectUsersSlice } from './projectUsers';
import { settingsSlice } from './settings';
import { userSlice } from './user';
import { projectDubsSlice } from './projectDubs';
import { projectsSlice } from './projects';
import { toastSlice } from './toast';
import { clipsSlice } from './clips';
import { clipTracksSlice } from './clipTracks';
import { transcriptLinesSlice } from './transcriptLines';
import { voicesSlice } from './voices';
import { workspacesSlice } from './workspaces';
import { workspaceUsersSlice } from './workspaceUsers';
import { transcriptCharactersSlice } from './transcriptCharacters';
import { exportJobsSlice } from './exportJobs';
import { captionsSlice } from './captions';
import { fontsSlice } from './fonts';

export const reducers = combineReducers({
  [activitiesSlice.name]: activitiesSlice.reducer,
  [captionsSlice.name]: captionsSlice.reducer,
  [clipsSlice.name]: clipsSlice.reducer,
  [clipTracksSlice.name]: clipTracksSlice.reducer,
  [charactersSlice.name]: charactersSlice.reducer,
  [editorSlice.name]: editorSlice.reducer,
  [exportJobsSlice.name]: exportJobsSlice.reducer,
  [fontsSlice.name]: fontsSlice.reducer,
  [linesSlice.name]: linesSlice.reducer,
  [projectsSlice.name]: projectsSlice.reducer,
  [projectDubsSlice.name]: projectDubsSlice.reducer,
  [projectUsersSlice.name]: projectUsersSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
  [transcriptCharactersSlice.name]: transcriptCharactersSlice.reducer,
  [transcriptLinesSlice.name]: transcriptLinesSlice.reducer,
  [toastSlice.name]: toastSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [voicesSlice.name]: voicesSlice.reducer,
  [workspacesSlice.name]: workspacesSlice.reducer,
  [workspaceUsersSlice.name]: workspaceUsersSlice.reducer,
});
