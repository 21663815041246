import { IconMenu2, IconPlus, IconSettings } from '@tabler/icons-react';
import { ProjectDubType, ProjectType } from 'lib/Types';
import api from 'lib/api';
import { FeatureFlags } from 'lib/constants';
import { getCaptionByProjectDubId } from 'lib/redux/selectors/captions';
import { getProjectDubLines } from 'lib/redux/selectors/lines';
import { getUserMe } from 'lib/redux/selectors/users';
import { upsertCaptions } from 'lib/redux/slices/captions';
import { setProjectSettingsOpen } from 'lib/redux/slices/editor';
import { upsertLines } from 'lib/redux/slices/lines';
import { NavRoutes } from 'lib/routes';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'modules/radix/DropdownMenu';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { Button } from 'modules/shared/ui';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ExportPopover } from './components/export-popover';
import { ProjectSelect } from './components/project-select';

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
};

export const Toolbar = ({ project, projectDub }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUserMe);
  const handleBack = () => navigate(NavRoutes.Projects);
  const lines = useSelector(getProjectDubLines(projectDub.id));
  const [hasMissingLines, setHasMissingLines] = useState(false);
  const [loadingLines, setLoadingLines] = useState(false);
  const caption = useSelector(getCaptionByProjectDubId(projectDub.id));
  const captionsFlagEnabled = useFeatureFlagEnabled(FeatureFlags.CAPTIONS);
  const [loadingCaptions, setLoadingCaptions] = useState(false);

  useEffect(() => {
    const missingLines = lines.filter((line) => !line.src && !line.is_loading);
    setHasMissingLines(missingLines.length > 0);
  }, [projectDub, lines]);

  const handleGenerateMissingLines = async () => {
    if (loadingLines) return;

    setLoadingLines(true);
    try {
      const { data } = await api.projectDubs.dubMissingLines(projectDub.id);
      dispatch(upsertLines(data.lines));
    } catch (err) {
      alert('error generating missing lines');
    } finally {
      setLoadingLines(false);
    }
  };

  const handleRegenerateAdmin = async () => {
    try {
      const { data } = await api.projectDubs.regenerateAdmin(projectDub.id);
      dispatch(upsertLines(data.lines));
    } catch (err) {
      alert('error generating missing lines');
    }
  };

  const handleCreateCaptions = async () => {
    if (!project.duration) {
      alert("Project is missing duration, can't create captions");
      return;
    }

    if (loadingCaptions) return;

    try {
      setLoadingCaptions(true);
      const { data } = await api.captions.create({
        project_dub_id: projectDub.id,
        end_time: project.duration,
      });
      dispatch(upsertCaptions([data.caption]));
      dispatch(upsertLines(data.lines));
    } catch (err) {
      alert('Error creating captions');
    } finally {
      setLoadingCaptions(false);
    }
  };

  return (
    <Container>
      <Flex>
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <HoverIcon style={{ color: 'var(--text-primary)' }}>
              <IconMenu2 style={{ width: 16, height: 16 }} />
            </HoverIcon>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={handleBack}>
              Back to projects
            </DropdownMenuItem>
            {user?.role === 'ADMIN' && (
              <DropdownMenuItem onClick={handleRegenerateAdmin}>
                Regenerate dub
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
        <Title>{project.name}</Title>
      </Flex>
      <Flex>
        {/* Only allow user to add one caption per project dub */}
        {caption === undefined && captionsFlagEnabled && (
          <Button onClick={handleCreateCaptions}>
            {loadingCaptions ? (
              <TailSpin width={36} height={36} color="var(--text-light)" />
            ) : (
              <>
                <IconPlus /> Add caption
              </>
            )}
          </Button>
        )}
        {hasMissingLines && (
          <PrimaryButton onClick={handleGenerateMissingLines}>
            {loadingLines ? (
              <TailSpin width={36} height={36} color="var(--text-light)" />
            ) : (
              'Generate Missing Lines'
            )}
          </PrimaryButton>
        )}
        <HoverIcon onClick={() => dispatch(setProjectSettingsOpen(true))}>
          <IconSettings />
        </HoverIcon>
        <ProjectSelect project={project} projectDub={projectDub} />
        <ExportPopover project={project} projectDub={projectDub} />
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  background: var(--background);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px;
  width: calc(100%-12px);
  -webkit-app-region: drag;
  border-bottom: 1px solid var(--border);
  box-sizing: border-box;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
`;

const PrimaryButton = styled(Button)`
  cursor: default;
  min-width: 140px;

  /* Add white overlay on hover */
  position: relative;
  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
    mix-blend-mode: lighten;
    pointer-events: none; // Prevents the pseudo-element from interfering with button functionality
  }
`;
