import { getComputedColor } from 'lib/utils/utils';

function hexToRgb(hex: string): [number, number, number] {
  let cleanHex = hex.replace('#', '');
  if (cleanHex.length === 3) {
    cleanHex = cleanHex
      .split('')
      .map((char) => char + char)
      .join('');
  }
  const r = parseInt(cleanHex.slice(0, 2), 16);
  const g = parseInt(cleanHex.slice(2, 4), 16);
  const b = parseInt(cleanHex.slice(4, 6), 16);
  return [r, g, b];
}

function rgbToHex(r: number, g: number, b: number): string {
  return `#${[r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')}`;
}

export function blendColorsWithBg(
  foregroundColor: string,
  opacity: number,
): string {
  // May be hex or var (e.g var(--color))
  const [fr, fg, fb] = hexToRgb(getComputedColor(foregroundColor));
  const [br, bg, bb] = hexToRgb(getComputedColor('var(--background)'));

  const blendedR = Math.round(opacity * fr + (1 - opacity) * br);
  const blendedG = Math.round(opacity * fg + (1 - opacity) * bg);
  const blendedB = Math.round(opacity * fb + (1 - opacity) * bb);

  return rgbToHex(blendedR, blendedG, blendedB);
}
