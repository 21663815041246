import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import { getFonts } from 'lib/redux/selectors/fonts';
import { capitalize } from 'lodash';
import {
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { FlexRow } from 'modules/shared/ui';
import { useSelector } from 'react-redux';
import { WebsafeFontsEnum } from '../../../lib/fonts';

export type SelectItemType = {
  value: string;
  label: string;
  style?: React.CSSProperties;
};

export const PLACEHOLDER_VALUE = 'PLACEHOLDER_VALUE';

export const enumToSelectItems = (
  enumObj: Record<string, string>,
  placeholder?: string,
): SelectItemType[] => {
  const items = Object.keys(enumObj).map((key) => ({
    value: enumObj[key],
    label: capitalize(enumObj[key]),
    style: { fontFamily: enumObj[key], fontSize: 14 },
  }));

  if (!placeholder) return items;
  return [{ value: PLACEHOLDER_VALUE, label: placeholder }, ...items];
};

export const selectValueToResult = (value: string): string =>
  value === PLACEHOLDER_VALUE ? '' : value;

type Props = {
  value: string;
  onValueChange: (value: string) => void;
};

export const FontSelect = ({ value, onValueChange }: Props) => {
  const customFonts = useSelector(getFonts);
  const fontSelectItems = enumToSelectItems(WebsafeFontsEnum, undefined);
  const customFontItems = customFonts.map((f) => ({
    value: f.name,
    label: f.name,
    style: { fontFamily: f.name, fontSize: 14 },
  }));

  return (
    <SelectRoot value={value} onValueChange={onValueChange}>
      <SelectTrigger style={{ fontFamily: value, fontSize: 13, width: 100 }}>
        <FlexRow style={{ justifyContent: 'space-between', width: '100%' }}>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <SelectValue />
          </div>
          <SelectIcon>
            <IconChevronDown width={13} height={13} />
          </SelectIcon>
        </FlexRow>
      </SelectTrigger>
      <SelectContent position="popper">
        <SelectViewport>
          <SelectGroup>
            {customFontItems.length > 0 && (
              <SelectLabel style={{ marginLeft: -16 }}>
                Custom Fonts
              </SelectLabel>
            )}
            {customFontItems.map((item) => (
              <SelectItem
                key={item.value}
                value={item.value}
                style={item.style}
              >
                <SelectItemText>{item.label}</SelectItemText>
                <SelectItemIndicator>
                  <IconCheck width={16} height={16} />
                </SelectItemIndicator>
              </SelectItem>
            ))}
          </SelectGroup>
          <SelectGroup>
            {customFontItems.length > 0 && (
              <SelectLabel style={{ marginLeft: -16 }}>
                Default Fonts
              </SelectLabel>
            )}
            {fontSelectItems.map((item) => (
              <SelectItem
                key={item.value}
                value={item.value}
                style={item.style}
              >
                <SelectItemText>{item.label}</SelectItemText>
                <SelectItemIndicator>
                  <IconCheck width={16} height={16} />
                </SelectItemIndicator>
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectViewport>
      </SelectContent>
    </SelectRoot>
  );
};
