import { KonvaAction } from 'modules/shared/konva/konva-action';
import { TimelineColorsEnum } from 'modules/shared/konva/consts';
import { TimelineAction } from 'lib/engine/engine';
import { WorkerTask } from './hooks/use-worker-pool';

type Props = {
  action: TimelineAction;
  stageScale: number;
  defaultPxPerSec: number;
  rowHeight: number;
  rowTop: number;
  lineTopPaddingPx: number;
  stageX: number;
  stageY: number;
  dimensions: { width: number; height: number };
  enqueueTask: (task: WorkerTask) => void;
};

export const StemAction = ({
  action,
  stageScale,
  defaultPxPerSec,
  rowHeight,
  rowTop,
  lineTopPaddingPx,
  stageX,
  stageY,
  dimensions,
  enqueueTask,
}: Props) => {
  const handleDragEnd = () => {};
  const handleTransformEnd = () => {};
  const onClick = () => {};
  const color = {
    activeFill: TimelineColorsEnum.ACTIVE_FILL,
    inactiveFill: TimelineColorsEnum.FILL,
    border: TimelineColorsEnum.DEFAULT_BORDER,
    waveform: TimelineColorsEnum.DEFAULT_WAVEFORM,
  };

  return (
    <KonvaAction
      action={action}
      stageScale={stageScale}
      defaultPxPerSec={defaultPxPerSec}
      rowHeight={rowHeight}
      rowTop={rowTop}
      lineTopPaddingPx={lineTopPaddingPx}
      stageX={stageX}
      stageY={stageY}
      dimensions={dimensions}
      color={color}
      handleDragEnd={handleDragEnd}
      handleTransformEnd={handleTransformEnd}
      handleClick={onClick}
      enqueueTask={enqueueTask}
    />
  );
};
