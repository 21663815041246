import { IconUpload, IconX } from '@tabler/icons-react';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { DndProvider } from 'modules/shared/dnd-provider';
import { FlexCol } from 'modules/shared/ui';
import { useCallback } from 'react';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';

type Props = {
  files?: File[];
  maxFiles?: number;
  onDropFiles?: (files: File[]) => void;
  isLoading?: boolean;
  error?: string;
  style?: any;
  placeholder?: string;
};

export function VoiceDnd({
  files = [],
  maxFiles,
  onDropFiles,
  isLoading,
  error,
  style,
  placeholder,
}: Props) {
  const handleFileDrop = useCallback(
    (item: any) => {
      if (isLoading || !onDropFiles) return;
      const droppedFiles = item?.files ?? [];
      if (droppedFiles.length === 0) return;
      if (maxFiles && droppedFiles.length >= maxFiles) return;

      const newFiles = files.concat(Array.from(droppedFiles));
      onDropFiles(newFiles);
    },
    [files, maxFiles, onDropFiles, isLoading],
  );

  const removeFile = useCallback(
    (index: number) => {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      if (onDropFiles) onDropFiles(newFiles);
    },
    [files, onDropFiles],
  );

  const getBody = () => {
    if (isLoading) {
      return <TailSpin width={16} height={16} color="var(--text-light)" />;
    }
    return (
      <>
        <DndContainer>
          <IconUpload style={{ width: 16, height: 16 }} />
          <div>{placeholder || 'Click or Drag Files'}</div>
          {error && <div>{error}</div>}
        </DndContainer>
        <FlexCol style={{ gap: 8 }}>
          {files.map((file, index) => (
            <FileRow key={file.name}>
              <span>{file.name}</span>
              <HoverIcon onClick={() => removeFile(index)}>
                <IconX style={{ width: 12, height: 12 }} />
              </HoverIcon>
            </FileRow>
          ))}
        </FlexCol>
      </>
    );
  };

  return (
    <FlexCol>
      <DndProvider
        onDrop={handleFileDrop}
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'auto',
          borderRadius: 4,
          border: '1px dashed var(--border)',
          padding: 8,
          ...style,
        }}
      >
        {getBody()}
      </DndProvider>
    </FlexCol>
  );
}

const DndContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  font-weight: 400;
  font-size: 13px;
  gap: 8px;
  padding: 16px;
`;

const FileRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border);
  border-radius: 4px;
  transition: 0.2s ease-in-out;
  font-size: 12px;

  :hover {
    background: var(--hover);
  }
`;
