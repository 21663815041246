import React from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import styled from 'styled-components';

type DndProviderProps = {
  onDrop: (item: { files: any[] }) => void;
  children?: React.ReactNode;
  style?: any;
  renderOverlay?: boolean;
  frosted?: boolean;
};

export const DndProvider = ({
  onDrop,
  children,
  style,
  renderOverlay,
  frosted,
}: DndProviderProps) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (onDrop) {
          onDrop(item);
        }
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop],
  );

  const isActive = canDrop && isOver;
  return (
    <div
      ref={drop}
      style={
        isActive
          ? { background: 'var(--hover)', ...style }
          : { transition: 'all 0.2s ease', ...style }
      }
    >
      {children}
      {renderOverlay ? (
        frosted ? (
          <FrostedOverlay isActive={isActive} />
        ) : (
          <Overlay isActive={isActive} />
        )
      ) : null}
    </div>
  );
};

const Overlay = styled.div<{ isActive: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: ${({ isActive }) => (isActive ? 0.3 : 0)};
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
`;

const FrostedOverlay = styled.div<{ isActive: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--frosted);
  backdrop-filter: blur(4px);
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
`;
