import styled from 'styled-components';

export const Title = styled.div`
  font-size: 16px;
  color: var(--text-primary);
  margin-bottom: 4px;
  font-weight: 500;
`;

export const Description = styled.div`
  color: var(--text-light);
  font-size: 11px;
  font-weight: 400;
  margin-top: 8px;
  margin-left: 4px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Input = styled.input`
  all: unset;
  font-size: 13px;
  height: 34px;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--text-primary);
  background: var(--background-secondary);
  border: 1px solid var(--border);

  &:disabled {
    color: var(--text-light);
    cursor: not-allowed;
  }
`;

export const Label = styled.div`
  color: var(--text-light);
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 8px;
  margin-left: 4px;
`;
