import { createSelector } from '@reduxjs/toolkit';
import { ProjectUserType } from 'lib/Types';
import { RootState } from '..';

export const getProjectUsersDict = (state: RootState) => state.projectUsers;

export const getProjectUsers = createSelector(
  [getProjectUsersDict],
  (projectUsers): ProjectUserType[] => Object.values(projectUsers),
);

export const makeGetProjUsersByProjId = (projectId: string) =>
  createSelector([getProjectUsers], (projectUsers) =>
    projectUsers.filter((pu) => pu.project_id === projectId),
  );
