import { ProjectDubType } from 'lib/Types';
import { RootState } from 'lib/redux';
import { getProjectDubLines } from 'lib/redux/selectors/lines';
import { setEditorSpinnerRotation } from 'lib/redux/slices/editor';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ROTATION_INCREMENT = 10;
const UPDATE_INTERVAL = 20; // Update every 20 milliseconds
const MAX_ROTATION = 360; // Maximum rotation value before rolling over

type Props = {
  projectDub: ProjectDubType;
};

export const useKonvaSpinnerRotation = ({ projectDub }: Props) => {
  const dispatch = useDispatch();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const rotationRef = useRef(0);

  const lines = useSelector((state: RootState) =>
    getProjectDubLines(projectDub.id)(state),
  );

  const isUiLoading = useMemo(
    () => (lines || []).some((line) => line.uiLoading),
    [lines],
  );

  useEffect(() => {
    if (isUiLoading) {
      rotationRef.current = 0;
      dispatch(setEditorSpinnerRotation(0));
      intervalRef.current = setInterval(() => {
        rotationRef.current =
          (rotationRef.current + ROTATION_INCREMENT) % MAX_ROTATION;
        dispatch(setEditorSpinnerRotation(rotationRef.current));
      }, UPDATE_INTERVAL);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [dispatch, isUiLoading]);
};
