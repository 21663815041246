import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import React from 'react';
import { ClipTrackType } from 'lib/Types';
import { renameEditableInput } from 'lib/utils/utils';
import { useClipTrackApi } from 'lib/hooks/api/use-clip-track-api';

type Props = {
  clipTrack: ClipTrackType;
  children?: React.ReactNode;
};

export const ClipTrackContextMenu = ({ clipTrack, children }: Props) => {
  const { handleDeleteClipTrack } = useClipTrackApi();

  return (
    <ContextMenu>
      <ContextMenuTrigger>{children}</ContextMenuTrigger>
      <ContextMenuContent onClick={(e) => e.stopPropagation()}>
        <ContextMenuItem
          title="Rename"
          icon="edit"
          onSelect={() => {
            renameEditableInput(`#timeline-row-${clipTrack.id}`);
          }}
        />
        <ContextMenuItem
          title="Delete"
          icon="delete"
          onSelect={() => handleDeleteClipTrack(clipTrack.id)}
        />
      </ContextMenuContent>
    </ContextMenu>
  );
};
