import { IconMenu2 } from '@tabler/icons-react';
import { NavRoutes } from 'lib/routes';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'modules/radix/DropdownMenu';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const BackToolbar = () => {
  const navigate = useNavigate();
  const handleBack = () => navigate(NavRoutes.Projects);
  return (
    <Container>
      <Flex>
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <HoverIcon style={{ color: 'var(--text-primary)' }}>
              <IconMenu2 style={{ width: 16, height: 16 }} />
            </HoverIcon>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={handleBack}>
              Back to projects
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  background: var(--background);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px;
  width: calc(100%-12px);
  -webkit-app-region: drag;
  border-bottom: 1px solid var(--border);
  box-sizing: border-box;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
