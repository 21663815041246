import {
  Popover,
  PopoverPortal,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import { ProjectDubType, ProjectType } from 'lib/Types';
import { getUserMe } from 'lib/redux/selectors/users';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { PopoverContent } from 'modules/radix/Popover';
import { FlexRow } from 'modules/shared/ui';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from 'lib/redux';
import { getProjectDubLines } from 'lib/redux/selectors/lines';
import { ExportHistoryTab } from './components/export-history-tab';
import { ExportTab } from './components/export-tab';
import { PublishTab } from './components/publish-tab';
import { CaptionExportDialog } from './components/caption-export-dialog';

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
};

export const ExportPopover = ({ project, projectDub }: Props) => {
  const user = useSelector(getUserMe);
  const [tab, setTab] = useState<'export' | 'history' | 'publish'>('export');
  const lines = useSelector((state: RootState) =>
    getProjectDubLines(projectDub.id)(state),
  );
  const [numLinesLoading, setNumLinesLoading] = useState(lines.length);
  const [captionsExportProgress, setCaptionsExportProgress] = useState<
    number | null
  >(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [dialogWasOpen, setDialogWasOpen] = useState(false);

  useEffect(() => {
    const loadingLines = lines.filter((line) => line.is_loading);
    setNumLinesLoading(loadingLines.length);
  }, [lines]);

  // Keep the popover open after captions export modal closes
  useEffect(() => {
    if (captionsExportProgress !== null) {
      setDialogWasOpen(true);
    } else {
      if (dialogWasOpen) {
        setPopoverOpen(true);
        setDialogWasOpen(false);
      }
    }
  }, [captionsExportProgress]);

  const renderBody = () => {
    switch (tab) {
      case 'export':
        return (
          <ExportTab
            project={project}
            projectDub={projectDub}
            numLinesLoading={numLinesLoading}
            setTab={setTab}
            setCaptionsExportProgress={setCaptionsExportProgress}
          />
        );
      case 'history':
        return <ExportHistoryTab project={project} projectDub={projectDub} />;
      case 'publish':
        return <PublishTab project={project} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger>
          <Button style={{ padding: '8px 12px' }}>Export</Button>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent sideOffset={4} style={{ width: 500 }}>
            <FlexRow
              style={{
                borderBottom: '1px solid var(--border)',
                padding: 16,
                gap: 8,
                zIndex: 5,
              }}
            >
              <HoverIcon
                onClick={() => setTab('export')}
                style={{ fontWeight: tab === 'export' ? 600 : 400 }}
              >
                Export
              </HoverIcon>
              <HoverIcon
                onClick={() => setTab('history')}
                style={{ fontWeight: tab === 'history' ? 600 : 400 }}
              >
                History
              </HoverIcon>
              {user?.role === 'ADMIN' ? (
                <HoverIcon
                  onClick={() => setTab('publish')}
                  style={{ fontWeight: tab === 'publish' ? 600 : 400 }}
                >
                  Publish
                </HoverIcon>
              ) : null}
            </FlexRow>
            {renderBody()}
          </PopoverContent>
        </PopoverPortal>
      </Popover>
      <CaptionExportDialog captionsExportProgress={captionsExportProgress} />
    </>
  );
};

// This is the same button as shared/ui.tsx but inherits from a div
// PopoverTrigger implicity uses a button element and two buttons cannot be nested
export const Button = styled.div`
  all: unset;
  cursor: pointer;
  background: linear-gradient(#4437ca, #3d31b5);
  border: 1px solid var(--border);
  padding: 8px;
  font-size: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  color: white;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  gap: 8px;
  box-shadow:
    var(--hover),
    inset 0px 0px 0px 1px var(--border);

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:hover {
    background: linear-gradient(#4638cb, #3e32b6); // Example hover state
  }
`;
