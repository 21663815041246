import { ProjectUserRole } from 'lib/Types';
import api from 'lib/api';
import { makeGetProjUsersByProjId } from 'lib/redux/selectors/projectUsers';
import { getUsersDict } from 'lib/redux/selectors/users';
import { upsertProjectUsers } from 'lib/redux/slices/projectUsers';
import { capitalize } from 'lodash';
import { SettingsRow } from 'modules/shared/sidebar-modal/settings-row';
import { Button, FlexRow as FlexNoGap, Input } from 'modules/shared/ui';
import { useState } from 'react';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { TailSpin } from 'react-loader-spinner';
import { ProjectUserRoleSelect } from './components/project-user-role-select';

type Props = {
  projectId: string;
};

export const ManagePermissions = ({ projectId }: Props) => {
  const dispatch = useDispatch();
  const projectUsers = useSelector(makeGetProjUsersByProjId(projectId));
  const usersDict = useSelector(getUsersDict);
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);

  projectUsers.sort((a, b) => {
    // Prioritize users with the 'OWNER' role
    if (a.role === ProjectUserRole.OWNER && b.role !== ProjectUserRole.OWNER) {
      return -1;
    }

    if (b.role === ProjectUserRole.OWNER && a.role !== ProjectUserRole.OWNER) {
      return 1;
    }

    // If roles are the same, sort alphabetically by name
    const aName = usersDict[a.user_id].name;
    const bName = usersDict[b.user_id].name;
    return aName.localeCompare(bName);
  });

  const handleAddUser = async () => {
    const projectUser = projectUsers.find((pu) => pu.user_id === value);
    if (projectUser) return;

    try {
      setLoading(true);
      const { data } = await api.projectUsers.add({
        project_id: projectId,
        email: value,
      });
      dispatch(upsertProjectUsers([data.project_user]));
      setValue('');
    } catch (e) {
      alert('Could not find a user with this email');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FlexCol>
      <FlexRow>
        {/* TODO combobox to prefill people in your team */}
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Email"
        />
        <Button
          onClick={handleAddUser}
          disabled={isLoading}
          style={{ width: 160 }}
        >
          {isLoading ? (
            <TailSpin width={13} height={13} color="var(--text-primary)" />
          ) : (
            'Invite to project'
          )}
        </Button>
      </FlexRow>
      <RowContainer>
        {projectUsers.map((pu) => (
          <SettingsRow
            title={`${usersDict[pu.user_id].name} (${capitalize(pu.role)})`}
            subtitle={usersDict[pu.user_id].email}
            key={pu.user_id}
            item={<ProjectUserRoleSelect projectUser={pu} />}
            icon={<Avatar name={usersDict[pu.user_id].name} round size="28" />}
          />
        ))}
      </RowContainer>
    </FlexCol>
  );
};

const FlexRow = styled(FlexNoGap)`
  gap: 16px;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  gap: 8px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  height: 90%;
  padding: 12px 0;
  overflow: auto;
`;
