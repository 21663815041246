import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CaptionType } from 'lib/Types';

export type CaptionsReduxType = { [id: string]: CaptionType };
export const initialState = {} as CaptionsReduxType;

export const captionsSlice = createSlice({
  name: 'captions',
  initialState,
  reducers: {
    upsertCaptions: (
      state: CaptionsReduxType,
      action: PayloadAction<({ id: string } & Partial<CaptionType>)[]>,
    ) => {
      action.payload.forEach((caption) => {
        state[caption.id] = { ...(state[caption.id] || {}), ...caption };
      });
      return state;
    },
    resetCaptions: () => initialState,
  },
});

export const { upsertCaptions, resetCaptions } = captionsSlice.actions;
