import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectDubType } from 'lib/Types';

export type ProjectDubsReduxType = { [id: string]: ProjectDubType };
const initialState = {} as ProjectDubsReduxType;

export const projectDubsSlice = createSlice({
  name: 'projectDubs',
  initialState,
  reducers: {
    upsertProjectDubs: (
      state: ProjectDubsReduxType,
      action: PayloadAction<({ id: string } & Partial<ProjectDubType>)[]>,
    ) => {
      action.payload.forEach((projectDub) => {
        state[projectDub.id] = {
          ...(state[projectDub.id] || {}),
          ...projectDub,
        };
      });
      return state;
    },
    deleteProjectDub: (
      state: ProjectDubsReduxType,
      action: PayloadAction<string>,
    ) => {
      delete state[action.payload];
      return state;
    },
    resetProjectDubs: () => initialState,
  },
});

export const { upsertProjectDubs, deleteProjectDub, resetProjectDubs } =
  projectDubsSlice.actions;
