import { TranscriptCharacterType } from 'lib/Types';
import api from 'lib/api';
import {
  deleteTranscriptCharacter,
  upsertTranscriptCharacters,
} from 'lib/redux/slices/transcriptCharacters';
import { useDispatch } from 'react-redux';

export const useTranscriptCharacterApi = () => {
  const dispatch = useDispatch();

  const handleCreateTranscriptCharacter = async (d: { project_id: string }) => {
    try {
      const { data } = await api.transcriptCharacters.create(d);
      dispatch(upsertTranscriptCharacters([{ ...data.transcript_character }]));
    } catch (err) {
      alert('Error creating character');
    }
  };

  const handleUpdateTranscriptCharacter = async (
    character: { id: string } & Partial<TranscriptCharacterType>,
  ) => {
    try {
      dispatch(upsertTranscriptCharacters([{ ...character }]));
      await api.transcriptCharacters.update(character.id, character);
    } catch (err) {
      alert('Error updating character');
    }
  };

  const handleDeleteTranscriptCharacter = async (character: { id: string }) => {
    try {
      await api.transcriptCharacters.delete(character.id);
      dispatch(deleteTranscriptCharacter(character.id));
    } catch (err) {
      alert('Error deleting character');
    }
  };

  return {
    handleCreateTranscriptCharacter,
    handleUpdateTranscriptCharacter,
    handleDeleteTranscriptCharacter,
  };
};
