import { offset, useFloating } from '@floating-ui/react-dom';
import { Popover, PopoverTrigger } from '@radix-ui/react-popover';
import {
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
  IconCheck,
  IconDots,
  IconLetterCase,
  IconLetterCaseUpper,
} from '@tabler/icons-react';
import { CaptionType, FontStyleEnum } from 'lib/Types';
import api from 'lib/api';
import { upsertCaptions } from 'lib/redux/slices/captions';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { PopoverContent } from 'modules/radix/Popover';
import {
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { FlexCol, FlexRow, Input, Label } from 'modules/shared/ui';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useClickAway, useKey } from 'react-use';
import styled from 'styled-components';
import { UseFontProperties } from '../../lib/hooks/use-font-properties';
import { ColorPicker } from './components/color-picker';
import { FontSelect } from './components/font-select';
import { ShadowPicker } from './components/shadow-picker';
import { StylePicker } from './components/style-picker';
import { OutlinePicker } from './components/outline-picker';

interface Props {
  caption: CaptionType;
  position: {
    x: number;
    y: number;
  };
  dimensions: {
    width: number;
    height: number;
  };
  onClose: () => void;
}

export const CaptionPopover = ({
  caption,
  position,
  dimensions,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const { fontProperties } = UseFontProperties();
  const [fontSize, setFontSize] = useState(caption.font_size);
  const { x, y, refs, strategy, update } = useFloating<HTMLDivElement>({
    placement: 'top',
    middleware: [offset(4)],
  });
  const inputRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(update, [update, position, dimensions]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleUpdate = async (data: Partial<CaptionType>) => {
    try {
      dispatch(upsertCaptions([{ id: caption.id, ...data }]));
      await api.captions.update(caption.id, data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFontUpdate = (font: string) => {
    // Check if font is compatible with the current font weight and style
    const { availableWeights, supportsItalic } = fontProperties[font];
    const validWeight = availableWeights.includes(caption.font_weight)
      ? caption.font_weight
      : availableWeights[0];
    const validStyle = supportsItalic
      ? caption.font_style
      : FontStyleEnum.NORMAL;

    handleUpdate({
      font,
      font_weight: validWeight,
      font_style: validStyle,
    });
  };

  const handleStyleUpdate = (combinedStyle: string) => {
    const [fontWeight, fontStyle] = combinedStyle.split(' ');
    if (Number.isNaN(Number(fontWeight))) return;

    handleUpdate({
      font_weight: Number(fontWeight),
      font_style: fontStyle as any,
    });
  };

  const handleClose = () => {
    onClose();
  };

  // Close the popover on click away and escape
  useClickAway(refs.floating, handleClose);
  useKey('Escape', handleClose);

  return (
    <>
      <div
        ref={refs.setReference}
        style={{
          position: 'absolute',
          left: position.x,
          top: position.y,
          width: dimensions.width,
          height: dimensions.height,
          //   background: 'red',
          pointerEvents: 'none',
        }}
      />
      <div
        ref={refs.setFloating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
      >
        <PopoverContainer>
          <FontSelect value={caption.font} onValueChange={handleFontUpdate} />
          <Input
            value={fontSize}
            onChange={(e) => setFontSize(Number(e.target.value))}
            onBlur={() => handleUpdate({ font_size: fontSize })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }
            }}
            style={{ width: 48, padding: 0, fontSize: 13 }}
          />
          <HoverIcon
            onClick={() => {
              const alignOrder = ['center', 'left', 'right'];
              const currentIndex = alignOrder.indexOf(caption.align);
              const newIndex = (currentIndex + 1) % alignOrder.length;
              handleUpdate({ align: alignOrder[newIndex] as any });
            }}
          >
            {caption.align === 'center' ? (
              <IconAlignCenter style={{ width: 18, height: 18 }} />
            ) : caption.align === 'right' ? (
              <IconAlignRight style={{ width: 18, height: 18 }} />
            ) : (
              <IconAlignLeft style={{ width: 18, height: 18 }} />
            )}
          </HoverIcon>
          <ColorPicker
            color={caption.color}
            onChange={(c) => handleUpdate({ color: c.hex })}
          />
          <Popover>
            <PopoverTrigger>
              <HoverIcon>
                <IconDots style={{ width: 18, height: 18 }} />
              </HoverIcon>
            </PopoverTrigger>
            <PopoverContent style={{ width: 260 }} side="top">
              <FlexCol
                style={{ padding: 16, boxSizing: 'border-box', gap: 16 }}
              >
                <SpacedFlexRow>
                  <Label>Words per Line</Label>
                  <SelectRoot
                    value={caption.strategy}
                    onValueChange={(v) => handleUpdate({ strategy: v as any })}
                  >
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent
                      position="popper"
                      style={{ marginTop: '4px' }}
                    >
                      <SelectViewport>
                        {Object.entries({
                          auto: 'Automatic',
                          word: 'Single Word',
                        }).map(([k, v]) => (
                          <SelectItem value={k} key={k}>
                            <SelectItemText>{v}</SelectItemText>
                            <SelectItemIndicator>
                              <IconCheck />
                            </SelectItemIndicator>
                          </SelectItem>
                        ))}
                      </SelectViewport>
                    </SelectContent>
                  </SelectRoot>
                </SpacedFlexRow>
                <SpacedFlexRow>
                  <Label>Capitalization</Label>
                  <ToggleContainer>
                    <ToggleButton
                      isActive={caption.capitalization === 'normal'}
                      onClick={() => handleUpdate({ capitalization: 'normal' })}
                    >
                      <IconLetterCase />
                    </ToggleButton>
                    <ToggleButton
                      isActive={caption.capitalization === 'uppercase'}
                      onClick={() =>
                        handleUpdate({ capitalization: 'uppercase' })
                      }
                    >
                      <IconLetterCaseUpper />
                    </ToggleButton>
                  </ToggleContainer>
                </SpacedFlexRow>
                <SpacedFlexRow>
                  <Label>Style</Label>
                  <StylePicker
                    font={caption.font}
                    fontWeight={caption.font_weight}
                    fontStyle={caption.font_style}
                    onValueChange={handleStyleUpdate}
                  />
                </SpacedFlexRow>
                <SpacedFlexRow>
                  <Label>Outline</Label>
                  <OutlinePicker caption={caption} onUpdate={handleUpdate} />
                </SpacedFlexRow>
                <ShadowPicker
                  caption={caption}
                  onUpdate={(s) => handleUpdate({ shadow: s })}
                />
              </FlexCol>
            </PopoverContent>
          </Popover>
        </PopoverContainer>
      </div>
    </>
  );
};

const SpacedFlexRow = styled(FlexRow)`
  justify-content: space-between;
`;
const PopoverContainer = styled(FlexRow)`
  padding: 4px;
  font-size: 12px;
  text-align: center;
  border-radius: 4px;
  gap: 8px;
  color: var(--text-primary);
  border: 1px solid var(--border);
  background: var(--background-secondary);
`;

const ToggleContainer = styled(FlexRow)`
  gap: 4px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 2px;
  transition: border-color 0.2s ease-in-out;

  &:hover,
  &:focus-within {
    border-color: var(--border);
  }
`;

const ToggleButton = styled(HoverIcon)<{ isActive?: boolean }>`
  background: ${(props) =>
    props.isActive ? 'var(--text-light)' : 'transparent'};

  &:hover {
    background: ${(props) =>
      props.isActive ? 'var(--text-light)' : 'var(--hover)'};
  }
  &:active {
    background: ${(props) =>
      props.isActive ? 'var(--text-light)' : 'var(--hover)'};
  }
`;
