import { IconCheck } from '@tabler/icons-react';
import fileDownload from 'js-file-download';
import { ProjectDubType, ProjectType } from 'lib/Types';
import api from 'lib/api';
import {
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { Button, FlexCol, Label } from 'modules/shared/ui';
import { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
};

const ExportTranscriptFormats = {
  docx: 'DOCX',
};

export const ExportTranscript = ({ project, projectDub }: Props) => {
  const [format, setFormat] = useState<'docx'>('docx');
  const [isLoading, setLoading] = useState(false);

  const handleExport = async () => {
    try {
      setLoading(true);
      const { data } = await api.projectDubs.downloadTranscript(projectDub.id);
      const name = `${project.name} (${projectDub.lang})`;
      fileDownload(data, `${name}.docx`);
    } catch (e) {
      alert(`Error exporting ${format}`);
    } finally {
      setLoading(false);
    }
  };
  return (
    <FlexCol style={{ gap: 16 }}>
      <div>
        <Label>Format</Label>
        <SelectRoot
          value={format}
          onValueChange={(e: any) => {
            setFormat(e);
          }}
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectViewport>
              {Object.entries(ExportTranscriptFormats).map(([k, v]) => (
                <SelectItem value={k} key={k}>
                  <SelectItemText>{v}</SelectItemText>
                  <SelectItemIndicator>
                    <IconCheck />
                  </SelectItemIndicator>
                </SelectItem>
              ))}
            </SelectViewport>
          </SelectContent>
        </SelectRoot>
      </div>
      <Button onClick={handleExport}>
        {isLoading ? (
          <TailSpin width={13} height={13} color="white" />
        ) : (
          <div>Export</div>
        )}
      </Button>
    </FlexCol>
  );
};
