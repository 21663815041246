import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkspaceType } from 'lib/Types';

export type WorkspaceReduxType = { [id: string]: WorkspaceType };
const initialState = {} as WorkspaceReduxType;

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    upsertWorkspaces: (
      state: WorkspaceReduxType,
      action: PayloadAction<({ id: string } & Partial<WorkspaceType>)[]>,
    ) => {
      action.payload.forEach((workspace) => {
        state[workspace.id] = {
          ...(state[workspace.id] || {}),
          ...workspace,
        };
      });
      return state;
    },
    resetWorkspaces: () => initialState,
  },
});

export const { upsertWorkspaces, resetWorkspaces } = workspacesSlice.actions;
