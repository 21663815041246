import { TimelineEngine } from 'lib/engine/engine';
import { ProjectType } from 'lib/Types';
import { useTimelineEffects } from 'modules/editor/hooks/use-timeline-effects';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useTranscriptCharacterApi } from 'lib/hooks/api/use-transcript-character-api';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import { useTranscriptEditorData } from 'modules/transcript-editor/hooks/use-transcript-editor-data';
import { KonvaTimeline } from './components/konva-timeline';
import { TimelineSidebar } from './components/timeline-sidebar';

type Props = {
  project: ProjectType;
  timelineEngine: MutableRefObject<TimelineEngine>;
};

export const TimelinePanel = ({ project, timelineEngine }: Props) => {
  const [stageY, setStageY] = useState(0);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const { data } = useTranscriptEditorData({ project });
  const { refs, effects } = useTimelineEffects();
  const actions = data.map((r) => r.actions).flat();
  const containerRef = useRef<any>();
  const rowHeight = 40;
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { handleCreateTranscriptCharacter } = useTranscriptCharacterApi();

  useEffect(() => {
    if (!timelineEngine.current) return;
    timelineEngine.current.effects = effects;
  }, [effects]);

  useEffect(() => {
    if (!timelineEngine.current) return;
    timelineEngine.current.data = data;
    timelineEngine.current.reRender();
  }, [data]);

  useEffect(() => {
    const observeTarget = containerRef.current;
    if (observeTarget) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (!entries || entries.length === 0) {
          return;
        }
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });
      resizeObserver.observe(observeTarget);
      return () => resizeObserver.unobserve(observeTarget);
    }
  }, []);

  return (
    <>
      <PanelGroup direction="horizontal">
        <Panel defaultSize={20} style={{ background: 'var(--background)' }}>
          <ContextMenu>
            <ContextMenuTrigger>
              <TimelineSidebar
                ref={sidebarRef}
                data={data}
                rowHeight={rowHeight}
                setStageY={setStageY}
              />
            </ContextMenuTrigger>
            <ContextMenuContent>
              <ContextMenuItem
                title="Add Speaker"
                icon="add"
                onSelect={() =>
                  handleCreateTranscriptCharacter({ project_id: project.id })
                }
              />
            </ContextMenuContent>
          </ContextMenu>
        </Panel>
        <PanelResizeHandle
          style={{ width: 10, background: 'var(--background)' }}
        />
        <Panel>
          <div
            ref={containerRef as any}
            style={{ width: '100%', height: '100%' }}
          >
            {dimensions.width && dimensions.height ? (
              <KonvaTimeline
                project={project}
                data={data}
                timelineEngine={timelineEngine}
                rowHeight={rowHeight}
                dimensions={dimensions}
                sidebarRef={sidebarRef}
                stageY={stageY}
                setStageY={setStageY}
              />
            ) : null}
          </div>
        </Panel>
      </PanelGroup>
      <>
        {actions
          .filter((a) => a.src)
          .map((a) => (
            <ReactPlayer
              key={a.id}
              // hls_src drifts if there is an sr mismatch between original audio sr and 44.1kHz
              url={a.src as string}
              ref={(node) => {
                refs.current[a.id as string] = node;
              }}
            />
          ))}
      </>
    </>
  );
};
