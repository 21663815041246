import { IconMenu2, IconSettings } from '@tabler/icons-react';
import { ProjectType } from 'lib/Types';
import { RootState } from 'lib/redux';
import { getProjectDubs } from 'lib/redux/selectors/projectDubs';
import { getProjectTranscriptLines } from 'lib/redux/selectors/transcriptLines';
import { setProjectSettingsOpen } from 'lib/redux/slices/editor';
import { NavRoutes } from 'lib/routes';
import { ProjectSelect } from 'modules/editor/components/toolbar/components/project-select';
import { AlertDialog } from 'modules/radix/AlertDialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'modules/radix/DropdownMenu';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { CreateProjectDubModal } from 'modules/shared/create-project-dub-modal';
import { Button } from 'modules/shared/ui';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ImportTranscriptModal } from './import-transcript-modal';

type Props = {
  project: ProjectType;
};

export const Toolbar = ({ project }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAlertDialogOpen, setAlertDialogOpen] = useState(false);
  const [isProjectDubModalOpen, setProjectDubModalOpen] = useState(false);
  const [isImportTranscriptModalOpen, setImportTranscriptModalOpen] =
    useState(false);
  const handleBack = () => navigate(NavRoutes.Projects);
  const transcriptLines = useSelector((state: RootState) =>
    getProjectTranscriptLines(project.id)(state),
  );
  const projectDubs = useSelector(getProjectDubs).filter(
    (pd) => pd.project_id === project.id,
  );

  const numApproved = transcriptLines.filter((t) => !!t.approved_at).length;

  return (
    <>
      <Container>
        <Flex>
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
              <HoverIcon style={{ color: 'var(--text-primary)' }}>
                <IconMenu2 style={{ width: 16, height: 16 }} />
              </HoverIcon>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onClick={handleBack}>
                Back to projects
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => setImportTranscriptModalOpen(true)}
                disabled={projectDubs.length > 0}
              >
                Import transcript
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Title>{project.name}</Title>
        </Flex>
        <Flex>
          <HoverIcon onClick={() => dispatch(setProjectSettingsOpen(true))}>
            <IconSettings />
          </HoverIcon>
          {projectDubs.length ? (
            <ProjectSelect project={project} />
          ) : (
            <AlertDialog
              title="Are you sure you want to generate a dub?"
              description={`You have only approved ${numApproved} out of ${transcriptLines.length} lines. Mistakes in the transcript will be reflected in the dub.`}
              confirmTitle="Generate dub"
              onConfirm={() => {
                setProjectDubModalOpen(true);
              }}
              open={isAlertDialogOpen}
              onOpenChange={setAlertDialogOpen}
            >
              <Button
                onClick={(e) => {
                  if (numApproved >= transcriptLines.length) {
                    setProjectDubModalOpen(true);
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                Generate dub ({numApproved}/{transcriptLines.length} approved)
              </Button>
            </AlertDialog>
          )}
        </Flex>
      </Container>
      <CreateProjectDubModal
        isOpen={isProjectDubModalOpen}
        setOpen={setProjectDubModalOpen}
        project={project}
      />
      <ImportTranscriptModal
        isOpen={isImportTranscriptModalOpen}
        setOpen={setImportTranscriptModalOpen}
        project={project}
      />
    </>
  );
};

const Container = styled.div`
  background: var(--background);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px;
  width: calc(100%-12px);
  -webkit-app-region: drag;
  border-bottom: 1px solid var(--border);
  box-sizing: border-box;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
`;
