import { TimelineEngine } from 'lib/engine/engine';
import $ from 'jquery';
import {
  getEditorDuration,
  getEditorSeekTime,
  getTimelineZoom,
} from 'lib/redux/selectors/editor';
import {
  setEditorSeekTime,
  setTimelineZoom,
  soloOrUnsoloRowId,
} from 'lib/redux/slices/editor';
import { MutableRefObject, useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranscriptLineApi } from 'lib/hooks/api/use-transcript-line-api';
import { useDispatch, useSelector } from 'react-redux';
import { useActiveTranscriptLine } from './use-active-transcript-line';
import { useTranscriptLineNav } from './use-transcript-line-nav';

type Props = {
  timelineEngine: MutableRefObject<TimelineEngine>;
  keepPlaybackOnPause?: boolean;
};

export const useTranscriptEditorHotkeys = ({
  timelineEngine,
  keepPlaybackOnPause,
}: Props) => {
  const dispatch = useDispatch();
  const { handlePlayPause } = useEditorHandler({
    timelineEngine,
    keepPlaybackOnPause,
  });
  const { handleApproveTranscriptLine } = useTranscriptLineApi();
  const zoom = useSelector(getTimelineZoom);
  const duration = useSelector(getEditorDuration);
  // Must use a ref to update duration inside useHotkeys
  const durationRef = useRef(duration);
  const { activeTranscriptLine } = useActiveTranscriptLine();
  const { handleNextTranscriptLine, handlePrevTranscriptLine } =
    useTranscriptLineNav();

  useEffect(() => {
    durationRef.current = duration;
  }, [duration]);

  const checkFocused = () => {
    const dialog = $('[role="dialog"]');
    return !dialog.length;
  };

  useHotkeys(
    'space',
    (e) => {
      if (!checkFocused()) return;
      handlePlayPause();
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, handlePlayPause],
  );

  useHotkeys(
    'e',
    (e) => {
      if (
        !checkFocused() ||
        !activeTranscriptLine.id ||
        activeTranscriptLine.approved_at
      ) {
        return;
      }
      handleApproveTranscriptLine(activeTranscriptLine.id);
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, activeTranscriptLine],
  );

  useHotkeys(
    's',
    (e) => {
      if (!checkFocused() || !activeTranscriptLine.id) return;
      dispatch(
        soloOrUnsoloRowId({
          id: activeTranscriptLine.transcript_character_id,
          multi: false,
        }),
      );
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, activeTranscriptLine],
  );

  useHotkeys(
    'mod+=,mod+NumpadAdd',
    (e) => {
      if (!checkFocused()) return;
      dispatch(setTimelineZoom(zoom * 1.5));
      e.preventDefault();
      e.stopPropagation();
    },
    { enableOnFormTags: ['INPUT'], enableOnContentEditable: true },
    [checkFocused, zoom],
  );

  useHotkeys(
    'mod+minus,mod+NumpadSubtract',
    (e) => {
      if (!checkFocused()) return;
      dispatch(setTimelineZoom(zoom * 0.5));
      e.preventDefault();
      e.stopPropagation();
    },
    { enableOnFormTags: ['INPUT'], enableOnContentEditable: true },
    [checkFocused, zoom],
  );

  useHotkeys('mod+left', (e) => {
    if (!checkFocused()) return;
    if (timelineEngine.current.isPlaying) {
      timelineEngine.current.pause();
    }
    timelineEngine.current.setTime(0);
    e.preventDefault();
    e.stopPropagation();
  });

  useHotkeys('mod+right', (e) => {
    if (!checkFocused()) return;
    if (timelineEngine.current.isPlaying) {
      timelineEngine.current.pause();
    }
    timelineEngine.current.setTime(durationRef.current);
    e.preventDefault();
    e.stopPropagation();
  });

  useHotkeys(
    'up,left',
    (e) => {
      if (!activeTranscriptLine) return;
      handlePrevTranscriptLine();
      e.preventDefault();
      e.stopPropagation();
    },
    [handlePrevTranscriptLine, activeTranscriptLine],
  );

  useHotkeys(
    'down,right',
    (e) => {
      if (!activeTranscriptLine) return;
      handleNextTranscriptLine();
      e.preventDefault();
      e.stopPropagation();
    },
    [handleNextTranscriptLine, activeTranscriptLine],
  );
};

type EditorHandlerProps = {
  timelineEngine: React.MutableRefObject<TimelineEngine>;
  keepPlaybackOnPause?: boolean;
};

export const useEditorHandler = ({
  timelineEngine,
  keepPlaybackOnPause,
}: EditorHandlerProps) => {
  const dispatch = useDispatch();
  const seekTime = useSelector(getEditorSeekTime);

  const handlePlayPause = () => {
    if (timelineEngine.current.isPlaying) {
      timelineEngine.current.pause();
      if (keepPlaybackOnPause) {
        dispatch(setEditorSeekTime(timelineEngine.current.getTime()));
      } else {
        timelineEngine.current.setTime(seekTime);
      }
    } else timelineEngine.current.play({});
  };

  return { handlePlayPause };
};
