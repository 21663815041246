import styled from 'styled-components';
import * as SwitchPrimitive from '@radix-ui/react-switch';

const StyledSwitch = styled(SwitchPrimitive.Root)`
  width: 42px;
  height: 25px;
  background-color: var(--background-secondary);
  border: 1px solid var(--border);
  box-sizing: border-box;
  border-radius: 9999px;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &[data-state='checked'] {
    background-color: var(--purple);
    box-shadow: 0 2px 10px var(--background);
  }

  :disabled {
    opacity: 0.4;
  }
`;

const StyledThumb = styled(SwitchPrimitive.Thumb)`
  display: block;
  width: 22px;
  height: 22px;
  background-color: var(--background);
  border: 1px solid var(--border);
  border-radius: 9999px;
  box-shadow: 0 2px 2px var(--black-a7);
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;

  &[data-state='checked'] {
    transform: translateX(18px);
  }
`;

export const Switch = StyledSwitch;
export const SwitchThumb = StyledThumb;
