const MAX_CACHE_ENTRIES = 1000;
const DB_VERSION = 4;
const DB_NAME = 'waveformDB';

export const setupIndexedDB = (): Promise<IDBDatabase> =>
  new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result;

      // Delete existing object store(s) and create new ones
      if (db.objectStoreNames.contains('mipmaps')) {
        db.deleteObjectStore('mipmaps');
      }
      const store = db.createObjectStore('mipmaps');
      store.createIndex('utcTimestamp', 'utcTimestamp', { unique: false });
    };

    request.onerror = (event: Event) => {
      console.error(
        'IndexedDB error:',
        (event.target as IDBOpenDBRequest).error,
      );
      reject((event.target as IDBOpenDBRequest).error);
    };

    request.onsuccess = (event: Event) => {
      resolve((event.target as IDBOpenDBRequest).result);
    };

    request.onblocked = () => {
      console.warn(
        'IndexedDB setup is blocked. This might be due to other open connections.',
      );
    };
  });

export const getMipmapFromDB = async (
  url: string,
  level: number,
): Promise<Float32Array | null> => {
  const db = await setupIndexedDB();
  return new Promise((resolve, reject) => {
    // Specify readonly to allow concurrent access
    const transaction = db.transaction(['mipmaps'], 'readonly');
    const store = transaction.objectStore('mipmaps');
    const request = store.get(url);

    request.onerror = (event: Event) => {
      console.error(
        'Error fetching mipmap from IndexedDB:',
        (event.target as IDBRequest).error,
      );
      reject((event.target as IDBRequest).error);
    };

    request.onsuccess = () => {
      const { result } = request;

      if (result && result.mipmaps[level]) {
        resolve(result.mipmaps[level]);
      } else {
        resolve(null);
      }
    };
  });
};

export const storeMipmapInDB = async (
  url: string,
  mipmaps: Float32Array[],
): Promise<void> => {
  const db = await setupIndexedDB();
  const utcTimestamp = new Date().getTime();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['mipmaps'], 'readwrite');
    const store = transaction.objectStore('mipmaps');
    const request = store.put({ url, mipmaps, utcTimestamp }, url);

    request.onerror = (event: Event) => {
      console.error(
        'Error storing mipmap in IndexedDB:',
        (event.target as IDBRequest).error,
      );
      reject((event.target as IDBRequest).error);
    };

    request.onsuccess = () => {
      resolve();
      trimCache(db).catch(console.error);
    };
  });
};

export const deleteMipmapFromDB = async (lineId: string): Promise<void> => {
  const db = await setupIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['mipmaps'], 'readwrite');
    const store = transaction.objectStore('mipmaps');
    const deleteRequest = store.delete(lineId);

    deleteRequest.onerror = (event: Event) => {
      console.error(
        'Error deleting mipmap from IndexedDB:',
        (event.target as IDBRequest).error,
      );
      reject((event.target as IDBRequest).error);
    };

    deleteRequest.onsuccess = () => {
      resolve();
    };
  });
};

// Trim cache if there are more than MAX_CACHE_ENTRIES
const trimCache = async (db: IDBDatabase): Promise<void> => {
  const transaction = db.transaction(['mipmaps'], 'readwrite');
  const store = transaction.objectStore('mipmaps');
  const index = store.index('utcTimestamp');

  const countRequest = store.count();
  countRequest.onsuccess = () => {
    const totalCount = countRequest.result;
    if (totalCount > MAX_CACHE_ENTRIES) {
      // Trim the oldest entry
      const excess = totalCount - MAX_CACHE_ENTRIES;
      let deletedCount = 0;
      // Open cursor on timestamp index in ascending order (oldest first)
      const cursorRequest = index.openCursor();
      cursorRequest.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
        if (cursor && deletedCount < excess) {
          cursor.delete(); // Delete the entry
          deletedCount++;
          cursor.continue(); // Move to the next entry
        }
      };
    }
  };

  countRequest.onerror = () => {
    throw new Error('Error counting mipmaps in IndexedDB.');
  };
};
