import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from '..';
import { getUserMe } from './users';

export const getExportJobsDict = (state: RootState) => state.exportJobs;
export const getExportJobs = createSelector(getExportJobsDict, (exportJobs) =>
  Object.values(exportJobs),
);

export const getProjectDubExportJobs = memoize((projectDubId: string) =>
  createSelector([getExportJobs, getUserMe], (exportJobs, user) =>
    exportJobs
      .filter(
        (c) => c.project_dub_id === projectDubId && c.user_id === user?.id,
      )
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ),
  ),
);
