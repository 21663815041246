import { IconCheck, IconReload } from '@tabler/icons-react';
import { LineType, VoiceEngineType } from 'lib/Types';
import api from 'lib/api';
import { TimelineEngine } from 'lib/engine/engine';
import { useLineApi } from 'lib/hooks/api/use-line-api';
import { useQueryParams } from 'lib/hooks/use-query-params';
import { RootState } from 'lib/redux';
import {
  getCharactersDict,
  getProjectDubCharacters,
} from 'lib/redux/selectors/characters';
import { upsertLines } from 'lib/redux/slices/lines';
import { debounce } from 'lodash';
import { useActiveLine } from 'modules/editor/hooks/use-active-line';
import {
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { LineContextMenu } from 'modules/shared/context-menu/line-context-menu';
import { EditableDiv } from 'modules/shared/editable-components/editable-div';
import { ButtonIcon, FlexRow, LoadingShine } from 'modules/shared/ui';
import { MutableRefObject, useRef } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getProjectDubById } from 'lib/redux/selectors/projectDubs';
import { renameEditableInput } from 'lib/utils/utils';
import { EditablePercentageInput } from 'modules/shared/editable-components/editable-percentage-input';
import { AdaptationModal } from './adaptation-modal';

type Props = {
  line: LineType;
  focusTimeline: () => void;
  timelineEngine: MutableRefObject<TimelineEngine>;
};

export const LineRow = ({ line, focusTimeline, timelineEngine }: Props) => {
  const dispatch = useDispatch();
  const { updateQueryParams } = useQueryParams();
  const projectDub = useSelector(getProjectDubById(line.project_dub_id));
  const characters = useSelector((state: RootState) =>
    getProjectDubCharacters(line.project_dub_id)(state),
  );
  const character = useSelector(getCharactersDict)[line.character_id || ''];
  const {
    handleUpdateLine,
    handleApproveLine,
    handleUnapproveLine,
    handleInference,
    handleSplitLineByText,
  } = useLineApi();
  const { activeLine } = useActiveLine();
  const singleClickTimeout = useRef<number | null>(null);

  const updateActiveLine = (editable: boolean) => {
    updateQueryParams({ activeId: line.id });
    if (!editable) {
      focusTimeline();
      timelineEngine.current?.setTime(line.start);
    }
  };

  /* Allow users to double click by adding a delay between single click and
  setting the active line id */
  const handleClick = (editable: boolean, e?: React.MouseEvent) => {
    if (singleClickTimeout.current) {
      clearTimeout(singleClickTimeout.current);
      singleClickTimeout.current = null;
    }

    if (!e || e.detail >= 2) {
      updateActiveLine(editable);
      return;
    }

    singleClickTimeout.current = window.setTimeout(() => {
      updateActiveLine(editable);
    }, 10);
  };

  const debouncedHandleChange = debounce((e: string) => {
    api.lines
      .update(line.id, { text: e })
      .catch(() => console.error('Error saving line to DB'));
  }, 300);

  const handleChange = (text: string) => {
    if (text === line.text) return;
    let data: Partial<LineType> = { text };
    // Don't wipe the audio if there's only whitespace change
    if (text.trim() !== line.text.trim()) {
      data = {
        ...data,
        raw_src: undefined,
        src: undefined,
        warp_factor: undefined,
      };
    }
    dispatch(upsertLines([{ id: line.id, ...data }]));
    debouncedHandleChange(text); // Debounced server-side update
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Ensure meta key is not pressed to avoid conflict with hotkey
    if (e.key === 'Enter' && !e.metaKey) {
      e.preventDefault();
      // Flush any updates to the text
      debouncedHandleChange.flush();
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const cursorPosition = range.startOffset;
        handleSplitLineByText(line.id, cursorPosition);
      }
    }
  };

  const handleApprovalChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (line.approved_at) {
      handleUnapproveLine(line.id);
    } else {
      handleApproveLine(line.id);
    }
  };

  const handleDoneEditing = (warp_factor: number) => {
    handleUpdateLine({ id: line.id, warp_factor, src: undefined });
  };

  return (
    <LineContextMenu line={line}>
      <Container
        id={`line-row-${line.id}`}
        style={{
          background: activeLine?.id === line.id ? 'var(--hover)' : undefined,
        }}
      >
        <FlexRow
          style={{ gap: 8, alignItems: 'flex-start' }}
          onClick={() => handleClick(false)}
        >
          <ButtonIcon disabled={!line.src} onClick={handleApprovalChange}>
            <IconCheck color={line.approved_at ? 'var(--green)' : undefined} />
          </ButtonIcon>
          <ButtonIcon
            disabled={!character?.voice_data_id || !!line.approved_at}
          >
            {line.uiLoading ? (
              <TailSpin width={16} height={16} color="var(--text-primary)" />
            ) : (
              <IconReload
                onClick={() =>
                  handleInference(
                    line,
                    projectDub.voice_engine || VoiceEngineType.ELEVENLABS,
                  )
                }
              />
            )}
          </ButtonIcon>
          <SelectRoot
            value={line.character_id}
            onValueChange={(e: any) => {
              handleUpdateLine({ id: line.id, character_id: e });
            }}
            disabled={!!line.approved_at}
          >
            <SelectTrigger
              style={{
                width: 64,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <SelectValue placeholder="Select" />
            </SelectTrigger>
            <SelectContent>
              <SelectViewport>
                {characters.map((c) => (
                  <SelectItem value={c.id} key={c.id}>
                    <SelectItemText>{c.name}</SelectItemText>
                    <SelectItemIndicator>
                      <IconCheck />
                    </SelectItemIndicator>
                  </SelectItem>
                ))}
              </SelectViewport>
            </SelectContent>
          </SelectRoot>
        </FlexRow>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <FlexRow
            style={{
              justifyContent: 'space-between',
              alignItems: 'start',
            }}
          >
            <OriginalText onClick={() => handleClick(false)}>
              {line.orig_text}
            </OriginalText>
            <FlexRow style={{ gap: 8 }}>
              {!!line.warp_factor && (
                <div
                  onClick={() => renameEditableInput(`#warp-factor-${line.id}`)}
                >
                  <EditablePercentageInput
                    id={`warp-factor-${line.id}`}
                    value={line.warp_factor}
                    handleDoneEditing={handleDoneEditing}
                  />
                </div>
              )}
              <AdaptationModal line={line} />
            </FlexRow>
          </FlexRow>
          {line.is_loading && line.text === '' ? (
            <LoadingShine
              style={{ minHeight: 18, maxWidth: 240, width: '100%' }}
            />
          ) : (
            <EditableDiv
              value={line.text}
              onChange={handleChange}
              disabled={!!line.approved_at}
              onClick={(e: React.MouseEvent) => handleClick(true, e)}
              onKeyPress={handleKeyPress}
            />
          )}
        </div>
      </Container>
    </LineContextMenu>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 13px;
  padding: 8px;
  gap: 16px;
  box-sizing: border-box;
  /* line-height: 32px; */
`;

// const Textarea = styled.div`
//   all: unset;
//   width: 100%;
//   overflow-y: hidden;

//   &[readonly] {
//     cursor: default;
//   }
// `;

const OriginalText = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
  cursor: pointer;
`;
