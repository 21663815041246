import { TimelineAction } from 'lib/engine/engine';
import { useClipApi } from 'lib/hooks/api/use-clip-api';
import { upsertClips } from 'lib/redux/slices/clips';
import { DragInfo, TimelineColorsEnum } from 'modules/shared/konva/consts';
import { WorkerTask } from 'modules/shared/konva/hooks/use-worker-pool';
import { KonvaAction } from 'modules/shared/konva/konva-action';
import { useDispatch } from 'react-redux';

type Props = {
  action: TimelineAction;
  stageScale: number;
  defaultPxPerSec: number;
  rowHeight: number;
  rowTop: number;
  lineTopPaddingPx: number;
  stageX: number;
  stageY: number;
  dimensions: { width: number; height: number };
  enqueueTask: (task: WorkerTask) => void;
};

export const ClipAction = ({
  action,
  stageScale,
  defaultPxPerSec,
  rowHeight,
  rowTop,
  lineTopPaddingPx,
  stageX,
  stageY,
  dimensions,
  enqueueTask,
}: Props) => {
  const dispatch = useDispatch();
  const { handleUpdateClip } = useClipApi();

  const handleDragEnd = ({ id, start, end }: DragInfo) => {
    if (!start || !end || action.trim_start === undefined || !action.duration) {
      return;
    }
    const newStart = Math.max(0, start);
    handleUpdateClip({ id, start_time: newStart, end_time: end });
  };

  const handleTransform = ({ id, node }: DragInfo) => {
    if (!node || !stageScale || !defaultPxPerSec) return;
    const newWidth = node.width() * node.scaleX();
    node.width(newWidth);
    node.scaleX(1);
    const start = node.x() / stageScale / defaultPxPerSec;
    const end = start + newWidth / stageScale / defaultPxPerSec;

    if (action.trim_start === undefined || !action.duration) return;
    // Assume either the start or end is being moved
    // Even without moving we see a fractional pixel diff in start and end time
    const didMoveStart =
      Math.abs(start - action.start) > Math.abs(end - action.end);

    if (didMoveStart) {
      // Calculate new trim start
      const newTrimStart = Math.max(
        0,
        action.trim_start - (action.start - start),
      );
      const newStart = Math.max(
        0,
        action.start - (action.trim_start - newTrimStart),
      );
      dispatch(
        upsertClips([
          {
            id,
            start_time: newStart,
            trim_start: newTrimStart,
          },
        ]),
      );
      return;
    }

    const newEnd = Math.min(
      action.start + action.duration - action.trim_start,
      end,
    );

    dispatch(
      upsertClips([
        {
          id,
          end_time: newEnd,
        },
      ]),
    );
  };

  const handleTransformEnd = ({ id, node }: DragInfo) => {
    if (!node || !stageScale || !defaultPxPerSec) return;

    if (action.trim_start === undefined || !action.duration) return;

    handleUpdateClip({
      id,
      start_time: action.start,
      trim_start: action.trim_start,
      end_time: action.end,
    });
  };
  const onClick = () => {};
  const color = {
    activeFill: TimelineColorsEnum.ACTIVE_FILL,
    inactiveFill: TimelineColorsEnum.FILL,
    border: TimelineColorsEnum.DEFAULT_WAVEFORM,
    waveform: TimelineColorsEnum.DEFAULT_WAVEFORM,
  };

  return (
    <KonvaAction
      action={action}
      stageScale={stageScale}
      defaultPxPerSec={defaultPxPerSec}
      rowHeight={rowHeight}
      rowTop={rowTop}
      lineTopPaddingPx={lineTopPaddingPx}
      stageX={stageX}
      stageY={stageY}
      dimensions={dimensions}
      color={color}
      handleDragEnd={handleDragEnd}
      handleTransform={handleTransform}
      handleTransformEnd={handleTransformEnd}
      handleClick={onClick}
      enqueueTask={enqueueTask}
    />
  );
};
