import { Container } from 'modules/admin/ui';
import { FlexCol, FlexRow, Subtitle, Title } from '../ui';
import { BackToolbar } from './back-toolbar';

export const ProjectNotFound = () => (
  <div style={{ height: '100%' }}>
    <BackToolbar />
    <Container style={{ height: 'calc(100% - 48px)' }}>
      <FlexCol style={{ gap: 8, textAlign: 'center' }}>
        <FlexRow style={{ gap: 8, justifyContent: 'center' }}>
          <Title>Project Not Found</Title>
        </FlexRow>
        <Subtitle style={{ maxWidth: 340 }}>
          This project does not exist or you do not have access to it. Please
          double check the URL is correct.
        </Subtitle>
      </FlexCol>
    </Container>
  </div>
);
