import { TimelineRow } from 'lib/engine/engine';
import { DropResult } from 'react-beautiful-dnd';

interface CalculateNewIndexResult {
  draggedRow: TimelineRow;
  updatedIndexNumber: number;
}

export function calculateNewIndexForCharacter(
  result: DropResult,
  data: TimelineRow[],
  charactersDict: any,
): CalculateNewIndexResult | null {
  const { destination, source } = result;
  if (
    !destination ||
    (destination.droppableId === source.droppableId &&
      destination.index === source.index)
  ) {
    return null;
  }
  const draggedRow = data[source.index];
  let updatedIndexNumber = 0;
  const dataCharacters = data.filter((row) => row.type === 'character');
  const offset = data.length - dataCharacters.length;
  const indexOfCharacterDrop = destination.index - offset;

  if (indexOfCharacterDrop === 0) {
    const firstTranscriptCharacter = charactersDict[dataCharacters[0].id];
    updatedIndexNumber = firstTranscriptCharacter.index_number / 2;
  } else if (indexOfCharacterDrop === dataCharacters.length - 1) {
    const lastTranscriptCharacter =
      charactersDict[dataCharacters[dataCharacters.length - 1].id];
    updatedIndexNumber = lastTranscriptCharacter.index_number + 1024;
  } else {
    const prevIndex =
      source.index < destination.index
        ? destination.index
        : destination.index - 1;
    const nextIndex =
      source.index < destination.index
        ? destination.index + 1
        : destination.index;
    const prevTranscriptCharacter = charactersDict[data[prevIndex].id];
    const nextTranscriptCharacter = charactersDict[data[nextIndex].id];

    updatedIndexNumber =
      (prevTranscriptCharacter.index_number +
        nextTranscriptCharacter.index_number) /
      2;
  }

  return { draggedRow, updatedIndexNumber };
}
