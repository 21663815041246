import { ActivityType } from 'lib/Types';
import api from 'lib/api';
import { setHistoryModalId } from 'lib/redux/slices/editor';
import { upsertLines } from 'lib/redux/slices/lines';
import { DateTime } from 'luxon';
import { ButtonSecondary, FlexRow, Label } from 'modules/shared/ui';
import { Waveform } from 'modules/shared/waveform';
import { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';

type Props = {
  activity: ActivityType;
  isActive?: boolean;
};

export const HistoryRow = ({ activity, isActive }: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleRestore = async () => {
    try {
      const { data } = await api.lines.restore(activity.data.id, {
        activity_id: activity.id,
      });
      dispatch(upsertLines([data.line]));
      dispatch(setHistoryModalId(null));
    } catch (error) {
      alert(`Error restoring line ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FlexRow style={{ gap: 8 }}>
      <div style={{ width: 100 }}>
        <Label>
          {activity.type}{' '}
          {DateTime.fromISO(activity.updatedAt, {
            zone: 'utc',
          }).toRelative()}
        </Label>
      </div>
      <div
        style={{
          border: isActive
            ? '1px solid var(--text-light)'
            : '1px solid var(--border)',
          borderRadius: 4,
          flex: 1,
          padding: '0px 8px',
        }}
      >
        <FlexRow style={{ gap: 16 }}>
          <div style={{ flex: 1 }}>
            <Waveform
              audioUrl={activity.data.raw_src}
              waveformHeight={60}
              style={{ height: '60px' }}
            />
          </div>
          <div onClick={handleRestore}>
            <ButtonSecondary
              onClick={() => setLoading(true)}
              disabled={isActive}
            >
              {isLoading ? (
                <TailSpin width={36} height={36} color="var(--secondary)" />
              ) : (
                'Restore'
              )}
            </ButtonSecondary>
          </div>
        </FlexRow>
      </div>
    </FlexRow>
  );
};
