import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getUserMe } from 'lib/redux/selectors/users';
import { useUserApi } from 'lib/hooks/api/use-user-api';
import { Switch, SwitchThumb } from 'modules/radix/Switch';
import { ThemeCard } from './components/theme-card';
import { Container, Description, Title } from '../ui';

export const Experience = () => {
  const user = useSelector(getUserMe);
  const { handleUpdateUser } = useUserApi();

  if (!user) return null;

  return (
    <Container>
      <Title>Experience</Title>

      {user.role === 'ADMIN' && (
        <FlexRow>
          <div>
            <ThemeCard
              variant="light"
              isActive={user?.theme === 'LIGHT'}
              onClick={() => handleUpdateUser({ id: user.id, theme: 'LIGHT' })}
            />
            <ThemeLabel>Light</ThemeLabel>
          </div>
          <div>
            <ThemeCard
              variant="dark"
              isActive={user?.theme === 'DARK'}
              onClick={() => handleUpdateUser({ id: user.id, theme: 'DARK' })}
            />
            <ThemeLabel>Dark</ThemeLabel>
          </div>
          <div>
            <ThemeCard
              variant="system"
              isActive={user?.theme === 'SYSTEM'}
              onClick={() => handleUpdateUser({ id: user.id, theme: 'SYSTEM' })}
            />
            <ThemeLabel>System settings</ThemeLabel>
          </div>
        </FlexRow>
      )}

      <div style={{ marginTop: 16 }}>
        <FlexRow
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <div>
            <div>Snap to grid</div>
            <Description style={{ marginLeft: 0 }}>
              Snap actions to grid when moving in timeline
            </Description>
          </div>
          <Switch
            checked={user.grid_snap_enabled}
            onCheckedChange={(e) => {
              handleUpdateUser({ id: user.id, grid_snap_enabled: e });
            }}
          >
            <SwitchThumb />
          </Switch>
        </FlexRow>
      </div>
    </Container>
  );
};

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const ThemeLabel = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: var(--text-secondary);
  margin-top: 4px;
`;
