import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getQueryParams = (params: string[]) => {
    const queryParams = new URLSearchParams(location.search);
    const result: Record<string, string | undefined> = {};
    params.forEach((param) => {
      result[param] = queryParams.get(param) || undefined;
    });
    return result;
  };

  const updateQueryParams = (params: Record<string, string | null>) => {
    const queryParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) => {
      if (value === null) {
        queryParams.delete(key);
      } else {
        queryParams.set(key, value);
      }
    });

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  };

  return { getQueryParams, updateQueryParams };
};
