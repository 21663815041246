import { IconChevronLeft } from '@tabler/icons-react';
import api from 'lib/api';
import { upsertVoices } from 'lib/redux/slices/voices';
import { DialogTitle } from 'modules/radix/Dialog';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { Button, FlexRow, Input, Label } from 'modules/shared/ui';
import { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getUserMe } from 'lib/redux/selectors/users';
import { VoiceDnd } from './voice-dnd';

type Props = {
  onClose: () => void;
};

export const CreateVoice = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserMe);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const isValid = name.length > 0 && files.length > 0 && confirm;

  const handleCreate = async () => {
    if (!isValid) return;
    setLoading(true);
    try {
      const { data } = await api.voices.create({
        name,
        description,
        files,
        workspace_id: user?.active_workspace_id,
      });

      dispatch(upsertVoices([data.voice]));
      onClose();
    } catch (err) {
      console.error('error creating voice', err);
      alert('Error creating voice');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <LeftAlignRow>
        <HoverIcon onClick={onClose}>
          <IconChevronLeft />
        </HoverIcon>
        <DialogTitle>Create a voice</DialogTitle>
      </LeftAlignRow>
      <Row>
        <Label>Name</Label>
        <Input value={name} onChange={(e) => setName(e.target.value)} />
      </Row>
      <Row>
        <Label>Description</Label>
        <Input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="How would you describe the voice?"
        />
      </Row>
      <Row>
        <Label>Samples</Label>
        <VoiceDnd
          onDropFiles={setFiles}
          files={files}
          placeholder="Drag to upload audio (.wav or .mp3)"
        />
      </Row>
      <FlexRow style={{ gap: 8 }}>
        <input
          type="checkbox"
          checked={confirm}
          onChange={(e) => setConfirm(e.target.checked)}
        />
        <Label style={{ cursor: 'default' }}>
          I hereby confirm that I have all necessary rights or consents to
          upload and clone these voice samples and that I will not use the
          platform-generated content for any illegal, fraudulent, or harmful
          purpose. I reaffirm my obligation to abide by the Terms of Service and
          Privacy Policy.
        </Label>
      </FlexRow>
      <Row>
        <Button onClick={handleCreate} disabled={!isValid}>
          {isLoading ? (
            <TailSpin width={14} height={14} color="white" />
          ) : (
            'Create Voice'
          )}
        </Button>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  gap: 24px;
  overflow: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LeftAlignRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
