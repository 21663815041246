import { ClipTrackType } from 'lib/Types';
import api from 'lib/api';
import { deleteClipTrack, upsertClipTracks } from 'lib/redux/slices/clipTracks';
import { upsertClips } from 'lib/redux/slices/clips';
import { useDispatch } from 'react-redux';

export const useClipTrackApi = () => {
  const dispatch = useDispatch();

  const handleCreateClipTrack = async (clipTrack: {
    project_dub_id: string;
  }) => {
    try {
      const { data } = await api.clipTracks.create(clipTrack);
      dispatch(upsertClipTracks([data.clip_track]));
    } catch (error) {
      console.error(error);
      alert('Error creating clip track');
    }
  };

  const handleCreateDXTrack = async (clipTrack: { project_dub_id: string }) => {
    try {
      const { data } = await api.clipTracks.createDX(clipTrack);
      dispatch(upsertClipTracks([data.clip_track]));
      dispatch(upsertClips(data.clips));
    } catch (error) {
      console.error(error);
      alert('Error creating clip track');
    }
  };

  const handleUpdateClipTrack = async (
    clipTrack: {
      id: string;
    } & Partial<ClipTrackType>,
  ) => {
    try {
      dispatch(upsertClipTracks([{ ...clipTrack }]));
      await api.clipTracks.update(clipTrack.id, clipTrack);
    } catch (error) {
      console.error(error);
      alert('Error updating clip track');
    }
  };

  const handleDeleteClipTrack = async (id: string) => {
    try {
      await api.clipTracks.delete(id);
      dispatch(deleteClipTrack(id));
    } catch (error) {
      console.error(error);
      alert('Error updating clip track');
    }
  };

  return {
    handleCreateClipTrack,
    handleCreateDXTrack,
    handleUpdateClipTrack,
    handleDeleteClipTrack,
  };
};
