import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from '..';

export const getTranscriptCharactersDict = (state: RootState) =>
  state.transcriptCharacters;
export const getTranscriptCharacters = createSelector(
  getTranscriptCharactersDict,
  (transcriptTranscriptCharacters) =>
    Object.values(transcriptTranscriptCharacters),
);

export const getProjectTranscriptCharacters = memoize((projectId: string) =>
  createSelector(getTranscriptCharacters, (characters) =>
    characters
      .filter((c) => c.project_id === projectId)
      .sort((a, b) => {
        if (a.index_number !== b.index_number) {
          return a.index_number - b.index_number;
        }
        if (a.createdAt.localeCompare(b.createdAt) === 0) {
          return a.name.localeCompare(b.name);
        }
        return a.createdAt.localeCompare(b.createdAt);
      }),
  ),
);
