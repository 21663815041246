import { ProjectDubType, ProjectType } from 'lib/Types';
import { RootState } from 'lib/redux';
import { getProjectDubExportJobs } from 'lib/redux/selectors/exportJobs';
import { Label } from 'modules/shared/ui';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { usePingExportJobs } from './use-ping-export-jobs';
import { ExportJobRow } from './components/export-job-row';

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
};

export const ExportHistoryTab = ({ project, projectDub }: Props) => {
  const exportJobs = useSelector((state: RootState) =>
    getProjectDubExportJobs(projectDub.id)(state),
  );
  usePingExportJobs({ exportJobs });

  if (!exportJobs.length) {
    return (
      <Container>
        <Label style={{ textAlign: 'center', padding: '12px 0px' }}>
          No export history
        </Label>
      </Container>
    );
  }

  return (
    <Container>
      <div style={{ overflow: 'auto', height: '100%' }}>
        {exportJobs.map((job) => (
          <ExportJobRow key={job.id} exportJob={job} project={project} />
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 8px 0px;
  max-height: 400px;
  overflow: auto;
`;
