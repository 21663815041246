import { UserType } from 'lib/Types';
import axios from './axios';

export const Users = {
  me: () => axios.get('/users/me'),
  get: () => axios.get('/users'),
  update: (id: string, data: Partial<UserType>) =>
    axios.put(`/users/${id}`, data),
  delete: (id: string) => axios.delete(`/users/${id}`),
  create: (data: {
    email: string;
    name: string;
    password: string;
    active_workspace_id?: string;
  }) => axios.post('/users', data),
};
