import {
  getMipmapFromDB,
  storeMipmapInDB,
} from 'modules/shared/konva/lib/indexedDB';
import { memo, useEffect, useMemo, useState } from 'react';
import { Group, Shape } from 'react-konva';
import { WorkerTask } from './hooks/use-worker-pool';
import { MIPMAP_LEVELS } from './lib/utils';

type Props = {
  url: string;
  width: number;
  height: number;
  x: number;
  y: number;
  color: string;
  // Percentage (0-1), which part of the whole audio's waveform to include
  trimStart: number;
  trimEnd: number;
  // Percentage (0-1), which parts of the trimmed waveform to display
  visibleStart: number;
  visibleEnd: number;
  enqueueTask: (task: WorkerTask) => void;
};

const WAVEFORM_CUTOFF = 5;

export const KonvaWaveform = memo(
  ({
    url,
    width,
    height,
    x,
    y,
    color,
    trimStart,
    trimEnd,
    visibleStart,
    visibleEnd,
    enqueueTask,
  }: Props) => {
    const visibleWidth = width * (visibleEnd - visibleStart);
    const [mipmap, setMipmap] = useState<Float32Array | null>(null);

    const optimalLevel = useMemo(() => {
      const optimal = Math.max(
        0,
        Math.min(
          Math.floor(Math.log2((width / (trimEnd - trimStart)) * 0.6)),
          MIPMAP_LEVELS - 1,
        ),
      );
      return optimal;
    }, [width, trimStart, trimEnd]);

    useEffect(() => {
      const fetchMipmap = async () => {
        url = url.replace(
          'https://deeptune-editor.s3.amazonaws.com',
          'https://d3k0hwsi4cp4qn.cloudfront.net',
        );

        // Try to fetch mipmap from IndexedDB first
        const storedMipmap = await getMipmapFromDB(url, optimalLevel);
        if (storedMipmap instanceof Float32Array && storedMipmap.length > 0) {
          setMipmap(storedMipmap);
          return;
        }

        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const audioContext = new ((window as any).OfflineAudioContext ||
          (window as any).webkitOfflineAudioContext)(1, 1, 44100);
        const buffer = await new Promise<AudioBuffer>((resolve) => {
          audioContext.decodeAudioData(arrayBuffer, resolve);
        });
        const rawData = buffer.getChannelData(0);

        const onMessage = async (event: MessageEvent<Float32Array[]>) => {
          setMipmap(event.data[optimalLevel]);
          await storeMipmapInDB(url, event.data);
        };

        enqueueTask({ rawData, url, onMessage });
      };

      fetchMipmap();
    }, [url, optimalLevel]);

    const renderWaveform = useMemo(() => {
      if (!mipmap) {
        return null;
      }

      if (width < WAVEFORM_CUTOFF) {
        return (
          <Shape
            sceneFunc={(context) => {
              context.beginPath();
              context.rect(0, 0, visibleWidth, height);
              context.fillStyle = color;
              context.fill();
            }}
          />
        );
      }

      const samples = mipmap.length;
      const trimStartIdx = Math.floor(trimStart * samples);
      const trimEndIdx = Math.floor(trimEnd * samples);
      const startIndex = Math.max(
        trimStartIdx,
        Math.floor(trimStartIdx + visibleStart * (trimEndIdx - trimStartIdx)),
      );
      const endIndex = Math.min(
        trimEndIdx - 1,
        Math.ceil(trimStartIdx + visibleEnd * (trimEndIdx - trimStartIdx)),
      );

      const max = Math.max(...mipmap);

      return (
        <Shape
          sceneFunc={(context) => {
            context.beginPath();
            context.moveTo(0, height / 2);

            for (let i = startIndex; i <= endIndex; i++) {
              const value = mipmap[i];
              const shapeX =
                ((i - startIndex) / (endIndex - startIndex)) * visibleWidth;
              const shapeY = height / 2 - ((value / max) * height) / 2;
              context.lineTo(shapeX, shapeY);
            }

            for (let i = endIndex; i >= startIndex; i--) {
              const value = mipmap[i];
              const shapeX =
                ((i - startIndex) / (endIndex - startIndex)) * visibleWidth;
              const shapeY = height / 2 + ((value / max) * height) / 2;
              context.lineTo(shapeX, shapeY);
            }

            context.closePath();
            context.fillStyle = color;
            context.fill();

            context.lineWidth = 0.5;
            context.strokeStyle = color;
            context.stroke();
          }}
        />
      );
    }, [
      mipmap,
      optimalLevel,
      width,
      height,
      color,
      visibleWidth,
      visibleStart,
      visibleEnd,
      trimStart,
      trimEnd,
      visibleWidth,
    ]);

    return (
      <Group x={x} y={y} clip={{ x: 0, y: 0, width: visibleWidth, height }}>
        {renderWaveform}
      </Group>
    );
  },
);
