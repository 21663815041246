import { blackA, violet } from '@radix-ui/colors';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { styled } from '@stitches/react';

const StyledSlider = styled(SliderPrimitive.Root, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  touchAction: 'none',
  width: '100%',
  height: '20px',

  // '&[data-orientation="horizontal"]': {
  //   height: 20,
  // },

  // '&[data-orientation="vertical"]': {
  //   flexDirection: 'column',
  //   width: 20,
  //   height: 100,
  // },
  '&[data-disabled]': {
    opacity: 0.3,
    cursor: 'default',
    pointerEvents: 'none',
    ':hover': {
      background: 'transparent',
    },
  },
});

const StyledTrack = styled(SliderPrimitive.Track, {
  backgroundColor: 'var(--border)',
  position: 'relative',
  flexGrow: 1,
  borderRadius: '9999px',
  height: '5px',

  // '&[data-orientation="horizontal"]': { height: 2 },
  // '&[data-orientation="vertical"]': { width: 2 },
});

const StyledRange = styled(SliderPrimitive.Range, {
  position: 'absolute',
  backgroundColor: 'var(--purple)',
  borderRadius: '9999px',
  height: '100%',
});

const StyledThumb = styled(SliderPrimitive.Thumb, {
  all: 'unset',
  display: 'block',
  width: 12,
  height: 12,
  backgroundColor: 'white',
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
  borderRadius: 24,
  '&:hover': { backgroundColor: violet.violet3 },
  '&:focus': { boxShadow: `0 0 0 5px ${blackA.blackA8}` },
});

export const Slider = StyledSlider;
export const SliderTrack = StyledTrack;
export const SliderRange = StyledRange;
export const SliderThumb = StyledThumb;
