import { ExportJobStatusEnum, ExportJobType } from 'lib/Types';
import api from 'lib/api';
import { upsertExportJobs } from 'lib/redux/slices/exportJobs';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  exportJobs: ExportJobType[];
};

export const usePingExportJobs = ({ exportJobs }: Props) => {
  const dispatch = useDispatch();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const loadingJobIds = useMemo(
    () =>
      exportJobs
        .filter((job) =>
          [
            ExportJobStatusEnum.IN_PROGRESS,
            ExportJobStatusEnum.PENDING,
          ].includes(job.status),
        )
        .map((job) => job.id),
    [exportJobs],
  );

  useEffect(() => {
    const pingStatusEndpoint = async () => {
      if (loadingJobIds.length > 0) {
        try {
          const { data } = await api.exportJobs.status({
            export_job_ids: loadingJobIds,
          });
          dispatch(upsertExportJobs(data.export_jobs));
        } catch (error) {
          console.error('Error pinging status endpoint:', error);
        }
      }
    };

    if (loadingJobIds.length) {
      intervalRef.current = setInterval(pingStatusEndpoint, 3000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [loadingJobIds]);
};
