import { ProjectDubType } from 'lib/Types';
import { TimelineEngine, TimelineRow } from 'lib/engine/engine';
import { useCharacterApi } from 'lib/hooks/api/use-character-api';
import { useClipTrackApi } from 'lib/hooks/api/use-clip-track-api';
import { getCaptionByProjectDubId } from 'lib/redux/selectors/captions';
import { getCharactersDict } from 'lib/redux/selectors/characters';
import { getClipTracksDict } from 'lib/redux/selectors/clipTracks';
import { CAPTIONBAR_HEIGHT, SCROLLBAR_SIZE } from 'modules/editor/constants';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import { CharacterTrackContextMenu } from 'modules/shared/context-menu/character-track-context-menu';
import { ClipTrackContextMenu } from 'modules/shared/context-menu/clip-track-context-menu';
import { DraggableComponent } from 'modules/shared/draggable-component';
import { StrictModeDroppable } from 'modules/shared/sidebar-modal/strict-mode-droppable';
import { calculateNewIndexForCharacter } from 'modules/shared/utils/calculate-new-index-for-character';
import React, { forwardRef } from 'react';
import {
  DragDropContext,
  DropResult,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { TailSpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TimelineSidebarRow } from './timeline-sidebar-row';

type Props = {
  data: TimelineRow[];
  timelineEngine: React.MutableRefObject<TimelineEngine>;
  projectDub: ProjectDubType;
  rowHeight: number;
  setStageY: (y: number) => void;
};

export const TimelineSidebar = forwardRef(
  ({ data, timelineEngine, projectDub, rowHeight, setStageY }: Props, ref) => {
    const charactersDict = useSelector(getCharactersDict);
    const clipTracksDict = useSelector(getClipTracksDict);
    const { handleUpdateCharacter, handleCreateCharacter } = useCharacterApi();
    const { handleUpdateClipTrack } = useClipTrackApi();
    const { handleCreateClipTrack, handleCreateDXTrack } = useClipTrackApi();
    const caption = useSelector(getCaptionByProjectDubId(projectDub.id));

    const onDragEnd = (result: DropResult) => {
      const { destination, source } = result;
      const updateResult = calculateNewIndexForCharacter(
        result,
        data,
        charactersDict,
      );
      if (!updateResult || !destination) return;

      const { draggedRow, updatedIndexNumber } = updateResult;

      data.splice(source.index, 1);
      data.splice(destination.index, 0, draggedRow);

      handleUpdateCharacter({
        id: draggedRow.id,
        index_number: updatedIndexNumber,
      });
    };

    const renderTimelineRow = (row: TimelineRow, index: number) => {
      if (row.type === 'character') {
        const character = charactersDict[row.id];
        return (
          <DraggableComponent row={row} index={index} key={row.id}>
            <CharacterTrackContextMenu character={character} key={row.id}>
              <TimelineSidebarRow
                row={row}
                rowHeight={rowHeight}
                style={{ color: row.color }}
                timelineEngine={timelineEngine}
                handleUpdate={handleUpdateCharacter}
                items={
                  character.isLoading ? (
                    <TailSpin
                      width={10}
                      height={10}
                      color="var(--text-primary)"
                    />
                  ) : null
                }
                projectDub={projectDub}
              />
            </CharacterTrackContextMenu>
          </DraggableComponent>
        );
      }
      if (row.type === 'clipTrack') {
        const clipTrack = clipTracksDict[row.id];
        return (
          <ClipTrackContextMenu clipTrack={clipTrack} key={row.id}>
            <TimelineSidebarRow
              row={row}
              rowHeight={rowHeight}
              style={{ color: row.color }}
              timelineEngine={timelineEngine}
              handleUpdate={handleUpdateClipTrack}
              projectDub={projectDub}
            />
          </ClipTrackContextMenu>
        );
      }
      return (
        <TimelineSidebarRow
          row={row}
          rowHeight={rowHeight}
          style={{ color: row.color }}
          key={row.id}
          timelineEngine={timelineEngine}
          projectDub={projectDub}
        />
      );
    };

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="timelineDroppable">
          {(provided: DroppableProvided) => (
            <div
              ref={provided.innerRef}
              style={{
                height: '100%',
              }}
              {...provided.droppableProps}
            >
              <ContextMenu>
                <ContextMenuTrigger>
                  <Container
                    ref={ref as any}
                    style={{
                      overflow: 'overlay',
                      height: '100%',
                      marginTop: rowHeight + (caption ? CAPTIONBAR_HEIGHT : 0),
                      paddingBottom: rowHeight + SCROLLBAR_SIZE,
                    }}
                    onScroll={(e) => {
                      const target = e.target as HTMLDivElement;
                      // Call the onScroll callback with the scroll position
                      setStageY(-target.scrollTop);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    {data.map((row, index) =>
                      renderTimelineRow(row as any, index),
                    )}
                  </Container>
                </ContextMenuTrigger>
                <ContextMenuContent>
                  <ContextMenuItem
                    title="Add Speaker"
                    icon="add"
                    onSelect={() =>
                      handleCreateCharacter({ project_dub_id: projectDub.id })
                    }
                  />
                  <ContextMenuSub>
                    <ContextMenuSubItem title="Add Clip Track" icon="add" />
                    <ContextMenuSubContent>
                      <ContextMenuItem
                        title="DX Track"
                        onSelect={() =>
                          handleCreateDXTrack({
                            project_dub_id: projectDub.id,
                          })
                        }
                      />
                      <ContextMenuItem
                        title="Empty Track"
                        onSelect={() =>
                          handleCreateClipTrack({
                            project_dub_id: projectDub.id,
                          })
                        }
                      />
                    </ContextMenuSubContent>
                  </ContextMenuSub>
                </ContextMenuContent>
              </ContextMenu>
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* margin-top: 42px; */
  /* margin-top: 10px; */
  /* flex: 0 1 auto; */
  overflow: overlay;
  padding-left: 10px;

  /* hide scrollbar */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;
