import * as Toast from '@radix-ui/react-toast';
import { ProjectDubType, ProjectType } from 'lib/Types';
import { TimelineAction, TimelineEngine } from 'lib/engine/engine';
import { useClipApi } from 'lib/hooks/api/use-clip-api';
import { getCaptionByProjectDubId } from 'lib/redux/selectors/captions';
import { Player } from 'modules/editor/components/player';
import {
  ToastAction,
  ToastDescription,
  ToastRoot,
  ToastTitle,
  ToastViewport,
} from 'modules/radix/Toast';
import { DndProvider } from 'modules/shared/dnd-provider';
import { useCallback, useRef, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import styled from 'styled-components';
import { useEditorHotkeys } from '../hooks/use-editor-hotkeys';
import { CaptionsEditor } from './captions-editor';
import { EditorTimeline } from './editor-timeline';
import { LineEditor } from './line-editor';

export interface CustomTimelineAction extends TimelineAction {
  data: {
    src: string;
    name: string;
  };
}

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
};

const EditorDetail = ({ project, projectDub }: Props) => {
  const timelineEngine = useRef<TimelineEngine>(new TimelineEngine());
  useEditorHotkeys({ timelineEngine });
  const lineEditorRef = useRef<HTMLDivElement>(null);
  const timelinePanelRef = useRef<HTMLDivElement>(null);
  const [fileProgress, setFileProgress] = useState<any>({});
  const { handleCreateClip } = useClipApi();
  const caption = useSelector(getCaptionByProjectDubId(projectDub.id));

  const handleFileDrop = useCallback((item: any) => {
    if (item) {
      const { files } = item;
      files.forEach((file: File) => {
        handleCreateClip({
          file,
          project_id: project?.id || '',
          project_dub_id: projectDub?.id || '',
          onUploadProgress: (progressEvent) => {
            if (!progressEvent.total) return;
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            if (progress === 100) {
              const temp = fileProgress;
              delete temp[file.name];
              setFileProgress({ ...temp });
            } else {
              setFileProgress({ ...fileProgress, [file.name]: progress });
            }
          },
        });
      });
    }
  }, []);

  const focusTimeline = () => {
    timelinePanelRef.current?.focus();
  };

  return (
    <Container>
      <DndProvider
        onDrop={handleFileDrop}
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
        renderOverlay
      >
        <PanelGroup direction="vertical">
          <Panel defaultSize={50}>
            <PanelGroup direction="vertical">
              <Panel defaultSize={50}>
                <PanelGroup direction="horizontal">
                  <Panel defaultSize={50} minSize={30} order={0}>
                    <FocusDiv ref={lineEditorRef} tabIndex={-1}>
                      <LineEditor
                        projectDub={projectDub}
                        focusTimeline={focusTimeline}
                        timelineEngine={timelineEngine}
                      />
                    </FocusDiv>
                  </Panel>
                  <PanelResizeHandle
                    style={{
                      width: 10,
                      background: 'black',
                      borderLeft: '1px solid var(--border)',
                    }}
                  />
                  <Panel order={1}>
                    <PlayerContainer>
                      {caption ? (
                        <CaptionsEditor
                          project={project}
                          projectDub={projectDub}
                          timelineEngine={timelineEngine}
                        />
                      ) : (
                        <Player
                          project={project}
                          timelineEngine={timelineEngine}
                        />
                      )}
                    </PlayerContainer>
                  </Panel>
                </PanelGroup>
              </Panel>
              <PanelResizeHandle
                style={{
                  height: 10,
                  background: 'var(--background)',
                  borderTop: '1px solid var(--border)',
                }}
              />
              <Panel style={{ background: 'var(--background)' }}>
                <FocusDiv ref={timelinePanelRef} tabIndex={-1}>
                  <EditorTimeline
                    project={project}
                    projectDub={projectDub}
                    timelineEngine={timelineEngine}
                  />
                </FocusDiv>
              </Panel>
            </PanelGroup>
          </Panel>
        </PanelGroup>
      </DndProvider>
      <Toast.Provider swipeDirection="right">
        <ToastRoot open={Object.entries(fileProgress).length > 0}>
          <ToastTitle>Uploading Files</ToastTitle>
          <ToastDescription>
            {Object.entries(fileProgress).map(([name, progress]: any) => (
              <div>
                {name} ({Math.round(progress)}%)
              </div>
            ))}
          </ToastDescription>
          <ToastAction asChild altText="Loading">
            <TailSpin width={18} height={18} color="var(--text-secondary)" />
          </ToastAction>
        </ToastRoot>
        {Object.entries(fileProgress).length > 0 && <ToastViewport />}
      </Toast.Provider>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
  flex: 1;
  overflow: auto;
  font-size: 12px;
  background: var(--background-secondary);
`;

const FocusDiv = styled.div`
  outline: none;
  height: 100%;
  width: 100%;
`;

const PlayerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: black;
  padding: 16px;
  box-sizing: border-box;
`;

export default EditorDetail;
