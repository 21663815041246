import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUserMe } from 'lib/redux/selectors/users';
import { UserTheme } from 'lib/Types';

export const useTheme = () => {
  const user = useSelector(getUserMe);

  useEffect(() => {
    if (!user) {
      const savedTheme = localStorage.getItem('theme') || getSystemTheme();
      setCurrentTheme(savedTheme as UserTheme);
    } else {
      setCurrentTheme(user.theme);
      localStorage.setItem('theme', user.theme);
    }
  }, [user?.theme]);

  const getSystemTheme = () =>
    window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'DARK'
      : 'LIGHT';

  const setCurrentTheme = (theme: UserTheme) => {
    let t = theme;
    if (theme === 'SYSTEM') {
      t = getSystemTheme();
    }
    if (t === 'DARK') {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.removeAttribute('data-theme');
    }
  };

  return { setCurrentTheme };
};
