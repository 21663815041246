/* eslint-disable */

import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';
import { CaptionType, LineType, ProjectType } from 'lib/Types';
import { useCallback, useRef } from 'react';
import {
  OnscreenSegmentType,
  parseTextShadow,
  recalculateTextSegments,
} from '../components/captions-editor/lib/utils';

type Props = {
  project: ProjectType;
};

export const useKonvaExport = ({ project }: Props) => {
  const animationRef = useRef<number>(0);

  const constructVideoElement = () => {
    const videoElement = document.createElement('video');
    videoElement.crossOrigin = 'anonymous';
    videoElement.src = project.video_src;
    videoElement.muted = true;
    videoElement.load(); // Important to call this if dynamically setting src

    return videoElement;
  };

  const constructStage = (width: number, height: number) => {
    const virtualContainer = document.createElement('div');
    const stage = new Konva.Stage({
      width,
      height,
      container: virtualContainer,
    });
    stage.container().style.backgroundColor = 'transparent';

    const layer = new Konva.Layer();
    stage.add(layer);

    return stage;
  };

  const updateFrame = (
    videoElement: HTMLVideoElement,
    stage: Stage,
    textSegments: OnscreenSegmentType[],
    caption: CaptionType,
    height: number,
    width: number,
  ) => {
    const layer = stage?.children[0];
    if (!layer) return;

    layer.removeChildren();

    // Fetch current video time and format as HH:MM:SS
    const { currentTime } = videoElement;
    const currentCaption = textSegments.find(
      (segment) => currentTime >= segment.start && currentTime <= segment.end,
    );

    const shadow = parseTextShadow(caption.shadow || '');

    // Add text
    const text = new Konva.Text({
      x: width * caption.left_pct,
      y: height * caption.top_pct,
      text: currentCaption ? currentCaption.text : '',
      fontSize: caption.font_size,
      fontFamily: caption.font,
      fontStyle: `${caption.font_style} ${caption.font_weight}`,
      fill: caption.color,
      align: caption.align,
      verticalAlign: 'middle',
      width: width * (caption.right_pct - caption.left_pct),
      height: height * (caption.bottom_pct - caption.top_pct),
      shadowColor: shadow?.color,
      shadowBlur: shadow?.blurRadius,
      shadowOffsetX: shadow?.offsetX,
      shadowOffsetY: shadow?.offsetY,
      stroke: caption.stroke,
      strokeWidth: caption.stroke_width,
    });
    layer.add(text);

    layer.draw();

    // Request next frame update
    animationRef.current = requestAnimationFrame(() =>
      updateFrame(videoElement, stage, textSegments, caption, height, width),
    );
  };

  const attachEventListeners = useCallback(
    (
      videoElement: HTMLVideoElement,
      textSegments: OnscreenSegmentType[],
      stage: Stage,
      caption: CaptionType,
      height: number,
      width: number,
    ) => {
      const handlePlay = () => {
        animationRef.current = requestAnimationFrame(() =>
          updateFrame(
            videoElement,
            stage,
            textSegments,
            caption,
            height,
            width,
          ),
        );
      };

      const handlePause = () => {
        cancelAnimationFrame(animationRef.current);
      };

      const handleEnded = () => {
        cancelAnimationFrame(animationRef.current);
      };

      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('pause', handlePause);
      videoElement.addEventListener('ended', handleEnded);

      return () => {
        videoElement.removeEventListener('play', handlePlay);
        videoElement.removeEventListener('pause', handlePause);
        videoElement.removeEventListener('ended', handleEnded);
      };
    },
    [],
  );

  const cleanup = (videoElement: HTMLVideoElement, stage: Stage) => {
    videoElement.src = '';
    stage.destroy();

    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
  };

  const captureCanvas = async (lines: LineType[], caption: CaptionType) => {
    const width = project.video_width ?? 1920;
    const height = project.video_height ?? 1080;
    const videoElement = constructVideoElement();
    const stage = constructStage(width, height);
    const segments = recalculateTextSegments(
      width * (caption.right_pct - caption.left_pct),
      lines,
      caption,
    );

    attachEventListeners(videoElement, segments, stage, caption, height, width);

    // Assuming the first child of the stage content is the Layer and its first child is the Canvas
    const konvaCanvasElement = stage.getLayers()[0].getCanvas()._canvas;

    if (!konvaCanvasElement) {
      console.error('Failed to access the Konva canvas element.');
      return;
    }

    // Capture the canvas stream
    const stream = konvaCanvasElement.captureStream(30); // 30 FPS

    // Create the MediaRecorder
    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: 'video/webm; codecs=vp9',
    });

    const chunks: BlobPart[] = [];

    return new Promise<Blob | undefined>(async (resolve, reject) => {
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const videoBlob = new Blob(chunks, { type: 'video/webm' });
        cleanup(videoElement, stage);
        resolve(videoBlob);
      };

      mediaRecorder.onerror = (event) => {
        console.error('Recording error', event);
        cleanup(videoElement, stage);
        reject();
      };

      const handleRecording = async () => {
        mediaRecorder.start();
        const videoDuration = project.duration ?? 0;

        setTimeout(() => {
          mediaRecorder.stop();
        }, videoDuration * 1000);
      };

      try {
        if (videoElement.paused || videoElement.ended) {
          await videoElement.play();
        }
        await handleRecording();
      } catch (error) {
        console.error('Error playing video', error);
        cleanup(videoElement, stage);
        reject();
      }
    });
  };

  return { captureCanvas };
};
