import { MutableRefObject, useMemo } from 'react';
import { Group, Line, Rect, Text } from 'react-konva';
import { getComputedColor } from 'lib/utils/utils';
import { TimelineEngine } from 'lib/engine/engine';
import { KonvaEventObject } from 'konva/lib/Node';

type Props = {
  timelineEngine: MutableRefObject<TimelineEngine>;
  stageX: number;
  stageScale: number;
  width: number;
  defaultPxPerSec: number;
  rowHeight: number;
  scaleTimesteps?: number; // Adjust default timesteps
};

// TODO: markers should always be spaced at a regular interval (e.g 5 or 10)
// Stretch the space between markers instead of changing the marker timings to weird intervals
export const KonvaMarkers = ({
  timelineEngine,
  stageX,
  stageScale,
  width,
  defaultPxPerSec,
  rowHeight,
  scaleTimesteps,
}: Props) => {
  const markers = useMemo(() => {
    const result = [];

    // Calculate the visible range of the timeline
    const visibleStartTime = -stageX / (defaultPxPerSec * stageScale);
    const visibleEndTime = (-stageX + width) / (defaultPxPerSec * stageScale);
    const visibleDuration = visibleEndTime - visibleStartTime;

    // Determine the appropriate timescale based on the visible duration
    let timeStep: number;
    if (visibleDuration >= 4000) {
      timeStep = 300;
    } else if (visibleDuration >= 2000) {
      timeStep = 120;
    } else if (visibleDuration >= 1000) {
      timeStep = 60;
    } else if (visibleDuration >= 300) {
      timeStep = 20;
    } else if (visibleDuration >= 100) {
      timeStep = 10;
    } else if (visibleDuration >= 50) {
      timeStep = 5; // 5 seconds per tick for zoomed out (50 to 100 seconds visible)
    } else if (visibleDuration >= 20) {
      timeStep = 2; // 1 second per tick for default zoom (5 to 50 seconds visible)
    } else if (visibleDuration >= 5) {
      timeStep = 1; // 1 second per tick for default zoom (5 to 50 seconds visible)
    } else if (visibleDuration >= 1) {
      timeStep = 0.5; // 0.5 seconds per tick for zoomed in (1 to 5 seconds visible)
    } else {
      timeStep = 0.1; // 0.1 seconds per tick for very zoomed in (less than 1 seconds visible)
    }

    if (scaleTimesteps) {
      // Scale timestep because transcript timeline is very small
      timeStep *= scaleTimesteps;
    }

    const formatScale = (scale: number) => {
      const min = Math.floor(scale / 60);
      const second = Math.floor(scale % 60);
      const millisecond = Math.floor((scale % 1) * 1000);

      if (timeStep < 1) {
        return `${min}:${second.toString().padStart(2, '0')}.${millisecond
          .toString()
          .slice(0, 1)
          .padEnd(1, '0')}`;
      }
      return `${min}:${second.toString().padStart(2, '0')}`;
    };

    // Determine the number of smaller ticks between main markers
    const smallerTicks = 4;
    const smallerTickStep = timeStep / (smallerTicks + 1);

    // Generate markers and smaller ticks for the visible range
    for (
      let time = Math.floor(visibleStartTime / timeStep) * timeStep;
      time <= visibleEndTime;
      time += timeStep
    ) {
      const position = time * defaultPxPerSec * stageScale;
      result.push(
        <Line
          key={`line-${time}`}
          points={[position, rowHeight - 10, position, rowHeight]}
          strokeWidth={1}
          stroke={getComputedColor('var(--text-tertiary)')}
        />,
      );
      result.push(
        <Text
          key={`text-${time}`}
          x={position}
          y={rowHeight - 28}
          text={`${formatScale(time)}`}
          fontSize={11}
          fill={getComputedColor('var(--text-secondary)')}
        />,
      );

      // Generate smaller ticks between main markers
      for (let i = 1; i <= smallerTicks; i++) {
        const smallerTickTime = time + i * smallerTickStep;
        const smallerTickPosition =
          smallerTickTime * defaultPxPerSec * stageScale;
        result.push(
          <Line
            key={`smallerLine-${smallerTickTime}`}
            points={[
              smallerTickPosition,
              rowHeight - 5,
              smallerTickPosition,
              rowHeight,
            ]}
            strokeWidth={1}
            stroke={getComputedColor('var(--text-tertiary)')}
          />,
        );
      }
    }

    return result;
  }, [stageX, stageScale, width, defaultPxPerSec]);

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    if (!stage) return;

    const updateTimelinePosition = (eMove: MouseEvent) => {
      const stageRect = stage.container().getBoundingClientRect();
      const clickedPosition = eMove.clientX - stageRect.left;
      const clickedTime =
        (clickedPosition + -stageX) / (defaultPxPerSec * stageScale);
      if (timelineEngine.current.isPlaying) {
        timelineEngine.current.pause();
      }
      timelineEngine.current.setTime(Math.max(0, clickedTime));
    };

    updateTimelinePosition(e.evt); // Update timeline position on click

    const handleMouseMove = (eMove: MouseEvent) => {
      updateTimelinePosition(eMove); // Update timeline position on drag
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <Group onMouseDown={handleMouseDown}>
      {/* You need this rect to be able to listen for clicks anywhere in the markers */}
      <Rect
        x={-stageX}
        y={0}
        width={width}
        height={rowHeight}
        fill={getComputedColor('--background')}
      />
      {markers}
    </Group>
  );
};
