import React from 'react';
import {
  SelectRoot,
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { UserRole, UserType } from 'lib/Types';
import api from 'lib/api';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, upsertUsers } from 'lib/redux/slices/user';
import { getUserMe } from 'lib/redux/selectors/users';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'lib/utils/firebase';
import { IconCheck } from '@tabler/icons-react';

type Props = {
  user: UserType;
};

export const MemberSelect = ({ user }: Props) => {
  const dispatch = useDispatch();
  const me = useSelector(getUserMe);

  const handleSelect = async (role: UserRole | 'REMOVE' | 'RESET_PASSWORD') => {
    try {
      if (role === 'REMOVE') {
        const { data } = await api.users.delete(user.id);
        dispatch(deleteUser(data.user.id));
      } else if (role === 'RESET_PASSWORD') {
        try {
          sendPasswordResetEmail(auth, user.email);
        } catch (error) {
          alert('Failed to send password reset email');
        }
      } else {
        const { data } = await api.users.update(user.id, { role });
        dispatch(upsertUsers([data.user]));
      }
    } catch (e) {
      console.log(e);
      alert('Failed to update user');
    }
  };

  return (
    <SelectRoot value={user.role} onValueChange={handleSelect}>
      <SelectTrigger disabled={user.id === me?.id}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectViewport>
          <SelectItem value="ADMIN">
            <SelectItemText>Admin</SelectItemText>
            <SelectItemIndicator>
              <IconCheck />
            </SelectItemIndicator>
          </SelectItem>
          <SelectItem value="USER">
            <SelectItemText>User</SelectItemText>
            <SelectItemIndicator>
              <IconCheck />
            </SelectItemIndicator>
          </SelectItem>
          <SelectItem value="RESET_PASSWORD">Reset Password</SelectItem>
          <SelectItem value="REMOVE" style={{ color: 'var(--red)' }}>
            Remove
          </SelectItem>
        </SelectViewport>
      </SelectContent>
    </SelectRoot>
  );
};
