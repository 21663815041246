import { Emitter } from './emitter';
import { EventTypes, Events } from './events';

const PLAYING = 'playing';
const PAUSED = 'paused';
type PlayState = 'playing' | 'paused';

/**
 * Basic parameters of an action
 * @export
 * @interface TimelineAction
 */
export interface TimelineAction {
  /** Action ID */
  id: string;
  /** Action start time */
  start: number;
  /** Action end time */
  end: number;
  /** Effect ID corresponding to the action */
  effectId: string;

  /** Whether the action is selected */
  selected?: boolean;
  /** Whether the action is resizable */
  flexible?: boolean;
  /** Whether the action is movable */
  movable?: boolean;
  /** Whether the action is disabled from running */
  disable?: boolean;

  /** Minimum start time limit of the action */
  minStart?: number;
  /** Maximum end time limit of the action */
  maxEnd?: number;

  // Deeptune variables
  src?: string;
  hls_src?: string;
  // Stem is original or MFX, clip is uploaded audio (e.g laughter), line is a LineType
  type: 'line' | 'stem' | 'clip';
  color?: string;
  trim_start?: number;
  warp_factor?: number;
  duration?: number;
  uiLoading?: boolean;
}

/**
 * Basic parameters of an action row
 * @export
 * @interface TimelineRow
 */
export interface TimelineRow {
  /** Action row ID */
  id: string;
  /** List of actions in the row */
  actions: TimelineAction[];
  /** Custom row height */
  rowHeight?: number;
  /** Whether the row is selected */
  selected?: boolean;
  /** Additional class names for the row */
  classNames?: string[];

  name: string;
  type: 'character' | 'clipTrack' | 'default';
  volume: number;
  disabled: boolean;
  color?: string;
}

export interface TimelineEffect {
  /** Effect ID */
  id: string;
  /** Effect name */
  name?: string;
  /** Effect runtime code */
  source?: TimeLineEffectSource;
}

export interface EffectSourceParam {
  /** Current time */
  time: number;
  /** Whether it is currently running */
  isPlaying: boolean;
  /** Action */
  action: TimelineAction;
  /** Action effect */
  effect: TimelineEffect;
  /** Runtime engine */
  engine: TimelineEngine;
}

/**
 * Effect execution callback
 * @export
 * @interface TimeLineEffectSource
 */
export interface TimeLineEffectSource {
  /** Callback when starting to play in the current action time range */
  start?: (param: EffectSourceParam) => void;
  /** Callback when time enters the action */
  enter?: (param: EffectSourceParam) => void;
  /** Callback when the action is updated */
  update?: (param: EffectSourceParam) => void;
  /** Callback when time leaves the action */
  leave?: (param: EffectSourceParam) => void;
  /** Callback when stopping play in the current action time range */
  stop?: (param: EffectSourceParam) => void;
  // Callback for when the time is updated
  timeUpdate?: (param: EffectSourceParam) => void;
}

export interface ITimelineEngine extends Emitter<EventTypes> {
  readonly isPlaying: boolean;
  readonly isPaused: boolean;
  effects: Record<string, TimelineEffect>;
  data: TimelineRow[];
  /** Set playback rate */
  setPlayRate(rate: number): boolean;
  /** Get playback rate */
  getPlayRate(): number;
  /** Re-render the current time */
  reRender(): void;
  /** Set playback time */
  setTime(time: number, isTick?: boolean): boolean;
  /** Get playback time */
  getTime(): number;
  /** Play */
  play(param: {
    /** By default, run from start to end, takes precedence over autoEnd */
    toTime?: number;
    /** Whether to automatically end after playback */
    autoEnd?: boolean;
  }): boolean;
  /** Pause */
  pause(): void;
}

/**
 * Timeline player
 * Can run independently from the editor
 * @export
 * @class TimelineEngine
 * @extends {Emitter<EventTypes>}
 */
export class TimelineEngine
  extends Emitter<EventTypes>
  implements ITimelineEngine
{
  constructor() {
    super(new Events());
  }

  /** requestAnimationFrame timerId */
  private _timerId: number = 0;

  /** Playback rate */
  private _playRate = 1;
  /** Current time */
  private _currentTime: number = 0;
  /** Playback state */
  private _playState: PlayState = 'paused';
  /** Time frame pre data */
  private _prev: number = 0;

  /** Action effect map */
  private _effectMap: Record<string, TimelineEffect> = {};
  /** Action map to be executed */
  private _actionMap: Record<string, TimelineAction> = {};
  /** Action ID array sorted in ascending order by action start time */
  private _actionSortIds: string[] = [];

  /** Current action index being traversed */
  private _next: number = 0;
  /** List of actionIDs whose time range includes the current time */
  private _activeActionIds: string[] = [];

  /** Whether it is currently playing */
  get isPlaying() {
    return this._playState === 'playing';
  }
  /** Whether it is currently paused */
  get isPaused() {
    return this._playState === 'paused';
  }

  set effects(effects: Record<string, TimelineEffect>) {
    this._effectMap = effects;
  }
  set data(data: TimelineRow[]) {
    // if (this.isPlaying) this.pause();
    this._dealData(data);
    this._dealClear();
    this._dealEnter(this._currentTime);
  }

  /**
   * Set playback rate
   * @memberof TimelineEngine
   */
  setPlayRate(rate: number): boolean {
    if (rate <= 0) {
      throw new Error('Error: rate cannot be less than 0!');
    }
    const result = this.trigger('beforeSetPlayRate', { rate, engine: this });
    if (!result) return false;
    this._playRate = rate;
    this.trigger('afterSetPlayRate', { rate, engine: this });

    return true;
  }
  /**
   * Get playback rate
   * @memberof TimelineEngine
   */
  getPlayRate() {
    return this._playRate;
  }

  /**
   * Re-render the current time
   * @return {*}
   * @memberof TimelineEngine
   */
  reRender() {
    if (this.isPlaying) return;
    this._tickAction(this._currentTime);
  }

  /**
   * Set playback time
   * @param {number} time
   * @param {boolean} [isTick] Whether triggered by tick
   * @memberof TimelineEngine
   */
  setTime(time: number, isTick?: boolean): boolean {
    const result =
      isTick || this.trigger('beforeSetTime', { time, engine: this });
    if (!result) return false;

    this._currentTime = time;

    this._next = 0;
    this._dealLeave(time);
    this._dealEnter(time);

    // Trigger timeUpdate callback for all active effects
    if (!isTick) {
      for (let i = 0; i < this._activeActionIds.length; i++) {
        const actionId = this._activeActionIds[i];
        const action = this._actionMap[actionId];
        const effect = this._effectMap[action.effectId];
        if (effect && effect.source?.timeUpdate) {
          effect.source.timeUpdate({
            time,
            action,
            isPlaying: this.isPlaying,
            effect,
            engine: this,
          });
        }
      }
    }

    if (isTick) this.trigger('setTimeByTick', { time, engine: this });
    else this.trigger('afterSetTime', { time, engine: this });
    return true;
  }
  /**
   * Get current time
   * @return {*}  {number}
   * @memberof TimelineEngine
   */
  getTime(): number {
    return this._currentTime;
  }

  /**
   * Run: Start time is the current time
   * @param param
   * @return {boolean} {boolean}
   */
  play(param: {
    /** By default, run from start to end, takes precedence over autoEnd */
    toTime?: number;
    /** Whether to automatically end after playback */
    autoEnd?: boolean;
  }): boolean {
    const { toTime, autoEnd } = param;

    const currentTime = this.getTime();
    // If the current status is playing or the end time is less than the start time, return directly
    if (this.isPlaying || (toTime && toTime <= currentTime)) return false;

    // Set running state
    this._playState = PLAYING;

    // Run start for activeIds
    this._startOrStop('start');

    // Trigger event
    this.trigger('play', { engine: this });

    this._timerId = requestAnimationFrame((time: number) => {
      this._prev = time;
      this._tick({ now: time, autoEnd, to: toTime });
    });
    return true;
  }

  /**
   * Pause playback
   * @memberof TimelineEngine
   */
  pause() {
    if (this.isPlaying) {
      this._playState = PAUSED;
      // Run stop for activeIds
      this._startOrStop('stop');

      this.trigger('paused', { engine: this });
    }
    cancelAnimationFrame(this._timerId);
  }

  /** Playback completed */
  private _end() {
    this.pause();
    this.trigger('ended', { engine: this });
  }

  private _startOrStop(type?: 'start' | 'stop') {
    for (let i = 0; i < this._activeActionIds.length; i++) {
      const actionId = this._activeActionIds[i];
      const action = this._actionMap[actionId];
      const effect = this._effectMap[action?.effectId];

      if (type === 'start') {
        if (effect?.source?.start) {
          effect.source.start({
            action,
            effect,
            engine: this,
            isPlaying: this.isPlaying,
            time: this.getTime(),
          });
        }
      } else if (type === 'stop') {
        if (effect?.source?.stop) {
          effect.source.stop({
            action,
            effect,
            engine: this,
            isPlaying: this.isPlaying,
            time: this.getTime(),
          });
        }
      }
    }
  }

  /** Executed every frame */
  private _tick(data: { now: number; autoEnd?: boolean; to?: number }) {
    if (this.isPaused) return;
    const { now, autoEnd, to } = data;

    // Calculate current time
    let currentTime =
      this.getTime() +
      (Math.min(1000, now - this._prev) / 1000) * this._playRate;
    this._prev = now;

    // Set current time
    if (to && to <= currentTime) currentTime = to;
    this.setTime(currentTime, true);

    // Execute actions
    this._tickAction(currentTime);
    // In the case of auto-stop, determine if all actions have been executed
    if (
      !to &&
      autoEnd &&
      this._next >= this._actionSortIds.length &&
      this._activeActionIds.length === 0
    ) {
      this._end();
      return;
    }

    // Determine if it should terminate
    if (to && to <= currentTime) {
      this._end();
    }

    if (this.isPaused) return;
    this._timerId = requestAnimationFrame((time) => {
      this._tick({ now: time, autoEnd, to });
    });
  }

  /** Run actions on tick */
  private _tickAction(time: number) {
    this._dealEnter(time);
    this._dealLeave(time);

    // Render
    for (let i = 0; i < this._activeActionIds.length; i++) {
      const actionId = this._activeActionIds[i];
      const action = this._actionMap[actionId];
      const effect = this._effectMap[action.effectId];
      if (effect && effect.source?.update) {
        effect.source.update({
          time,
          action,
          isPlaying: this.isPlaying,
          effect,
          engine: this,
        });
      }
    }
  }

  /** Reset active data */
  private _dealClear() {
    while (this._activeActionIds.length) {
      const actionId = this._activeActionIds.shift();
      const action = this._actionMap[actionId || ''];

      const effect = this._effectMap[action?.effectId];
      if (effect?.source?.leave) {
        effect.source.leave({
          action,
          effect,
          engine: this,
          isPlaying: this.isPlaying,
          time: this.getTime(),
        });
      }
    }
    this._next = 0;
  }

  /** Handle action time enter */
  private _dealEnter(time: number) {
    // Add to active
    while (this._actionSortIds[this._next]) {
      const actionId = this._actionSortIds[this._next];
      const action = this._actionMap[actionId];

      if (!action.disable) {
        // Determine if the action start time has been reached

        if (action.start > time) break;
        // Action can start executing
        if (action.end > time && !this._activeActionIds.includes(actionId)) {
          const effect = this._effectMap[action.effectId];
          if (effect && effect.source?.enter) {
            effect.source.enter({
              action,
              effect,
              isPlaying: this.isPlaying,
              time,
              engine: this,
            });
          }

          this._activeActionIds.push(actionId);
        }
      }
      this._next++;
    }
  }

  /** Handle action time leave */
  private _dealLeave(time: number) {
    let i = 0;
    while (this._activeActionIds[i]) {
      const actionId = this._activeActionIds[i];
      const action = this._actionMap[actionId];

      // Not within the playback range
      if (action.start > time || action.end < time) {
        const effect = this._effectMap[action.effectId];

        if (effect && effect.source?.leave) {
          effect.source.leave({
            action,
            effect,
            isPlaying: this.isPlaying,
            time,
            engine: this,
          });
        }

        this._activeActionIds.splice(i, 1);
        continue;
      }
      i++;
    }
  }

  /** Process data */
  private _dealData(data: TimelineRow[]) {
    const actions: TimelineAction[] = [];
    data.forEach((row) => {
      actions.push(...row.actions);
    });
    const sortActions = actions.sort((a, b) => a.start - b.start);
    const actionMap: Record<string, TimelineAction> = {};
    const actionSortIds: string[] = [];

    sortActions.forEach((action) => {
      actionSortIds.push(action.id);
      actionMap[action.id] = { ...action };
    });
    this._actionMap = actionMap;
    this._actionSortIds = actionSortIds;
  }
}
