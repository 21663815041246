import { useState, useEffect } from 'react';
import { CaptionType } from 'lib/Types';
import { Switch, SwitchThumb } from 'modules/radix/Switch';
import { FlexCol, FlexRow, Input, Label } from 'modules/shared/ui';
import { IconArrowDown, IconArrowRight } from '@tabler/icons-react';
import { ColorPicker } from './color-picker';
import { parseTextShadow } from '../../../lib/utils';

type Props = {
  caption: CaptionType;
  onUpdate: (shadow: string) => void;
};

type ShadowParts = {
  offsetX: number;
  offsetY: number;
  blurRadius: number;
  color: string;
};

const formatTextShadow = ({
  offsetX,
  offsetY,
  blurRadius,
  color,
}: ShadowParts) => `${offsetX}px ${offsetY}px ${blurRadius}px ${color}`;

export const ShadowPicker = ({ caption, onUpdate }: Props) => {
  const isEnabled = !!caption.shadow?.length;
  const [shadowParts, setShadowParts] = useState<ShadowParts>({
    offsetX: 0,
    offsetY: 0,
    blurRadius: 16,
    color: 'rgba(255, 255, 255, 0.7)',
  });

  useEffect(() => {
    if (caption?.shadow?.length) {
      const parts = parseTextShadow(caption.shadow);
      if (parts) setShadowParts(parts);
    }
  }, [caption.shadow]);

  const handleUpdate = (updatedParts: Partial<ShadowParts>) => {
    const newShadowParts = { ...shadowParts, ...updatedParts };
    setShadowParts(newShadowParts);
    onUpdate(formatTextShadow(newShadowParts));
  };

  return (
    <FlexCol style={{ gap: 16 }}>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <Label>Shadow</Label>
        <Switch
          checked={isEnabled}
          onCheckedChange={() =>
            onUpdate(isEnabled ? '' : formatTextShadow(shadowParts))
          }
        >
          <SwitchThumb />
        </Switch>
      </FlexRow>
      <FlexCol
        style={{
          background: 'var(--background-secondary)',
          border: '1px solid var(--border)',
          borderRadius: 4,
          padding: 8,
          gap: 16,
          opacity: isEnabled ? 1 : 0.5,
          pointerEvents: isEnabled ? 'all' : 'none',
        }}
      >
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <Label>Color</Label>
          <ColorPicker
            color={shadowParts.color}
            onChange={(c) => handleUpdate({ color: c.hex })}
          />
        </FlexRow>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <Label>Blur</Label>
          <Input
            style={{ width: 40, padding: 0 }}
            type="number"
            value={shadowParts.blurRadius}
            onChange={(e) =>
              handleUpdate({ blurRadius: Number(e.target.value) })
            }
          />
        </FlexRow>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <Label>Offsets</Label>
          <FlexRow style={{ gap: 8 }}>
            <FlexRow style={{ gap: 4 }}>
              <IconArrowRight style={{ width: 13, height: 13 }} />
              <Input
                type="number"
                style={{ width: 40, padding: 0 }}
                value={shadowParts.offsetX}
                onChange={(e) =>
                  handleUpdate({ offsetX: Number(e.target.value) })
                }
              />
            </FlexRow>
            <FlexRow style={{ gap: 4 }}>
              <IconArrowDown style={{ width: 13, height: 13 }} />
              <Input
                type="number"
                style={{ width: 40, padding: 0 }}
                value={shadowParts.offsetY}
                onChange={(e) =>
                  handleUpdate({ offsetY: Number(e.target.value) })
                }
              />
            </FlexRow>
          </FlexRow>
        </FlexRow>
      </FlexCol>
    </FlexCol>
  );
};
