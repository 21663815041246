import { UserRole } from 'lib/Types';
import { useUserApi } from 'lib/hooks/api/use-user-api';
import { getUsers } from 'lib/redux/selectors/users';
import { isValidEmail } from 'lib/utils/utils';
import { useState } from 'react';
import Avatar from 'react-avatar';
import { TailSpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FlexRow, Input, Button, Divider } from 'modules/shared/ui';
import { SettingsRow } from '../../../sidebar-modal/settings-row';
import { Title } from '../ui';
import { MemberSelect } from './components/member-select';

export const Members = () => {
  const { handleCreateUser } = useUserApi();
  const users = useSelector(getUsers);
  const roleValues: UserRole[] = ['ADMIN', 'USER'];
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [workspaceId, setWorkspaceId] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleAddUser = async () => {
    try {
      setLoading(true);
      await handleCreateUser({
        email,
        name,
        password,
        active_workspace_id: workspaceId.length ? workspaceId : undefined,
      });
      setName('');
      setEmail('');
      setPassword('');
      setWorkspaceId('');
    } catch (err) {
      alert('error creating user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title>Members</Title>
      <Divider />
      <FlexRow
        style={{ justifyContent: 'space-between', margin: '20px 0px', gap: 16 }}
      >
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <Input
          value={workspaceId}
          onChange={(e) => setWorkspaceId(e.target.value)}
          placeholder="Workspace ID"
          style={{ opacity: workspaceId.length ? 1 : 0.3 }}
        />
        <Button
          onClick={handleAddUser}
          style={{ minWidth: 100 }}
          disabled={!isValidEmail(email)}
        >
          {isLoading ? (
            <TailSpin width={13} height={13} color="white" />
          ) : (
            'Invite user'
          )}
        </Button>
      </FlexRow>
      <RowContainer>
        {users
          .sort((a, b) => {
            // Compare enum values
            const aEnumIndex = roleValues.indexOf(a.role);
            const bEnumIndex = roleValues.indexOf(b.role);
            if (aEnumIndex !== bEnumIndex) {
              return aEnumIndex - bEnumIndex;
            }

            // Enum values are equal, compare alphabetically
            return a.name.localeCompare(b.name);
          })
          .map((user) => (
            <SettingsRow
              title={user.name}
              subtitle={user.email}
              icon={<Avatar name={user?.name || ''} round size="28" />}
              item={<MemberSelect user={user} />}
            />
          ))}
      </RowContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  height: 90%;
  padding: 12px 0;
  overflow: auto;
`;
