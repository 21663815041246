import api from 'lib/api';
import { deleteVoice } from 'lib/redux/slices/voices';
import { useDispatch } from 'react-redux';

export const useVoiceApi = () => {
  const dispatch = useDispatch();

  const handleDeleteVoice = async (voice: { id: string }) => {
    try {
      dispatch(deleteVoice(voice.id));
      await api.voices.delete(voice.id);
    } catch (error) {
      alert(`Error delete line ${error}`);
    }
  };

  return { handleDeleteVoice };
};
