import {
  IconChevronLeft,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
} from '@tabler/icons-react';
import { CharacterType, LineType, VoiceDataType } from 'lib/Types';
import api from 'lib/api';
import { RootState } from 'lib/redux';
import { getProjectDubLines } from 'lib/redux/selectors/lines';
import { getProjectById } from 'lib/redux/selectors/projects';
import { upsertCharacters } from 'lib/redux/slices/characters';
import { useActiveLine } from 'modules/editor/hooks/use-active-line';
import { DialogTitle } from 'modules/radix/Dialog';
import { HoverIcon } from 'modules/radix/HoverIcon';
import {
  Slider,
  SliderRange,
  SliderThumb,
  SliderTrack,
} from 'modules/radix/Slider';
import {
  Button,
  FlexCol,
  FlexRow,
  Input,
  Label,
  Title,
} from 'modules/shared/ui';
import { useEffect, useRef, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

type Props = {
  character: CharacterType;
  onClose: () => void;
};

export const CloneVoiceSettings = ({ character, onClose }: Props) => {
  const dispatch = useDispatch();
  const playerRef = useRef<ReactPlayer>(null);
  const [isLoading, setLoading] = useState(true);
  const voiceLines = useSelector((state: RootState) =>
    getProjectDubLines(character.project_dub_id)(state),
  )
    .filter((line) => line.character_id === character.id)
    .sort((a, b) => b.end - b.start - (a.end - a.start));
  const project = useSelector(getProjectById(character.project_id || ''));
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedLine, setSelectedLine] = useState<LineType | null>(null);
  const [checkedLineIds, setCheckedLineIds] = useState<string[]>([]);
  const [voiceData, setVoiceData] = useState<VoiceDataType | null>(null);
  const { activeLine } = useActiveLine();
  const [testText, setTestText] = useState<string>(
    activeLine?.text || voiceLines[0]?.text || '',
  );
  const [testLoading, setTestLoading] = useState(false);
  const [testResultUri, setTestResultUri] = useState<string>('');
  const [updateVoiceDataLoading, setUpdateVoiceDataLoading] = useState(false);
  const [elSettingsLoading, setElSettingsLoading] = useState(false);

  const handleLoad = async () => {
    try {
      if (!character.clone_voice_data_id) return;
      setLoading(true);
      const { data } = await api.voiceData.getVoiceDataById(
        character.clone_voice_data_id,
      );
      setVoiceData(data.voice_data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      if (!character.clone_voice_data_id) return;
      setUpdateVoiceDataLoading(true);
      const { data } = await api.characters.updateVoiceData(character.id, {
        line_ids: checkedLineIds,
      });
      dispatch(upsertCharacters([data.character]));
      setCheckedLineIds([]);
      setVoiceData(data.voice_data);
      setTestResultUri('');
    } catch (error) {
      alert('Failed to update voice');
    } finally {
      setUpdateVoiceDataLoading(false);
    }
  };

  const handleUpdateElSettings = async () => {
    try {
      if (!character.clone_voice_data_id || !voiceData) return;
      setElSettingsLoading(true);
      const { data } = await api.voiceData.updateElVoiceSettings(
        character.clone_voice_data_id,
        voiceData.el_voice_settings,
      );
      setVoiceData(data.voice_data);
      setTestResultUri('');
    } catch (error) {
      alert('failed to update voice settings');
    } finally {
      setElSettingsLoading(false);
    }
  };

  const handleTestVoice = async () => {
    if (!character.clone_voice_data_id) return;
    try {
      setTestLoading(true);
      setTestResultUri('');
      const response = await api.voiceData.testVoiceData(
        character.clone_voice_data_id,
        {
          text: testText,
        },
      );
      if (response.data) {
        const blob = new Blob([response.data], { type: 'audio/mpeg' });
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          setTestResultUri(base64data);
        };
        reader.readAsDataURL(blob);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTestLoading(false);
    }
  };

  useEffect(() => {
    handleLoad();
  }, [character?.clone_voice_data_id]);

  const getBody = () => {
    if (isLoading) {
      return <Container>Loading...</Container>;
    }
    return (
      <FlexCol style={{ gap: 32 }}>
        <FlexCol style={{ gap: 8 }}>
          <Title>Test Voice</Title>
          {testResultUri ? (
            <audio src={testResultUri} controls />
          ) : testLoading ? (
            <TailSpin width={13} height={13} color="var(--text)" />
          ) : null}
          <Input
            type="text"
            value={testText}
            onChange={(e) => setTestText(e.target.value)}
          />
          <Button
            disabled={!testText.length || testLoading}
            onClick={handleTestVoice}
          >
            {testLoading ? (
              <TailSpin width={13} height={13} color="white" />
            ) : (
              'Test Voice'
            )}
          </Button>
        </FlexCol>
        <FlexCol style={{ gap: 8 }}>
          <FlexRow style={{ gap: 8 }}>
            <Title>Voice Settings</Title>
            {elSettingsLoading ? (
              <TailSpin width={13} height={13} color="white" />
            ) : null}
          </FlexRow>
          {voiceData ? (
            <>
              <div>
                <Label>Stability (More variable to more stable)</Label>
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={[voiceData.el_voice_settings.stability]}
                  onValueChange={(e) =>
                    setVoiceData({
                      ...voiceData,
                      el_voice_settings: {
                        ...voiceData.el_voice_settings,
                        stability: e[0],
                      },
                    })
                  }
                  onValueCommit={handleUpdateElSettings}
                  disabled={elSettingsLoading}
                >
                  <SliderTrack>
                    <SliderRange />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </div>
              <div>
                <Label>Similarity (Low to high)</Label>
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={[voiceData.el_voice_settings.similarity_boost]}
                  onValueChange={(e) =>
                    setVoiceData({
                      ...voiceData,
                      el_voice_settings: {
                        ...voiceData.el_voice_settings,
                        similarity_boost: e[0],
                      },
                    })
                  }
                  onValueCommit={handleUpdateElSettings}
                  disabled={elSettingsLoading}
                >
                  <SliderTrack>
                    <SliderRange />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </div>
              <div>
                <Label>Style Exaggeration (None to exaggerated)</Label>
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={[voiceData.el_voice_settings.style]}
                  onValueChange={(e) =>
                    setVoiceData({
                      ...voiceData,
                      el_voice_settings: {
                        ...voiceData.el_voice_settings,
                        style: e[0],
                      },
                    })
                  }
                  onValueCommit={handleUpdateElSettings}
                  disabled={elSettingsLoading}
                >
                  <SliderTrack>
                    <SliderRange />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </div>
            </>
          ) : null}
          <Label>Reference clips</Label>
          {(voiceData?.data_urls || []).map((data_url) => (
            <audio key={data_url} src={data_url} controls />
          ))}
        </FlexCol>
        <FlexCol style={{ gap: 8 }}>
          <Title>Choose new reference clips</Title>
          <div style={{ paddingBottom: 8 }}>
            Select reference lines below to clone the voice. For best quality,
            choose lines that are over 2 seconds and have no other background
            noise or character talking.
          </div>
          {voiceLines.map((line) => (
            <FlexRow
              key={line.id}
              style={{
                justifyContent: 'space-between',
                border: '1px solid var(--border)',
                borderRadius: 4,
                padding: 8,
              }}
            >
              <FlexRow style={{ gap: 8 }}>
                <HoverIcon
                  onClick={() => {
                    if (playerRef.current) {
                      if (selectedLine?.id === line.id) {
                        if (isPlaying) {
                          playerRef.current.getInternalPlayer().pause();
                        } else {
                          playerRef.current.getInternalPlayer().play();
                          playerRef.current.seekTo(line.start, 'seconds');
                        }
                      } else {
                        setSelectedLine(line);
                        playerRef.current.seekTo(line.start, 'seconds');
                        playerRef.current.getInternalPlayer().play();
                      }
                    }
                  }}
                >
                  {selectedLine?.id === line.id && isPlaying ? (
                    <IconPlayerPauseFilled />
                  ) : (
                    <IconPlayerPlayFilled />
                  )}
                </HoverIcon>
                <div>
                  {line.orig_text}
                  <Label>{Number(line.end - line.start).toFixed(1)} sec</Label>
                </div>
              </FlexRow>
              <input
                type="checkbox"
                checked={checkedLineIds.includes(line.id)}
                onChange={() =>
                  setCheckedLineIds(
                    checkedLineIds.includes(line.id)
                      ? checkedLineIds.filter((id) => id !== line.id)
                      : [...checkedLineIds, line.id],
                  )
                }
              />
            </FlexRow>
          ))}
          <Button
            onClick={handleUpdate}
            disabled={!checkedLineIds.length || updateVoiceDataLoading}
          >
            {updateVoiceDataLoading ? (
              <TailSpin width={13} height={13} color="white" />
            ) : (
              'Update Voice'
            )}
          </Button>
        </FlexCol>
      </FlexCol>
    );
  };

  return (
    <Container>
      <LeftAlignRow>
        <HoverIcon onClick={onClose}>
          <IconChevronLeft />
        </HoverIcon>
        <DialogTitle>Edit cloned voice</DialogTitle>
      </LeftAlignRow>
      <div>{getBody()}</div>
      <ReactPlayer
        ref={playerRef}
        url={project.dx_src}
        controls={false}
        width={0}
        height={0}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onEnded={() => {
          setIsPlaying(false);
          setSelectedLine(null);
        }}
        progressInterval={100}
        onProgress={({ playedSeconds }) => {
          if (selectedLine && playedSeconds >= selectedLine.end) {
            setIsPlaying(false);
            setSelectedLine(null);
            playerRef.current?.getInternalPlayer().pause();
          }
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  gap: 24px;
  overflow: auto;
`;

const LeftAlignRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
