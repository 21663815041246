import { LineType } from 'lib/Types';
import axios from './axios';

export const TranscriptLines = {
  create: (data: {
    text: string;
    start: number;
    end: number;
    project_id: string;
    character_id?: string;
  }) => axios.post('/transcript_lines', data),
  update: (id: string, data: Partial<LineType>) =>
    axios.put(`/transcript_lines/${id}`, data),
  approve: (id: string) => axios.post(`/transcript_lines/${id}/approve`),
  unapprove: (id: string) => axios.post(`/transcript_lines/${id}/unapprove`),
  merge: (id: string, data: { direction: 'next' | 'prev' }) =>
    axios.post(`/transcript_lines/${id}/merge`, data),
  split: (id: string, data: { pos: number }) =>
    axios.post(`/transcript_lines/${id}/split`, data),
  delete: (id: string) => axios.delete(`/transcript_lines/${id}`),
};
