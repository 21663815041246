import React from 'react';
import styled from 'styled-components';
import Sidebar from '../sidebar';

type Props = {
  children?: React.ReactNode;
};

export function SidebarRoute({ children }: Props) {
  return (
    <Container>
      <Sidebar />
      <BodyContainer>
        {/* <Navbar /> */}
        <Body>{children}</Body>
      </BodyContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  /* padding: 12px 12px 12px 0px; */
  width: 100%;
`;

const Body = styled.div`
  background: var(--background);
  width: 100%;
  height: 100%;
  /* border-radius: 8px; */
  /* border-left: 1px solid var(--border); */
  box-sizing: border-box;
`;
