import { IconAlertTriangle } from '@tabler/icons-react';
import { LineType, VoiceEngineType } from 'lib/Types';
import api from 'lib/api';
import { useLineApi } from 'lib/hooks/api/use-line-api';
import { Dialog, DialogContent } from 'modules/radix/Dialog';
import { Tag } from 'modules/shared/ui';
import { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';

type Props = {
  line: LineType;
};

export const AdaptationModal = ({ line }: Props) => {
  const warpPercentage = Math.abs(1 - (line.warp_factor || 0));
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState<{ text: string }[]>([]);
  const { handleUpdateLine, handleInference } = useLineApi();

  const handleAdapt = async () => {
    if (options.length) return;
    try {
      setLoading(true);
      const { data } = await api.lines.adapt(line.id);
      setOptions(data.options);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOptionClick = async (text: string) => {
    if (line.uiLoading) return;
    setOpen(false);
    await handleUpdateLine({ id: line.id, text });
    await handleInference({ ...line, text }, VoiceEngineType.ELEVENLABS);
    setOptions([]);
  };

  const getBody = () => {
    if (isLoading) {
      return ['', '', ''].map(() => (
        <OptionContainer style={{ cursor: 'default' }}>
          <TailSpin width={13} height={13} color="var(--text-primary" />
        </OptionContainer>
      ));
    }
    return options.map((option) => (
      <OptionContainer onClick={() => handleOptionClick(option.text)}>
        {option.text}
      </OptionContainer>
    ));
  };

  if (line.approved_at || !line.warp_factor || warpPercentage < 0.3) {
    return null;
  }

  const isFast = 1 - (line?.warp_factor || 0) > 0.0;
  return (
    <>
      <Dialog open={isOpen} onOpenChange={setOpen}>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <div style={{ fontSize: 15, fontWeight: 500 }}>
              Line is too {isFast ? 'fast' : 'slow'}
            </div>
            <WarningContainer style={{ marginTop: 8 }}>
              <IconAlertTriangle style={{ width: 13, height: 13 }} />
              {line.text}
            </WarningContainer>
            <div style={{ marginTop: 24 }}>
              Try a {isFast ? 'shorter' : 'longer'} translation instead:
            </div>
            {getBody()}
          </div>
        </DialogContent>
      </Dialog>
      <Tag
        style={{ color: 'var(--yellow)' }}
        onClick={() => {
          setOpen(true);
          handleAdapt();
        }}
      >
        <IconAlertTriangle style={{ width: 11, height: 11 }} />
        Line is too {isFast ? 'fast' : 'slow'}
      </Tag>
    </>
  );
};

const OptionContainer = styled.div`
  cursor: pointer;
  border: 1px solid var(--border);
  padding: 16px;
  color: var(--text-primary);
  background: var(--background-secondary);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: var(--hover);
  }
`;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: default;
  padding: 16px;
  color: var(--yellow);
  border: 1px solid var(--yellow);
  border-radius: 4px;
`;
