import { getProjectDubById } from 'lib/redux/selectors/projectDubs';
import { getProjectById } from 'lib/redux/selectors/projects';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Only use these in the top level index.tsx of a component since they may
// return undefined. Pass as props to children.

// Use this if the URL has the project dub id
export const useProjectDubParam = () => {
  const { id } = useParams();
  const projectDub = useSelector(getProjectDubById(id || ''));
  const project = useSelector(getProjectById(projectDub?.project_id));

  return { project, projectDub };
};

// Use this if the URL has the project id
export const useProjectParam = () => {
  const { id } = useParams();
  const project = useSelector(getProjectById(id || ''));
  return { project };
};
