import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClipTrackType } from 'lib/Types';

export type ClipTracksRedexType = { [id: string]: ClipTrackType };
const initialState = {} as ClipTracksRedexType;

export const clipTracksSlice = createSlice({
  name: 'clipTracks',
  initialState,
  reducers: {
    upsertClipTracks: (
      state: ClipTracksRedexType,
      action: PayloadAction<({ id: string } & Partial<ClipTrackType>)[]>,
    ) => {
      action.payload.forEach((clipTrack) => {
        state[clipTrack.id] = { ...(state[clipTrack.id] || {}), ...clipTrack };
      });
      return state;
    },
    deleteClipTrack: (
      state: ClipTracksRedexType,
      action: PayloadAction<string>,
    ) => {
      delete state[action.payload];
      return state;
    },
    resetClipTracks: () => initialState,
  },
});

export const { upsertClipTracks, deleteClipTrack, resetClipTracks } =
  clipTracksSlice.actions;
