import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomFontType } from 'lib/Types';

export type FontReduxType = { [id: string]: CustomFontType };
const initialState = {} as FontReduxType;

export const fontsSlice = createSlice({
  name: 'fonts',
  initialState,
  reducers: {
    upsertFonts: (
      state: FontReduxType,
      action: PayloadAction<({ id: string } & Partial<CustomFontType>)[]>,
    ) => {
      action.payload.forEach((font) => {
        state[font.id] = {
          ...(state[font.id] || {}),
          ...font,
        };
      });
      return state;
    },
    resetFonts: () => initialState,
  },
});

export const { upsertFonts, resetFonts } = fontsSlice.actions;
