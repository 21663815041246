import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { getProjectDubClipTracks } from 'lib/redux/selectors/clipTracks';
import { getProjectDubCharacters } from 'lib/redux/selectors/characters';
import { RootState } from '..';

export const getDisabledRowIds = memoize((projectDubId: string) =>
  createSelector(
    [
      getProjectDubClipTracks(projectDubId),
      getProjectDubCharacters(projectDubId),
    ],
    (clipTracks, characters) => {
      const disabledRowIds: { [id: string]: boolean } = {};
      clipTracks.forEach((track) => {
        disabledRowIds[track.id] = track.is_disabled;
      });
      characters.forEach((character) => {
        disabledRowIds[character.id] = character.is_disabled;
      });

      return disabledRowIds;
    },
  ),
);
export const getSoloRowIds = (state: RootState) => state.editor.soloRowIds;
export const getOriginalMuted = (state: RootState) =>
  state.editor.isOriginalMuted;
export const getMFXMuted = (state: RootState) => state.editor.isMFXMuted;
export const getTimelineZoom = (state: RootState) => state.editor.zoom;
export const getEditorSeekTime = (state: RootState) => state.editor.seekTime;
export const getEditorDuration = (state: RootState) => state.editor.duration;
export const getEditorSpinnerRotation = (state: RootState) =>
  state.editor.spinnerRotation;
export const getCharacterModalId = (state: RootState) =>
  state.editor.characterModalId;
export const getActivityModalType = (state: RootState) =>
  state.editor.activityModalType;
export const getScaleSize = (state: RootState) => state.editor.scaleSize;
export const getScaleWidth = (state: RootState) => state.editor.scaleWidth;
export const getHistoryModalId = (state: RootState) =>
  state.editor.historyModalId;
export const getProjectSettingsOpen = (state: RootState) =>
  state.editor.isProjectSettingsOpen;
export const getPromptLineId = (state: RootState) => state.editor.promptLineId;
