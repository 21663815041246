import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

export function Loading({
  width = 36,
  height = 36,
  color = 'var(--text-light)',
}: Props) {
  return (
    <Container>
      <TailSpin width={width} height={height} color={color} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
