import { useEffect, useRef } from 'react';

type Props = {
  value: string;
  onChange: (value: string) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  className?: string;
  style?: any;
};

export const EditableDiv = ({
  value,
  onChange,
  onClick,
  onKeyPress,
  disabled,
  className,
  style,
}: Props) => {
  const editableDivRef = useRef<HTMLDivElement>(null);

  const handleInput = () => {
    if (!editableDivRef.current) return;
    onChange(editableDivRef.current.innerHTML);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!editableDivRef.current || !onKeyPress) return;
    onKeyPress(e);
  };

  useEffect(() => {
    if (editableDivRef.current && editableDivRef.current.innerHTML !== value) {
      editableDivRef.current.innerHTML = value;
    }
  }, [value]);

  return (
    <div
      ref={editableDivRef}
      contentEditable={disabled ? false : 'plaintext-only'}
      className={className}
      style={{
        minHeight: '20px',
        minWidth: '100%',
        ...style,
        overflowY: 'visible',
        outline: 'none',
        wordBreak: 'break-word',
      }}
      onInput={handleInput}
      onClick={onClick}
      onKeyDown={handleKeyDown}
    />
  );
};
