import { IconBorderStyle2, IconPalette } from '@tabler/icons-react';
import { CaptionType } from 'lib/Types';
import { FlexRow, Input } from 'modules/shared/ui';
import { ColorPicker } from './color-picker';

type Props = {
  caption: CaptionType;
  onUpdate: (v: Partial<CaptionType>) => void;
};

export const OutlinePicker = ({ caption, onUpdate }: Props) => (
  <FlexRow style={{ gap: '12px' }}>
    <FlexRow style={{ gap: '4px' }}>
      <IconBorderStyle2 />
      <Input
        type="number"
        style={{ width: 40, padding: 0 }}
        value={caption.stroke_width}
        onChange={(e) => onUpdate({ stroke_width: Number(e.target.value) })}
      />
    </FlexRow>
    <FlexRow style={{ gap: '4px' }}>
      <IconPalette style={{ color: 'var(--text-secondary)' }} />
      <ColorPicker
        color={caption.stroke}
        onChange={(c) => onUpdate({ stroke: c.hex })}
      />
    </FlexRow>
  </FlexRow>
);
