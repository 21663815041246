import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  color: 'var(--text-primary)',
  backgroundColor: 'var(--background)',
  minWidth: 180,
  border: '1px solid var(--border)',
  borderRadius: 4,
  padding: 4,
  // boxShadow: '0 2px 10px var(--blackA7)',
  fontSize: 13,
  gap: 5,
  zIndex: 100,
});

const itemStyles = {
  fontSize: 13,
  height: 28,
  color: 'var(--text-primary)',
  borderRadius: 2,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  userSelect: 'none',
  padding: '0 6px',
  cursor: 'pointer',

  '&[data-disabled]': {
    color: 'var(--text-light)',
    pointerEvents: 'none',
  },

  '&:hover': {
    background: 'var(--hover)',
  },
  '&:focus': {
    outline: 'none',
  },
};

const StyledItem = styled(DropdownMenuPrimitive.Item, { ...itemStyles });
const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, {
  ...itemStyles,
});
const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, {
  ...itemStyles,
});

const StyledLabel = styled(DropdownMenuPrimitive.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: '25px',
  color: 'var(--text-primary)',
});

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: 'var(--text-light)',
  margin: 5,
});

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledArrow = styled(DropdownMenuPrimitive.Arrow, {
  fill: 'white',
});

export const RightSlot = styled('div', {
  marginLeft: 'auto',
  paddingLeft: 20,
  color: 'var(--text-light)',
  ':focus > &': { color: 'white' },
  '[data-disabled] &': { color: 'var(--hover)' },
});

// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = StyledContent;
export const DropdownMenuItem = StyledItem;
export const DropdownMenuCheckboxItem = StyledCheckboxItem;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuRadioItem = StyledRadioItem;
export const DropdownMenuItemIndicator = StyledItemIndicator;
export const DropdownMenuLabel = StyledLabel;
export const DropdownMenuSeparator = StyledSeparator;
export const DropdownMenuArrow = StyledArrow;
