import React from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  subtitle?: string;
  item?: React.ReactNode;
  icon?: React.ReactNode;
};

export const SettingsRow = ({ title, item, icon, subtitle }: Props) => (
  <Container>
    <RightContainer>
      {icon ? (
        <div style={{ height: 28, width: 28, marginRight: 8 }}>{icon}</div>
      ) : null}
      <div>
        <div style={{ fontSize: 14, color: 'var(--text-primary)' }}>
          {title}
        </div>
        <div style={{ fontSize: 12, color: 'var(--text-secondary)' }}>
          {subtitle}
        </div>
      </div>
    </RightContainer>
    {item}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  max-height: 40px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;
