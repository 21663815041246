import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';
import { styled } from '@stitches/react';
import { IconChevronRight } from '@tabler/icons-react';

const contentStyles = {
  color: 'var(--text-primary)',
  backgroundColor: 'var(--background)',
  minWidth: 180,
  border: '1px solid var(--border)',
  borderRadius: 4,
  padding: 4,
  // boxShadow: '0 2px 10px var(--hover)',
  fontSize: 13,
  gap: 5,
  zIndex: 100,
};

export const RightSlot = styled('div', {
  marginLeft: 'auto',
  paddingLeft: 20,
});

const itemStyles = {
  fontSize: 13,
  height: 28,
  color: 'var(--text-primary)',
  fontWeight: 400,
  borderRadius: 2,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  userSelect: 'none',
  padding: '0px 6px',
  cursor: 'pointer',

  '&:hover': {
    background: 'var(--hover)',
  },
  '&:focus': {
    outline: 'none !important',
  },

  '&[data-disabled]': {
    opacity: 0.3,
    cursor: 'default',
    pointerEvents: 'none',
    ':hover': {
      background: 'transparent',
    },
  },
};

export const ContextMenu = ContextMenuPrimitive.Root;
export const ContextMenuTrigger = ContextMenuPrimitive.Trigger;
export const ContextMenuSub = ContextMenuPrimitive.Sub;
export const ContextMenuPortal = ContextMenuPrimitive.Portal;
export const ContextMenuContent = styled(
  ContextMenuPrimitive.Content,
  contentStyles,
);
export const ContextMenuSubContent = styled(
  ContextMenuPrimitive.SubContent,
  contentStyles,
);
export const ContextMenuLabel = styled(ContextMenuPrimitive.Label, {
  padding: 4,
  fontSize: 12,
  color: 'var(--text-light)',
  cursor: 'default',
});

export const ContextMenuDivider = styled('div', {
  width: '100%',
  border: '1px solid rgba(144, 160, 183, 0.12)',
  margin: '0px',
});

const StyledItem = styled(ContextMenuPrimitive.Item, itemStyles);

type Props = {
  title: string;
  icon?: string;
  onSelect?: (e: any) => void;
  disabled?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  shortcut?: string;
};

export const ContextMenuItem = ({
  title,
  icon,
  onSelect,
  disabled,
  shortcut,
}: Props) => (
  <StyledItem disabled={disabled} onSelect={onSelect}>
    {icon ? (
      <span
        className="material-symbols-outlined"
        style={{ fontSize: 13, marginRight: 8 }}
      >
        {icon}
      </span>
    ) : null}
    <span>{title}</span>
    {shortcut ? (
      <span
        style={{
          marginLeft: 'auto',
          color: 'var(--text-light)',
          paddingLeft: '8px',
        }}
      >
        {shortcut}
      </span>
    ) : null}
  </StyledItem>
);

const ContextMenuSubTrigger = styled(ContextMenuPrimitive.SubTrigger, {
  '&[data-state="open"]': {
    backgroundColor: 'var(--hover)',
  },
  ...itemStyles,
});

export const ContextMenuSubItem = ({
  title,
  icon,
  onSelect,
  disabled,
}: Props) => (
  <ContextMenuSubTrigger disabled={disabled} onSelect={onSelect}>
    <span
      className="material-symbols-outlined"
      style={{ fontSize: 13, marginRight: 8 }}
    >
      {icon}
    </span>
    <span>{title}</span>
    <RightSlot>
      <IconChevronRight />
    </RightSlot>
  </ContextMenuSubTrigger>
);
