import { red } from '@radix-ui/colors';
import { IconVolume } from '@tabler/icons-react';
import { Howl } from 'howler';
import { CharacterType } from 'lib/Types';
import { useCharacterApi } from 'lib/hooks/api/use-character-api';
import { setCharacterModalId } from 'lib/redux/slices/editor';
import { AlertDialog } from 'modules/radix/AlertDialog';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { ButtonSecondary, Tag } from 'modules/shared/ui';
import React, { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

type Props = {
  character: CharacterType;
  voice_data_id: string;
  title: string;
  description: string;
  tags: string[];
  previewUrl?: string;
  isActive?: boolean;
  rightChildren?: React.ReactNode;
};

export const VoiceRow = ({
  character,
  voice_data_id,
  title,
  description,
  tags,
  previewUrl,
  isActive,
  rightChildren,
}: Props) => {
  const dispatch = useDispatch();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { handleUpdateVoice } = useCharacterApi();

  const handleConfirm = async () => {
    await handleUpdateVoice(character.id, { voice_data_id });
    setIsConfirmOpen(false);
    dispatch(setCharacterModalId(null));
  };

  const onClick = () => {
    if (!previewUrl) return;
    const sound = new Howl({
      src: [previewUrl],
    });
    sound.play();
  };

  return (
    <Container isActive={isActive}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FlexRow>
          <Title>{title}</Title>
          {previewUrl && (
            <HoverIcon style={{ marginLeft: 4 }} onClick={onClick}>
              <IconVolume style={{ width: 13, height: 13 }} />
            </HoverIcon>
          )}
        </FlexRow>
        {description ? <Subtitle>{description}</Subtitle> : null}
        {tags?.length ? (
          <FlexRow style={{ gap: 4, marginTop: 4, flexWrap: 'wrap' }}>
            {(tags || []).map((tag) => (
              <Tag key={tag} style={{ padding: '2px 4px' }} disabled>
                {tag}
              </Tag>
            ))}
          </FlexRow>
        ) : null}
      </div>
      <FlexRow
        style={{ gap: 8 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {rightChildren}
        <AlertDialog
          title="Are you sure you want to apply this voice?"
          description="Applying will wipe and re-generate all lines for this speaker. Any approved lines will remain unchanged."
          confirmTitle={
            character.isLoading ? (
              <TailSpin width={12} height={12} color={red.red11} />
            ) : (
              'Apply voice'
            )
          }
          isLoading={character.isLoading}
          onConfirm={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleConfirm();
          }}
          open={isConfirmOpen}
          onOpenChange={setIsConfirmOpen}
        >
          <ButtonSecondary
            disabled={isActive}
            onClick={() => setIsConfirmOpen(true)}
          >
            Apply
          </ButtonSecondary>
        </AlertDialog>
      </FlexRow>
    </Container>
  );
};

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  font-size: 13px;
  color: var(--text-primary);
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: var(--text-secondary);
`;

const Container = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px;
  background: ${({ isActive }) =>
    isActive ? 'var(--background-secondary)' : 'transparent'};
  border: ${({ isActive }) =>
    isActive ? '1px solid var(--text-light)' : '1px solid var(--border)'};
  border-radius: 4px;
  font-size: 12px;
  justify-content: space-between;
  gap: 8px;
  transition: 0.2s ease-in-out;
`;
