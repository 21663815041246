import axios from './axios';

export const TranscriptCharacters = {
  create: (data: { project_id: string }) =>
    axios.post('/transcript_characters', data),
  update: (
    id: string,
    data: {
      name?: string;
    },
  ) => axios.put(`/transcript_characters/${id}`, data),
  delete: (id: string) => axios.delete(`/transcript_characters/${id}`),
  getById: (id: string) => axios.get(`/transcript_characters/${id}`),
};
