import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CharacterType } from 'lib/Types';

export type CharactersReduxType = { [id: string]: CharacterType };
const initialState = {} as CharactersReduxType;

export const charactersSlice = createSlice({
  name: 'characters',
  initialState,
  reducers: {
    upsertCharacters: (
      state: CharactersReduxType,
      action: PayloadAction<({ id: string } & Partial<CharacterType>)[]>,
    ) => {
      action.payload.forEach((character) => {
        state[character.id] = { ...(state[character.id] || {}), ...character };
      });
      return state;
    },
    deleteCharacter: (
      state: CharactersReduxType,
      action: PayloadAction<string>,
    ) => {
      delete state[action.payload];
      return state;
    },
    resetCharacters: () => initialState,
  },
});

export const { upsertCharacters, deleteCharacter, resetCharacters } =
  charactersSlice.actions;
