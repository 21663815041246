import {
  IconAlertTriangle,
  IconDownload,
  IconInfoCircle,
} from '@tabler/icons-react';
import { ExportJobStatusEnum, ExportJobType, ProjectType } from 'lib/Types';
import { DateTime } from 'luxon';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { Tooltip } from 'modules/radix/Tooltip';
import { FlexCol, FlexRow, Label } from 'modules/shared/ui';
import { useEffect, useMemo, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';

type Props = {
  exportJob: ExportJobType;
  project: ProjectType;
};

export const ExportJobRow = ({ exportJob, project }: Props) => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (
      exportJob.status === ExportJobStatusEnum.IN_PROGRESS ||
      exportJob.status === ExportJobStatusEnum.PENDING
    ) {
      interval = setInterval(() => {
        setCurrentTime(new Date().getTime());
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [exportJob.status]);

  const data =
    typeof exportJob.data === 'string'
      ? JSON.parse(exportJob.data)
      : exportJob.data || {};

  const createdAt = DateTime.fromISO(exportJob.createdAt as string, {
    zone: 'utc',
  });
  const startedAt = DateTime.fromISO(exportJob.started_at as string, {
    zone: 'utc',
  });
  const projectedSeconds = useMemo(() => {
    if (!project.duration) return 0;
    switch (data.format) {
      case 'mp4':
        return project.duration / 1;
      default:
        return project.duration / 10;
    }
  }, [data.format, project.duration]);

  const percentComplete = Math.round(
    Math.max(
      0,
      Math.min(
        99,
        ((currentTime / 1000 - startedAt.toSeconds()) / projectedSeconds) * 100,
      ),
    ),
  );

  const renderExportJobStatus = () => {
    switch (exportJob.status) {
      case ExportJobStatusEnum.PENDING:
        return (
          <FlexRow style={{ gap: 8 }}>
            Queued
            <div style={{ width: 16 }}>
              <TailSpin width={13} height={13} color="var(--text-secondary)" />
            </div>
          </FlexRow>
        );
      case ExportJobStatusEnum.IN_PROGRESS:
        return (
          <FlexRow style={{ gap: 8 }}>
            {exportJob.status === ExportJobStatusEnum.IN_PROGRESS
              ? `${percentComplete}%`
              : null}
            <div style={{ width: 16 }}>
              <TailSpin width={13} height={13} color="var(--text-secondary)" />
            </div>
          </FlexRow>
        );
      case ExportJobStatusEnum.SUCCESS:
        return (
          <HoverIcon
            onClick={() => {
              window.open(exportJob.download_src, '_blank');
            }}
          >
            <IconDownload
              color="var(--text-primary)"
              style={{ width: 13, height: 13 }}
            />
          </HoverIcon>
        );
      case ExportJobStatusEnum.FAILED:
        return (
          <IconAlertTriangle
            color="var(--red)"
            style={{ width: 13, height: 13 }}
          />
        );
      default:
        return null;
    }
  };

  // const projectedJobTimeSec = (1000 * (project.duration || 0)) / 2;
  return (
    <Container>
      <FlexCol style={{ gap: 2 }}>
        <FlexRow style={{ gap: 4 }}>
          New Export{data.format ? ` (${data.format})` : null}
          <Tooltip
            content={
              <div>
                {Object.entries(data)
                  .filter((v) => v[1])
                  .map(([k, v]) => (
                    <div key={k}>
                      {k}: {v as any}
                    </div>
                  ))}
              </div>
            }
          >
            <IconInfoCircle width={13} height={13} color="var(--text-light)" />
          </Tooltip>
        </FlexRow>
        <Label style={{ fontSize: 11 }}>
          {createdAt.toJSDate().toLocaleString()}
        </Label>
      </FlexCol>
      <FlexRow style={{ gap: 8 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {renderExportJobStatus()}
        </div>
      </FlexRow>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  gap: 8px;

  svg {
    width: 13px;
    height: 13px;
  }
  :hover {
    background: var(--hover);
  }
`;
