import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectUserType } from 'lib/Types';

export type ProjectUsersReduxType = { [id: string]: ProjectUserType };
const initialState = {} as ProjectUsersReduxType;

export const projectUsersSlice = createSlice({
  name: 'projectUsers',
  initialState,
  reducers: {
    upsertProjectUsers: (
      state: ProjectUsersReduxType,
      action: PayloadAction<({ id: string } & Partial<ProjectUserType>)[]>,
    ) => {
      action.payload.forEach((projectUser) => {
        state[projectUser.id] = {
          ...(state[projectUser.id] || {}),
          ...projectUser,
        };
      });
      return state;
    },
    deleteProjectUser: (
      state: ProjectUsersReduxType,
      action: PayloadAction<string>,
    ) => {
      delete state[action.payload];
      return state;
    },
    deleteProjectUserByProjectId: (
      state: ProjectUsersReduxType,
      action: PayloadAction<string>,
    ) => {
      Object.keys(state).forEach((id) => {
        if (state[id].project_id === action.payload) {
          delete state[id];
        }
      });
      return state;
    },
    resetProjectUsers: () => initialState,
  },
});

export const {
  upsertProjectUsers,
  deleteProjectUser,
  resetProjectUsers,
  deleteProjectUserByProjectId,
} = projectUsersSlice.actions;
