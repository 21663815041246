import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  RightSlot,
} from 'modules/radix/DropdownMenu';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsOpen } from 'lib/redux/selectors/settings';
import { setSettingsOpen } from 'lib/redux/slices/settings';
import Avatar from 'react-avatar';
import { getUserMe } from 'lib/redux/selectors/users';
import { useLogout } from 'lib/hooks/use-logout';

export function Profile() {
  const dispatch = useDispatch();
  const user = useSelector(getUserMe);
  const settingsOpen = useSelector(getSettingsOpen);
  const { handleLogout } = useLogout();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <HoverIcon>
          <Avatar name={user?.name || ''} round size="22" />
        </HoverIcon>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={5}>
        <DropdownMenuItem
          onClick={() => dispatch(setSettingsOpen(!settingsOpen))}
        >
          Preferences <RightSlot>⌘,</RightSlot>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleLogout}>Log out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
