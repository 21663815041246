import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import { getUserMe } from 'lib/redux/selectors/users';
import {
  getWorkspaces,
  getWorkspacesDict,
} from 'lib/redux/selectors/workspaces';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { FlexRow } from 'modules/shared/ui';

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { WorkspaceSelectRow } from './components/workspace-select-row';

export const WorkspaceSelect = () => {
  const user = useSelector(getUserMe);
  const workspaces = useSelector(getWorkspaces);
  const activeWorkspace =
    useSelector(getWorkspacesDict)[user?.active_workspace_id || ''];

  if (!user) return null;

  return (
    <Popover>
      <PopoverTrigger>
        <HoverIcon>
          <FlexRow style={{ gap: 8 }}>
            {activeWorkspace?.profile_src ? (
              <Icon src={activeWorkspace.profile_src} />
            ) : null}
            <TitleRow>{activeWorkspace?.name}</TitleRow>
          </FlexRow>
        </HoverIcon>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent>
          <Container>
            {workspaces
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((w) => (
                <WorkspaceSelectRow workspace={w} key={w.id} />
              ))}
          </Container>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};

const TitleRow = styled.div`
  font-size: 13px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-weight: 500;
  color: var(--text-primary);
  cursor: default;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 4px;
`;

const Container = styled.div`
  font-size: 12px;
  color: var(--text-primary);
  background: var(--background);
  border: 1px solid var(--border);
  border-radius: 8px;
  min-width: 200px;
  max-height: 400px;
  overflow: auto;
`;
