import { shift, useFloating, flip, size } from '@floating-ui/react-dom';
import { useLayoutEffect, useState } from 'react';
import { useClickAway, useKey } from 'react-use';
import { ColorResult, SketchPicker } from 'react-color';
import styled from 'styled-components';

type Props = {
  color: string;
  onChange: (color: ColorResult) => void;
};

export const ColorPicker = ({ color, onChange }: Props) => {
  const [isPickerVisible, setPickerVisible] = useState(false);
  const [fitWithinHighlight, setFitWithinHighlight] = useState(false);

  const middleware = [
    size({
      apply({ availableWidth }) {
        if (availableWidth < 200) {
          // Assuming BlockPicker width is 200px
          setFitWithinHighlight(true);
        } else {
          setFitWithinHighlight(false);
        }
      },
    }),
    shift(),
    flip(),
  ];

  const { x, y, refs, strategy, update } = useFloating<HTMLDivElement>({
    placement: 'right',
    middleware,
  });

  useLayoutEffect(() => {
    update();
  }, [update, color]);

  useClickAway(refs.floating, () => setPickerVisible(false));
  useKey('Escape', () => setPickerVisible(false));

  const handlePreviewClick = () => {
    setPickerVisible(!isPickerVisible);
    update();
  };

  return (
    <>
      <div ref={refs.setReference} onClick={handlePreviewClick}>
        <ColorPreviewOuter>
          <ColorPreviewInner style={{ background: color }} />
        </ColorPreviewOuter>
      </div>

      {isPickerVisible && (
        <div
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: (y ?? 0) + 10,
            left: fitWithinHighlight ? 0 : x ?? 0,
            zIndex: 99,
          }}
        >
          <SketchPicker
            color={color}
            onChangeComplete={(c: ColorResult) => {
              onChange(c);
              setPickerVisible(false);
            }}
          />
        </div>
      )}
    </>
  );
};

const ColorPreviewOuter = styled.div`
  width: 24px;
  height: 24px;
  background: var(--text-secondary);
  border-radius: 4px;
  cursor: pointer;
`;

const ColorPreviewInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;
