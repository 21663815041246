import { FlexRow, Title } from 'modules/shared/ui';
import React from 'react';
import { ProjectType } from 'lib/Types';
import styled from 'styled-components';
import { useTranscriptCharacterApi } from 'lib/hooks/api/use-transcript-character-api';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectTranscriptCharacters } from 'lib/redux/selectors/transcriptCharacters';
import { Dialog, DialogContent } from 'modules/radix/Dialog';
import { getProjectSettingsOpen } from 'lib/redux/selectors/editor';
import { RootState } from 'lib/redux';
import { setProjectSettingsOpen } from 'lib/redux/slices/editor';
import { TranscriptCharacterRow } from './components/transcript-character-row';

type Props = {
  project: ProjectType;
};

export const TranscriptCharacterModal = ({ project }: Props) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getProjectSettingsOpen);
  const { handleCreateTranscriptCharacter } = useTranscriptCharacterApi();
  const transcriptCharacters = useSelector((state: RootState) =>
    getProjectTranscriptCharacters(project.id)(state),
  );
  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => dispatch(setProjectSettingsOpen(false))}
    >
      <DialogContent
        style={{
          maxWidth: 800,
          padding: 0,
          maxHeight: 700,
          height: '100%',
          background: 'var(--background)',
        }}
      >
        <Container>
          <FlexRow
            style={{
              justifyContent: 'space-between',
              padding: '16px 32px',
              borderBottom: '1px solid var(--border)',
            }}
          >
            <Title>Speakers</Title>
            <Button
              onClick={() =>
                handleCreateTranscriptCharacter({ project_id: project.id })
              }
            >
              Add Speaker
            </Button>
          </FlexRow>
          <div
            style={{
              overflow: 'auto',
              padding: '16px 0px',
            }}
          >
            {transcriptCharacters.map((c) => (
              <TranscriptCharacterRow key={c.id} transcriptCharacter={c} />
            ))}
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

const Button = styled.button`
  cursor: pointer;
  all: unset;
  border: 1px solid var(--hover);
  padding: 0px 12px;
  height: 32px;
  font-size: 12px;
  border-radius: 6px;

  :disabled {
    opacity: 0.4;
  }
  :hover {
    background-color: var(--hover);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 32px; */
  width: 100%;
  height: 100%;
`;
