import { CustomFontType } from 'lib/Types';
import { getFonts } from 'lib/redux/selectors/fonts';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useCustomFonts = () => {
  const customFonts = useSelector(getFonts);

  const loadCustomFonts = (fonts: CustomFontType[]) => {
    const styleElems = fonts.flatMap((f) =>
      f.variants.map((variant) => {
        const newStyle = document.createElement('style');
        newStyle.appendChild(
          document.createTextNode(`
              @font-face {
                font-family: '${f.name}';
                src: url(${variant.url}) format('truetype');
                font-weight: ${variant.weight};
                font-style: ${variant.style};
              }
            `),
        );
        document.head.appendChild(newStyle);
        return newStyle;
      }),
    );

    return styleElems;
  };

  // Load in custom fonts
  useEffect(() => {
    const loadedStyles = loadCustomFonts(customFonts);
    return () => {
      loadedStyles.forEach((style) => {
        document.head.removeChild(style);
      });
    };
  }, [customFonts]);
};
