import { LineType, VoiceEngineType } from 'lib/Types';
import { useLineApi } from 'lib/hooks/api/use-line-api';
import { getUserMe } from 'lib/redux/selectors/users';
import { getProjectDubById } from 'lib/redux/selectors/projectDubs';
import { setHistoryModalId, setPromptLineId } from 'lib/redux/slices/editor';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuDivider,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuPortal,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

type Props = {
  line: LineType;
  showSplit?: boolean;
  children?: React.ReactNode;
};

export const LineContextMenu = ({
  line,
  children,
  showSplit = false,
}: Props) => (
  <ContextMenu>
    <ContextMenuTrigger>{children}</ContextMenuTrigger>
    <ContextMenuPortal>
      <LineContextMenuContent line={line} showSplit={showSplit} />
    </ContextMenuPortal>
  </ContextMenu>
);

type ContentProps = {
  line: LineType;
  showSplit: boolean;
};

export const LineContextMenuContent = ({ line, showSplit }: ContentProps) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserMe);
  const projectDub = useSelector(getProjectDubById(line.project_dub_id));
  const {
    handleApproveLine,
    handleUnapproveLine,
    handleUnwarpLine,
    handleInference,
    handleDeleteLine,
    handleMergeLine,
    handleUpdateLine,
    handleSplitLineAtPlayhead,
    handleRetranslateLine,
  } = useLineApi();

  if (line.is_disabled) {
    return (
      <ContextMenuContent onClick={(e) => e.stopPropagation()}>
        <ContextMenuItem
          title="Enable"
          icon="visibility"
          onSelect={() => {
            handleUpdateLine({
              id: line.id,
              is_disabled: !line.is_disabled,
            });
          }}
          disabled={!!line.approved_at}
        />
        <ContextMenuItem
          title="Delete"
          icon="delete"
          onSelect={() => handleDeleteLine(line)}
          disabled={!!line.approved_at}
        />
        {user?.role === 'ADMIN' ? (
          <>
            <ContextMenuLabel>Admin</ContextMenuLabel>
            <ContextMenuItem
              title="Copy UUID"
              icon="content_copy"
              onSelect={() => {
                navigator.clipboard.writeText(line.id);
              }}
            />
          </>
        ) : null}
      </ContextMenuContent>
    );
  }

  return (
    <ContextMenuContent onClick={(e) => e.stopPropagation()}>
      <ContextMenuItem
        title={line.approved_at ? 'Edit' : 'Approve'}
        icon={line.approved_at ? 'edit' : 'check'}
        onSelect={
          line.approved_at
            ? () => handleUnapproveLine(line.id)
            : () => handleApproveLine(line.id)
        }
        disabled={!line.src}
        shortcut={line.approved_at ? '' : 'A'}
      />
      <ContextMenuItem
        title="Unwarp"
        icon="fit_width"
        onSelect={() => handleUnwarpLine(line.id)}
        disabled={!!line.approved_at}
        shortcut="U"
      />
      <ContextMenuItem
        title={line.is_disabled ? 'Enable' : 'Disable'}
        icon={line.is_disabled ? 'visibility' : 'visibility_off'}
        onSelect={() => {
          handleUpdateLine({ id: line.id, is_disabled: !line.is_disabled });
        }}
        disabled={!!line.approved_at}
        shortcut="M"
      />
      <PaddedContextMenuDivider />
      <ContextMenuItem
        title="Re-generate audio"
        icon="refresh"
        onSelect={() =>
          handleInference(
            line,
            projectDub.voice_engine || VoiceEngineType.ELEVENLABS,
          )
        }
        disabled={!!line.approved_at || !!line.uiLoading}
        shortcut="⌘↵"
      />
      <ContextMenuItem
        title="Re-translate"
        icon="translate"
        onSelect={() => handleRetranslateLine(line.id)}
        disabled={!!line.approved_at || !!line.uiLoading}
        shortcut="⌘B"
      />
      <PaddedContextMenuDivider />
      <ContextMenuItem
        title="Prompt emotion"
        icon="sentiment_very_satisfied"
        onSelect={() => dispatch(setPromptLineId(line.id))}
        disabled={!!line.approved_at || !!line.uiLoading}
        shortcut="⌘P"
      />
      <PaddedContextMenuDivider />
      {!!line.raw_src && showSplit && (
        <ContextMenuItem
          title="Split at cursor"
          icon="content_cut"
          onSelect={() => handleSplitLineAtPlayhead(line)}
          shortcut="⌘K"
          disabled={!!line.approved_at}
        />
      )}
      <ContextMenuSub>
        <ContextMenuSubItem
          title="Combine"
          icon="merge"
          disabled={!!line.approved_at}
        />
        <ContextMenuSubContent>
          <ContextMenuItem
            title="Combine with previous"
            icon="merge"
            onSelect={() => handleMergeLine(line.id, 'prev')}
            shortcut="⌘⇧J"
          />
          <ContextMenuItem
            title="Combine with next"
            icon="arrow_and_edge"
            onSelect={() => handleMergeLine(line.id, 'next')}
            shortcut="⌘J"
          />
        </ContextMenuSubContent>
      </ContextMenuSub>
      <PaddedContextMenuDivider />
      <ContextMenuItem
        title="History"
        icon="history"
        onSelect={() => dispatch(setHistoryModalId(line.id))}
        disabled={!!line.approved_at || !!line.uiLoading}
        shortcut="⌘H"
      />
      <ContextMenuItem
        title="Delete"
        icon="delete"
        onSelect={() => handleDeleteLine(line)}
        disabled={!!line.approved_at}
      />
      {user?.role === 'ADMIN' ? (
        <>
          <ContextMenuLabel>Admin</ContextMenuLabel>
          <ContextMenuItem
            title="Copy UUID"
            icon="content_copy"
            onSelect={() => {
              navigator.clipboard.writeText(line.id);
            }}
          />
          <ContextMenuItem
            title="Regenerate with Elevelabs"
            icon="refresh"
            onSelect={() => handleInference(line, VoiceEngineType.ELEVENLABS)}
            disabled={!!line.uiLoading}
          />
          <ContextMenuItem
            title="Regenerate with Deeptune"
            icon="refresh"
            onSelect={() => handleInference(line, VoiceEngineType.DEEPTUNE)}
            disabled={!!line.uiLoading}
          />
        </>
      ) : null}
    </ContextMenuContent>
  );
};

const PaddedContextMenuDivider = styled(ContextMenuDivider)`
  margin: 4px 0;
`;
