import { IconAlertTriangle } from '@tabler/icons-react';
import { Container } from 'modules/admin/ui';
import {
  ErrorMessage,
  FlexCol,
  FlexRow,
  Subtitle,
  Title,
} from 'modules/shared/ui';
import { ProjectType } from 'lib/Types';
import { BackToolbar } from './back-toolbar';

type Props = {
  project: ProjectType;
};

export const ProjectFailed = ({ project }: Props) => (
  <div style={{ height: '100%' }}>
    <BackToolbar />
    <Container style={{ height: 'calc(100% - 48px)' }}>
      <FlexCol style={{ gap: 8, textAlign: 'center', alignItems: 'center' }}>
        <FlexRow style={{ gap: 8, justifyContent: 'center' }}>
          <IconAlertTriangle color="var(--red)" />
          <Title>Project Failed</Title>
        </FlexRow>
        <Subtitle style={{ maxWidth: 340 }}>
          Please contact support at support@deeptune.com.
        </Subtitle>
        <ErrorMessage
          style={{
            fontSize: 11,
            padding: 8,
            width: '100%',
            justifyContent: 'center',
            marginTop: 8,
            maxWidth: 400,
          }}
        >
          {project.failed_reason}
        </ErrorMessage>
      </FlexCol>
    </Container>
  </div>
);
