import { LineType } from 'lib/Types';
import axios from './axios';

export const Lines = {
  create: (data: {
    orig_text: string;
    start: number;
    end: number;
    project_id: string;
    project_dub_id: string;
    character_id?: string;
  }) => axios.post('/lines', data),
  update: (id: string, data: Partial<LineType>) =>
    axios.put(`/lines/${id}`, data),
  approve: (id: string) => axios.post(`/lines/${id}/approve`),
  unapprove: (id: string) => axios.post(`/lines/${id}/unapprove`),
  unwarp: (id: string) => axios.post(`/lines/${id}/unwarp`),
  delete: (id: string) => axios.delete(`/lines/${id}`),
  inference: (id: string, data: { text: string }) =>
    axios.post(`/lines/${id}/inference`, data),
  inferenceDeeptune: (id: string, data: { text: string }) =>
    axios.post(`/lines/${id}/inference-deeptune`, data),
  merge: (id: string, data: { direction: 'next' | 'prev' }) =>
    axios.post(`/lines/${id}/merge`, data),
  adapt: (id: string) => axios.post(`/lines/${id}/adapt`),
  split: (id: string, data: { pos: number }) =>
    axios.post(`/lines/${id}/split`, data),
  splitAtPlayhead: (id: string, data: { seek_time: number }) =>
    axios.post(`/lines/${id}/split_at_playhead`, data),
  history: (id: string) => axios.get(`/lines/${id}/history`),
  restore: (id: string, data: { activity_id: string }) =>
    axios.post(`/lines/${id}/restore`, data),
  prompt: (id: string, data: { prompt: string }) =>
    axios.post(`/lines/${id}/prompt`, data),
  status: (data: { line_ids: string[] }) => axios.post('/lines/status', data),
  retranslate: (id: string) => axios.post(`/lines/${id}/retranslate`),
};
