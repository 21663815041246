import { ProjectUserRole } from 'lib/Types';
import axios from './axios';

export const ProjectUsers = {
  add: (data: { project_id: string; email: string }) =>
    axios.post('/project_users', data),
  update: (id: string, { role }: { role: ProjectUserRole }) =>
    axios.put(`/project_users/${id}`, { role }),
  remove: (id: string) => axios.delete(`/project_users/${id}`),
};
