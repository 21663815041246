import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActivityTypeEnum } from 'lib/Types';

export type EditorReduxType = {
  zoom: number;
  seekTime: number;
  duration: number;
  spinnerRotation: number;
  soloRowIds: { [id: string]: boolean };
  isOriginalMuted: boolean;
  isMFXMuted: boolean;
  characterModalId: string | null | undefined;
  promptLineId: string | null;
  activityModalType: ActivityTypeEnum | null;
  scaleSize: number;
  scaleWidth: number;
  historyModalId: string | null;
  isProjectSettingsOpen: boolean;
};

export const defaultEditorZoom = 50;

const initialState = {
  zoom: defaultEditorZoom,
  seekTime: 0,
  duration: 0,
  spinnerRotation: 0,
  soloRowIds: {},
  isOriginalMuted: true,
  isMFXMuted: false,
  characterModalId: undefined,
  promptLineId: null,
  activityModalType: null,
  scaleSize: 60,
  scaleWidth: 60,
  historyModalId: null,
  isProjectSettingsOpen: false,
} as EditorReduxType;

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setEditorSeekTime: (
      state: EditorReduxType,
      action: PayloadAction<number>,
    ) => {
      state.seekTime = action.payload;
      return state;
    },
    setEditorDuration: (
      state: EditorReduxType,
      action: PayloadAction<number>,
    ) => {
      state.duration = action.payload;
      return state;
    },
    setEditorSpinnerRotation: (
      state: EditorReduxType,
      action: PayloadAction<number>,
    ) => {
      state.spinnerRotation = action.payload;
      return state;
    },
    setEditorZoom: (state: EditorReduxType, action: PayloadAction<number>) => {
      state.zoom = action.payload;
      return state;
    },
    soloOrUnsoloRowId: (
      state,
      action: PayloadAction<{
        id: string;
        multi?: boolean;
        forceSolo?: boolean;
      }>,
    ) => {
      if (state.soloRowIds[action.payload.id]) {
        if (!action.payload.multi && Object.keys(state.soloRowIds).length > 1) {
          state.soloRowIds = {};
          return state;
        }
        delete state.soloRowIds[action.payload.id];
      } else {
        if (!action.payload.multi) state.soloRowIds = {};
        state.soloRowIds[action.payload.id] = true;
      }
      return state;
    },
    setRowSoloState: (
      state,
      action: PayloadAction<{ id: string; solo: boolean; multi?: boolean }>,
    ) => {
      if (action.payload.solo) {
        if (!action.payload.multi) state.soloRowIds = {};
        state.soloRowIds[action.payload.id] = true;
      } else {
        delete state.soloRowIds[action.payload.id];
      }
    },
    setOriginalMuted: (state, action: PayloadAction<boolean>) => {
      state.isOriginalMuted = action.payload;
      return state;
    },
    setMFXMuted: (state, action: PayloadAction<boolean>) => {
      state.isMFXMuted = action.payload;
      return state;
    },
    setTimelineZoom: (
      state: EditorReduxType,
      action: PayloadAction<number>,
    ) => {
      state.zoom = action.payload;
      return state;
    },
    setCharacterModalId: (
      state: EditorReduxType,
      action: PayloadAction<string | null | undefined>,
    ) => {
      state.characterModalId = action.payload;
      return state;
    },
    setPromptLineId: (
      state: EditorReduxType,
      action: PayloadAction<string | null>,
    ) => {
      state.promptLineId = action.payload;
      return state;
    },
    setActivityModalType: (
      state: EditorReduxType,
      action: PayloadAction<ActivityTypeEnum | null>,
    ) => {
      state.activityModalType = action.payload;
      return state;
    },
    setProjectSettingsOpen: (
      state: EditorReduxType,
      action: PayloadAction<boolean>,
    ) => {
      state.isProjectSettingsOpen = action.payload;
      return state;
    },
    setScaleSize: (state: EditorReduxType, action: PayloadAction<number>) => {
      state.scaleSize = action.payload;
      return state;
    },
    setScaleWidth: (state: EditorReduxType, action: PayloadAction<number>) => {
      state.scaleWidth = action.payload;
      return state;
    },
    setHistoryModalId: (
      state: EditorReduxType,
      action: PayloadAction<string | null>,
    ) => {
      state.historyModalId = action.payload;
      return state;
    },
    resetEditor: () => initialState,
  },
});

export const {
  setEditorZoom,
  setEditorSeekTime,
  setEditorDuration,
  setEditorSpinnerRotation,
  setPromptLineId,
  soloOrUnsoloRowId,
  setRowSoloState,
  setOriginalMuted,
  setMFXMuted,
  setCharacterModalId,
  setProjectSettingsOpen,
  setActivityModalType,
  resetEditor,
  setTimelineZoom,
  setScaleSize,
  setScaleWidth,
  setHistoryModalId,
} = editorSlice.actions;
