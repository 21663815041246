export enum TimelineColorsEnum {
  ACTIVE_FILL = '#4D4D4D',
  FILL = '#1D1D1D',
  EMPTY_FILL = 'transparent',
  DEFAULT_BORDER = 'var(--border)',
  DEFAULT_WAVEFORM = '#FFFFFF',
}
export interface DragInfo {
  id: string;
  node?: any;
  start?: number;
  end?: number;
  heldShift?: boolean;
}
export interface ColorInfo {
  activeFill: string;
  inactiveFill: string;
  border: string;
  waveform: string;
}
