import { ProjectDubType } from 'lib/Types';
import { TimelineEngine } from 'lib/engine/engine';
import { getProjectDubLines } from 'lib/redux/selectors/lines';
import { setEditorSeekTime } from 'lib/redux/slices/editor';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import styled from 'styled-components';
import { useActiveLine } from '../../hooks/use-active-line';
import { LineRow } from './components/line-row';
import { useLoadingStatusPing } from './components/use-line-status-ping';

type Props = {
  projectDub: ProjectDubType;
  focusTimeline: () => void;
  timelineEngine: MutableRefObject<TimelineEngine>;
};

const SMOOTH_SCROLL_THRESHOLD = 10;

export const LineEditor = ({
  projectDub,
  focusTimeline,
  timelineEngine,
}: Props) => {
  const dispatch = useDispatch();
  const lines = useSelector(getProjectDubLines(projectDub.id));
  const { activeLine } = useActiveLine();
  const virtuosoRef = useRef<VirtuosoHandle | null>(null);
  const [visibleRange, setVisibleRange] = useState({
    startIndex: 0,
    endIndex: 0,
  });
  useLoadingStatusPing({ lines });

  useEffect(() => {
    if (!activeLine || !virtuosoRef.current) return;
    timelineEngine.current?.setTime(activeLine.start);
    dispatch(setEditorSeekTime(activeLine.start));
    const lineIdx = lines.findIndex((line) => line.id === activeLine.id);
    if (lineIdx === -1) return;

    // If scrolling >= 10 lines, snap to the line, else smooth scroll
    let behavior: 'auto' | 'smooth' = 'smooth';

    if (
      lineIdx < visibleRange.startIndex - SMOOTH_SCROLL_THRESHOLD ||
      lineIdx > visibleRange.endIndex + SMOOTH_SCROLL_THRESHOLD
    ) {
      behavior = 'auto';
    }

    virtuosoRef.current.scrollToIndex({
      index: lineIdx,
      behavior,
      align: 'center',
    });
  }, [activeLine?.id]);

  if (!lines.length) {
    return <Container>No lines detected.</Container>;
  }

  return (
    <Container>
      <Virtuoso
        data={lines}
        rangeChanged={setVisibleRange}
        increaseViewportBy={400}
        style={{ height: '100%' }}
        ref={virtuosoRef}
        itemContent={(i) => (
          <LineRow
            key={lines[i].id}
            line={lines[i]}
            focusTimeline={focusTimeline}
            timelineEngine={timelineEngine}
          />
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 4px;
  overflow: auto;
  height: 100%;
`;
