import React from 'react';
import {
  SelectRoot,
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { ProjectUserRole, ProjectUserType } from 'lib/Types';
import api from 'lib/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProjectUser,
  upsertProjectUsers,
} from 'lib/redux/slices/projectUsers';
import { getUserMe } from 'lib/redux/selectors/users';
import { makeGetProjUsersByProjId } from 'lib/redux/selectors/projectUsers';
import { IconCheck } from '@tabler/icons-react';

type Props = {
  projectUser: ProjectUserType;
};

export const ProjectUserRoleSelect = ({ projectUser }: Props) => {
  const dispatch = useDispatch();
  const me = useSelector(getUserMe);
  const meProjectUser = useSelector(
    makeGetProjUsersByProjId(projectUser.project_id),
  ).find((pu) => pu.user_id === me?.id);
  const isDisabled =
    projectUser.user_id === me?.id ||
    (meProjectUser?.role !== 'OWNER' && me?.role !== 'ADMIN');

  const handleSelect = async (role: ProjectUserRole | 'REMOVE') => {
    try {
      if (role === 'REMOVE') {
        await api.projectUsers.remove(projectUser.id);
        dispatch(deleteProjectUser(projectUser.id));
      } else {
        const { data } = await api.projectUsers.update(projectUser.id, {
          role,
        });
        dispatch(upsertProjectUsers([data.project_user]));
      }
    } catch (e) {
      console.log(e);
      alert('Failed to update user');
    }
  };

  return (
    <SelectRoot value={projectUser.role} onValueChange={handleSelect}>
      <SelectTrigger disabled={isDisabled}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectViewport>
          <SelectItem value="OWNER">
            <SelectItemText>Owner</SelectItemText>
            <SelectItemIndicator>
              <IconCheck />
            </SelectItemIndicator>
          </SelectItem>
          <SelectItem value="EDITOR">
            <SelectItemText>Can edit</SelectItemText>
            <SelectItemIndicator>
              <IconCheck />
            </SelectItemIndicator>
          </SelectItem>
          <SelectItem value="REMOVE" style={{ color: 'var(--red)' }}>
            Remove
          </SelectItem>
        </SelectViewport>
      </SelectContent>
    </SelectRoot>
  );
};
