import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { auth } from 'lib/utils/firebase';
import { getUserMe } from 'lib/redux/selectors/users';
import { Button, Input } from 'modules/shared/ui';

function Auth() {
  const navigate = useNavigate();
  const user = useSelector(getUserMe);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    await signInWithEmailAndPassword(auth, email, password);
    navigate('/');
  };

  if (user) return <Navigate to="/" />;

  return (
    <Container>
      <Card>
        <Title>Welcome to Deeptune</Title>
        <Subtitle style={{ marginTop: 8 }}>
          Login or register with your email.
        </Subtitle>
        <Input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginTop: 30 }}
        />
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginTop: 30 }}
        />
        <Button role="button" onClick={handleLogin} style={{ marginTop: 10 }}>
          Continue
        </Button>
      </Card>
    </Container>
  );
}
export default Auth;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;
`;

const Title = styled.div`
  font-size: 22px;
  color: var(--text-primary);
  font-weight: 500;
`;

const Subtitle = styled.div`
  font-size: 16px;
  color: var(--text-tertiary);
`;
