import { TranscriptCharacterType } from 'lib/Types';
import { Dot, FlexRow } from 'modules/shared/ui';
import React from 'react';
import styled from 'styled-components';
import { EditableStringInput } from 'modules/shared/editable-components/editable-string-input';
import { TranscriptCharacterContextMenu } from 'modules/shared/context-menu/transcript-character-context-menu';
import { useTranscriptCharacterApi } from 'lib/hooks/api/use-transcript-character-api';

type Props = {
  transcriptCharacter: TranscriptCharacterType;
};

export const TranscriptCharacterRow = ({ transcriptCharacter }: Props) => {
  const { handleUpdateTranscriptCharacter } = useTranscriptCharacterApi();

  return (
    <TranscriptCharacterContextMenu
      transcriptCharacter={transcriptCharacter}
      entrypoint="modal"
    >
      <Container key={transcriptCharacter.id}>
        <FlexRow style={{ flexGrow: 1 }}>
          <Dot
            style={{
              background: transcriptCharacter.color,
              marginRight: 16,
              flexShrink: 0,
            }}
          />
          <EditableStringInput
            id={`transcript-character-row-${transcriptCharacter.id}-modal`}
            value={transcriptCharacter.name}
            handleDoneEditing={(v) => {
              handleUpdateTranscriptCharacter({
                id: transcriptCharacter.id,
                name: v,
              });
            }}
          />
        </FlexRow>
      </Container>
    </TranscriptCharacterContextMenu>
  );
};

const Container = styled(FlexRow)`
  font-size: 13px;
  font-weight: 400;
  color: var(--text-primary);
  padding: 8px 32px;

  :hover {
    background-color: var(--hover);
  }
`;
