import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDt_WphEBZqFNPWxeAORnf9Aca4x4-CzQ0',
  authDomain: 'deeptune-f12f9.firebaseapp.com',
  projectId: 'deeptune-f12f9',
  storageBucket: 'deeptune-f12f9.appspot.com',
  messagingSenderId: '431922623278',
  appId: '1:431922623278:web:5325c9aa4280d1f91bc0bf',
  measurementId: 'G-MXN1GSDSHX',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
