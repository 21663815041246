import { TimelineEngine, TimelineRow } from 'lib/engine/engine';
import {
  getEditorSeekTime,
  getOriginalMuted,
  getMFXMuted,
} from 'lib/redux/selectors/editor';
import {
  setOriginalMuted,
  setMFXMuted,
  soloOrUnsoloRowId,
} from 'lib/redux/slices/editor';
import { useDispatch, useSelector } from 'react-redux';
import { useClipTrackApi } from 'lib/hooks/api/use-clip-track-api';
import { useCharacterApi } from 'lib/hooks/api/use-character-api';

type Props = {
  row: TimelineRow;
  timelineEngine: React.MutableRefObject<TimelineEngine>;
};

export const useTimelineActions = ({ timelineEngine, row }: Props) => {
  const dispatch = useDispatch();
  const originalMuted = useSelector(getOriginalMuted);
  const mfxMuted = useSelector(getMFXMuted);
  const seekTime = useSelector(getEditorSeekTime);
  const { handleUpdateClipTrack } = useClipTrackApi();
  const { handleUpdateCharacter } = useCharacterApi();

  const handleButtonPress = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    // Pause to reset seek times and sync up tracks
    timelineEngine.current.pause();
    timelineEngine.current.setTime(seekTime);
  };

  const handleSolo = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let multi = false;
    if (e.metaKey || e.ctrlKey) multi = true;
    dispatch(soloOrUnsoloRowId({ id: row.id, multi }));
    handleButtonPress(e);
  };

  const handleDisable = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (row.id === 'original') {
      dispatch(setOriginalMuted(!originalMuted));
    } else if (row.id === 'mfx') {
      dispatch(setMFXMuted(!mfxMuted));
    } else {
      if (row.type === 'clipTrack') {
        handleUpdateClipTrack({ id: row.id, is_disabled: !row.disabled });
      } else if (row.type === 'character') {
        handleUpdateCharacter({ id: row.id, is_disabled: !row.disabled });
      }
    }
    handleButtonPress(e);
  };

  return { handleSolo, handleDisable };
};
