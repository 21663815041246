import { IconChevronLeft } from '@tabler/icons-react';
import { VoiceDataType } from 'lib/Types';
import api from 'lib/api';
import { getVoices } from 'lib/redux/selectors/voices';
import { upsertVoices } from 'lib/redux/slices/voices';
import { useActiveLine } from 'modules/editor/hooks/use-active-line';
import { DialogTitle } from 'modules/radix/Dialog';
import { HoverIcon } from 'modules/radix/HoverIcon';
import {
  Slider,
  SliderRange,
  SliderThumb,
  SliderTrack,
} from 'modules/radix/Slider';
import {
  Button,
  ButtonSecondary,
  FlexCol,
  FlexRow,
  Input,
  Label,
  Title,
} from 'modules/shared/ui';
import { useEffect, useMemo, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { VoiceDnd } from './voice-dnd';

type Props = {
  voiceDataId: string;
  onClose: () => void;
};

export const CustomVoiceSettings = ({ voiceDataId, onClose }: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [voiceData, setVoiceData] = useState<VoiceDataType | null>(null);
  const { activeLine } = useActiveLine();
  const [testText, setTestText] = useState<string>(
    activeLine?.text || 'Did you know some parrots can live up to 60 years?',
  );
  const [testLoading, setTestLoading] = useState(false);
  const [testResultUri, setTestResultUri] = useState<string>('');
  const [updateVoiceDataLoading, setUpdateVoiceDataLoading] = useState(false);
  const [elSettingsLoading, setElSettingsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [confirmCopyright, setConfirmCopyright] = useState(false);
  const voices = useSelector(getVoices);
  const voice = useMemo(
    () => voices.find((v) => v.voice_data_id === voiceDataId),
    [voices, voiceDataId],
  );
  const canUpdate =
    !updateVoiceDataLoading &&
    (voiceData?.data_urls.length || (files.length && confirmCopyright)) &&
    voiceData;

  const handleLoad = async () => {
    try {
      setLoading(true);
      const { data } = await api.voiceData.getVoiceDataById(voiceDataId);
      setVoiceData(data.voice_data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setUpdateVoiceDataLoading(true);
      if (!voiceData || !voice || (!files && !voiceData.data_urls)) return;

      const { data } = await api.voices.update({
        id: voice.id,
        files,
        data_urls: voiceData?.data_urls || [],
      });

      dispatch(upsertVoices([data.voice]));
      setVoiceData(data.voice_data);
      setFiles([]);
    } catch (error) {
      alert('Failed to update voice');
    } finally {
      setUpdateVoiceDataLoading(false);
    }
  };

  const handleUpdateElSettings = async () => {
    try {
      if (!voiceData) return;
      setElSettingsLoading(true);
      const { data } = await api.voiceData.updateElVoiceSettings(
        voiceDataId,
        voiceData.el_voice_settings,
      );
      setVoiceData(data.voice_data);
      setTestResultUri('');
    } catch (error) {
      alert('failed to update voice settings');
    } finally {
      setElSettingsLoading(false);
    }
  };

  const handleTestVoice = async () => {
    try {
      setTestLoading(true);
      setTestResultUri('');
      const response = await api.voiceData.testVoiceData(voiceDataId, {
        text: testText,
      });
      if (response.data) {
        const blob = new Blob([response.data], { type: 'audio/mpeg' });
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          setTestResultUri(base64data);
        };
        reader.readAsDataURL(blob);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTestLoading(false);
    }
  };

  const handleDeleteClip = (data_url: string) => {
    setVoiceData((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        data_urls: prev?.data_urls.filter((url) => url !== data_url) || [],
      };
    });
  };

  useEffect(() => {
    handleLoad();
  }, [voiceDataId]);

  const getBody = () => {
    if (isLoading) {
      return <Container>Loading...</Container>;
    }
    return (
      <FlexCol style={{ gap: 32 }}>
        <FlexCol style={{ gap: 8 }}>
          <Title>Test Voice</Title>
          {testResultUri ? (
            <audio src={testResultUri} controls />
          ) : testLoading ? (
            <TailSpin width={13} height={13} color="var(--text)" />
          ) : null}
          <Input
            type="text"
            value={testText}
            onChange={(e) => setTestText(e.target.value)}
          />
          <Button
            disabled={!testText.length || testLoading}
            onClick={handleTestVoice}
          >
            {testLoading ? (
              <TailSpin width={13} height={13} color="white" />
            ) : (
              'Test Voice'
            )}
          </Button>
        </FlexCol>
        <FlexCol style={{ gap: 8 }}>
          <FlexRow style={{ gap: 8 }}>
            <Title>Voice Settings</Title>
            {elSettingsLoading ? (
              <TailSpin width={13} height={13} color="white" />
            ) : null}
          </FlexRow>
          {voiceData ? (
            <>
              <div>
                <Label>Stability (More variable to more stable)</Label>
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={[voiceData.el_voice_settings.stability]}
                  onValueChange={(e) =>
                    setVoiceData({
                      ...voiceData,
                      el_voice_settings: {
                        ...voiceData.el_voice_settings,
                        stability: e[0],
                      },
                    })
                  }
                  onValueCommit={handleUpdateElSettings}
                  disabled={elSettingsLoading}
                >
                  <SliderTrack>
                    <SliderRange />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </div>
              <div>
                <Label>Similarity (Low to high)</Label>
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={[voiceData.el_voice_settings.similarity_boost]}
                  onValueChange={(e) =>
                    setVoiceData({
                      ...voiceData,
                      el_voice_settings: {
                        ...voiceData.el_voice_settings,
                        similarity_boost: e[0],
                      },
                    })
                  }
                  onValueCommit={handleUpdateElSettings}
                  disabled={elSettingsLoading}
                >
                  <SliderTrack>
                    <SliderRange />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </div>
              <div>
                <Label>Style Exaggeration (None to exaggerated)</Label>
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={[voiceData.el_voice_settings.style]}
                  onValueChange={(e) =>
                    setVoiceData({
                      ...voiceData,
                      el_voice_settings: {
                        ...voiceData.el_voice_settings,
                        style: e[0],
                      },
                    })
                  }
                  onValueCommit={handleUpdateElSettings}
                  disabled={elSettingsLoading}
                >
                  <SliderTrack>
                    <SliderRange />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </div>
            </>
          ) : null}
        </FlexCol>
        <FlexCol style={{ gap: 8 }}>
          <Title>Choose new reference clips</Title>
          <div style={{ paddingBottom: 8 }}>
            Upload reference clips below to clone the voice. For best quality,
            choose clips that are over 2 seconds and have no other background
            noise or character talking.
          </div>
          <Label>Reference clips</Label>
          {(voiceData?.data_urls || []).map((data_url) => (
            <Row key={data_url}>
              <audio src={data_url} controls />
              <ButtonSecondaryShort onClick={() => handleDeleteClip(data_url)}>
                Remove
              </ButtonSecondaryShort>
            </Row>
          ))}
          <Label>Samples</Label>
          <VoiceDnd
            onDropFiles={setFiles}
            files={files}
            placeholder="Drag to upload audio (.wav or .mp3)"
          />
          {files.length > 0 && (
            <FlexRow style={{ gap: 8 }}>
              <input
                type="checkbox"
                checked={confirmCopyright}
                onChange={(e) => setConfirmCopyright(e.target.checked)}
              />
              <Label style={{ cursor: 'default' }}>
                I hereby confirm that I have all necessary rights or consents to
                upload and clone these voice samples and that I will not use the
                platform-generated content for any illegal, fraudulent, or
                harmful purpose. I reaffirm my obligation to abide by the Terms
                of Service and Privacy Policy.
              </Label>
            </FlexRow>
          )}
          <Button onClick={handleUpdate} disabled={!canUpdate}>
            {updateVoiceDataLoading ? (
              <TailSpin width={13} height={13} color="white" />
            ) : (
              'Update Voice'
            )}
          </Button>
        </FlexCol>
      </FlexCol>
    );
  };

  return (
    <Container>
      <LeftAlignRow>
        <HoverIcon onClick={onClose}>
          <IconChevronLeft />
        </HoverIcon>
        <DialogTitle>Edit custom voice</DialogTitle>
      </LeftAlignRow>
      <div>{getBody()}</div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  gap: 24px;
  overflow: auto;
`;

const LeftAlignRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const ButtonSecondaryShort = styled(ButtonSecondary)`
  height: 20px;
`;
