import { CaptionType } from 'lib/Types';
import axios from './axios';

export const Captions = {
  // get: (project_dub_id: string) => axios.get(`/captions/${project_dub_id}`),
  create: (data: {
    project_dub_id: string;
    top_pct?: number;
    left_pct?: number;
    bottom_pct?: number;
    right_pct?: number;
    start_time?: number;
    end_time: number;
    font_size?: number;
    font?: string;
    color?: string;
    shadow?: string;
  }) => axios.post('/captions', data),
  update: (id: string, data: Partial<CaptionType>) =>
    axios.put(`/captions/${id}`, data),
  getFonts: () => axios.get('/captions/fonts'),
};
