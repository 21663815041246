import { FontStyleEnum, FontWeightEnum } from 'lib/Types';
import { getFonts } from 'lib/redux/selectors/fonts';
import { useSelector } from 'react-redux';
import { FontInfo, WebsafeFontProperties } from '../fonts';

export const UseFontProperties = () => {
  const customFonts = useSelector(getFonts);
  const customFontProperties: Record<string, FontInfo> = Object.fromEntries(
    customFonts.map((font) => {
      const availableWeights = Array.from(
        new Set(
          font.variants.map((variant) => variant.weight as FontWeightEnum),
        ),
      ).sort((a, b) => a - b);

      const supportsItalic = font.variants.some(
        (variant) => variant.style === FontStyleEnum.ITALIC,
      );

      return [
        font.name,
        {
          availableWeights,
          supportsItalic,
        },
      ];
    }),
  );
  const fontProperties = {
    ...WebsafeFontProperties,
    ...customFontProperties,
  };

  return {
    fontProperties,
  };
};
