import api from 'lib/api';
import { useCustomFonts } from 'lib/hooks/use-custom-fonts';
import { useTheme } from 'lib/hooks/use-theme';
import { getUserMe } from 'lib/redux/selectors/users';
import { getWorkspacesDict } from 'lib/redux/selectors/workspaces';
import { upsertFonts } from 'lib/redux/slices/fonts';
import { upsertProjectDubs } from 'lib/redux/slices/projectDubs';
import { upsertProjectUsers } from 'lib/redux/slices/projectUsers';
import { upsertProjects } from 'lib/redux/slices/projects';
import { upsertUser, upsertUsers } from 'lib/redux/slices/user';
import { upsertVoices } from 'lib/redux/slices/voices';
import { upsertWorkspaceUsers } from 'lib/redux/slices/workspaceUsers';
import { upsertWorkspaces } from 'lib/redux/slices/workspaces';
import { auth } from 'lib/utils/firebase';
import { Loading } from 'modules/shared/loading';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

type Props = {
  children?: any;
};

export function Initialize({ children }: Props) {
  const dispatch = useDispatch();

  const [user, loading] = useAuthState(auth);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const activeUser = useSelector(getUserMe);
  const activeWorkspace =
    useSelector(getWorkspacesDict)[activeUser?.active_workspace_id || ''];
  const posthog = usePostHog();
  useTheme();
  useCustomFonts();

  useEffect(() => {
    // Only init once firebase user is loaded
    if (loading) return;

    handleInit();
  }, [user, loading]);

  const handleInit = async () => {
    if (!user) {
      setLoading(false);
      return;
    }
    try {
      setLoading(true);

      const [
        userRes,
        usersRes,
        projectRes,
        voicesRes,
        workspacesRes,
        customFontsRes,
      ] = await Promise.all([
        api.users.me(),
        api.users.get(),
        api.projects.get(),
        api.voices.get(),
        api.workspaces.get(),
        api.captions.getFonts(),
      ]);
      dispatch(upsertUser(userRes.data.user));
      dispatch(upsertUsers(usersRes.data.users));

      // TODO: this overlaps with handleInitWorkspace?
      dispatch(upsertProjects(projectRes.data.projects));
      dispatch(upsertProjectDubs(projectRes.data.project_dubs));
      dispatch(upsertProjectUsers(projectRes.data.project_users));
      dispatch(upsertVoices(voicesRes.data.voices));
      dispatch(upsertWorkspaces(workspacesRes.data.workspaces));
      dispatch(upsertWorkspaceUsers(workspacesRes.data.workspace_users));
      dispatch(upsertFonts(customFontsRes.data.fonts));
      posthog?.identify(userRes.data.user.id, {
        ...userRes.data.user,
      });
    } catch (err: any) {
      setError('Could not connect to server');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!activeWorkspace) return;

    const handleInitWorkspace = async () => {
      try {
        setLoading(true);
        const [projectRes, voicesRes] = await Promise.all([
          api.projects.get(),
          api.voices.get(),
          api.workspaces.get(),
        ]);
        dispatch(upsertProjects(projectRes.data.projects));
        dispatch(upsertProjectDubs(projectRes.data.project_dubs));
        dispatch(upsertProjectUsers(projectRes.data.project_users));
        dispatch(upsertVoices(voicesRes.data.voices));
      } catch (err) {
        alert('failed fetching projects');
      } finally {
        setLoading(false);
      }
    };

    handleInitWorkspace();
  }, [activeWorkspace]);

  if (isLoading) {
    return <Loading />;
  }

  if (error.length) {
    return <Container>{error}</Container>;
  }

  return children;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
  height: calc(100%);
  font-size: 12px;
  background: var(--background);
  height: 100%;
  align-items: center;
  justify-content: center;
`;
