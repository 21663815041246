import { TranscriptLineType } from 'lib/Types';
import { useTranscriptLineApi } from 'lib/hooks/api/use-transcript-line-api';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuPortal,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import React from 'react';

type Props = {
  transcriptLine: TranscriptLineType;
  children?: React.ReactNode;
};

export const TranscriptLineContextMenu = ({
  transcriptLine,
  children,
}: Props) => (
  <ContextMenu>
    <ContextMenuTrigger>{children}</ContextMenuTrigger>
    <ContextMenuPortal>
      <TranscriptLineMenuContent transcriptLine={transcriptLine} />
    </ContextMenuPortal>
  </ContextMenu>
);

type TranscriptContentProps = {
  transcriptLine: TranscriptLineType;
};

export const TranscriptLineMenuContent = ({
  transcriptLine,
}: TranscriptContentProps) => {
  const {
    handleDeleteTranscriptLine,
    handleMergeTranscriptLine,
    handleApproveTranscriptLine,
    handleUnapproveTranscriptLine,
  } = useTranscriptLineApi();

  return (
    <ContextMenuContent onClick={(e) => e.stopPropagation()}>
      <ContextMenuItem
        title={transcriptLine.approved_at ? 'Edit' : 'Approve'}
        icon={transcriptLine.approved_at ? 'edit' : 'check'}
        onSelect={
          transcriptLine.approved_at
            ? () => handleUnapproveTranscriptLine(transcriptLine.id)
            : () => handleApproveTranscriptLine(transcriptLine.id)
        }
      />
      <ContextMenuSub>
        <ContextMenuSubItem
          title="Combine"
          icon="merge"
          disabled={!!transcriptLine.approved_at}
        />
        <ContextMenuSubContent>
          <ContextMenuItem
            title="Combine with previous"
            icon="merge"
            onSelect={() => {
              handleMergeTranscriptLine(transcriptLine.id, 'prev');
            }}
          />
          <ContextMenuItem
            title="Combine with next"
            icon="arrow_and_edge"
            onSelect={() =>
              handleMergeTranscriptLine(transcriptLine.id, 'next')
            }
          />
        </ContextMenuSubContent>
      </ContextMenuSub>
      <ContextMenuItem
        title="Delete"
        icon="delete"
        onSelect={() => {
          handleDeleteTranscriptLine(transcriptLine);
        }}
        disabled={!!transcriptLine.approved_at}
      />
    </ContextMenuContent>
  );
};
