import { ProjectType } from 'lib/Types';
import { TimelineEngine } from 'lib/engine/engine';
import { useTimelinePlayer } from 'modules/editor/hooks/use-timeline-player';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import { MutableRefObject } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

type Props = {
  project: ProjectType;
  timelineEngine: MutableRefObject<TimelineEngine>;
  onPlayerReady?: () => void;
};

export const Player = ({ project, timelineEngine, onPlayerReady }: Props) => {
  const { playerRef, isPlaying, onDuration } = useTimelinePlayer({
    timelineEngine,
  });

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <Container>
          <ReactPlayer
            ref={playerRef}
            url={project.hls_video_src || project.video_src}
            width="100%"
            height="100%"
            progressInterval={10}
            playing={isPlaying}
            loop={false}
            onDuration={onDuration}
            muted
            onReady={onPlayerReady}
            config={{ file: { attributes: { crossOrigin: 'anonymous' } } }}
          />
        </Container>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem
          title="Download"
          icon="download"
          onSelect={() => {
            window.open(project.video_src, '_blank')?.focus();
          }}
        />
        <ContextMenuItem
          title="Copy Video URL"
          icon="content_copy"
          onSelect={() => {
            navigator.clipboard.writeText(project.video_src);
          }}
        />
      </ContextMenuContent>
    </ContextMenu>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  background: black;
  height: 100%;
`;
