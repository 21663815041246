import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getUserMe } from 'lib/redux/selectors/users';
import { NavRoutes } from 'lib/routes';

type Props = {
  children: any;
};

export function PrivateRoute({ children }: Props) {
  const user = useSelector(getUserMe);

  if (!user) return <Navigate to={NavRoutes.Auth} />;
  return children;
}
