import $ from 'jquery';
import { ProjectDubType, ProjectType } from 'lib/Types';
import { TimelineEngine, TimelineRow } from 'lib/engine/engine';
import { useLineApi } from 'lib/hooks/api/use-line-api';
import { getCaptionByProjectDubId } from 'lib/redux/selectors/captions';
import { getClipsDict } from 'lib/redux/selectors/clips';
import { getEditorSeekTime, getPromptLineId } from 'lib/redux/selectors/editor';
import { getLinesDict } from 'lib/redux/selectors/lines';

import { setPromptLineId } from 'lib/redux/slices/editor';
import { getComputedColor } from 'lib/utils/utils';
import {
  CAPTIONBAR_HEIGHT,
  OVERSCAN_PADDING,
  SCROLLBAR_SIZE,
} from 'modules/editor/constants';
import { useActiveLine } from 'modules/editor/hooks/use-active-line';
import { useKonvaSpinnerRotation } from 'modules/editor/hooks/use-konva-spinner';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import { ClipContextMenuContent } from 'modules/shared/context-menu/clip-context-menu';
import { LineContextMenuContent } from 'modules/shared/context-menu/line-context-menu';
import { useWorkerPool } from 'modules/shared/konva/hooks/use-worker-pool';
import { KonvaMarkers } from 'modules/shared/konva/konva-markers';
import { StemAction } from 'modules/shared/konva/stem-action';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Group, Layer, Line, Rect, Stage } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { useGesture } from 'react-use-gesture';
import { CaptionBar } from './caption-bar';
import { ClipAction } from './clip-action';
import { LineAction } from './line-action';
import { WaveformPopover } from './prompt-popover';

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
  data: TimelineRow[];
  timelineEngine: React.MutableRefObject<TimelineEngine>;
  rowHeight: number;
  dimensions: { width: number; height: number };
  sidebarRef: React.MutableRefObject<HTMLDivElement | null>;
  stageY: number;
  setStageY: (y: number) => void;
};

export const KonvaTimeline = ({
  project,
  projectDub,
  data,
  timelineEngine,
  rowHeight,
  dimensions,
  sidebarRef,
  stageY,
  setStageY,
}: Props) => {
  const dispatch = useDispatch();
  const lastOffset = useRef(0);
  const lineTopPaddingPx = 2;
  const defaultPxPerSec = 10;
  const stageRef = useRef<any>(null);
  const [stageScale, setStageScale] = useState(1);
  const [stageX, setStageX] = useState(0);
  const [currTime, setCurrTime] = useState(0);
  const seekTime = useSelector(getEditorSeekTime);
  const lineDict = useSelector(getLinesDict);
  const clipsDict = useSelector(getClipsDict);
  const [contextMenuId, setContextMenuId] = useState<string | null>(null);
  const contextMenuLine = lineDict[contextMenuId || ''];
  const contextMenuClip = clipsDict[contextMenuId || ''];
  const { handleCreateLine } = useLineApi();
  const { activeLine } = useActiveLine();
  const activeLineIndex = data.findIndex(
    (d) => d && d.id === activeLine?.character_id,
  );
  const scrollHeight = rowHeight * (data.length + 1) + SCROLLBAR_SIZE;
  const showPopover = useSelector(getPromptLineId);
  const popoverLineId = useSelector(getPromptLineId);
  const popoverLine = popoverLineId ? lineDict[popoverLineId] : null;
  const popoverLineIndex = data.findIndex(
    (d) => d && d.id === popoverLine?.character_id,
  );
  const caption = useSelector(getCaptionByProjectDubId(projectDub.id));
  useKonvaSpinnerRotation({ projectDub });
  const enqueueTask = useWorkerPool(4);
  const captionsHeight = caption ? CAPTIONBAR_HEIGHT : 0;

  const checkFocused = () => {
    const dialog = $('[role="dialog"]');
    return !dialog.length;
  };

  const handleZoom = (newScale: number) => {
    setStageScale(newScale);
    const newStageX =
      -seekTime * newScale * defaultPxPerSec + dimensions.width / 2;
    setStageX(Math.min(0, newStageX));
  };

  useHotkeys(
    'mod+=,mod+NumpadAdd',
    (e) => {
      if (!checkFocused()) return;
      handleZoom(stageScale * 1.5);
      e.preventDefault();
      e.stopPropagation();
    },
    { enableOnFormTags: ['INPUT'], enableOnContentEditable: true },
    [checkFocused, stageScale],
  );

  useHotkeys(
    'mod+minus,mod+NumpadSubtract',
    (e) => {
      if (!checkFocused()) return;
      handleZoom(stageScale * 0.5);
      e.preventDefault();
      e.stopPropagation();
    },
    { enableOnFormTags: ['INPUT'], enableOnContentEditable: true },
    [checkFocused, stageScale],
  );

  useEffect(() => {
    setCurrTime(seekTime);
  }, [seekTime]);

  // Center the cursor when the active line changes
  useEffect(() => {
    if (!activeLine) return;
    const time = activeLine?.start;

    // Check if the cursor is not visible
    const cursorPosition = time * stageScale * defaultPxPerSec;
    if (
      cursorPosition < -stageX ||
      cursorPosition > -stageX + dimensions.width
    ) {
      // Calculate the new stage x-position to center the cursor
      const newStageX =
        -time * stageScale * defaultPxPerSec + dimensions.width / 2;
      setStageX(Math.min(0, newStageX));
    }

    // Calculate the new stage y-position to center the cursor vertically
    if (activeLineIndex !== -1) {
      const lineVerticalPosition =
        (activeLineIndex + 1) * rowHeight + lineTopPaddingPx;

      const isVisible =
        lineVerticalPosition + rowHeight >= -stageY &&
        lineVerticalPosition <= -stageY + dimensions.height;

      if (!isVisible) {
        const newStageY = -lineVerticalPosition + dimensions.height / 2;
        setStageY(
          Math.min(0, Math.max(newStageY, dimensions.height - scrollHeight)),
        );
      }
    }
  }, [activeLine?.id]);

  useEffect(() => {
    const newY = Math.min(
      0,
      Math.max(stageY, dimensions.height - scrollHeight),
    );
    setStageY(newY);
  }, [dimensions.height]);

  useEffect(() => {
    // Update playhead while the video plays
    const setTickTimeHandler = timelineEngine.current.on(
      'setTimeByTick',
      ({ time }) => {
        setCurrTime(time);
      },
    );
    // Update playhead on pause
    const setTimeHandler = timelineEngine.current.on(
      'afterSetTime',
      ({ time }) => setCurrTime(time),
    );
    return () => {
      timelineEngine.current.off(setTickTimeHandler);
      timelineEngine.current.off(setTimeHandler);
    };
  }, [timelineEngine.current]);

  useGesture(
    {
      onPinch: (state) => {
        const {
          offset: [d],
        } = state;
        const scaleBy = 1.07;
        const stage = stageRef.current as any;
        const pointer = stage.getPointerPosition();
        const oldScale = stageScale;
        const mousePointTo = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };

        const newScale =
          d > lastOffset.current ? oldScale * scaleBy : oldScale / scaleBy;
        setStageScale(newScale);
        const newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };
        setStageX(Math.min(newPos.x, 0));
        lastOffset.current = d;
      },
      onWheel: ({ delta: [dx, dy], event }) => {
        event.preventDefault();
        // Panning speed, adjust as necessary
        const speed = 2;
        const newX = stageX - dx * speed;
        setStageX(Math.min(0, newX));

        let newY = stageY - dy * speed;
        newY = Math.min(0, Math.max(newY, dimensions.height - scrollHeight));
        setStageY(newY);
        sidebarRef.current?.scrollTo({
          top: -newY,
        });
      },
    },
    {
      domTarget: stageRef, // Use the stageRef as the target
      eventOptions: { passive: false }, // Ensure the event is not passive
    },
  );

  const handleMouseDownVertical = (e: any) => {
    const stage = e.target.getStage();
    const initialMousePos = stage.getPointerPosition().y;
    const initialStageY = stageY;

    const handleMouseMove = () => {
      const currentMousePos = stage.getPointerPosition().y;
      const mouseDelta = currentMousePos - initialMousePos;
      const newStageY = Math.min(
        0,
        Math.max(
          initialStageY - (mouseDelta / dimensions.height) * scrollHeight,
          dimensions.height - scrollHeight,
        ),
      );
      setStageY(newStageY);
      sidebarRef.current?.scrollTo({
        top: -newStageY,
      });
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDownHorizontal = (e: any) => {
    const stage = e.target.getStage();
    const initialMousePos = stage.getPointerPosition().x;
    const initialStageX = stageX;

    const handleMouseMove = () => {
      const currentMousePos = stage.getPointerPosition().x;
      const mouseDelta = currentMousePos - initialMousePos;
      const newStageX = Math.min(
        0,
        initialStageX -
          (mouseDelta / (dimensions.width - SCROLLBAR_SIZE)) * totalPixelWidth,
      );
      setStageX(newStageX);
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const scrollbarHeight = dimensions.height - rowHeight - SCROLLBAR_SIZE;
  const scrollbarYHeight = Math.min(
    scrollbarHeight,
    scrollbarHeight / scrollHeight,
  );

  const visibleTime = dimensions.width / (stageScale * defaultPxPerSec);
  const percentVisible = visibleTime / (project.duration || 1);
  const scrollbarWidth =
    Math.max(0.05, Math.min(1, percentVisible)) *
    (dimensions.width - SCROLLBAR_SIZE);

  // also calculate percentage that stageX is from total possible width
  const totalPixelWidth =
    (project.duration || 1) * defaultPxPerSec * stageScale;
  const percentX = -stageX / totalPixelWidth;

  // Popover math
  const stageDOMElement = stageRef.current?.container();
  const rect = stageDOMElement?.getBoundingClientRect();
  const popoverX =
    popoverLine && rect
      ? popoverLine.start * stageScale * defaultPxPerSec + stageX + rect.left
      : 0;
  const popoverY =
    popoverLine && rect ? popoverLineIndex * rowHeight + stageY + rect.top : 0;

  return (
    <>
      <ContextMenu>
        <ContextMenuTrigger>
          <Stage
            ref={stageRef}
            width={dimensions.width}
            height={dimensions.height}
            x={stageX}
            onContextMenu={(e) => {
              const actionId = e.target.parent?.attrs?.id;
              const line = lineDict[actionId || ''];
              const clip = clipsDict[actionId || ''];

              // Get the x-coordinate of the click relative to the stage
              const pointerPosition = stageRef.current.getPointerPosition();
              const clickX = pointerPosition.x;
              timelineEngine.current.setTime(
                Math.max(0, (clickX - stageX) / (defaultPxPerSec * stageScale)),
              );

              if (!actionId || (!line && !clip)) {
                setContextMenuId(null);
                return;
              }
              setContextMenuId(actionId);
            }}
          >
            <Layer scaleY={1}>
              {caption && (
                <CaptionBar
                  caption={caption}
                  defaultPxPerSec={defaultPxPerSec}
                  stageScale={stageScale}
                  rowHeight={rowHeight}
                />
              )}
              {data.map((row, idx) => {
                const rowTop = (idx + 1) * rowHeight + captionsHeight;
                const rowBottom = rowHeight;
                return (
                  <Group
                    id={row.id}
                    key={row.id}
                    y={rowTop + stageY}
                    height={rowHeight}
                    onDblClick={(e) => {
                      if (row.type !== 'character') return;
                      const stage = e.target.getStage();
                      if (!stage) return;
                      const pointerPosition = stage.getPointerPosition();
                      if (!pointerPosition) return;
                      const time =
                        (pointerPosition.x - stageX) /
                        (stageScale * defaultPxPerSec);
                      handleCreateLine({
                        orig_text: 'Placeholder',
                        character_id: row.id,
                        start: time,
                        end: time + 1,
                        project_id: project.id,
                        project_dub_id: projectDub.id,
                      });
                    }}
                  >
                    <Rect
                      x={-stageX}
                      y={0}
                      width={dimensions.width + -stageX}
                      height={rowHeight}
                      fill="transparent"
                    />
                    <Line
                      points={[
                        0,
                        rowBottom,
                        dimensions.width + -stageX,
                        rowBottom,
                      ]}
                      stroke={getComputedColor('var(--border)')}
                      strokeWidth={1}
                    />
                    {/* Reverse actions so that earlier clips have higher prio than later ones */}
                    {[...row.actions].reverse().map((action) => {
                      // Check if the clip is within the visible area of the stage
                      const actionWidth =
                        (action.end - action.start) *
                        defaultPxPerSec *
                        stageScale;
                      const clipRect = {
                        x: action.start * defaultPxPerSec * stageScale,
                        y: rowTop,
                        width: actionWidth,
                        height: rowHeight,
                      };
                      const isVisible =
                        clipRect.x + stageX <=
                          dimensions.width + OVERSCAN_PADDING &&
                        clipRect.x + clipRect.width + stageX >=
                          0 - OVERSCAN_PADDING;

                      if (!isVisible) return null;

                      if (action.type === 'stem') {
                        return (
                          <StemAction
                            key={action.id}
                            action={action}
                            stageScale={stageScale}
                            defaultPxPerSec={defaultPxPerSec}
                            rowHeight={rowHeight}
                            rowTop={rowTop}
                            lineTopPaddingPx={lineTopPaddingPx}
                            stageX={stageX}
                            stageY={stageY}
                            dimensions={dimensions}
                            enqueueTask={enqueueTask}
                          />
                        );
                      }

                      if (action.type === 'clip') {
                        return (
                          <ClipAction
                            key={action.id}
                            action={action}
                            stageScale={stageScale}
                            defaultPxPerSec={defaultPxPerSec}
                            rowHeight={rowHeight}
                            rowTop={rowTop}
                            lineTopPaddingPx={lineTopPaddingPx}
                            stageX={stageX}
                            stageY={stageY}
                            dimensions={dimensions}
                            enqueueTask={enqueueTask}
                          />
                        );
                      }

                      return (
                        <LineAction
                          key={action.id}
                          action={action}
                          stageScale={stageScale}
                          defaultPxPerSec={defaultPxPerSec}
                          rowHeight={rowHeight}
                          rowTop={rowTop}
                          lineTopPaddingPx={lineTopPaddingPx}
                          stageX={stageX}
                          stageY={stageY}
                          dimensions={dimensions}
                          enqueueTask={enqueueTask}
                        />
                      );
                    })}
                  </Group>
                );
              })}
              <KonvaMarkers
                timelineEngine={timelineEngine}
                stageX={stageX}
                stageScale={stageScale}
                width={dimensions.width}
                defaultPxPerSec={defaultPxPerSec}
                rowHeight={rowHeight}
              />
            </Layer>
            <Layer>
              <Group x={currTime * stageScale * defaultPxPerSec} y={rowHeight}>
                <Line
                  points={[0, 0, 0, dimensions.height]}
                  stroke="#5297FE"
                  strokeWidth={1}
                />
                <Rect
                  x={-8 / 2}
                  y={-12}
                  width={8}
                  height={12}
                  fill="#5297FE"
                  cornerRadius={2}
                />
              </Group>
            </Layer>
            <Layer>
              <Group
                x={-stageX + dimensions.width - SCROLLBAR_SIZE}
                y={rowHeight}
                width={SCROLLBAR_SIZE}
                height={scrollbarHeight}
              >
                <Rect
                  x={0}
                  width={SCROLLBAR_SIZE}
                  height={scrollbarHeight}
                  stroke={getComputedColor('--border')}
                  fill={getComputedColor('--background')}
                />
                <Rect
                  x={0}
                  y={Math.abs(stageY / scrollHeight) * scrollbarHeight}
                  width={SCROLLBAR_SIZE}
                  height={scrollbarYHeight * dimensions.height}
                  fill={getComputedColor('--border')}
                  onMouseDown={handleMouseDownVertical}
                />
              </Group>
              <Group
                x={-stageX}
                y={dimensions.height - SCROLLBAR_SIZE}
                width={dimensions.width - SCROLLBAR_SIZE}
                height={SCROLLBAR_SIZE}
              >
                <Rect
                  width={dimensions.width - SCROLLBAR_SIZE}
                  height={SCROLLBAR_SIZE}
                  stroke={getComputedColor('--border')}
                  fill={getComputedColor('--background')}
                />
                <Rect
                  x={Math.max(
                    0,
                    Math.min(
                      dimensions.width - SCROLLBAR_SIZE - scrollbarWidth,
                      percentX * (dimensions.width - SCROLLBAR_SIZE),
                    ),
                  )}
                  width={scrollbarWidth}
                  height={SCROLLBAR_SIZE}
                  fill={getComputedColor('--border')}
                  onMouseDown={handleMouseDownHorizontal}
                />
              </Group>
            </Layer>
          </Stage>
        </ContextMenuTrigger>
        {contextMenuLine ? (
          <LineContextMenuContent line={contextMenuLine} showSplit={true} />
        ) : contextMenuClip ? (
          <ClipContextMenuContent
            clip={contextMenuClip}
            timelineEngine={timelineEngine}
          />
        ) : (
          <ContextMenuContent>
            <ContextMenuItem
              title="Zoom in"
              icon="zoom_in"
              onSelect={() => handleZoom(stageScale * 1.5)}
            />
            <ContextMenuItem
              title="Zoom out"
              icon="zoom_out"
              onSelect={() => handleZoom(stageScale * 0.5)}
            />
          </ContextMenuContent>
        )}
      </ContextMenu>
      {showPopover && activeLine && (
        <WaveformPopover
          position={{
            x: popoverX,
            y: popoverY,
          }}
          dimensions={{
            width:
              (activeLine.end - activeLine.start) *
              stageScale *
              defaultPxPerSec,
            height: rowHeight,
          }}
          onClose={() => dispatch(setPromptLineId(null))}
        />
      )}
    </>
  );
};
