import { IconPlus } from '@tabler/icons-react';
import { getWorkspaceProjects } from 'lib/redux/selectors/crossSlice';
import { getUserMe } from 'lib/redux/selectors/users';
import { getWorkspacesDict } from 'lib/redux/selectors/workspaces';
import { Button, Label } from 'modules/shared/ui';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CreateProjectModal } from './components/create-project-modal';
import { ProjectTable } from './components/project-table';

const Projects = () => {
  const projects = useSelector(getWorkspaceProjects);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const activeUser = useSelector(getUserMe);
  const activeWorkspace =
    useSelector(getWorkspacesDict)[activeUser?.active_workspace_id || ''];

  if (!activeWorkspace) {
    return (
      <NoWorkspace>
        You do not have access to a workspace. Contact support@deeptune.com for
        support
      </NoWorkspace>
    );
  }

  if (!projects.length) {
    return (
      <>
        <Container>
          <NoProjects>
            <Button onClick={() => setCreateModalOpen(true)}>
              <IconPlus /> Create Project
            </Button>
            <Label>Create a project to get started.</Label>
          </NoProjects>
        </Container>
        <CreateProjectModal
          isOpen={isCreateModalOpen}
          setOpen={setCreateModalOpen}
        />
      </>
    );
  }

  return (
    <Container>
      <Body>
        <ProjectTable projects={projects} />
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
`;

const Body = styled.div`
  padding: 40px 0px;
`;

const NoProjects = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  gap: 8px;
`;

const NoWorkspace = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
  height: calc(100%);
  font-size: 12px;
  background: var(--background);
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export default Projects;
