import { useEffect, useRef } from 'react';

export interface WorkerTask {
  rawData: Float32Array;
  url: string;
  onMessage: (e: MessageEvent<Float32Array[]>) => void;
}

interface WorkerState {
  available: boolean;
  worker: Worker;
}

export const useWorkerPool = (maxSize: number) => {
  const workers = useRef<WorkerState[]>([]);
  const taskQueue = useRef<WorkerTask[]>([]);
  const urlsProcessing = useRef<string[]>([]);

  useEffect(() => {
    // Create and initialize the worker pool
    for (let i = 0; i < maxSize; i++) {
      const worker = new Worker(
        new URL('../lib/mipmap.worker.ts', import.meta.url),
        { type: 'module' },
      );
      workers.current.push({ worker, available: true });
    }

    return () => {
      workers.current.forEach((worker) => worker.worker.terminate());
      workers.current = [];
      urlsProcessing.current = [];
    };
  }, [maxSize]);

  const processTask = () => {
    const nextTask = taskQueue.current.shift(); // Get the next task
    if (!nextTask) return; // If no tasks, do nothing
    urlsProcessing.current.push(nextTask.url);

    const availableWorker = workers.current.find((worker) => worker.available);
    if (!availableWorker) {
      taskQueue.current.unshift(nextTask); // Requeue the task if no workers are available
      urlsProcessing.current = urlsProcessing.current.filter(
        (url) => url !== nextTask.url,
      );
      return;
    }

    availableWorker.available = false;
    availableWorker.worker.onmessage = (event) => {
      nextTask.onMessage(event);
      urlsProcessing.current = urlsProcessing.current.filter(
        (url) => url !== nextTask.url,
      );
      availableWorker.available = true;
      processTask();
    };
    availableWorker.worker.postMessage({ rawData: nextTask.rawData }, [
      nextTask.rawData.buffer,
    ]);
  };

  const enqueueTask = (task: WorkerTask) => {
    // Prevent repeated fetches of the same url
    if (urlsProcessing.current.includes(task.url)) {
      return;
    }

    taskQueue.current.push(task);
    processTask(); // Try to process the task immediately
  };

  return enqueueTask;
};
