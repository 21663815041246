import { TimelineRow } from 'lib/engine/engine';
import { useClipTrackApi } from 'lib/hooks/api/use-clip-track-api';
import { useTranscriptCharacterApi } from 'lib/hooks/api/use-transcript-character-api';
import { getClipTracksDict } from 'lib/redux/selectors/clipTracks';
import { getTranscriptCharactersDict } from 'lib/redux/selectors/transcriptCharacters';
import { ClipTrackContextMenu } from 'modules/shared/context-menu/clip-track-context-menu';
import { TranscriptCharacterContextMenu } from 'modules/shared/context-menu/transcript-character-context-menu';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  DragDropContext,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'modules/shared/sidebar-modal/strict-mode-droppable';
import { calculateNewIndexForCharacter } from 'modules/shared/utils/calculate-new-index-for-character';
import { DraggableComponent } from 'modules/shared/draggable-component';
import { SCROLLBAR_SIZE } from 'modules/editor/constants';
import { TimelineSidebarRow } from './timeline-sidebar-row';

type Props = {
  data: TimelineRow[];
  rowHeight: number;
  setStageY: (y: number) => void;
};

export const TimelineSidebar = forwardRef(
  ({ data, rowHeight, setStageY }: Props, ref) => {
    const transcriptCharactersDict = useSelector(getTranscriptCharactersDict);
    const clipTracksDict = useSelector(getClipTracksDict);
    const { handleUpdateTranscriptCharacter } = useTranscriptCharacterApi();
    const { handleUpdateClipTrack } = useClipTrackApi();

    const onDragEnd = (result: DropResult) => {
      const { destination, source } = result;
      const updateResult = calculateNewIndexForCharacter(
        result,
        data,
        transcriptCharactersDict,
      );

      if (!updateResult || !destination) return;

      const { draggedRow, updatedIndexNumber } = updateResult;

      data.splice(source.index, 1);
      data.splice(destination.index, 0, draggedRow);

      handleUpdateTranscriptCharacter({
        id: draggedRow.id,
        index_number: updatedIndexNumber,
      });
    };

    const renderTimelineRow = (row: TimelineRow, index: number) => {
      if (row.type === 'character') {
        const transcriptCharacter = transcriptCharactersDict[row.id];
        return (
          <DraggableComponent row={row} index={index} key={row.id}>
            <TranscriptCharacterContextMenu
              transcriptCharacter={transcriptCharacter}
              entrypoint="sidebar"
            >
              <TimelineSidebarRow
                row={row}
                rowHeight={rowHeight}
                style={{ color: row.color }}
                handleUpdate={handleUpdateTranscriptCharacter}
              />
            </TranscriptCharacterContextMenu>
          </DraggableComponent>
        );
      }
      if (row.type === 'clipTrack') {
        const clipTrack = clipTracksDict[row.id];
        return (
          <ClipTrackContextMenu clipTrack={clipTrack} key={row.id}>
            <TimelineSidebarRow
              row={row}
              rowHeight={rowHeight}
              style={{ color: row.color }}
              handleUpdate={handleUpdateClipTrack}
            />
          </ClipTrackContextMenu>
        );
      }
      return (
        <TimelineSidebarRow
          row={row}
          rowHeight={rowHeight}
          style={{ color: row.color }}
          key={row.id}
        />
      );
    };
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="timelineRows">
          {(provided: DroppableProvided) => (
            <div
              ref={provided.innerRef}
              style={{
                height: '100%',
              }}
              {...provided.droppableProps}
            >
              <Container
                ref={ref as any}
                style={{
                  overflow: 'overlay',
                  height: '100%',
                  marginTop: rowHeight,
                  paddingBottom: rowHeight + SCROLLBAR_SIZE,
                }}
                onScroll={(e) => {
                  const target = e.target as HTMLDivElement;
                  setStageY(-target.scrollTop);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {data.map((row, index) => renderTimelineRow(row, index))}
              </Container>
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    );
  },
);
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* margin-top: 42px; */
  /* margin-top: 10px; */
  /* flex: 0 1 auto; */
  overflow: overlay;
  padding-left: 10px;

  /* hide scrollbar */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;
