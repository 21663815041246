import { TimelineRow } from 'lib/engine/engine';
import { EditableStringInput } from 'modules/shared/editable-components/editable-string-input';
import React from 'react';
import styled from 'styled-components';

type Props = {
  row: TimelineRow;
  rowHeight: number;
  subtitle?: string;
  handleUpdate?: (data: { id: string; name: string }) => void;
  onClick?: () => void;
  style?: React.CSSProperties;
};

export const TimelineSidebarRow = ({
  row,
  rowHeight,
  subtitle,
  handleUpdate,
  onClick,
  style,
}: Props) => {
  const handleDoneEditing = async (name: string) => {
    if (handleUpdate) handleUpdate({ id: row.id, name });
  };

  return (
    <div
      key={row.id}
      style={{
        minHeight: rowHeight,
        height: rowHeight,
        opacity: row.disabled ? 0.3 : 1,
        transition: 'all 0.1s ease-in-out',
      }}
    >
      <Container onClick={onClick} style={style}>
        <div style={{ width: '100%' }}>
          <EditableStringInput
            id={`transcript-character-row-${row.id}-sidebar`}
            value={row.name}
            handleDoneEditing={handleDoneEditing}
          />
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </div>
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  height: calc(100% - 4px);
  align-items: center;
  justify-content: space-between;
  background-color: var(--border);
  border-radius: 4px;
  padding: 0px 10px;
  color: #fff;

  :hover {
    background-color: var(--hover);
  }
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  margin-left: 8px;
  margin-top: 4px;
`;
