import { DndProvider } from 'modules/shared/dnd-provider';
import React, { useCallback } from 'react';
import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { IconX } from '@tabler/icons-react';
import { Waveform } from '../waveform';

type Props = {
  file?: File | null;
  audioUrl?: string | null;
  onDrop?: (audioUrl: string) => void;
  onDropFile?: (file: File | null) => void;
  isLoading?: boolean;
  error?: string;
  style?: any;
};

export function EditableDnd({
  file,
  audioUrl,
  onDrop,
  onDropFile,
  isLoading,
  error,
  style,
}: Props) {
  const handleFileDrop = useCallback(
    (item: any) => {
      if (isLoading || (!onDrop && !onDropFile)) return;
      if (item) {
        const { files } = item;
        if (!files[0]) return;
        if (onDrop) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            onDrop(e.target.result as string);
          };
          reader.readAsDataURL(files[0]);
        }
        if (onDropFile) onDropFile(files[0]);
      }
    },
    [onDrop, onDropFile],
  );

  const getBody = () => {
    if (isLoading) {
      return (
        <DndContainer>
          <TailSpin width={16} height={16} color="var(--text-light)" />
        </DndContainer>
      );
    }
    if (file) {
      return (
        <DndContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: 20,
            }}
          >
            <div>{file.name}</div>
            <HoverIcon
              onClick={() => {
                if (onDropFile) onDropFile(null);
              }}
            >
              <IconX style={{ width: 12, height: 12 }} />
            </HoverIcon>
          </div>
        </DndContainer>
      );
    }
    if (audioUrl) {
      return <Waveform audioUrl={audioUrl} style={{ width: '100%' }} />;
    }
    if (onDrop || onDropFile) {
      return (
        <DndContainer>
          <span className="material-symbols-rounded">upload</span>
          <div>Click or Drag File</div>
        </DndContainer>
      );
    }

    return <DndContainer>{error || ''}</DndContainer>;
  };

  return (
    <DndProvider
      onDrop={handleFileDrop}
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 120,
        borderRadius: 4,
        border:
          audioUrl || !onDrop
            ? '1px solid var(--border)'
            : '1px dashed var(--border)',
        ...style,
      }}
    >
      {getBody()}
    </DndProvider>
  );
}

const DndContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  font-weight: 400;
  font-size: 12px;
`;
