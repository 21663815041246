import { CaptionType, LineType } from 'lib/Types';

export type OnscreenSegmentType = {
  start: number;
  end: number;
  text: string;
};

const stripPunctuation = (str: string): string => {
  // Define a regular expression to match all punctuation except dashes and apostrophes
  const punctuationRegex = /[^\p{L}\p{N}\s\-']+/gu;

  // Replace consecutive punctuation (except dashes and apostrophes) with a single space
  const resultStr = str.replace(punctuationRegex, (match, offset, string) => {
    // Check if the match is an ellipsis (three or more dots)
    const isEllipsis = /^\.{3,}$/.test(match);

    if (isEllipsis) {
      // Only for ellipses, check if there's a space before or after
      const hasPrecedingSpace = offset > 0 && string[offset - 1] === ' ';
      const hasFollowingSpace =
        offset + match.length < string.length &&
        string[offset + match.length] === ' ';

      // If there's already a space before or after, return an empty string
      // Otherwise, return a single space
      return hasPrecedingSpace || hasFollowingSpace ? '' : ' ';
    }
    // For all other punctuation, simply remove it without adding a space
    return '';
  });

  // Trim any leading or trailing spaces
  return resultStr.trim();
};

export const recalculateTextSegments = (
  width: number,
  lines: LineType[],
  caption: CaptionType,
  paddingSecs: number = 0.5,
) => {
  const offScreenCanvas = document.createElement('canvas');
  const context = offScreenCanvas.getContext('2d');
  if (!context) return [];

  let segments: { start: number; end: number; text: string }[] = [];

  if (caption.strategy === 'word') {
    segments = lines
      .flatMap(
        (line) =>
          line.words?.map((word) => {
            const noPunctuationText = stripPunctuation(word.text);
            const capitalizedText =
              caption.capitalization === 'normal'
                ? noPunctuationText
                : noPunctuationText.toUpperCase();
            return {
              start: word.start + line.start,
              end: word.end + line.start,
              text: capitalizedText,
            };
          }) || [],
      )
      .filter((segment) => !!segment);
  } else {
    context.font = `${caption.font_size}px ${caption.font}`;
    segments = lines.flatMap((line) => {
      const { words } = line;
      if (!words || words.length === 0) return [];

      let currentSegment = {
        start: words[0].start + line.start,
        end: words[0].end + line.start,
        text: '',
      };
      const wordSegments = [];

      words.forEach((word) => {
        const wordStart = word.start + line.start;
        const wordEnd = word.end + line.start;

        if (wordEnd < caption.start_time || wordStart > line.end) {
          return;
        }
        const text = `${currentSegment.text} ${word}`.trim();
        const metrics = context.measureText(text);
        const textWidth = metrics.width;

        const noPunctuationWord = stripPunctuation(word.text);
        const capitalizedWord =
          caption.capitalization === 'normal'
            ? noPunctuationWord
            : noPunctuationWord.toUpperCase();

        if (textWidth > width) {
          wordSegments.push(currentSegment);
          currentSegment = {
            start: wordStart,
            end: wordEnd,
            text: capitalizedWord,
          };
        } else {
          currentSegment = {
            start: currentSegment.start,
            end: wordEnd,
            text: `${currentSegment.text} ${capitalizedWord}`,
          };
        }
      });

      wordSegments.push(currentSegment);
      return wordSegments;
    });
  }

  // Prevent text flickering, adjust end times to match start times of next segments
  const adjustedSegments = segments.map((segment, index, array) => {
    if (index < array.length - 1) {
      const nextSegment = array[index + 1];
      if (nextSegment.start - segment.end <= paddingSecs) {
        return { ...segment, end: nextSegment.start };
      }
    }
    return segment;
  });

  return adjustedSegments;
};

export const parseTextShadow = (shadowString: string) => {
  const rgbaPattern =
    /(\d+px) (\d+px) (\d+px) rgba\((\d+), (\d+), (\d+), ([0-9.]+)\)/;
  const hexPattern = /(\d+px) (\d+px) (\d+px) (#(?:[0-9a-fA-F]{3}){1,2})/;
  let matches = shadowString.match(rgbaPattern);

  if (matches) {
    return {
      offsetX: parseInt(matches[1], 10),
      offsetY: parseInt(matches[2], 10),
      blurRadius: parseInt(matches[3], 10),
      color: `rgba(${matches[4]}, ${matches[5]}, ${matches[6]}, ${matches[7]})`,
    };
  }

  matches = shadowString.match(hexPattern);
  if (matches) {
    return {
      offsetX: parseInt(matches[1], 10),
      offsetY: parseInt(matches[2], 10),
      blurRadius: parseInt(matches[3], 10),
      color: matches[4],
    };
  }

  return null;
};
