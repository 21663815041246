import { createSelector } from '@reduxjs/toolkit';
import { WorkspaceUserType } from 'lib/Types';
import { RootState } from '..';

export const getWorkspaceUsersDict = (state: RootState) => state.workspaceUsers;

export const getWorkspaceUsers = createSelector(
  [getWorkspaceUsersDict],
  (workspaceUsers): WorkspaceUserType[] => Object.values(workspaceUsers),
);
