import styled from 'styled-components';

export const SidebarLabel = styled.div`
  color: var(--text-light);
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 8px;
`;
export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 100%;
  border-right: 1px solid var(--border);
  box-sizing: border-box;
  padding: 12px 8px;
  color: var(--text-secondary);
  font-size: 13px;
  font-weight: 400;
  font-family: Inter;
  background: var(--background-secondary);
  gap: 30px;
`;
