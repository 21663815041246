import { IconCheck } from '@tabler/icons-react';
import { ProjectDubType, ProjectType } from 'lib/Types';
import api from 'lib/api';
import { getCaptionByProjectDubId } from 'lib/redux/selectors/captions';
import { getProjectDubLines } from 'lib/redux/selectors/lines';
import { upsertExportJobs } from 'lib/redux/slices/exportJobs';
import { useKonvaExport } from 'modules/editor/hooks/use-konva-export';
import {
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { Button, FlexCol, FlexRow, Input, Label } from 'modules/shared/ui';
import { Dispatch, SetStateAction, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
  setTab: (tab: 'export' | 'history' | 'publish') => void;
  setCaptionsExportProgress: Dispatch<SetStateAction<number | null>>;
};

const ExportVideoFormats = {
  mp4: 'MP4',
};

export const ExportVideo = ({
  project,
  projectDub,
  setTab,
  setCaptionsExportProgress,
}: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [format, setFormat] = useState<'mp4'>('mp4');
  const [trimStart, setTrimStart] = useState<number | null>(null);
  const [trimEnd, setTrimEnd] = useState<number | null>(null);
  const caption = useSelector(getCaptionByProjectDubId(projectDub.id));
  const lines = useSelector(getProjectDubLines(projectDub.id));
  const { captureCanvas } = useKonvaExport({
    project,
  });

  const handleExport = async () => {
    if (!project?.id) {
      alert('No project id found');
      return;
    }
    if (isLoading) return;

    try {
      setLoading(true);

      let canvasRecordingBlob: Blob | undefined;
      if (caption) {
        setCaptionsExportProgress(0);
        const duration = project.duration ?? caption.end_time;
        // Start progress bar
        const interval = setInterval(() => {
          setCaptionsExportProgress((prev) => {
            if (prev === null) {
              clearInterval(interval);
              return null;
            }
            const newProgress = prev + 95 / duration;
            if (newProgress >= 95) {
              // Progress bar increases to 95% and  hangs until recording is complete
              clearInterval(interval);
              return 95;
            }
            return newProgress;
          });
        }, 1000);
        canvasRecordingBlob = await captureCanvas(lines, caption);

        // Clear interval
        clearInterval(interval);
      }

      const { data } = await api.projectDubs.exportVideo(projectDub.id, {
        format,
        trim_start: trimStart || undefined,
        trim_end: trimEnd || undefined,
        canvas_recording: canvasRecordingBlob,
      });
      dispatch(upsertExportJobs([data.export_job]));
      setTab('history');
      setCaptionsExportProgress(null);
    } catch (e) {
      alert(`Error exporting ${format}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FlexCol style={{ gap: 16 }}>
      <FlexRow style={{ gap: 16, alignContent: 'center' }}>
        <FlexCol>
          <Label>Format</Label>
          <SelectRoot
            value={format}
            onValueChange={(e: any) => {
              setFormat(e);
            }}
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectViewport>
                {Object.entries(ExportVideoFormats).map(([k, v]) => (
                  <SelectItem value={k} key={k}>
                    <SelectItemText>{v}</SelectItemText>
                    <SelectItemIndicator>
                      <IconCheck />
                    </SelectItemIndicator>
                  </SelectItem>
                ))}
              </SelectViewport>
            </SelectContent>
          </SelectRoot>
        </FlexCol>
      </FlexRow>
      <FlexRow style={{ gap: 16 }}>
        <FlexCol
          style={{
            opacity: trimStart ? 1 : 0.4,
            transition: 'all 0.2s ease',
          }}
        >
          <Label>Trim Start Seconds (optional)</Label>
          <Input
            value={trimStart || ''}
            onChange={(e) => setTrimStart(Number(e.target.value))}
            type="number"
            step="any"
          />
        </FlexCol>
        <FlexCol
          style={{
            opacity: trimEnd ? 1 : 0.4,
            transition: 'all 0.2s ease',
          }}
        >
          <Label>Trim End Seconds (optional)</Label>
          <Input
            value={trimEnd || ''}
            onChange={(e) => setTrimEnd(Number(e.target.value))}
            type="number"
            step="any"
          />
        </FlexCol>
      </FlexRow>
      <FlexCol style={{ gap: '4px' }}>
        <Button onClick={handleExport}>
          {isLoading ? (
            <TailSpin width={13} height={13} color="white" />
          ) : (
            <div>Export</div>
          )}
        </Button>
      </FlexCol>
    </FlexCol>
  );
};
