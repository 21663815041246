import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import React from 'react';
import { CharacterType } from 'lib/Types';
import { useCharacterApi } from 'lib/hooks/api/use-character-api';
import { setCharacterModalId } from 'lib/redux/slices/editor';
import { useDispatch, useSelector } from 'react-redux';
import { renameEditableInput } from 'lib/utils/utils';
import { getUserMe } from 'lib/redux/selectors/users';

type Props = {
  character: CharacterType;
  children?: React.ReactNode;
};

export const CharacterTrackContextMenu = ({ character, children }: Props) => {
  const user = useSelector(getUserMe);
  const dispatch = useDispatch();
  const { handleDeleteCharacter } = useCharacterApi();

  return (
    <ContextMenu>
      <ContextMenuTrigger>{children}</ContextMenuTrigger>
      <ContextMenuContent onClick={(e) => e.stopPropagation()}>
        <ContextMenuItem
          title="Rename"
          icon="edit"
          onSelect={() => {
            renameEditableInput(`#timeline-row-${character.id}`);
          }}
        />
        <ContextMenuItem
          title="Voice Settings"
          icon="tune"
          onSelect={() => dispatch(setCharacterModalId(character.id))}
        />
        <ContextMenuItem
          title="Delete"
          icon="delete"
          onSelect={() => handleDeleteCharacter(character)}
        />
        {user?.role === 'ADMIN' ? (
          <>
            <ContextMenuLabel>Admin</ContextMenuLabel>
            <ContextMenuItem
              title="Copy UUID"
              icon="content_copy"
              onSelect={() => {
                navigator.clipboard.writeText(character.id);
              }}
            />
          </>
        ) : null}
      </ContextMenuContent>
    </ContextMenu>
  );
};
