import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import React from 'react';
import { VoiceType } from 'lib/Types';
import { useVoiceApi } from 'lib/hooks/api/use-voice-api';

type Props = {
  voice: VoiceType;
  children?: React.ReactNode;
};

export const VoiceContextMenu = ({ voice, children }: Props) => {
  const { handleDeleteVoice } = useVoiceApi();

  return (
    <ContextMenu>
      <ContextMenuTrigger>{children}</ContextMenuTrigger>
      <ContextMenuContent onClick={(e) => e.stopPropagation()}>
        <ContextMenuItem
          title="Delete"
          icon="delete"
          onSelect={() => handleDeleteVoice(voice)}
        />
      </ContextMenuContent>
    </ContextMenu>
  );
};
