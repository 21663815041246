import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectType } from 'lib/Types';

export type ProjectsReduxType = {
  projects: { [id: string]: ProjectType };
};
const initialState = {
  projects: {},
} as ProjectsReduxType;

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    deleteProject: (state, action: PayloadAction<string>) => {
      delete state.projects[action.payload];
      return state;
    },
    upsertProjects: (
      state: ProjectsReduxType,
      action: PayloadAction<({ id: string } & Partial<ProjectType>)[]>,
    ) => {
      action.payload.forEach((project) => {
        state.projects[project.id] = {
          ...(state.projects[project.id] || {}),
          ...project,
        };
      });
      return state;
    },
    resetProjects: () => initialState,
  },
});

export const { deleteProject, upsertProjects, resetProjects } =
  projectsSlice.actions;
