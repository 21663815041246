import { useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
  id: string;
  value: number; // Decimal
  handleDoneEditing: (newValue: number) => void;
  positiveOnly?: boolean;
};

export const EditablePercentageInput = ({
  id,
  value,
  handleDoneEditing,
  positiveOnly = true,
}: Props) => {
  const [isDisabled, setDisabled] = useState(true);
  // Assumes value is a decimal
  const [currValue, setCurrValue] = useState<string>((value * 100).toFixed(2));

  useEffect(() => {
    setCurrValue((value * 100).toFixed(2));
  }, [value]);

  // Listen for changes to the disabled attribute from jquery
  // TODO this might be better handled by tracking disabled state in redux
  useEffect(() => {
    const targetNode = document.getElementById(id);
    if (!targetNode) return; // Ensure targetNode is not null
    const config: MutationObserverInit = {
      attributes: true,
      childList: false,
      subtree: false,
    };
    const callback: MutationCallback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'disabled'
        ) {
          setDisabled((mutation.target as HTMLButtonElement).disabled);
        }
      });
    };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleEdit = () => {
    setDisabled(true);

    // If empty string is submitted, default to current value
    if (currValue.trim() === '') {
      setCurrValue((value * 100).toFixed(2));
      return;
    }

    setCurrValue((v) => parseFloat(v).toFixed(2));
    handleDoneEditing(parseFloat(currValue) / 100);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') (document.activeElement as any)?.blur();
  };

  // Require the input be a number
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val === '') {
      // User may have an empty string as a temp value, but may not submit it
      setCurrValue(val);
      return;
    }

    const numberValue = parseFloat(val);
    if (!Number.isNaN(numberValue) && (!positiveOnly || numberValue >= 0)) {
      setCurrValue(val);
    }
  };

  return (
    <InputWrapper>
      <InputRow
        id={id}
        value={currValue}
        onChange={handleChange}
        onBlur={handleEdit}
        onKeyDown={handleKeyPress}
        disabled={isDisabled}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
        autoComplete="off"
      />
      <PercentSign>%</PercentSign>
    </InputWrapper>
  );
};

const InputRow = styled.input`
  all: unset;
  overflow: hidden;
  /* flex: 2 0 auto; */
  width: 36px;
  color: var(--text-tertiary);
  font-size: 11px;
  cursor: text;
  text-align: right;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px;
  border-radius: 2px;
  cursor: pointer;

  :hover {
    background: var(--hover);
  }
`;

const PercentSign = styled.span`
  color: var(--text-tertiary);
  font-size: 11px;
`;
