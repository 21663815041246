export enum ExportModeEnum {
  VIDEO = 'video',
  AUDIO = 'audio',
  TIMELINE = 'timeline',
  TRANSCRIPT = 'transcript',
  SUBTITLES = 'subtitles',
  ASSETS = 'assets',
}

export const handleDownloadAssets = async (url?: string) => {
  if (!url) throw new Error('No asset zip found');
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(
      `There was an error fetching the file: ${response.statusText}`,
    );
  }
  const blob = await response.blob();
  const blobUrl = window.URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = blobUrl;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  window.URL.revokeObjectURL(blobUrl);
  document.body.removeChild(downloadLink);
};
