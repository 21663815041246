import React from 'react';
import {
  SelectRoot,
  SelectContent,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from 'modules/radix/Select';
import { WorkspaceUserType } from 'lib/Types';
import { IconCheck } from '@tabler/icons-react';

type Props = {
  workspaceUser: WorkspaceUserType;
};

export const WorkspaceUserRoleSelect = ({ workspaceUser }: Props) => (
  <SelectRoot value={workspaceUser.role}>
    <SelectTrigger disabled>
      <SelectValue />
    </SelectTrigger>
    <SelectContent>
      <SelectViewport>
        <SelectItem value="OWNER">
          <SelectItemText>Owner</SelectItemText>
          <SelectItemIndicator>
            <IconCheck />
          </SelectItemIndicator>
        </SelectItem>
        <SelectItem value="MEMBER">
          <SelectItemText>Member</SelectItemText>
          <SelectItemIndicator>
            <IconCheck />
          </SelectItemIndicator>
        </SelectItem>
        <SelectItem value="REMOVE" style={{ color: 'var(--red)' }}>
          Remove
        </SelectItem>
      </SelectViewport>
    </SelectContent>
  </SelectRoot>
);
