import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TranscriptCharacterType } from 'lib/Types';

export type TranscriptCharactersReduxType = {
  [id: string]: TranscriptCharacterType;
};
const initialState = {} as TranscriptCharactersReduxType;

export const transcriptCharactersSlice = createSlice({
  name: 'transcriptCharacters',
  initialState,
  reducers: {
    upsertTranscriptCharacters: (
      state: TranscriptCharactersReduxType,
      action: PayloadAction<
        ({ id: string } & Partial<TranscriptCharacterType>)[]
      >,
    ) => {
      action.payload.forEach((transcriptCharacter) => {
        state[transcriptCharacter.id] = {
          ...(state[transcriptCharacter.id] || {}),
          ...transcriptCharacter,
        };
      });
      return state;
    },
    deleteTranscriptCharacter: (
      state: TranscriptCharactersReduxType,
      action: PayloadAction<string>,
    ) => {
      delete state[action.payload];
      return state;
    },
    deleteProjectTranscriptCharacters: (
      state: TranscriptCharactersReduxType,
      action: PayloadAction<string>,
    ) => {
      Object.keys(state).forEach((transcriptCharacterId) => {
        if (state[transcriptCharacterId].project_id === action.payload) {
          delete state[transcriptCharacterId];
        }
      });
      return state;
    },
    resetTranscriptCharacters: () => initialState,
  },
});

export const {
  upsertTranscriptCharacters,
  deleteTranscriptCharacter,
  deleteProjectTranscriptCharacters,
  resetTranscriptCharacters,
} = transcriptCharactersSlice.actions;
