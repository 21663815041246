import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LineType } from 'lib/Types';

export type LinesReduxType = { [id: string]: LineType };
const initialState = {} as LinesReduxType;

export const linesSlice = createSlice({
  name: 'lines',
  initialState,
  reducers: {
    upsertLines: (
      state: LinesReduxType,
      action: PayloadAction<({ id: string } & Partial<LineType>)[]>,
    ) => {
      action.payload.forEach((line) => {
        const beforeLoading = state[line.id]?.is_loading;
        state[line.id] = { ...(state[line.id] || {}), ...line };
        const afterLoading = state[line.id]?.is_loading;
        // If is_loading has changed, update uiLoading to mimic it
        // We want sever loading to overwrite local loading
        // TODO this is kind of sketchy, we should do this better
        if (afterLoading != null && beforeLoading !== afterLoading) {
          state[line.id].uiLoading = afterLoading;
        }
      });
      return state;
    },
    deleteLine: (state: LinesReduxType, action: PayloadAction<string>) => {
      delete state[action.payload];
      return state;
    },
    deleteProjectDubLines: (
      state: LinesReduxType,
      action: PayloadAction<string>,
    ) => {
      Object.keys(state).forEach((lineId) => {
        if (state[lineId].project_dub_id === action.payload) {
          delete state[lineId];
        }
      });
      return state;
    },
    resetLines: () => initialState,
  },
});

export const { upsertLines, deleteLine, deleteProjectDubLines, resetLines } =
  linesSlice.actions;
