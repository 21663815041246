import { WorkspaceType, WorkspaceUserRole } from 'lib/Types';
import { getUsersDict } from 'lib/redux/selectors/users';
import { isValidEmail } from 'lib/utils/utils';
import { useState } from 'react';
import Avatar from 'react-avatar';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FlexRow, Input, Button } from 'modules/shared/ui';
import { getWorkspaceUsers } from 'lib/redux/selectors/workspaceUsers';
import { upsertWorkspaceUsers } from 'lib/redux/slices/workspaceUsers';
import api from 'lib/api';
import { SettingsRow } from '../../../sidebar-modal/settings-row';
import { Title } from '../ui';
import { WorkspaceUserRoleSelect } from './components/workspace-user-role-select';

type Props = {
  workspace: WorkspaceType;
};

export const WorkspaceMembers = ({ workspace }: Props) => {
  const dispatch = useDispatch();
  const workspaceUsers = useSelector(getWorkspaceUsers).filter(
    (wu) => wu.workspace_id === workspace?.id,
  );
  const usersDict = useSelector(getUsersDict);
  const roleValues: WorkspaceUserRole[] = [
    WorkspaceUserRole.OWNER,
    WorkspaceUserRole.MEMBER,
  ];
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleAddUser = async () => {
    if (!workspace) return;
    try {
      setLoading(true);
      const { data } = await api.workspaces.addUser(workspace.id, {
        email,
        role: WorkspaceUserRole.MEMBER,
      });
      dispatch(upsertWorkspaceUsers([data.workspace_user]));
      setEmail('');
    } catch (err) {
      alert('Error adding user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title>Members</Title>
      <FlexRow
        style={{ justifyContent: 'space-between', margin: '20px 0px', gap: 16 }}
      >
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <Button
          onClick={handleAddUser}
          style={{ width: 180 }}
          disabled={!isValidEmail(email)}
        >
          {isLoading ? (
            <TailSpin width={13} height={13} color="white" />
          ) : (
            'Invite user'
          )}
        </Button>
      </FlexRow>
      <RowContainer>
        {workspaceUsers
          .sort((a, b) => {
            // Compare enum values
            const aEnumIndex = roleValues.indexOf(a.role);
            const bEnumIndex = roleValues.indexOf(b.role);
            if (aEnumIndex !== bEnumIndex) {
              return aEnumIndex - bEnumIndex;
            }

            // Enum values are equal, compare alphabetically
            return usersDict[a.user_id].name.localeCompare(
              usersDict[b.user_id].name,
            );
          })
          .map((user) => (
            <SettingsRow
              title={usersDict[user.user_id].name}
              subtitle={usersDict[user.user_id].email}
              icon={
                <Avatar
                  name={usersDict[user.user_id].name || ''}
                  round
                  size="28"
                />
              }
              item={<WorkspaceUserRoleSelect workspaceUser={user} />}
            />
          ))}
      </RowContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  height: 90%;
  padding: 12px 0;
  overflow: auto;
`;
