import { FlexRow, Title } from 'modules/shared/ui';
import React from 'react';
import { ProjectDubType } from 'lib/Types';
import styled from 'styled-components';
import { useCharacterApi } from 'lib/hooks/api/use-character-api';
import { useSelector } from 'react-redux';
import { getProjectDubCharacters } from 'lib/redux/selectors/characters';
import { RootState } from 'lib/redux';
import { CharacterRow } from './components/character-row';

type Props = {
  projectDub: ProjectDubType;
};

export const CharacterPicker = ({ projectDub }: Props) => {
  const { handleCreateCharacter } = useCharacterApi();
  const characters = useSelector((state: RootState) =>
    getProjectDubCharacters(projectDub.id)(state),
  );

  return (
    <Container>
      <FlexRow
        style={{
          justifyContent: 'space-between',
          padding: '16px 32px',
          borderBottom: '1px solid var(--border)',
        }}
      >
        <Title>Speakers</Title>
        <Button
          onClick={() =>
            handleCreateCharacter({ project_dub_id: projectDub.id })
          }
        >
          Add Speaker
        </Button>
      </FlexRow>
      <div
        style={{
          overflow: 'auto',
          padding: '16px 0px',
        }}
      >
        {characters.map((c) => (
          <CharacterRow key={c.id} character={c} />
        ))}
      </div>
    </Container>
  );
};

const Button = styled.button`
  cursor: pointer;
  all: unset;
  border: 1px solid var(--hover);
  padding: 0px 12px;
  height: 32px;
  font-size: 12px;
  border-radius: 6px;

  :disabled {
    opacity: 0.4;
  }
  :hover {
    background-color: var(--hover);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
