import { CreateProjectModal } from 'modules/projects/components/create-project-modal';
import React, { useState } from 'react';
import styled from 'styled-components';

export const CreateProjectButton = () => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  return (
    <Container onClick={() => setCreateModalOpen(true)}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          className="material-symbols-rounded"
          style={{ fontSize: 18, margin: '0px 8px' }}
        >
          add
        </span>
        <span>Create New Project</span>
      </div>
      <CreateProjectModal
        isOpen={isCreateModalOpen}
        setOpen={setCreateModalOpen}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0px;
  border-radius: 4px;
  background: var(--background);
  color: var(--text-primary);
  border: 1px solid var(--border);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  cursor: pointer;

  :hover {
    background: var(--hover);
  }
`;
