import styled from 'styled-components';

export const Icon = styled.span`
  /* color: var(--text-primary); */
  text-align: center;
`;

export const HoverIcon = styled(Icon)`
  /* transition: all 0.2s ease-in-out; */
  border-radius: 4px;
  user-select: none;
  color: var(--text-primary);
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--hover);
  }
  &:active {
    background: var(--hover);
  }
`;
