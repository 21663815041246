import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from '..';

export const getTranscriptLinesDict = (state: RootState) =>
  state.transcriptLines;
export const getTranscriptLines = createSelector(
  getTranscriptLinesDict,
  (lines) => Object.values(lines),
);
export const getProjectTranscriptLines = memoize((projectId: string) =>
  createSelector(getTranscriptLines, (lines) =>
    lines
      .filter((l) => l.project_id === projectId)
      .sort((a, b) => a.start - b.start),
  ),
);
