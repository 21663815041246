import React, { useState } from 'react';
import styled from 'styled-components';
import api from 'lib/api';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import { upsertProjects } from 'lib/redux/slices/projects';
import { LanguageNames, ProjectType } from 'lib/Types';
import { getProjectDubs } from 'lib/redux/selectors/projectDubs';
import { Button, FlexRow, Input, Label } from 'modules/shared/ui';
import { DateTime } from 'luxon';

type Props = {
  project: ProjectType;
};

export const PublishTab = ({ project }: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [trimStart, setTrimStart] = useState<number | null>(null);
  const [trimEnd, setTrimEnd] = useState<number | null>(null);
  const projectDubs = useSelector(getProjectDubs).filter(
    (vd) => vd.project_id === project.id,
  );
  const [projectDubIds, setProjectDubIds] = useState<string[]>([]);

  const handlePublish = async () => {
    try {
      setLoading(true);
      const { data } = await api.projects.publish(project.id, {
        project_dub_ids: projectDubIds,
        trim_start: trimStart || undefined,
        trim_end: trimEnd || undefined,
      });
      dispatch(upsertProjects([data.project]));
    } catch (e) {
      alert(`error ${e}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {projectDubs
        .sort((a, b) => a.lang.localeCompare(b.lang))
        .map((vd) => (
          <DubRow key={vd.id}>
            <FlexRow style={{ gap: 4 }}>
              <input
                type="checkbox"
                checked={projectDubIds.includes(vd.id)}
                onChange={() =>
                  setProjectDubIds(
                    projectDubIds.includes(vd.id)
                      ? projectDubIds.filter((id) => id !== vd.id)
                      : [...projectDubIds, vd.id],
                  )
                }
              />
              {LanguageNames[vd.lang]}
            </FlexRow>
            {DateTime.fromISO(vd.updatedAt, {
              zone: 'utc',
            }).toRelative()}
          </DubRow>
        ))}
      <FlexRow style={{ gap: 16 }}>
        <Row
          style={{
            opacity: trimStart ? 1 : 0.3,
            transition: 'all 0.2s ease',
          }}
        >
          <Label>Trim Start Seconds (optional)</Label>
          <Input
            value={trimStart || ''}
            onChange={(e) => setTrimStart(Number(e.target.value))}
            type="number"
            step="any"
          />
        </Row>
        <Row
          style={{
            opacity: trimEnd ? 1 : 0.3,
            transition: 'all 0.2s ease',
          }}
        >
          <Label>Trim End Seconds (optional)</Label>
          <Input
            value={trimEnd || ''}
            onChange={(e) => setTrimEnd(Number(e.target.value))}
            type="number"
            step="any"
          />
        </Row>
      </FlexRow>
      <Button onClick={handlePublish}>
        {isLoading ? (
          <TailSpin width={13} height={13} color="white" />
        ) : (
          <div>Publish</div>
        )}
      </Button>
      {project?.demo_id ? (
        <div>
          Published: {`https://site.deeptune.ai/demos/${project.demo_id}`}
        </div>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const DubRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: var(--text-secondary);
  justify-content: space-between;
`;

const Row = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
