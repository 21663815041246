import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { TimelineEngine, TimelineRow } from 'lib/engine/engine';
import { ProjectDubType } from 'lib/Types';
import {
  getDisabledRowIds,
  getSoloRowIds,
  getOriginalMuted,
  getMFXMuted,
} from 'lib/redux/selectors/editor';
import { EditableStringInput } from 'modules/shared/editable-components/editable-string-input';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from 'lib/redux';
import { useTimelineActions } from '../../../hooks/use-timeline-actions';

type Props = {
  projectDub: ProjectDubType;
  row: TimelineRow;
  rowHeight: number;
  timelineEngine: React.MutableRefObject<TimelineEngine>;
  subtitle?: string;
  handleUpdate?: (data: { id: string; name: string }) => void;
  onClick?: () => void;
  style?: React.CSSProperties;
  items?: React.ReactNode;
};

export const TimelineSidebarRow = ({
  projectDub,
  row,
  rowHeight,
  timelineEngine,
  subtitle,
  handleUpdate,
  onClick,
  style,
  items,
}: Props) => {
  const { handleDisable, handleSolo } = useTimelineActions({
    timelineEngine,
    row,
  });
  const isOriginalMuted = useSelector(getOriginalMuted);
  const isMFXMuted = useSelector(getMFXMuted);

  const disabledRowIds = useSelector((state: RootState) =>
    getDisabledRowIds(projectDub.id)(state),
  );
  const soloRowIds = useSelector(getSoloRowIds);
  const isSolo = soloRowIds[row.id];

  const handleDoneEditing = async (name: string) => {
    if (handleUpdate) handleUpdate({ id: row.id, name });
  };

  return (
    <div
      key={row.id}
      style={{
        minHeight: rowHeight,
        height: rowHeight,
        opacity: row.disabled ? 0.3 : 1,
        transition: 'all 0.1s ease-in-out',
      }}
    >
      <Container onClick={onClick} style={style}>
        <div style={{ width: '100%' }}>
          <EditableStringInput
            id={`timeline-row-${row.id}`}
            value={row.name}
            handleDoneEditing={handleDoneEditing}
          />
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </div>
        <Flex>
          {items}
          <ActionButton onClick={handleSolo} isActive={isSolo}>
            <div>S</div>
          </ActionButton>
          <ActionButton onClick={handleDisable}>
            {row.id === 'original' ? (
              isOriginalMuted ? (
                <IconEyeOff />
              ) : (
                <IconEye />
              )
            ) : row.id === 'mfx' ? (
              isMFXMuted ? (
                <IconEyeOff />
              ) : (
                <IconEye />
              )
            ) : disabledRowIds[row.id] ? (
              <IconEyeOff />
            ) : (
              <IconEye />
            )}
          </ActionButton>
        </Flex>
      </Container>
    </div>
  );
};

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  height: calc(100% - 4px);
  align-items: center;
  justify-content: space-between;
  background-color: var(--border);
  border-radius: 4px;
  padding: 0px 10px;
  color: #fff;

  :hover {
    background-color: var(--hover);
  }
`;

const ActionButton = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default !important;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  background: ${({ isActive }) => (isActive ? '#fff' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#000' : 'var(--text-primary)')};
  :hover {
    background: ${({ isActive }) => (isActive ? '#fff' : 'var(--hover)')};
  }
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  margin-left: 8px;
  margin-top: 4px;
`;
