import axios from './axios';

export const VoiceData = {
  getVoiceDataById: (id: string) => axios.get(`/voice_data/${id}`),
  testVoiceData: (id: string, data: { text: string }) =>
    axios.post(`/voice_data/${id}/test`, data, { responseType: 'arraybuffer' }),
  updateElVoiceSettings: (
    id: string,
    data: { stability: number; similarity_boost: number; style: number },
  ) => axios.put(`/voice_data/${id}/el`, data),
};
