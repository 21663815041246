import { AxiosProgressEvent } from 'axios';
import { ClipType } from 'lib/Types';
import api from 'lib/api';
import { upsertClipTracks } from 'lib/redux/slices/clipTracks';
import { deleteClip, upsertClips } from 'lib/redux/slices/clips';
import { useDispatch } from 'react-redux';

export const useClipApi = () => {
  const dispatch = useDispatch();

  const handleCreateClip = async ({
    file,
    project_id,
    project_dub_id,
    onUploadProgress,
  }: {
    file: File;
    project_id: string;
    project_dub_id: string;
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  }) => {
    try {
      const { data } = await api.clips.create({
        project_id,
        project_dub_id,
        file,
        onUploadProgress,
      });
      dispatch(upsertClips([data.clip]));
      dispatch(upsertClipTracks([data.clip_track]));
    } catch {
      alert('err');
    }
  };

  const handleSplitClip = async (clipId: string, splitTimelineTime: number) => {
    try {
      dispatch(upsertClips([{ id: clipId, isLoading: true }]));
      const { data } = await api.clips.split(clipId, splitTimelineTime);
      dispatch(upsertClips(data.clips));
    } catch (error) {
      alert('Error splitting clip');
    } finally {
      dispatch(upsertClips([{ id: clipId, isLoading: false }]));
    }
  };

  const handleUpdateClip = async (clip: { id: string } & Partial<ClipType>) => {
    try {
      dispatch(upsertClips([{ ...clip, isLoading: true }]));
      const { data } = await api.clips.update(clip.id, clip);
      dispatch(upsertClips([{ ...data.clip, isLoading: false }]));
    } catch (error) {
      dispatch(upsertClips([{ id: clip.id, isLoading: false }]));
      alert('Error saving clip');
    }
  };

  const handleDeleteClip = async (clipId: string) => {
    try {
      dispatch(upsertClips([{ id: clipId, isLoading: true }]));
      await api.clips.delete(clipId);
      dispatch(deleteClip(clipId));
    } catch (error) {
      dispatch(upsertClips([{ id: clipId, isLoading: false }]));
      alert('Error deleting clip');
    }
  };

  return {
    handleCreateClip,
    handleSplitClip,
    handleUpdateClip,
    handleDeleteClip,
  };
};
