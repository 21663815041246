import { WorkspaceUserRole } from 'lib/Types';
import axios from './axios';

export const Workspaces = {
  get: () => axios.get('/workspaces'),
  addUser: (
    workspaceId: string,
    data: { email: string; role: WorkspaceUserRole },
  ) => axios.post(`/workspaces/${workspaceId}/users`, data),
};
