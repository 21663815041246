import { IconVolume } from '@tabler/icons-react';
import { Howl } from 'howler';
import { VoiceType } from 'lib/Types';
import { HoverIcon } from 'modules/radix/HoverIcon';
import { VoiceContextMenu } from 'modules/shared/context-menu/voice-context-menu';
import { Tag } from 'modules/shared/ui';
import styled from 'styled-components';

type Props = {
  voice: VoiceType;
};

export const VoiceRow = ({ voice }: Props) => {
  const onClick = () => {
    if (!voice.preview_url) return;
    const sound = new Howl({
      src: [voice.preview_url],
    });
    sound.play();
  };

  return (
    <VoiceContextMenu voice={voice}>
      <Container onClick={onClick}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FlexRow>
            <Title>{voice.name}</Title>
            {voice.preview_url && (
              <HoverIcon style={{ marginLeft: 4 }}>
                <IconVolume style={{ width: 13, height: 13 }} />
              </HoverIcon>
            )}
          </FlexRow>
          {voice.description ? <Subtitle>{voice.description}</Subtitle> : null}
          {voice.tags?.length ? (
            <FlexRow style={{ gap: 4, marginTop: 4, flexWrap: 'wrap' }}>
              {(voice.tags || []).map((tag) => (
                <Tag key={tag} style={{ padding: '2px 4px' }}>
                  {tag}
                </Tag>
              ))}
            </FlexRow>
          ) : null}
        </div>
      </Container>
    </VoiceContextMenu>
  );
};

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  font-size: 13px;
  color: var(--text-primary);
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: var(--text-secondary);
`;

// const Image = styled.img`
//   width: 40px;
//   height: 40px;
//   border-radius: 4px;
//   object-fit: cover;
// `;

const Container = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px;
  background: ${({ isActive }) =>
    isActive ? 'var(--background-secondary)' : 'transparent'};
  border: ${({ isActive }) =>
    isActive ? '1px solid var(--text-light)' : '1px solid var(--border)'};
  border-radius: 4px;
  font-size: 12px;
  justify-content: space-between;
  gap: 8px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background: var(--hover);
  }
`;
