import { UserType } from 'lib/Types';
import api from 'lib/api';
import { upsertUser, upsertUsers } from 'lib/redux/slices/user';
import { upsertWorkspaceUsers } from 'lib/redux/slices/workspaceUsers';
import { upsertWorkspaces } from 'lib/redux/slices/workspaces';
import { useDispatch } from 'react-redux';

export const useUserApi = () => {
  const dispatch = useDispatch();

  const handleCreateUser = async (d: {
    email: string;
    name: string;
    password: string;
    active_workspace_id?: string;
  }) => {
    try {
      const { data } = await api.users.create(d);
      dispatch(upsertUsers([data.user]));
      dispatch(upsertWorkspaces([data.workspace]));
      dispatch(upsertWorkspaceUsers([data.workspace_user]));
    } catch (error) {
      alert('Failed to create user');
    }
  };

  const handleUpdateUser = async (user: Partial<UserType> & { id: string }) => {
    try {
      const { data } = await api.users.update(user.id, user);
      dispatch(upsertUser(data.user));
    } catch (error) {
      alert('Failed to update user');
    }
  };

  return { handleCreateUser, handleUpdateUser };
};
