import React from 'react';
import {
  AnimatePresence,
  AnimationControls,
  motion,
  useAnimation,
} from 'framer-motion';
import { IconCheck } from '@tabler/icons-react';
import styled from 'styled-components';
import { grayScale, mainColors } from 'lib/colors';

type ThemeVariant = 'light' | 'dark' | 'system';

export type ColorSchemeSegmentProps = {
  variant: ThemeVariant;
  controls: AnimationControls;
} & React.ComponentPropsWithoutRef<'div'>;

const ColorSchemeSegment = ({
  variant,
  controls,
  style,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: any) => (
  <StyledColorSchemeBackground
    {...{ $variant: variant, style, onClick, onMouseEnter, onMouseLeave }}
  >
    <StyledColorSchemeContent animate={controls} $variant={variant}>
      Aa
    </StyledColorSchemeContent>
  </StyledColorSchemeBackground>
);

const StyledContainer = styled.div`
  position: relative;
`;

const StyledMixedColorSchemeSegment = styled.div`
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  display: flex;
  height: 80px;
  overflow: hidden;
  width: 120px;
`;

const StyledCheckmarkContainer = styled(motion.div)`
  bottom: 0px;
  padding: 8px;
  position: absolute;
  right: 0px;
`;

export type ThemeCardProps = {
  variant: ThemeVariant;
  isActive?: boolean;
  onClick?: () => void;
};

const checkmarkAnimationVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const ThemeCard = ({ variant, isActive, onClick }: ThemeCardProps) => {
  const controls = useAnimation();

  const handleMouseEnter = () => {
    controls.start({
      height: 61,
      fontSize: '22px',
      transition: { duration: 0.1 },
    });
  };

  const handleMouseLeave = () => {
    controls.start({
      height: 56,
      fontSize: '20px',
      transition: { duration: 0.1 },
    });
  };

  if (variant === 'system') {
    return (
      <StyledContainer>
        <StyledMixedColorSchemeSegment
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={onClick}
        >
          <ColorSchemeSegment
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            controls={controls}
            variant="light"
          />
          <ColorSchemeSegment
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            controls={controls}
            variant="dark"
          />
        </StyledMixedColorSchemeSegment>
        <AnimatePresence>
          {isActive && (
            <StyledCheckmarkContainer
              key="system"
              variants={checkmarkAnimationVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.3 }}
            >
              <CheckContainer>
                <IconCheck />
              </CheckContainer>
            </StyledCheckmarkContainer>
          )}
        </AnimatePresence>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <ColorSchemeSegment
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        controls={controls}
        variant={variant}
        onClick={onClick}
      />
      <AnimatePresence>
        {isActive && (
          <StyledCheckmarkContainer
            key={variant}
            variants={checkmarkAnimationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.3 }}
          >
            <CheckContainer>
              <IconCheck />
            </CheckContainer>
          </StyledCheckmarkContainer>
        )}
      </AnimatePresence>
    </StyledContainer>
  );
};

const StyledColorSchemeBackground = styled.div<{ $variant: ThemeVariant }>`
  align-items: flex-end;
  background: ${({ $variant }) => {
    switch ($variant) {
      case 'dark':
        return grayScale.gray90;
      case 'light':
      default:
        return grayScale.gray10;
    }
  }};
  border: ${({ $variant }) => {
    switch ($variant) {
      case 'dark':
        return `1px solid ${grayScale.gray70};`;
      case 'light':
      default:
        return `1px solid ${grayScale.gray20};`;
    }
  }};
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 80px;
  justify-content: flex-end;
  overflow: hidden;
  padding-left: 8px;
  padding-top: 8px;
  width: 120px;
`;

const StyledColorSchemeContent = styled(motion.div)<{ $variant: ThemeVariant }>`
  background: ${({ $variant }) => {
    switch ($variant) {
      case 'dark':
        return grayScale.gray75;
      case 'light':
        return grayScale.gray0;
      default:
        return grayScale.gray0;
    }
  }};

  border-left: ${({ $variant }) => {
    switch ($variant) {
      case 'dark':
        return `1px solid ${grayScale.gray60};`;
      case 'light':
      default:
        return `1px solid ${grayScale.gray20};`;
    }
  }};
  border-radius: 8px 0px 0px 0px;
  border-top: ${({ $variant }) => {
    switch ($variant) {
      case 'dark':
        return `1px solid ${grayScale.gray60};`;
      case 'light':
      default:
        return `1px solid ${grayScale.gray20};`;
    }
  }};
  box-sizing: border-box;
  color: ${({ $variant }) => {
    switch ($variant) {
      case 'dark':
        return grayScale.gray30;
      case 'light':
      default:
        return grayScale.gray60;
    }
  }};
  display: flex;
  flex: 1;
  font-size: 20px;
  height: 56px;
  padding-left: 4px;
  padding-top: 4px;
`;

const CheckContainer = styled.div`
  background: ${mainColors.blue};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    width: 12px;
    height: 12px;
    color: white;
  }
`;
