import { TranscriptLineType } from 'lib/Types';
import api from 'lib/api';
import {
  deleteTranscriptLine,
  upsertTranscriptLines,
} from 'lib/redux/slices/transcriptLines';
import { useDispatch } from 'react-redux';
import { useTranscriptLineNav } from 'modules/transcript-editor/hooks/use-transcript-line-nav';
import { useQueryParams } from '../use-query-params';

export const useTranscriptLineApi = () => {
  const dispatch = useDispatch();
  const { updateQueryParams } = useQueryParams();
  const { handleNextTranscriptLine } = useTranscriptLineNav();

  const handleCreateTranscriptLine = async (transcriptLine: {
    text: string;
    start: number;
    end: number;
    project_id: string;
    transcript_character_id?: string;
  }) => {
    try {
      const { data } = await api.transcriptLines.create(transcriptLine);
      dispatch(upsertTranscriptLines([data.transcript_line]));
    } catch (error) {
      alert(`Error creating line ${error}`);
    }
  };

  const handleUpdateTranscriptLine = async (
    transcriptLine: { id: string } & Partial<TranscriptLineType>,
  ) => {
    try {
      dispatch(upsertTranscriptLines([{ ...transcriptLine, uiLoading: true }]));
      const { data } = await api.transcriptLines.update(
        transcriptLine.id,
        transcriptLine,
      );
      dispatch(upsertTranscriptLines([data.transcript_line]));
    } catch (error) {
      alert(`Error updating line ${error}`);
    } finally {
      dispatch(
        upsertTranscriptLines([{ id: transcriptLine.id, uiLoading: false }]),
      );
    }
  };

  const handleApproveTranscriptLine = async (id: string) => {
    try {
      const { data } = await api.transcriptLines.approve(id);
      handleNextTranscriptLine(id);
      dispatch(upsertTranscriptLines([data.transcript_line]));
    } catch (e) {
      alert('Error approving line');
    } finally {
      dispatch(upsertTranscriptLines([{ id, uiLoading: false }]));
    }
  };

  const handleUnapproveTranscriptLine = async (id: string) => {
    try {
      const { data } = await api.transcriptLines.unapprove(id);
      dispatch(upsertTranscriptLines([data.transcript_line]));
    } catch (e) {
      alert('Error unapproving line');
    }
  };

  const handleMergeTranscriptLine = async (
    id: string,
    direction: 'next' | 'prev',
  ) => {
    try {
      dispatch(upsertTranscriptLines([{ id, uiLoading: true }]));
      const { data } = await api.transcriptLines.merge(id, { direction });
      dispatch(upsertTranscriptLines([data.transcript_line]));
      dispatch(deleteTranscriptLine(data.merged_transcript_line_id));
      updateQueryParams({ activeId: data.transcript_line.id });
    } catch (e: any) {
      alert(e?.response?.data?.detail || 'Error merging transcript line');
    } finally {
      dispatch(upsertTranscriptLines([{ id, uiLoading: false }]));
    }
  };

  const handleSplitTranscriptLine = async (id: string, pos: number) => {
    try {
      dispatch(upsertTranscriptLines([{ id, uiLoading: true }]));
      const { data } = await api.transcriptLines.split(id, { pos });
      dispatch(
        upsertTranscriptLines([data.transcript_line, data.new_transcript_line]),
      );
      updateQueryParams({ activeId: data.new_transcript_line.id });
    } catch (e) {
      alert('Error splitting line');
    } finally {
      dispatch(upsertTranscriptLines([{ id, uiLoading: false }]));
    }
  };

  const handleDeleteTranscriptLine = async (line: { id: string }) => {
    try {
      dispatch(deleteTranscriptLine(line.id));
      await api.transcriptLines.delete(line.id);
    } catch (error) {
      alert(`Error delete line ${error}`);
    }
  };

  return {
    handleCreateTranscriptLine,
    handleUpdateTranscriptLine,
    handleApproveTranscriptLine,
    handleUnapproveTranscriptLine,
    handleMergeTranscriptLine,
    handleSplitTranscriptLine,
    handleDeleteTranscriptLine,
  };
};
