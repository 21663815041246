import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LineType, TranscriptLineType } from 'lib/Types';

export type TranscriptLinesReduxType = { [id: string]: TranscriptLineType };
const initialState = {} as TranscriptLinesReduxType;

export const transcriptLinesSlice = createSlice({
  name: 'transcriptLines',
  initialState,
  reducers: {
    upsertTranscriptLines: (
      state: TranscriptLinesReduxType,
      action: PayloadAction<({ id: string } & Partial<LineType>)[]>,
    ) => {
      action.payload.forEach((line) => {
        state[line.id] = { ...(state[line.id] || {}), ...line };
      });
      return state;
    },
    deleteTranscriptLine: (
      state: TranscriptLinesReduxType,
      action: PayloadAction<string>,
    ) => {
      delete state[action.payload];
      return state;
    },
    deleteProjectTranscriptLines: (
      state: TranscriptLinesReduxType,
      action: PayloadAction<string>,
    ) => {
      Object.keys(state).forEach((lineId) => {
        if (state[lineId].project_id === action.payload) {
          delete state[lineId];
        }
      });
      return state;
    },
    resetTranscriptLines: () => initialState,
  },
});

export const {
  upsertTranscriptLines,
  deleteTranscriptLine,
  deleteProjectTranscriptLines,
  resetTranscriptLines,
} = transcriptLinesSlice.actions;
