import React from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  icon: React.ReactNode;
  onClick?: () => void;
  isActive?: boolean;
};

export const SidebarRow = ({ title, icon, onClick, isActive }: Props) => (
  <Container onClick={onClick} $background={isActive ? 'var(--hover)' : 'none'}>
    {icon}
    {title}
  </Container>
);

const Container = styled.div<{ $background: string }>`
  display: flex;
  flex-direction: row;
  padding: 4px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background: ${({ $background }) => $background};

  &&:hover {
    background: var(--hover);
  }
`;
