import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from '..';

export const getLinesDict = (state: RootState) => state.lines;
export const getLines = createSelector(getLinesDict, (lines) =>
  Object.values(lines),
);
export const getAllProjectDubLines = memoize((projectDubId: string) =>
  createSelector(getLines, (lines) =>
    lines
      .filter((l) => l.project_dub_id === projectDubId)
      .sort((a, b) => a.start - b.start),
  ),
);
export const getProjectDubLines = memoize((projectDubId: string) =>
  createSelector(getAllProjectDubLines(projectDubId), (lines) =>
    lines.filter((l) => !l.is_disabled),
  ),
);
