import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from '..';

export const getClipsDict = (state: RootState) => state.clips;
export const getClips = createSelector(getClipsDict, (clips) =>
  Object.values(clips),
);
export const getProjectDubClips = memoize((projectDubId: string) =>
  createSelector(getClips, (clips) =>
    clips.filter((c) => c.project_dub_id === projectDubId),
  ),
);
