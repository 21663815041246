import { IconBuildingSkyscraper } from '@tabler/icons-react';
import { WorkspaceType } from 'lib/Types';
import { useUserApi } from 'lib/hooks/api/use-user-api';
import { getUserMe } from 'lib/redux/selectors/users';
import { FlexRow } from 'modules/shared/ui';
import { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

type Props = {
  workspace: WorkspaceType;
};

export const WorkspaceSelectRow = ({ workspace }: Props) => {
  const user = useSelector(getUserMe);
  const { handleUpdateUser } = useUserApi();

  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    if (!user) return;
    try {
      setLoading(true);
      await handleUpdateUser({
        id: user.id,
        active_workspace_id: workspace.id,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container onClick={handleClick}>
      <FlexRow style={{ gap: 8 }}>
        {workspace.profile_src ? (
          <Icon src={workspace.profile_src} />
        ) : (
          <IconBuildingSkyscraper
            style={{ width: 16, height: 16, color: 'var(--text-light)' }}
          />
        )}
        <div>{workspace.name}</div>
      </FlexRow>
      <div>
        {isLoading ? (
          <TailSpin width={12} height={12} color="var(--text-primary)" />
        ) : null}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 13px;
  padding: 8px 16px;
  transition: 0.2s ease-in-out;
  :hover {
    background: var(--hover);
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 4px;
`;
