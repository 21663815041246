export type BaseType = {
  createdAt: string;
  updatedAt: string;
};

export enum VoiceEngineType {
  ELEVENLABS = 'ELEVENLABS',
  DEEPTUNE = 'DEEPTUNE',
}

export enum ActivityTypeEnum {
  APPROVE_LINE = 'APPROVE_LINE',
  UNAPPROVE_LINE = 'UNAPPROVE_LINE',
  INFERENCE_LINE = 'INFERENCE_LINE',
}

export type ActivityType = BaseType & {
  id: string;
  type: ActivityTypeEnum;
  data: any;
  user_id: string;
  project_id: string;
  project_dub_id: string;
};

export type CaptionType = BaseType & {
  id: string;
  project_dub_id: string;
  top_pct: number;
  left_pct: number;
  bottom_pct: number;
  right_pct: number;
  start_time: number;
  end_time: number;
  font_size: number;
  font: string;
  font_weight: FontWeightEnum;
  font_style: FontStyleEnum;
  color: string;
  align: 'left' | 'center' | 'right';
  strategy: 'auto' | 'word';
  capitalization: 'normal' | 'uppercase';
  stroke: string;
  stroke_width: number;
  shadow?: string;
};

export type CharacterType = BaseType & {
  id: string;
  name: string;
  project_id: string;
  project_dub_id: string;
  index_number: number;
  is_disabled: boolean;
  color?: string;
  voice_data_id?: string;
  clone_voice_data_id?: string;
  isLoading?: boolean;
};

export type ClipTrackType = BaseType & {
  id: string;
  project_id: string;
  project_dub_id: string;
  name: string;
  is_disabled: boolean;
};

export type ClipType = BaseType & {
  id: string;
  project_id: string;
  project_dub_id: string;
  clip_track_id: string;
  name: string;
  start_time: number;
  end_time: number;
  src: string;
  trim_start: number;
  is_disabled: boolean;
  isLoading: boolean;
  duration: number;
};

export type DatagenHistoryType = BaseType & {
  id: string;
  name: string;
  type: DatagenType;
  user_id: string;
  audio_src: string;
  status: DatagenStatus;
  gen_audio_src?: string;
};

export enum DatagenStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum DatagenType {
  DEMO = 'DEMO',
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH',
}

export enum ExportJobStatusEnum {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export type ExportJobType = BaseType & {
  id: string;
  project_id: string;
  project_dub_id: string;
  user_id: string;
  status: ExportJobStatusEnum;
  failed_reason?: string;
  data: any;
  download_src?: string;
  deleted_at?: string;
  started_at?: string;
};

export enum LanguageEnum {
  ARABIC = 'ARABIC',
  BULGARIAN = 'BULGARIAN',
  CHINESE = 'CHINESE',
  CROATIAN = 'CROATIAN',
  CZECH = 'CZECH',
  DANISH = 'DANISH',
  DUTCH = 'DUTCH',
  ENGLISH = 'ENGLISH',
  ES_LA = 'ES_LA',
  FILIPINO = 'FILIPINO',
  FINNISH = 'FINNISH',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  GREEK = 'GREEK',
  HINDI = 'HINDI',
  INDONESIAN = 'INDONESIAN',
  ITALIAN = 'ITALIAN',
  JAPANESE = 'JAPANESE',
  KOREAN = 'KOREAN',
  MALAYSIAN = 'MALAYSIAN',
  POLISH = 'POLISH',
  PORTUGUESE = 'PORTUGUESE',
  PT_BR = 'PT_BR',
  ROMANIAN = 'ROMANIAN',
  RUSSIAN = 'RUSSIAN',
  SPANISH = 'SPANISH',
  SWEDISH = 'SWEDISH',
  TA = 'TA',
  THAI = 'THAI',
  TURKISH = 'TURKISH',
  UKRAINIAN = 'UKRAINIAN',
  VIETNAMESE = 'VIETNAMESE',
}

export enum TranscriptOnlyEnum {
  FARSI = 'FARSI',
}

export type TranscriptLanguageEnum = LanguageEnum | TranscriptOnlyEnum;

export const LanguageNames: Record<TranscriptLanguageEnum, string> = {
  [LanguageEnum.ARABIC]: 'Arabic',
  [LanguageEnum.BULGARIAN]: 'Bulgarian',
  [LanguageEnum.CHINESE]: 'Mandarin',
  [LanguageEnum.CROATIAN]: 'Croatian',
  [LanguageEnum.CZECH]: 'Czech',
  [LanguageEnum.DANISH]: 'Danish',
  [LanguageEnum.DUTCH]: 'Dutch',
  [LanguageEnum.ENGLISH]: 'English',
  [LanguageEnum.ES_LA]: 'Spanish (Latin America)',
  [TranscriptOnlyEnum.FARSI]: 'Farsi',
  [LanguageEnum.FILIPINO]: 'Filipino',
  [LanguageEnum.FINNISH]: 'Finnish',
  [LanguageEnum.FRENCH]: 'French',
  [LanguageEnum.GERMAN]: 'German',
  [LanguageEnum.GREEK]: 'Greek',
  [LanguageEnum.HINDI]: 'Hindi',
  [LanguageEnum.INDONESIAN]: 'Indonesian',
  [LanguageEnum.ITALIAN]: 'Italian',
  [LanguageEnum.JAPANESE]: 'Japanese',
  [LanguageEnum.KOREAN]: 'Korean',
  [LanguageEnum.MALAYSIAN]: 'Malay',
  [LanguageEnum.POLISH]: 'Polish',
  [LanguageEnum.PORTUGUESE]: 'Portuguese (Portugal)',
  [LanguageEnum.PT_BR]: 'Portuguese (Brasil)',
  [LanguageEnum.ROMANIAN]: 'Romanian',
  [LanguageEnum.RUSSIAN]: 'Russian',
  [LanguageEnum.SPANISH]: 'Spanish (Spain)',
  [LanguageEnum.SWEDISH]: 'Swedish',
  [LanguageEnum.TA]: 'Tamil',
  [LanguageEnum.THAI]: 'Thai',
  [LanguageEnum.TURKISH]: 'Turkish',
  [LanguageEnum.UKRAINIAN]: 'Ukrainian',
  [LanguageEnum.VIETNAMESE]: 'Vietnamese',
};

export type WordType = {
  text: string;
  start: number;
  end: number;
};

export type LineType = {
  id: string;
  text: string;
  orig_text: string;
  start: number;
  end: number;
  character_id: string;
  project_id: string;
  project_dub_id: string;
  src?: string;
  raw_src?: string;
  approved_by?: string;
  approved_at?: string;
  warp_factor?: number;
  is_loading?: boolean;
  is_disabled?: boolean;
  uiLoading: boolean;
  words?: WordType[];
};

export type TranscriptLineType = {
  id: string;
  text: string;
  start: number;
  end: number;
  transcript_character_id: string;
  project_id: string;
  approved_by?: string;
  approved_at?: string;
  is_disabled?: boolean;
  uiLoading?: boolean;
};

export type ProjectBuildStatusType = 'BUILDING' | 'FAILED' | 'SUCCESS';

export type ProjectDubType = BaseType & {
  id: string;
  project_id: string;
  lang: LanguageEnum;
  translation_src?: string;
  final_src?: string;
  adaptation_src?: string;
  voice_engine?: VoiceEngineType;
};

export enum ProjectUserRole {
  EDITOR = 'EDITOR',
  OWNER = 'OWNER',
}

export type ProjectType = BaseType & {
  id: string;

  // added
  name: string;
  orig_lang: LanguageEnum;
  creator_id: string;
  build_status: ProjectBuildStatusType;
  workspace_id?: string;
  video_src: string;
  audio_src: string;
  dx_src: string;
  mfx_src: string;
  hls_video_src: string;
  hls_audio_src: string;
  hls_mfx_src: string;
  transcript_src?: string;
  auto_transcript_src?: string;
  characters?: CharacterType[];
  asset_zip_src?: string;
  demo_id?: string;
  duration?: number;
  failed_reason?: string;
  video_width?: number;
  video_height?: number;
};

export type ProjectUserType = {
  id: string;
  project_id: string;
  user_id: string;
  role: ProjectUserRole;
};

export type StemPreviewSegmentType = {
  start: number;
  end: number;
  url: string;
};

export type StemType = BaseType & {
  id: string;
  name: string;
  project_id: string;
  project_dub_id: string;
  raw_src: string;
  src: string;
  hls_src?: string;
  hls_raw_src?: string;
  is_edit: boolean;
  character_id: string;
  gain: number;
  preview_intervals?: StemPreviewSegmentType[];
};

export type ToastType = {
  id: string;
  title: string;
  description?: string;
  isLoading?: boolean;
};

export type TranscriptCharacterType = BaseType & {
  id: string;
  name: string;
  project_id: string;
  color: string;
  index_number: number;
  clone_voice_data_id?: string;
};

export type UserRole = 'ADMIN' | 'USER';
export type UserTheme = 'LIGHT' | 'DARK' | 'SYSTEM';
export type UserType = BaseType & {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  profile_src?: string;
  active_workspace_id?: string;
  // user settings
  theme: UserTheme;
  grid_snap_enabled: boolean;
};

export type VoiceType = BaseType & {
  id: string;
  name: string;
  description: string;
  voice_data_id: string;
  preview_url: string;
  tags: string[];
  data_urls: string[];
  workspace_id?: string;
  user_id?: string;
};

export type WorkspaceType = {
  id: string;
  name: string;
  profile_src?: string;
};

export enum WorkspaceUserRole {
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
}

export type WorkspaceUserType = {
  id: string;
  user_id: string;
  workspace_id: string;
  role: WorkspaceUserRole;
};

export type VoiceDataType = {
  id: string;
  data_urls: string[];
  el_voice_settings: {
    stability: number;
    similarity_boost: number;
    style: number;
    use_speaker_boost: boolean;
  };
  el_voice_id?: string;
  last_used_at?: string;
};

export enum DiffusionMethod {
  DDPM = 'DDPM',
  DDIM = 'DDIM',
}

export type CustomFontType = {
  id: string;
  name: string;
  variants: { url: string; weight: number; style: FontStyleEnum }[];
};

export enum FontWeightEnum {
  LIGHT = 300,
  NORMAL = 400,
  MEDIUM = 500,
  BOLD = 700,
}

export enum FontStyleEnum {
  NORMAL = 'normal',
  ITALIC = 'italic',
}
