import { CharacterType } from 'lib/Types';
import { Dot, FlexRow } from 'modules/shared/ui';
import React from 'react';
import styled from 'styled-components';
import { EditableStringInput } from 'modules/shared/editable-components/editable-string-input';
import { useCharacterApi } from 'lib/hooks/api/use-character-api';
import { CharacterContextMenu } from 'modules/shared/context-menu/character-context-menu';

type Props = {
  character: CharacterType;
};

export const CharacterRow = ({ character }: Props) => {
  const { handleUpdateCharacter } = useCharacterApi();

  return (
    <CharacterContextMenu character={character}>
      <Container key={character.id}>
        <FlexRow style={{ flexGrow: 1 }}>
          <Dot
            style={{
              background: character.color,
              marginRight: '10px',
              flexShrink: 0,
            }}
          />
          <EditableStringInput
            id={`character-row-${character.id}`}
            value={character.name}
            handleDoneEditing={(v) => {
              handleUpdateCharacter({ id: character.id, name: v });
            }}
          />
        </FlexRow>
      </Container>
    </CharacterContextMenu>
  );
};

const Container = styled(FlexRow)`
  font-size: 13px;
  font-weight: 400;
  color: var(--text-primary);
  padding: 8px 32px;

  :hover {
    background-color: var(--hover);
  }
`;
