import { TimelineEngine } from 'lib/engine/engine';
import { ProjectType } from 'lib/Types';
import { RootState } from 'lib/redux';
import { getProjectTranscriptLines } from 'lib/redux/selectors/transcriptLines';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import styled from 'styled-components';
import { setEditorSeekTime } from 'lib/redux/slices/editor';
import { useActiveTranscriptLine } from '../hooks/use-active-transcript-line';
import { TranscriptLineRow } from './transcript-line-row';

type Props = {
  project: ProjectType;
  focusTimeline: () => void;
  timelineEngine: MutableRefObject<TimelineEngine>;
};

const SMOOTH_SCROLL_THRESHOLD = 10;

export const TranscriptLineEditor = ({
  project,
  focusTimeline,
  timelineEngine,
}: Props) => {
  const dispatch = useDispatch();
  const transcriptLines = useSelector((state: RootState) =>
    getProjectTranscriptLines(project.id)(state),
  );
  const { activeTranscriptLine } = useActiveTranscriptLine();
  const virtuosoRef = useRef<VirtuosoHandle | null>(null);

  const [visibleRange, setVisibleRange] = useState({
    startIndex: 0,
    endIndex: 0,
  });

  useEffect(() => {
    if (!activeTranscriptLine || !virtuosoRef.current) return;
    timelineEngine.current?.setTime(activeTranscriptLine.start);
    dispatch(setEditorSeekTime(activeTranscriptLine.start));
    const lineIdx = transcriptLines.findIndex(
      (line) => line.id === activeTranscriptLine.id,
    );
    if (lineIdx === -1) return;

    // If scrolling >= 10 lines, snap to the line, else smooth scroll
    let behavior: 'auto' | 'smooth' = 'smooth';

    if (
      lineIdx < visibleRange.startIndex - SMOOTH_SCROLL_THRESHOLD ||
      lineIdx > visibleRange.endIndex + SMOOTH_SCROLL_THRESHOLD
    ) {
      behavior = 'auto';
    }

    virtuosoRef.current.scrollToIndex({
      index: lineIdx,
      behavior,
      align: 'center',
    });
  }, [activeTranscriptLine?.id]);

  if (!transcriptLines.length) {
    return <Container>No lines detected.</Container>;
  }

  return (
    <Container>
      <Virtuoso
        data={transcriptLines}
        rangeChanged={setVisibleRange}
        increaseViewportBy={400}
        style={{ height: '100%' }}
        ref={virtuosoRef}
        itemContent={(i) => (
          <TranscriptLineRow
            key={transcriptLines[i].id}
            transcriptLine={transcriptLines[i]}
            focusTimeline={focusTimeline}
            timelineEngine={timelineEngine}
          />
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 4px;
  overflow: auto;
  height: 100%;
`;
