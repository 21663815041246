import React from 'react';
import { ClipType } from 'lib/Types';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuPortal,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubItem,
  ContextMenuTrigger,
} from 'modules/radix/ContextMenu';
import { TimelineEngine } from 'lib/engine/engine';
import { useSelector } from 'react-redux';
import { getClipTracks } from 'lib/redux/selectors/clipTracks';
import { useClipApi } from 'lib/hooks/api/use-clip-api';

type Props = {
  timelineEngine: React.MutableRefObject<TimelineEngine>;
  clip: ClipType;
  children?: React.ReactNode;
};

export const ClipContextMenu = ({ timelineEngine, clip, children }: Props) => (
  <ContextMenu>
    <ContextMenuTrigger>{children}</ContextMenuTrigger>
    <ContextMenuPortal>
      <ClipContextMenuContent clip={clip} timelineEngine={timelineEngine} />
    </ContextMenuPortal>
  </ContextMenu>
);

type ContentProps = {
  timelineEngine: React.MutableRefObject<TimelineEngine>;
  clip: ClipType;
};

export const ClipContextMenuContent = ({
  timelineEngine,
  clip,
}: ContentProps) => {
  const { handleSplitClip, handleUpdateClip, handleDeleteClip } = useClipApi();
  const currentTime = timelineEngine.current.getTime() || 0;
  const clipTracks = useSelector(getClipTracks);

  return (
    <ContextMenuContent style={{ position: 'absolute' }}>
      <ContextMenuItem
        title={clip.is_disabled ? 'Enable' : 'Disable'}
        icon={clip.is_disabled ? 'visibility' : 'visibility_off'}
        onSelect={() => {
          handleUpdateClip({ id: clip.id, is_disabled: !clip.is_disabled });
        }}
      />
      <ContextMenuSub>
        <ContextMenuSubItem title="Move track" icon="dns" />
        <ContextMenuPortal>
          <ContextMenuSubContent sideOffset={2} alignOffset={-5}>
            {clipTracks.map((clipTrack) => (
              <ContextMenuItem
                key={clipTrack.id}
                title={clipTrack.name}
                disabled={clip.clip_track_id === clipTrack.id}
                onSelect={() => {
                  handleUpdateClip({
                    id: clip.id,
                    clip_track_id: clipTrack.id,
                  });
                }}
              />
            ))}
          </ContextMenuSubContent>
        </ContextMenuPortal>
      </ContextMenuSub>
      <ContextMenuItem
        title="Split at Cursor"
        icon="content_cut"
        onSelect={() => {
          handleSplitClip(clip.id, currentTime);
        }}
      />
      {clip?.src ? (
        <a href={clip?.src} style={{ all: 'unset' }} download target="blank">
          <ContextMenuItem title="Download" icon="download" />
        </a>
      ) : null}
      <ContextMenuItem
        title="Delete"
        icon="delete"
        onSelect={() => handleDeleteClip(clip.id)}
      />
    </ContextMenuContent>
  );
};
