import { AxiosProgressEvent } from 'axios';
import { ProjectType } from 'lib/Types';
import axios from './axios';

export const Projects = {
  get: () => axios.get('/projects'),
  getAll: () => axios.get('/projects/all'),
  create: ({
    name,
    workspace_id,
    file,
    src,
    orig_lang,
    trim_start,
    trim_end,
    onUploadProgress,
  }: {
    name: string;
    workspace_id: string;
    file: File | null;
    src: string;
    orig_lang: string;
    trim_start?: number;
    trim_end?: number;
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  }) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('workspace_id', workspace_id);
    if (file) formData.append('file', file);
    else formData.append('src', src);
    formData.append('orig_lang', orig_lang);
    if (trim_start) formData.append('trim_start', trim_start.toString());
    if (trim_end) formData.append('trim_end', trim_end.toString());
    return axios.post('/projects', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  },
  update: (id: string, data: Partial<ProjectType>) =>
    axios.put(`/projects/${id}`, data),
  delete: (id: string) => axios.delete(`/projects/${id}`),
  getById: (id: string) => axios.get(`/projects/${id}`),
  hardDelete: (id: string) => axios.delete(`/projects/${id}/hard`),
  rebuild: (id: string) => axios.post(`/projects/${id}/rebuild`),
  publish: (
    id: string,
    data: {
      project_dub_ids: string[];
      trim_start?: number;
      trim_end?: number;
    },
  ) => axios.post(`/projects/${id}/publish`, data),
  previewTranscript: ({
    id,
    file,
    onUploadProgress,
  }: {
    id: string;
    file: File;
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  }) => {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`/projects/${id}/preview/transcript`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  },
  importTranscript: ({
    id,
    parsed_lines,
    file,
    onUploadProgress,
  }: {
    id: string;
    parsed_lines: any[];
    file: File;
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('parsed_lines', JSON.stringify(parsed_lines));
    return axios.post(`/projects/${id}/import/transcript`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  },
  status: ({ project_ids }: { project_ids: string[] }) =>
    axios.post('/projects/status', { project_ids }),
};
