import axios from 'axios';
import firebase from 'firebase/auth';
import { WEBSERVER_URL } from 'lib/constants';
import { auth } from 'lib/utils/firebase';

const instance = axios.create({
  baseURL: WEBSERVER_URL,
});

let isRefreshing = false;

const refreshTokenAndRetry = async (error: any) => {
  // Prevent race conditions if making multiple refresh attempts at once
  if (isRefreshing) {
    return Promise.reject(error);
  }

  isRefreshing = true;

  try {
    const { currentUser } = auth;
    if (currentUser) {
      const idToken = await currentUser.getIdToken(true);
      error.config.headers.Authorization = `Bearer ${idToken}`;
      error.config._retry = true; // Prevent infinite retry loops
      instance.defaults.headers.common.Authorization = `Bearer ${idToken}`;

      return await instance(error.config);
    }
    return await Promise.reject(error);
  } catch (e) {
    return await Promise.reject(e);
  } finally {
    isRefreshing = false;
  }
};

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401 && !error.config._retry) {
      return refreshTokenAndRetry(error);
    }

    return Promise.reject(error);
  },
);

auth.onAuthStateChanged(async (user) => {
  if (user) {
    setToken(user);
  } else {
    delete instance.defaults.headers.common.Authorization;
  }
});

export const setToken = async (user: firebase.User, forceRefresh?: boolean) => {
  const token = await user.getIdToken(forceRefresh);
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default instance;
