import { KonvaEventObject } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import { CaptionType, LineType, ProjectType } from 'lib/Types';
import api from 'lib/api';
import { TimelineEngine } from 'lib/engine/engine';
import { getEditorSeekTime } from 'lib/redux/selectors/editor';
import { upsertCaptions } from 'lib/redux/slices/captions';
import { setEditorSeekTime } from 'lib/redux/slices/editor';
import { getComputedColor } from 'lib/utils/utils';
import { debounce } from 'lodash';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Group, Rect, Text, Transformer } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import {
  OnscreenSegmentType,
  parseTextShadow,
  recalculateTextSegments,
} from '../lib/utils';

type Props = {
  stageDims: { width: number; height: number };
  caption: CaptionType;
  lines: LineType[];
  timelineEngine: MutableRefObject<TimelineEngine>;
  project: ProjectType;
  selectedLayerIds: string[];
  setShowGuidelines: (show: boolean) => void;
  setGuidelines: (guidelines: { x: number | null; y: number | null }) => void;
  setSelectedLayerIds: (ids: string[]) => void;
};

export const SelectableCaption = ({
  stageDims,
  caption,
  lines,
  project,
  timelineEngine,
  selectedLayerIds,
  setShowGuidelines,
  setGuidelines,
  setSelectedLayerIds,
}: Props) => {
  const dispatch = useDispatch();
  const textRef = useRef<any>(null);
  const [groupDims, setGroupDims] = useState({
    x: caption.left_pct * stageDims.width,
    y: caption.top_pct * stageDims.height,
    width: stageDims.width * (caption.right_pct - caption.left_pct),
    height: stageDims.height * (caption.bottom_pct - caption.top_pct),
  });
  const [textSegments, setTextSegments] = useState<OnscreenSegmentType[]>([]);
  const [onscreenText, setOnscreenText] = useState('');
  const [isHover, setHover] = useState(false);
  const isSelected = selectedLayerIds.includes(caption.id);
  const showBorder = isHover || isSelected;
  const seekTime = useSelector(getEditorSeekTime);
  const [currTime, setCurrTime] = useState(0);
  const transformerRef = useRef<any>();
  const groupRef = useRef<any>();
  // Scale any text properties that are pixel dependent
  const videoScale = stageDims.width / (project.video_width ?? 1920);
  const fontSize = caption.font_size * videoScale;
  const strokeWidth = caption.stroke_width * videoScale;
  const shadow = parseTextShadow(caption.shadow || '');
  const blurRadius = shadow ? shadow.blurRadius * videoScale : undefined;
  const shadowOffsetX = shadow ? shadow.offsetX * videoScale : undefined;
  const shadowOffsetY = shadow ? shadow.offsetY * videoScale : undefined;

  // Resize captions box
  useEffect(() => {
    setGroupDims({
      x: caption.left_pct * stageDims.width,
      y: caption.top_pct * stageDims.height,
      width: stageDims.width * (caption.right_pct - caption.left_pct),
      height: stageDims.height * (caption.bottom_pct - caption.top_pct),
    });

    updateTextSegments(
      stageDims.width * (caption.right_pct - caption.left_pct),
    );
  }, [stageDims, caption]);

  // Update lines
  useEffect(() => {
    updateTextSegments(groupDims.width);
  }, [lines]);

  // Update currTime
  useEffect(() => {
    // Update captions while the video plays
    const setTickTimeHandler = timelineEngine.current.on(
      'setTimeByTick',
      ({ time }) => {
        setCurrTime(time);
      },
    );
    // Update captions on pause
    const setTimeHandler = timelineEngine.current.on(
      'afterSetTime',
      ({ time }) => setCurrTime(time),
    );

    return () => {
      timelineEngine.current.off(setTickTimeHandler);
      timelineEngine.current.off(setTimeHandler);
    };
  }, [timelineEngine.current]);

  useEffect(() => {
    const currentSegment = textSegments?.find(
      (segment) => currTime >= segment.start && currTime <= segment.end,
    );
    setOnscreenText(currentSegment ? currentSegment.text : '');
  }, [currTime]);

  // Custom drag bound function to prevent dragging out of bounds
  const dragBoundFunc = useCallback(
    (pos: Vector2d) => {
      const centerX = stageDims.width / 2;
      const centerY = stageDims.height / 2;
      const snapTolerance = 10;

      let newX = Math.max(
        0,
        Math.min(pos.x, stageDims.width - groupDims.width),
      );
      let newY = Math.max(
        0,
        Math.min(pos.y, stageDims.height - groupDims.height),
      );

      let snapX = null;
      let snapY = null;
      let snapping = false;

      if (Math.abs(newX + groupDims.width / 2 - centerX) < snapTolerance) {
        newX = centerX - groupDims.width / 2;
        snapX = centerX;
        snapping = true;
      }

      if (Math.abs(newY + groupDims.height / 2 - centerY) < snapTolerance) {
        newY = centerY - groupDims.height / 2;
        snapY = centerY;
        snapping = true;
      }

      setShowGuidelines(snapping);
      setGuidelines({ x: snapX, y: snapY });

      return {
        x: newX,
        y: newY,
      };
    },
    [stageDims, groupDims],
  );

  // Attach transformer to group
  useEffect(() => {
    if (transformerRef.current && groupRef.current) {
      transformerRef.current.nodes([groupRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [transformerRef?.current]);

  const pauseTimelineInPlace = () => {
    timelineEngine.current.pause();
    dispatch(setEditorSeekTime(timelineEngine.current.getTime()));
  };

  const handleMouseEnter = () => {
    const container = textRef.current.getStage().container();
    container.style.cursor = 'move';
    setHover(true);
  };

  const handleMouseLeave = () => {
    const container = textRef.current.getStage().container();
    container.style.cursor = 'default';
    setHover(false);
  };

  const updateCaption = async (captionInfo: Partial<CaptionType>) => {
    try {
      await api.captions.update(caption.id, captionInfo);
      // TODO: should I be upserting?
      // dispatch(upsertCaptions([data.caption]));
    } catch (e) {
      alert('Error updating caption');
    }
  };

  const debouncedUpdateCaption = useCallback(
    debounce((captionInfo: Partial<CaptionType>) => {
      updateCaption(captionInfo);
    }, 500),
    [caption.id],
  );

  const handleDragEnd = (e: KonvaEventObject<DragEvent>) => {
    const { x, y } = e.target.position();
    const leftPct = x / stageDims.width;
    const topPct = y / stageDims.height;
    const rightPct = (x + e.target.width()) / stageDims.width;
    const bottomPct = (y + e.target.height()) / stageDims.height;

    setShowGuidelines(false);

    // Update caption position
    dispatch(
      upsertCaptions([
        {
          id: caption.id,
          left_pct: leftPct,
          top_pct: topPct,
          right_pct: rightPct,
          bottom_pct: bottomPct,
        },
      ]),
    );

    debouncedUpdateCaption({
      left_pct: leftPct,
      top_pct: topPct,
      right_pct: rightPct,
      bottom_pct: bottomPct,
    });
  };

  const updateTextSegments = (groupWidth: number) => {
    // Convert groupWidth from on screen width to width in actual video dimensions
    // This is necessary to ensure the font size and group width have the same scale

    const scaledWidth =
      groupWidth / (stageDims.width / (project.video_width ?? 1920));
    const newSegments = recalculateTextSegments(scaledWidth, lines, caption);

    setTextSegments(newSegments);

    // Update the state with new segments
    setOnscreenText(
      newSegments.find(
        (segment) => currTime >= segment.start && currTime <= segment.end,
      )?.text || '',
    );
  };

  const handleTransform = () => {
    // When transforming the group, adjust the scale of the text
    const group = groupRef.current;
    const text = textRef.current;
    const newWidth = group.width() * group.scaleX();
    text.setAttrs({
      scaleX: 1 / group.scaleX(),
      scaleY: 1 / group.scaleY(),
      width: newWidth,
      height: group.height() * group.scaleY(),
    });

    updateTextSegments(newWidth);
  };

  const handleTransformEnd = async () => {
    // Update group dimensions, reset text scale
    const group = groupRef.current;
    const scaleX = group.scaleX();
    const scaleY = group.scaleY();

    const newDims = {
      x: group.x(),
      y: group.y(),
      width: group.width() * scaleX,
      height: group.height() * scaleY,
    };

    setGroupDims(newDims);
    group.setAttrs({
      scaleX: 1,
      scaleY: 1,
      width: newDims.width,
      height: newDims.height,
    });

    const text = textRef.current;
    text.setAttrs({
      scaleX: 1,
      scaleY: 1,
      width: newDims.width,
      height: newDims.height,
    });

    transformerRef.current.forceUpdate();
    group.getLayer().batchDraw();

    const newPos = {
      left_pct: newDims.x / stageDims.width,
      top_pct: newDims.y / stageDims.height,
      right_pct: (newDims.x + newDims.width) / stageDims.width,
      bottom_pct: (newDims.y + newDims.height) / stageDims.height,
    };

    dispatch(
      upsertCaptions([
        {
          id: caption.id,
          ...newPos,
        },
      ]),
    );

    debouncedUpdateCaption(newPos);
  };

  if (seekTime < caption.start_time || seekTime > caption.end_time) return null;

  return (
    <>
      <Group
        ref={groupRef}
        x={groupDims.x}
        y={groupDims.y}
        width={groupDims.width}
        height={groupDims.height}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onDragStart={pauseTimelineInPlace}
        onDragEnd={handleDragEnd}
        dragBoundFunc={dragBoundFunc}
        draggable
        onTransform={handleTransform}
        onTransformEnd={handleTransformEnd}
        onMouseDown={(e) => {
          pauseTimelineInPlace();
          setSelectedLayerIds([caption.id]);
          e.cancelBubble = true;
        }}
        flipEnabled={false}
      >
        {showBorder && (
          <Rect
            width={groupDims.width}
            height={groupDims.height}
            stroke={getComputedColor('--blue')}
          />
        )}
        <Text
          ref={textRef}
          text={onscreenText}
          fontSize={fontSize}
          fill={caption.color}
          fontFamily={caption.font}
          fontStyle={`${caption.font_style} ${caption.font_weight}`}
          align={caption.align}
          width={groupDims.width}
          height={groupDims.height}
          verticalAlign="middle"
          shadowColor={shadow?.color}
          shadowOffsetX={shadowOffsetX}
          shadowOffsetY={shadowOffsetY}
          shadowBlur={blurRadius}
          stroke={caption.stroke}
          strokeWidth={strokeWidth}
        />
      </Group>
      {showBorder && (
        <Transformer
          ref={transformerRef}
          resizeEnabled
          rotateEnabled={false}
          enabledAnchors={[
            'top-right',
            'top-left',
            'bottom-right',
            'bottom-left',
          ]}
          anchorCornerRadius={2}
          anchorStyleFunc={(anchor) => {
            anchor.height(8);
            anchor.width(8);
            anchor.offsetY(4);
            anchor.offsetX(4);
            anchor.strokeWidth(0);
            anchor.fill('white');
          }}
          borderStrokeWidth={0}
          keepRatio={false}
          onMouseDown={(e) => {
            e.cancelBubble = true;
          }}
        />
      )}
    </>
  );
};
