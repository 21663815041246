import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from '..';

export const getCaptionsDict = (state: RootState) => state.captions;
export const getCaptions = createSelector(getCaptionsDict, (captions) =>
  Object.values(captions),
);
export const getCaptionByProjectDubId = memoize((projectDubId: string) =>
  createSelector(getCaptions, (captions) =>
    captions.find((c) => c.project_dub_id === projectDubId),
  ),
);
