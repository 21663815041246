import { Captions } from './captions';
import { Characters } from './characters';
import { ClipTracks } from './clipTracks';
import { Clips } from './clips';
import { ExportJobs } from './exportJobs';
import { Lines } from './lines';
import { ProjectDubs } from './projectDubs';
import { ProjectUsers } from './projectUsers';
import { Projects } from './projects';
import { TranscriptCharacters } from './transcriptCharacters';
import { TranscriptLines } from './transcriptLines';
import { Users } from './users';
import { VoiceData } from './voiceData';
import { Voices } from './voices';
import { Workspaces } from './workspaces';

// eslint-disable-next-line @typescript-eslint/naming-convention
export default class api {
  static captions = Captions;
  static clips = Clips;
  static clipTracks = ClipTracks;
  static characters = Characters;
  static exportJobs = ExportJobs;
  static lines = Lines;
  static projects = Projects;
  static projectDubs = ProjectDubs;
  static projectUsers = ProjectUsers;
  static transcriptCharacters = TranscriptCharacters;
  static transcriptLines = TranscriptLines;
  static users = Users;
  static voiceData = VoiceData;
  static voices = Voices;
  static workspaces = Workspaces;
}
