import { ToastProvider } from '@radix-ui/react-toast';
import api from 'lib/api';
import { useProjectParam } from 'lib/hooks/use-id-param';
import { getToast } from 'lib/redux/selectors/toast';
import {
  ToastDescription,
  ToastRoot,
  ToastTitle,
  ToastViewport,
} from 'modules/radix/Toast';
import { Loading } from 'modules/shared/loading';
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { upsertTranscriptLines } from 'lib/redux/slices/transcriptLines';
import { upsertProjects } from 'lib/redux/slices/projects';
import { useParams } from 'react-router-dom';
import { upsertTranscriptCharacters } from 'lib/redux/slices/transcriptCharacters';
import { usePingProjects } from 'lib/hooks/use-ping-projects';
import { resetEditor } from 'lib/redux/slices/editor';
import { ProjectNotFound } from 'modules/shared/screens/project-not-found';
import { ProjectBuilding } from 'modules/shared/screens/project-building';
import { ProjectFailed } from 'modules/shared/screens/project-failed';
import { FlexRow } from 'modules/shared/ui';
import EditorDetail from './components/editor-detail';
import { Toolbar } from './components/toolbar';
import { TranscriptCharacterModal } from './components/transcript-character-modal';

const TranscriptEditor = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { project } = useProjectParam();
  const [isLoading, setLoading] = useState(true);
  const toast = useSelector(getToast);
  usePingProjects({
    projects: project ? [project] : [],
  });

  useEffect(() => {
    const handleLoad = async () => {
      if (!id) return;
      try {
        setLoading(true);
        const { data } = await api.projects.getById(id);
        dispatch(resetEditor());
        dispatch(upsertProjects([data.project]));
        dispatch(upsertTranscriptCharacters(data.transcript_characters));
        dispatch(upsertTranscriptLines(data.transcript_lines));
      } catch (e) {
        console.error('Error loading project.', e);
      } finally {
        setLoading(false);
      }
    };

    handleLoad();
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }

  if (!project) {
    return <ProjectNotFound />;
  }

  if (project.build_status === 'BUILDING') {
    return <ProjectBuilding />;
  }

  if (project.build_status === 'FAILED') {
    return <ProjectFailed project={project} />;
  }

  return (
    <>
      <Container>
        <Toolbar project={project} />
        <EditorDetail project={project} />
      </Container>
      <TranscriptCharacterModal project={project} />
      <ToastProvider swipeDirection="right">
        {toast.map((t) => (
          <ToastRoot open={true} key={t.id}>
            <FlexRow style={{ gap: 8 }}>
              {t.isLoading ? (
                <TailSpin width={16} height={16} color="var(--text-primary)" />
              ) : null}
              <div>
                <ToastTitle>{t.title}</ToastTitle>
                {t.description ? (
                  <ToastDescription>{t.description}</ToastDescription>
                ) : null}
              </div>
            </FlexRow>
          </ToastRoot>
        ))}
        <ToastViewport />
      </ToastProvider>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
  height: calc(100%);
  font-size: 12px;
  background: var(--background-secondary);
  height: 100%;
`;

export default TranscriptEditor;
