import { blackA, violet } from '@radix-ui/colors';
import { Trigger } from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

export const StyledDropdownTrigger = styled(Trigger)`
  all: unset;
  color: var(--text-primary);
  background-color: var(--background);
  border: 1px solid var(--border);
  border-radius: 4px;
  padding: 0px 12px;
  box-shadow: 0 2px 10px ${blackA.blackA7};
  font-size: 12px;
  height: 32px;
  gap: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: var(--hover);
  }
  // '&:focus': { boxShadow: '0 0 0 2px black' },
  ::placeholder {
    color: ${violet.violet9};
  }
`;

export const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
  font-weight: 400;
`;
