import { useQueryParams } from 'lib/hooks/use-query-params';
import { RootState } from 'lib/redux';
import { getLinesDict, getProjectDubLines } from 'lib/redux/selectors/lines';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useActiveLine = () => {
  const { getQueryParams } = useQueryParams();
  const { activeId } = getQueryParams(['activeId']);
  const linesDict = useSelector(getLinesDict);
  const activeLine = linesDict[activeId || ''] || null;

  return { activeLine };
};

// TODO can this be optimized?
export const useActiveLineIndex = ({
  projectDubId,
}: {
  projectDubId: string;
}) => {
  const { activeLine } = useActiveLine();
  const lines = useSelector((state: RootState) =>
    getProjectDubLines(projectDubId)(state),
  );

  const activeIndex = useMemo(
    () => lines.findIndex((line) => line.id === activeLine?.id),
    [activeLine?.id, lines],
  );

  return { activeIndex };
};
