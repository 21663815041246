import { CaptionType } from 'lib/Types';
import api from 'lib/api';
import { upsertCaptions } from 'lib/redux/slices/captions';
import { getComputedColor } from 'lib/utils/utils';
import { CAPTIONBAR_HEIGHT } from 'modules/editor/constants';
import { KonvaSpinner } from 'modules/shared/konva/konva-spinner';
import { useEffect, useRef, useState } from 'react';
import { Group, Rect, Text, Transformer } from 'react-konva';
import { useDispatch } from 'react-redux';

type Props = {
  caption: CaptionType;
  defaultPxPerSec: number;
  stageScale: number;
  rowHeight: number;
};

export const CaptionBar = ({
  caption,
  defaultPxPerSec,
  stageScale,
  rowHeight,
}: Props) => {
  const dispatch = useDispatch();
  const transformerRef = useRef<any>(null);
  const groupRef = useRef<any>(null);
  const textRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (transformerRef.current && groupRef.current) {
      transformerRef.current.nodes([groupRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [transformerRef?.current]);

  if (!caption) return null;

  const captionWidth =
    (caption.end_time - caption.start_time) * defaultPxPerSec * stageScale;
  const captionBlockPadding = 5;
  const captionX = caption.start_time * defaultPxPerSec * stageScale;

  const handleTransform = () => {
    // Prevent text stretching
    const group = groupRef.current;
    const text = textRef.current;
    const newWidth = group.width() * group.scaleX();
    text.setAttrs({
      scaleX: 1 / group.scaleX(),
      scaleY: 1 / group.scaleY(),
      width: newWidth,
      height: group.height() * group.scaleY(),
    });
  };

  const handleTransformEnd = async () => {
    try {
      setLoading(true);
      const group = groupRef.current;
      const { data } = await api.captions.update(caption.id, {
        start_time: group.x() / defaultPxPerSec / stageScale,
        end_time:
          (group.x() + group.width() * group.scaleX()) /
          defaultPxPerSec /
          stageScale,
      });
      group.scaleX(1);
      dispatch(upsertCaptions([data.caption]));
    } catch (error) {
      alert('Error updating caption duration');
    } finally {
      setLoading(false);
    }
  };

  const handleOnDragEnd = async () => {
    try {
      setLoading(true);
      const group = groupRef.current;
      const { data } = await api.captions.update(caption.id, {
        start_time: group.x() / defaultPxPerSec / stageScale,
        end_time:
          (group.x() + group.width() * group.scaleX()) /
          defaultPxPerSec /
          stageScale,
      });
      dispatch(upsertCaptions([data.caption]));
    } catch (error) {
      alert('Error updating caption position');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Group
        ref={groupRef}
        x={captionX}
        // Offset by marker height
        y={rowHeight}
        width={captionWidth}
        height={CAPTIONBAR_HEIGHT}
        onTransform={handleTransform}
        onTransformEnd={handleTransformEnd}
        draggable
        dragBoundFunc={(pos) => ({
          x: pos.x,
          y: rowHeight, // Keep y constant during drag
        })}
        onDragEnd={handleOnDragEnd}
      >
        <Rect
          fill={getComputedColor('--purple')}
          x={0}
          y={captionBlockPadding}
          height={CAPTIONBAR_HEIGHT - captionBlockPadding * 2}
          width={captionWidth}
          cornerRadius={4}
          strokeWidth={1}
        />
        {loading ? (
          // TODO: this doesn't rotate and is ugly
          <KonvaSpinner
            x={captionWidth / 2 + captionX}
            y={CAPTIONBAR_HEIGHT / 2}
          />
        ) : (
          <Text
            ref={textRef}
            text="Caption"
            x={5}
            y={0}
            height={CAPTIONBAR_HEIGHT}
            fill={getComputedColor('--text-secondary')}
            verticalAlign="middle"
          />
        )}
      </Group>
      <Transformer
        ref={transformerRef}
        resizeEnabled={!loading}
        rotateEnabled={false}
        enabledAnchors={['middle-left', 'middle-right']}
        boundBoxFunc={(oldBox, newBox) => {
          newBox.height = oldBox.height;
          return newBox;
        }}
        borderStrokeWidth={0}
        anchorStyleFunc={(anchor) => {
          anchor.height(rowHeight);
          anchor.offsetY(rowHeight / 2);
          anchor.width(10);
          anchor.stroke('transparent');
          anchor.fill('transparent');
        }}
        keepRatio={false}
      />
    </>
  );
};
