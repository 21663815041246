import {
  EffectSourceParam,
  TimelineAction,
  TimelineEffect,
} from 'lib/engine/engine';
import { useMemo, useRef } from 'react';
import ReactPlayer from 'react-player';

interface ESP extends EffectSourceParam {
  action: TimelineAction;
}

export const useTimelineEffects = () => {
  const refs = useRef<Record<string, ReactPlayer | null>>({});

  const handleStart = ({
    action, // engine,
    engine,
    time, // time,
  }: ESP) => {
    if (!engine.isPlaying) return;
    // console.log('start', action);
    refs.current[action.id]?.seekTo(
      time - action.start + (action.trim_start || 0),
      'seconds',
    );
    refs.current[action.id]?.getInternalPlayer()?.play();
  };

  const handleEnd = ({ action }: EffectSourceParam) => {
    // console.log('stop', action);
    refs.current[action.id]?.getInternalPlayer()?.pause();
  };

  const handleTimeUpdate = ({ action, time }: ESP) => {
    // console.log('pause', action);
    refs.current[action.id]?.seekTo(
      time - action.start + (action.trim_start || 0),
      'seconds',
    );
  };

  const effects: Record<string, TimelineEffect> = useMemo(
    () => ({
      effect0: {
        id: 'effect0',
        name: 'Disabled',
      },
      effect1: {
        id: 'effect1',
        name: 'Line',
        source: {
          start: handleStart as (param: EffectSourceParam) => void,
          enter: handleStart as (param: EffectSourceParam) => void,
          timeUpdate: handleTimeUpdate as (param: EffectSourceParam) => void,
          leave: handleEnd,
          stop: handleEnd,
        },
      },
    }),
    [],
  );

  return { refs, effects };
};
