import { ProjectDubType, VoiceEngineType } from 'lib/Types';
import axios from './axios';

export enum AdaptationRegenerationType {
  REROLL = 'reroll',
  LONGER = 'longer',
  SHORTER = 'shorter',
}

export const ProjectDubs = {
  create: (data: {
    project_id: string;
    lang: string;
    pivot_project_dub_id?: string;
    voice_engine?: VoiceEngineType;
  }) => axios.post('/project_dubs', data),
  getById: (id: string) => axios.get(`/project_dubs/${id}`),
  update: (id: string, data: Partial<ProjectDubType>) =>
    axios.put(`/project_dubs/${id}`, data),
  dubMissingLines: (id: string) =>
    axios.post(`/project_dubs/${id}/dub/missing`),
  regenerateAdmin: (id: string) =>
    axios.post(`/project_dubs/${id}/dub/regenerate`),
  exportAudio: (
    id: string,
    data: {
      format: 'wav' | 'mp3';
      trim_start?: number;
      trim_end?: number;
    },
  ) => axios.post(`/project_dubs/${id}/export/audio`, data),
  exportVideo: (
    id: string,
    data: {
      format: 'mp4';
      trim_start?: number;
      trim_end?: number;
      canvas_recording?: Blob;
    },
  ) => {
    const formData = new FormData();
    formData.append('format', data.format);
    if (data.trim_start) {
      formData.append('trim_start', data.trim_start.toString());
    }
    if (data.trim_end) {
      formData.append('trim_end', data.trim_end.toString());
    }
    if (data.canvas_recording) {
      formData.append('canvas_recording', data.canvas_recording);
    }
    return axios.post(`/project_dubs/${id}/export/video`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  exportCaptions: (
    id: string,
    data: {
      format: string;
      file: Blob;
    },
  ) => {
    const formData = new FormData();
    formData.append('file', data.file, `video.${data.format}`);
    return axios.post(`/project_dubs/${id}/export/captions`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  exportTimeline: (id: string) =>
    axios.post(`/project_dubs/${id}/export/timeline`),
  exportSrt: (id: string) =>
    axios.post(`/project_dubs/${id}/export/srt`, {}, { responseType: 'blob' }),
  // Use for happy scribe transcriptions and translations
  downloadTranscript: (id: string) =>
    axios.post(
      `/project_dubs/${id}/download/transcript`,
      {},
      {
        responseType: 'blob',
      },
    ),
};
