import axios from './axios';

export const Characters = {
  create: (data: { project_dub_id: string }) => axios.post('/characters', data),
  update: (
    id: string,
    data: {
      name?: string;
      is_disabled?: boolean;
    },
  ) => axios.put(`/characters/${id}`, data),
  delete: (id: string) => axios.delete(`/characters/${id}`),
  updateElVoice: (id: string, data: { voice_data_id: string }) =>
    axios.put(`/characters/${id}/voice`, data),
  updateVoiceData: (id: string, data: { line_ids: string[] }) =>
    axios.put(`/characters/${id}/voice_data`, data),
};
