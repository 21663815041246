import React from 'react';
import * as AlertDialogRadix from '@radix-ui/react-alert-dialog';
import { styled, keyframes } from '@stitches/react';
import { violet, blackA, red, mauve } from '@radix-ui/colors';

type Props = {
  title: string;
  onConfirm: React.MouseEventHandler;
  description?: string;
  confirmTitle?: React.ReactNode;
  children?: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  isLoading?: boolean;
};

export const AlertDialog = ({
  title,
  onConfirm,
  description,
  confirmTitle,
  children,
  open,
  onOpenChange,
  isLoading,
}: Props) => (
  <AlertDialogRadix.Root open={open} onOpenChange={onOpenChange}>
    <AlertDialogRadix.Trigger asChild>{children}</AlertDialogRadix.Trigger>
    <AlertDialogRadix.Portal>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogTitle>{title}</AlertDialogTitle>
        {description ? (
          <AlertDialogDescription>{description}</AlertDialogDescription>
        ) : null}
        <Flex css={{ justifyContent: 'flex-end' }}>
          <AlertDialogRadix.Cancel asChild disabled={isLoading}>
            <Button variant="mauve" css={{ marginRight: 25 }}>
              Cancel
            </Button>
          </AlertDialogRadix.Cancel>
          <AlertDialogRadix.Action asChild onClick={onConfirm}>
            <Button variant="red">{confirmTitle || 'Confirm'}</Button>
          </AlertDialogRadix.Action>
        </Flex>
      </AlertDialogContent>
    </AlertDialogRadix.Portal>
  </AlertDialogRadix.Root>
);

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const AlertDialogOverlay = styled(AlertDialogRadix.Overlay, {
  backgroundColor: blackA.blackA9,
  position: 'fixed',
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

export const AlertDialogContent = styled(AlertDialogRadix.Content, {
  backgroundColor: 'var(--background)',
  border: '1px solid var(--border)',
  borderRadius: 6,
  // boxShadow:
  //   'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: '500px',
  maxHeight: '85vh',
  padding: 25,
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  '&:focus': { outline: 'none' },
});

export const AlertDialogTitle = styled(AlertDialogRadix.Title, {
  margin: 0,
  color: 'var(--text-primary)',
  fontSize: 15,
  fontWeight: 500,
});

export const AlertDialogDescription = styled(AlertDialogRadix.Description, {
  marginBottom: 20,
  color: 'var(--text-secondary)',
  fontSize: 13,
  lineHeight: 1.5,
  marginTop: 8,
});

export const Flex = styled('div', { display: 'flex' });

export const Button = styled('button', {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  padding: '0 15px',
  fontSize: 13,
  lineHeight: 1,
  fontWeight: 500,
  height: 35,

  variants: {
    variant: {
      violet: {
        backgroundColor: 'white',
        color: violet.violet11,
        boxShadow: `0 2px 10px ${blackA.blackA7}`,
        '&:hover': { backgroundColor: mauve.mauve3 },
        '&:focus': { boxShadow: '0 0 0 2px black' },
      },
      red: {
        backgroundColor: red.red4,
        color: red.red11,
        '&:hover': { backgroundColor: red.red5 },
        '&:focus': { boxShadow: `0 0 0 2px ${red.red7}` },
      },
      mauve: {
        backgroundColor: mauve.mauve4,
        color: mauve.mauve11,
        '&:hover': { backgroundColor: mauve.mauve5 },
        '&:focus': { boxShadow: `0 0 0 2px ${mauve.mauve7}` },
      },
    },
  },

  defaultVariants: {
    variant: 'violet',
  },
});
