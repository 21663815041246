import { TimelineAction } from 'lib/engine/engine';
import { getComputedColor } from 'lib/utils/utils';
import { useActiveLine } from 'modules/editor/hooks/use-active-line';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Group, Rect, Transformer } from 'react-konva';
import { ColorInfo, DragInfo } from './consts';
import { KonvaSpinner } from './konva-spinner';
import { KonvaWaveform } from './konva-waveform';
import { blendColorsWithBg } from './lib/opacity';
import { WorkerTask } from './hooks/use-worker-pool';

type Props = {
  action: TimelineAction;
  stageScale: number;
  defaultPxPerSec: number;
  rowHeight: number;
  rowTop: number;
  lineTopPaddingPx: number;
  stageX: number;
  stageY: number;
  dimensions: { width: number; height: number };
  color: ColorInfo;
  handleDragEnd: (info: DragInfo) => void;
  handleTransformEnd: (info: DragInfo) => void;
  handleTransform?: (info: DragInfo) => void;
  handleClick: (actionId: string) => void;
  enqueueTask: (task: WorkerTask) => void;
};

export const KonvaAction = ({
  action,
  stageScale,
  defaultPxPerSec,
  rowHeight,
  rowTop,
  lineTopPaddingPx,
  stageX,
  stageY,
  dimensions,
  color,
  handleDragEnd,
  handleTransformEnd,
  handleTransform,
  handleClick,
  enqueueTask,
}: Props) => {
  const groupRef = useRef<any>();
  const transformerRef = useRef<any>();
  const { activeLine } = useActiveLine();
  const wordbarHeight = 0;

  // Compute colors if disabled
  const disabledBorder = useMemo(
    () => blendColorsWithBg(color.border, 0.3),
    [color.border],
  );
  const disabledWaveform = useMemo(
    () => blendColorsWithBg(color.waveform, 0.3),
    [color.waveform],
  );
  const disabledActiveFill = useMemo(
    () => blendColorsWithBg(color.activeFill, 0.3),
    [color.activeFill],
  );
  const disabledInactiveFill = useMemo(
    () => blendColorsWithBg(color.inactiveFill, 0.3),
    [color.inactiveFill],
  );
  const getFillColor = (
    isActive: boolean,
    colors: ColorInfo,
    isDisabled?: boolean,
  ) => {
    if (isActive) {
      return isDisabled ? disabledActiveFill : colors.activeFill;
    }
    return isDisabled ? disabledInactiveFill : colors.inactiveFill;
  };

  const shiftKeyHeldDuringTransform = useRef(false);

  // Check if shift key was held down during transform
  const [shiftBeingPressed, setShiftBeingPressed] = useState(false);

  const actionWidth =
    (action.end - action.start) * defaultPxPerSec * stageScale;
  const clipRect = {
    x: action.start * defaultPxPerSec * stageScale,
    y: rowTop,
    width: actionWidth,
    height: rowHeight,
  };

  // Check that action.trim_start exists and that action.duration exists and > 0
  const trimStartFraction =
    action.trim_start !== undefined && action.duration
      ? action.trim_start / action.duration
      : 0;
  const trimEndFraction =
    action.trim_start !== undefined && action.duration
      ? (action.end - action.start + action.trim_start) / action.duration
      : 1;

  useEffect(() => {
    if (transformerRef.current && groupRef.current) {
      transformerRef.current.nodes([groupRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [transformerRef?.current]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setShiftBeingPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setShiftBeingPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // Which sections of the clip you can see (by percentage)
  const visibleStart =
    stageX + clipRect.x >= 0 ? 0 : (-stageX - clipRect.x) / clipRect.width;
  const visibleEnd =
    stageX + clipRect.x + clipRect.width <= dimensions.width
      ? 1
      : (dimensions.width - stageX - clipRect.x) / clipRect.width;

  const visibleX = actionWidth * visibleStart;
  const isActive = activeLine?.id === action.id;

  return (
    <>
      <Group
        id={action.id}
        ref={groupRef}
        key={action.id}
        x={clipRect.x}
        y={lineTopPaddingPx}
        width={actionWidth}
        height={rowHeight - lineTopPaddingPx * 2}
        draggable={action.movable}
        dragBoundFunc={(pos) => ({
          x: pos.x,
          y: rowTop + lineTopPaddingPx + stageY, // Keep y constant during drag
        })}
        onClick={() => handleClick(action.id)}
        onDragEnd={(e) => {
          const start = e.target.attrs.x / stageScale / defaultPxPerSec;
          const end =
            start +
            Math.min(e.target.attrs.width, actionWidth) /
              stageScale /
              defaultPxPerSec;
          handleDragEnd({ id: action.id, start, end });
        }}
        onTransform={() => {
          if (!handleTransform) return;
          handleTransform({
            id: action.id,
            node: groupRef.current,
            heldShift: shiftKeyHeldDuringTransform.current,
          });
        }}
        onTransformStart={() => {
          // User does not have to hold shift during entire transformation, only the start
          shiftKeyHeldDuringTransform.current = shiftBeingPressed;
        }}
        onTransformEnd={() => {
          handleTransformEnd({
            id: action.id,
            node: groupRef.current,
            heldShift: shiftKeyHeldDuringTransform.current,
          });
        }}
      >
        <Rect
          key={action.id}
          width={actionWidth}
          height={rowHeight - lineTopPaddingPx * 2}
          fill={getFillColor(isActive, color, action.disable)}
          stroke={
            action.disable ? disabledBorder : getComputedColor(color.border)
          }
          strokeWidth={1}
          cornerRadius={2}
          dash={action.src ? undefined : [3, 3]}
        />
        {action?.uiLoading ? (
          <KonvaSpinner
            x={actionWidth / 2}
            y={(rowHeight - lineTopPaddingPx * 2) / 2}
            maxRadius={(actionWidth - 5) / 2}
          />
        ) : action?.src ? (
          <KonvaWaveform
            url={action.src.replace(
              'https://deeptune-editor.s3.amazonaws.com',
              'https://d3k0hwsi4cp4qn.cloudfront.net',
            )}
            width={actionWidth}
            height={rowHeight - lineTopPaddingPx * 2 - wordbarHeight}
            color={
              action.disable
                ? disabledWaveform
                : getComputedColor(color.waveform)
            }
            x={visibleX}
            y={wordbarHeight}
            visibleStart={visibleStart}
            visibleEnd={visibleEnd}
            trimStart={trimStartFraction}
            trimEnd={trimEndFraction}
            enqueueTask={enqueueTask}
          />
        ) : null}
      </Group>
      {clipRect.width > 10 ? (
        <Transformer
          ref={transformerRef}
          resizeEnabled={action.flexible}
          rotateEnabled={false}
          enabledAnchors={['middle-left', 'middle-right']}
          boundBoxFunc={(oldBox, newBox) => {
            newBox.height = oldBox.height;
            return newBox;
          }}
          borderStrokeWidth={0}
          anchorStyleFunc={(anchor) => {
            anchor.height(rowHeight);
            anchor.offsetY(rowHeight / 2);
            anchor.width(10);
            anchor.stroke('transparent');
            anchor.fill('transparent');
          }}
          keepRatio={false}
        />
      ) : null}
    </>
  );
};
