import { useQueryParams } from 'lib/hooks/use-query-params';
import { RootState } from 'lib/redux';
import { getProjectDubLines } from 'lib/redux/selectors/lines';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useActiveLineIndex } from './use-active-line';

export const useLineNav = () => {
  const { updateQueryParams } = useQueryParams();
  const { id } = useParams();
  const lines = useSelector((state: RootState) =>
    getProjectDubLines(id || '')(state),
  );
  const { activeIndex } = useActiveLineIndex({
    projectDubId: id || '',
  });

  // Memoize the map of line IDs to indexes for quick lookup
  const lineIndexMap = useMemo(
    () => new Map(lines.map((line, index) => [line.id, index])),
    [lines],
  );

  const handleNextLine = (lineId?: string) => {
    // Use activeIndex if no lineId is provided
    const currentIndex = lineId ? lineIndexMap.get(lineId) : activeIndex;
    if (currentIndex === undefined) return;

    const newId = lines[Math.min(lines.length - 1, currentIndex + 1)]?.id;
    updateQueryParams({ activeId: newId || null });
  };

  const handlePrevLine = (lineId?: string) => {
    // Use activeIndex if no lineId is provided
    const currentIndex = lineId ? lineIndexMap.get(lineId) : activeIndex;
    if (currentIndex === undefined) return;

    const newId = lines[Math.max(0, currentIndex - 1)]?.id;
    updateQueryParams({ activeId: newId || null });
  };

  return { handleNextLine, handlePrevLine };
};
