import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExportJobType } from 'lib/Types';

export type ExportJobReduxType = { [id: string]: ExportJobType };
const initialState = {} as ExportJobReduxType;

export const exportJobsSlice = createSlice({
  name: 'exportJobs',
  initialState,
  reducers: {
    upsertExportJobs: (
      state: ExportJobReduxType,
      action: PayloadAction<({ id: string } & Partial<ExportJobType>)[]>,
    ) => {
      action.payload.forEach((exportJob) => {
        state[exportJob.id] = {
          ...(state[exportJob.id] || {}),
          ...exportJob,
        };
      });
      return state;
    },
    resetExportJobs: () => initialState,
  },
});

export const { upsertExportJobs, resetExportJobs } = exportJobsSlice.actions;
