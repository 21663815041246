import type { Events } from './events';

/**
 * Event emitter
 */
export class Emitter<EventTypes> {
  private events: Map<keyof EventTypes, Set<CallableFunction>> = new Map();

  constructor(events: Events) {
    Object.keys(events.handlers).forEach((key) => {
      this.events.set(key as keyof EventTypes, new Set());
    });
  }

  on<K extends keyof EventTypes>(
    name: K,
    handler: (args: EventTypes[K]) => boolean | unknown,
  ): CallableFunction {
    const handlers = this.events.get(name);
    if (!handlers) {
      throw new Error(`The event ${String(name)} does not exist`);
    }
    handlers.add(handler);
    return handler;
  }

  trigger<K extends keyof EventTypes>(name: K, params: EventTypes[K]) {
    const handlers = this.events.get(name);
    if (!handlers) {
      throw new Error(`The event ${String(name)} cannot be triggered`);
    }
    return [...handlers].reduce(
      (r: boolean, e: CallableFunction) => e(params) !== false && r,
      true,
    ); // return false if at least one event is false
  }

  bind(name: keyof EventTypes) {
    if (this.events.has(name)) {
      throw new Error(`The event ${String(name)} is already bound`);
    }
    this.events.set(name, new Set());
  }

  exist(name: keyof EventTypes) {
    return this.events.has(name);
  }

  off(handler: CallableFunction) {
    this.events.forEach((handlers) => {
      handlers.delete(handler);
    });
  }

  offAll() {
    this.events.forEach((handlers) => {
      handlers.clear();
    });
  }
}
