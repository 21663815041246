import { TimelineAction } from 'lib/engine/engine';
import { useLineApi } from 'lib/hooks/api/use-line-api';
import { useQueryParams } from 'lib/hooks/use-query-params';
import { DragInfo, TimelineColorsEnum } from 'modules/shared/konva/consts';
import { WorkerTask } from 'modules/shared/konva/hooks/use-worker-pool';
import { KonvaAction } from 'modules/shared/konva/konva-action';

type Props = {
  action: TimelineAction;
  stageScale: number;
  defaultPxPerSec: number;
  rowHeight: number;
  rowTop: number;
  lineTopPaddingPx: number;
  stageX: number;
  stageY: number;
  dimensions: { width: number; height: number };
  enqueueTask: (task: WorkerTask) => void;
};

export const LineAction = ({
  action,
  stageScale,
  defaultPxPerSec,
  rowHeight,
  rowTop,
  lineTopPaddingPx,
  stageX,
  stageY,
  dimensions,
  enqueueTask,
}: Props) => {
  const { updateQueryParams } = useQueryParams();
  const { handleUpdateLine } = useLineApi();

  const handleDragEnd = ({ id, start, end }: DragInfo) =>
    handleUpdateLine({ id, start, end });
  const handleTransformEnd = ({ id, node }: DragInfo) => {
    if (!node) return;
    const newWidth = node.width() * node.scaleX();
    node.width(newWidth);
    node.scaleX(1);
    const newStart = node.x() / stageScale / defaultPxPerSec;
    const newEnd = newStart + newWidth / stageScale / defaultPxPerSec;

    handleUpdateLine({ id, start: newStart, end: newEnd, do_warp: true });
  };
  const onClick = () => updateQueryParams({ activeId: action.id });
  const color = {
    activeFill: TimelineColorsEnum.ACTIVE_FILL,
    inactiveFill: TimelineColorsEnum.FILL,
    border: action.color || TimelineColorsEnum.DEFAULT_BORDER,
    waveform: action.color || TimelineColorsEnum.DEFAULT_WAVEFORM,
  };

  return (
    <KonvaAction
      action={action}
      stageScale={stageScale}
      defaultPxPerSec={defaultPxPerSec}
      rowHeight={rowHeight}
      rowTop={rowTop}
      lineTopPaddingPx={lineTopPaddingPx}
      stageX={stageX}
      stageY={stageY}
      dimensions={dimensions}
      color={color}
      handleDragEnd={handleDragEnd}
      handleTransformEnd={handleTransformEnd}
      handleClick={onClick}
      enqueueTask={enqueueTask}
    />
  );
};
