import { useQueryParams } from 'lib/hooks/use-query-params';
import { RootState } from 'lib/redux';
import {
  getProjectTranscriptLines,
  getTranscriptLinesDict,
} from 'lib/redux/selectors/transcriptLines';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useActiveTranscriptLine = () => {
  const { getQueryParams } = useQueryParams();
  const { activeId } = getQueryParams(['activeId']);
  const transcriptLinesDict = useSelector(getTranscriptLinesDict);
  const activeTranscriptLine = transcriptLinesDict[activeId || ''] || null;

  return { activeTranscriptLine };
};

// TODO can this be optimized?
export const useActiveTranscriptLineIndex = ({
  projectId,
}: {
  projectId: string;
}) => {
  const { activeTranscriptLine } = useActiveTranscriptLine();
  const transcriptLines = useSelector((state: RootState) =>
    getProjectTranscriptLines(projectId)(state),
  );

  const activeIndex = useMemo(
    () =>
      transcriptLines.findIndex(
        (transcriptLine) => transcriptLine.id === activeTranscriptLine?.id,
      ),
    [activeTranscriptLine?.id, transcriptLines],
  );

  return { activeIndex };
};
