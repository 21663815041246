import { ProjectDubType, ProjectType } from 'lib/Types';
import { TimelineEngine } from 'lib/engine/engine';
import { getCaptionByProjectDubId } from 'lib/redux/selectors/captions';
import { getProjectDubLines } from 'lib/redux/selectors/lines';
import { getComputedColor } from 'lib/utils/utils';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTimelinePlayer } from 'modules/editor/hooks/use-timeline-player';
import { Image, Layer, Line, Stage } from 'react-konva';
import { TailSpin } from 'react-loader-spinner';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { SelectableCaption } from './components/selectable-captions';
import { CaptionPopover } from './components/caption-popover';

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
  timelineEngine: MutableRefObject<TimelineEngine>;
};

export const CaptionsEditor = ({
  project,
  projectDub,
  timelineEngine,
}: Props) => {
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null,
  );
  const layerRef = useRef<any>(null);
  const animationRef = useRef(0); // Ref to store requestAnimationFrame ID
  // Captions
  const caption = useSelector(getCaptionByProjectDubId(projectDub.id));
  const lines = useSelector(getProjectDubLines(projectDub.id));
  // Guidelines
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [guidelines, setGuidelines] = useState<{
    x: number | null;
    y: number | null;
  }>({ x: null, y: null });
  const [selectedLayerIds, setSelectedLayerIds] = useState<string[]>([]);
  const containerRef = useRef<any>();
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [playerReady, setPlayerReady] = useState(false);
  const { playerRef, isPlaying, onDuration } = useTimelinePlayer({
    timelineEngine,
  });
  const videoAspectRatio =
    project.video_width && project.video_height
      ? project.video_width / project.video_height
      : 16 / 9;

  // Paint initial frame
  useEffect(() => {
    if (!playerReady || !playerRef.current || !layerRef.current) return;
    const vidPlayer = playerRef.current.getInternalPlayer();
    if (!vidPlayer) return;

    if (vidPlayer && vidPlayer instanceof HTMLVideoElement) {
      setVideoElement(vidPlayer);
      // TODO: this is jank
      setTimeout(() => {
        layerRef.current.batchDraw();
      }, 500); // Delay to allow video to load
    }
  }, [playerReady, playerRef.current, layerRef.current]);

  useEffect(() => {
    if (!videoElement) return;

    const updateFrame = () => {
      if (layerRef.current) layerRef.current.batchDraw();
      animationRef.current = requestAnimationFrame(updateFrame);
    };

    // TODO: switch to timelineEngine
    const handlePlay = () => {
      animationRef.current = requestAnimationFrame(updateFrame);
    };

    const handlePause = () => {
      cancelAnimationFrame(animationRef.current);
    };

    const handleEnded = () => {
      cancelAnimationFrame(animationRef.current);
    };

    const setTimeHandler = timelineEngine.current.on('afterSetTime', () => {
      updateFrame();
    });

    if (videoElement) {
      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('pause', handlePause);
      videoElement.addEventListener('ended', handleEnded);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', handlePlay);
        videoElement.removeEventListener('pause', handlePause);
        videoElement.removeEventListener('ended', handleEnded);
      }

      timelineEngine.current.off(setTimeHandler);
    };
  }, [videoElement, layerRef.current]);

  useEffect(() => {
    const observeTarget = containerRef.current;
    if (observeTarget) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (!entries || entries.length === 0) {
          return;
        }
        const { width, height } = entries[0].contentRect;
        // setDimensions({ width, height });
        const bbAspectRatio = width / height;
        if (bbAspectRatio > videoAspectRatio) {
          // Bounding box is wider than the video, letterboxing
          setDimensions({
            width: height * videoAspectRatio,
            height,
          });
        } else {
          // Bounding box is taller than the video, pillarboxing
          setDimensions({
            width,
            height: width / videoAspectRatio,
          });
        }
      });
      resizeObserver.observe(observeTarget);
      return () => resizeObserver.unobserve(observeTarget);
    }
  }, []);

  if (!caption) {
    return <TailSpin />;
  }

  return (
    <div
      ref={containerRef as any}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: dimensions.width,
          height: dimensions.height,
          position: 'relative',
        }}
      >
        <ReactPlayer
          ref={playerRef}
          url={project.hls_video_src || project.video_src}
          width={0}
          height={0}
          progressInterval={10}
          playing={isPlaying}
          loop={false}
          onDuration={onDuration}
          muted
          onReady={() => setPlayerReady(true)}
          config={{ file: { attributes: { crossOrigin: 'anonymous' } } }}
        />
        <Stage
          width={dimensions.width}
          height={dimensions.height}
          onMouseDown={() => {
            setSelectedLayerIds([]);
          }}
        >
          <Layer ref={layerRef}>
            {videoElement && (
              <Image
                image={videoElement}
                x={0}
                y={0}
                width={dimensions.width}
                height={dimensions.height}
              />
            )}
            <SelectableCaption
              stageDims={dimensions}
              caption={caption}
              lines={lines}
              timelineEngine={timelineEngine}
              project={project}
              selectedLayerIds={selectedLayerIds}
              setShowGuidelines={setShowGuidelines}
              setGuidelines={setGuidelines}
              setSelectedLayerIds={setSelectedLayerIds}
            />
            {showGuidelines && (
              <>
                {guidelines.x !== null && (
                  <Line
                    points={[guidelines.x, 0, guidelines.x, dimensions.height]}
                    stroke={getComputedColor('--blue')}
                    strokeWidth={1}
                    dash={[4, 4]}
                  />
                )}
                {guidelines.y !== null && (
                  <Line
                    points={[0, guidelines.y, dimensions.width, guidelines.y]}
                    stroke={getComputedColor('--blue')}
                    strokeWidth={1}
                    dash={[4, 4]}
                  />
                )}
              </>
            )}
          </Layer>
        </Stage>
        {selectedLayerIds.includes(caption.id) && (
          <CaptionPopover
            caption={caption}
            position={{
              x: caption.left_pct * dimensions.width,
              y: caption.top_pct * dimensions.height,
            }}
            dimensions={{
              width: (caption.right_pct - caption.left_pct) * dimensions.width,
              height:
                (caption.bottom_pct - caption.top_pct) * dimensions.height,
            }}
            onClose={() => {}}
          />
        )}
      </div>
    </div>
  );
};
