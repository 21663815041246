import { ProjectDubType, ProjectType } from 'lib/Types';
import { getProjectSettingsOpen } from 'lib/redux/selectors/editor';
import { setProjectSettingsOpen } from 'lib/redux/slices/editor';
import { Dialog, DialogContent } from 'modules/radix/Dialog';
import { MaterialIcon } from 'modules/shared/mui-icons';
import { SidebarRow } from 'modules/shared/sidebar-modal/sidebar-row';
import {
  SidebarContainer,
  SidebarLabel,
} from 'modules/shared/sidebar-modal/ui';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IconUsers } from '@tabler/icons-react';
import { CharacterPicker } from './components/character-picker';
import { ManagePermissions } from './components/manage-permissions';

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
};

export const ProjectSettingsModal = ({ project, projectDub }: Props) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getProjectSettingsOpen);
  const [tab, setTab] = useState<'speakers' | 'share'>('speakers');

  const getBody = () => {
    switch (tab) {
      case 'speakers':
        return <CharacterPicker projectDub={projectDub} />;
      case 'share':
        return <ManagePermissions projectId={project.id} />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => dispatch(setProjectSettingsOpen(false))}
    >
      <DialogContent
        style={{
          maxWidth: 1100,
          padding: 0,
          maxHeight: 700,
          height: '100%',
          background: 'var(--background)',
        }}
      >
        <Container>
          <SidebarContainer>
            <div>
              <SidebarLabel style={{ marginTop: 8 }}>
                Project Settings
              </SidebarLabel>
              <SidebarRow
                title="Speakers"
                icon={<MaterialIcon icon="record_voice_over" />}
                onClick={() => setTab('speakers')}
                isActive={tab === 'speakers'}
              />
              <SidebarRow
                title="Members"
                icon={<IconUsers />}
                onClick={() => setTab('share')}
                isActive={tab === 'share'}
              />
            </div>
          </SidebarContainer>
          {getBody()}
        </Container>
      </DialogContent>
    </Dialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;
