import { ProjectDubType, ProjectType } from 'lib/Types';
import { TimelineRow } from 'lib/engine/engine';
import { RootState } from 'lib/redux';
import { getProjectDubCharacters } from 'lib/redux/selectors/characters';
import { getProjectDubClipTracks } from 'lib/redux/selectors/clipTracks';
import { getProjectDubClips } from 'lib/redux/selectors/clips';
import {
  getSoloRowIds,
  getDisabledRowIds,
  getOriginalMuted,
  getMFXMuted,
} from 'lib/redux/selectors/editor';
import { getAllProjectDubLines } from 'lib/redux/selectors/lines';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  project: ProjectType;
  projectDub: ProjectDubType;
};

type RowIsDisabledInputs = {
  id: string;
  soloRowIds: { [id: string]: boolean };
  isOriginalMuted: boolean;
  isMFXMuted: boolean;
  disabledRowIds: { [id: string]: boolean };
};

export const getRowIsDisabled = ({
  id,
  soloRowIds,
  isOriginalMuted,
  isMFXMuted,
  disabledRowIds,
}: RowIsDisabledInputs) => {
  const isSolo = soloRowIds[id];
  // If solo is active, disable all but soloed rows
  if (Object.keys(soloRowIds).length) {
    if (isSolo) return false;
    return true;
  }
  if (id === 'original') return isOriginalMuted;
  if (id === 'mfx') return isMFXMuted;
  return disabledRowIds[id];
};

export const ORIGINAL_ID = 'original';
export const MFX_ID = 'mfx';
export const DX_ID = 'dx';

export const useEditorData = ({ project, projectDub }: Props) => {
  const isOriginalMuted = useSelector(getOriginalMuted);
  const isMFXMuted = useSelector(getMFXMuted);

  const disabledRowIds = useSelector((state: RootState) =>
    getDisabledRowIds(projectDub.id)(state),
  );
  const soloRowIds = useSelector(getSoloRowIds);
  const characters = useSelector((state: RootState) =>
    getProjectDubCharacters(projectDub.id)(state),
  );
  const lines = useSelector((state: RootState) =>
    getAllProjectDubLines(projectDub.id)(state),
  );
  const clipTracks = useSelector((state: RootState) =>
    getProjectDubClipTracks(projectDub.id)(state),
  );
  const clips = useSelector((state: RootState) =>
    getProjectDubClips(projectDub.id)(state),
  );

  const data: TimelineRow[] = useMemo(() => {
    const temp: TimelineRow[] = [];
    // Add row for original audio
    temp.push({
      id: ORIGINAL_ID,
      name: 'Original',
      type: 'default',
      volume: 1,
      disabled: getRowIsDisabled({
        id: ORIGINAL_ID,
        soloRowIds,
        isOriginalMuted,
        isMFXMuted,
        disabledRowIds,
      }),
      actions: [
        {
          id: ORIGINAL_ID,
          start: 0,
          end: project?.duration || 10,
          effectId: 'effect1',
          flexible: false,
          src: project.audio_src,
          hls_src: project.hls_audio_src,
          type: 'stem',
          disable: getRowIsDisabled({
            id: ORIGINAL_ID,
            soloRowIds,
            isOriginalMuted,
            isMFXMuted,
            disabledRowIds,
          }),
        },
      ],
    });

    // Add row for mfx audio
    temp.push({
      id: MFX_ID,
      name: 'MFX',
      type: 'default',
      volume: 1,
      disabled: getRowIsDisabled({
        id: MFX_ID,
        soloRowIds,
        isOriginalMuted,
        isMFXMuted,
        disabledRowIds,
      }),
      actions: [
        {
          id: MFX_ID,
          start: 0,
          end: project?.duration || 10,
          effectId: 'effect1',
          flexible: false,
          movable: false,
          src: project.mfx_src,
          hls_src: project.hls_mfx_src,
          type: 'stem',
          disable: getRowIsDisabled({
            id: MFX_ID,
            soloRowIds,
            isOriginalMuted,
            isMFXMuted,
            disabledRowIds,
          }),
        },
      ],
    });

    const clipData: TimelineRow[] = clipTracks.map((c) => ({
      id: c.id,
      name: c.name,
      volume: 1,
      type: 'clipTrack',
      disabled: getRowIsDisabled({
        id: c.id,
        soloRowIds,
        isOriginalMuted,
        isMFXMuted,
        disabledRowIds,
      }),
      actions: clips
        .filter((l) => l.clip_track_id === c.id)
        .map((clip) => ({
          id: clip.id,
          start: clip.start_time,
          end: clip.end_time,
          trim_start: clip.trim_start,
          duration: clip.duration,
          effectId: 'effect1',
          src: clip?.src,
          hls_src: undefined,
          type: 'clip',
          movable: true,
          flexible: true,
          disable:
            clip.is_disabled ||
            getRowIsDisabled({
              id: c.id,
              soloRowIds,
              isOriginalMuted,
              isMFXMuted,
              disabledRowIds,
            }),
        })),
    }));
    temp.push(...(clipData as any[]));

    const characterData: TimelineRow[] = characters.map((c) => ({
      id: c.id,
      volume: 1,
      name: c.name,
      type: 'character',
      color: c.color,
      disabled: getRowIsDisabled({
        id: c.id,
        soloRowIds,
        isOriginalMuted,
        isMFXMuted,
        disabledRowIds,
      }),
      actions: lines
        .filter((l) => l.character_id === c.id)
        .map((line) => ({
          id: line.id,
          start: line.start,
          end: line.end,
          effectId: 'effect1',
          src: line?.src,
          hls_src: undefined,
          flexible: !line.approved_at && !line.uiLoading,
          movable: !line.approved_at && !line.uiLoading,
          uiLoading: line.uiLoading,
          type: 'line',
          disable:
            line.uiLoading ||
            line.is_disabled ||
            getRowIsDisabled({
              id: c.id,
              soloRowIds,
              isOriginalMuted,
              isMFXMuted,
              disabledRowIds,
            }),
          color: line.approved_at ? 'var(--green)' : c.color,
        })),
    }));
    temp.push(...(characterData as any[]));

    return temp;
  }, [
    disabledRowIds,
    isOriginalMuted,
    isMFXMuted,
    project,
    soloRowIds,
    lines,
    characters,
    clips,
    clipTracks,
  ]);

  return { data };
};
