import { createSelector } from '@reduxjs/toolkit';
import { memoize } from 'lodash';
import { RootState } from '..';

export const getClipTracksDict = (state: RootState) => state.clipTracks;
export const getClipTracks = createSelector(getClipTracksDict, (clipTracks) =>
  Object.values(clipTracks),
);
export const getProjectDubClipTracks = memoize((projectDubId: string) =>
  createSelector(getClipTracks, (clipTracks) =>
    clipTracks.filter((c) => c.project_dub_id === projectDubId),
  ),
);
