import $ from 'jquery';
import { TimelineEngine } from 'lib/engine/engine';
import { VoiceEngineType } from 'lib/Types';
import { useLineApi } from 'lib/hooks/api/use-line-api';
import {
  getEditorDuration,
  getEditorSeekTime,
  getPromptLineId,
} from 'lib/redux/selectors/editor';
import {
  setEditorSeekTime,
  setHistoryModalId,
  setPromptLineId,
  soloOrUnsoloRowId,
} from 'lib/redux/slices/editor';
import { getProjectDubById } from 'lib/redux/selectors/projectDubs';
import { useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useActiveLine } from './use-active-line';
import { useLineNav } from './use-line-nav';

type Props = {
  timelineEngine: React.MutableRefObject<TimelineEngine>;
  keepPlaybackOnPause?: boolean;
};

export const useEditorHotkeys = ({
  timelineEngine,
  keepPlaybackOnPause,
}: Props) => {
  const dispatch = useDispatch();
  const { handlePlayPause, handleToggleDub } = useEditorHandler({
    timelineEngine,
    keepPlaybackOnPause,
  });
  const {
    handleUpdateLine,
    handleInference,
    handleApproveLine,
    handleSplitLineAtPlayhead,
    handleUnwarpLine,
    handleMergeLine,
    handleRetranslateLine,
  } = useLineApi();
  const duration = useSelector(getEditorDuration);
  // Must use a ref to update duration inside useHotkeys
  const durationRef = useRef(duration);
  const { activeLine } = useActiveLine();
  const { handleNextLine, handlePrevLine } = useLineNav();
  const promptLineId = useSelector(getPromptLineId);
  const projectDub = useSelector(getProjectDubById(activeLine?.project_dub_id));
  useEffect(() => {
    durationRef.current = duration;
  }, [duration]);

  const checkFocused = () => {
    const dialog = $('[role="dialog"]');
    return !dialog.length;
  };

  useHotkeys(
    'space',
    (e) => {
      if (!checkFocused()) return;
      handlePlayPause();
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, handlePlayPause],
  );

  useHotkeys(
    'd',
    (e) => {
      if (!checkFocused()) return;
      handleToggleDub();
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, handleToggleDub],
  );

  useHotkeys(
    'mod+enter',
    (e) => {
      if (!checkFocused() || !activeLine.id) return;
      handleInference(
        activeLine,
        projectDub.voice_engine || VoiceEngineType.ELEVENLABS,
      );
      e.preventDefault();
      e.stopPropagation();
    },
    { enableOnFormTags: ['INPUT'], enableOnContentEditable: true },
    [checkFocused, activeLine, handleInference],
  );

  useHotkeys(
    'mod+p',
    (e) => {
      if (!checkFocused() || !activeLine) return;
      dispatch(setPromptLineId(promptLineId ? null : activeLine.id));
      e.preventDefault();
      e.stopPropagation();
    },
    { enableOnFormTags: ['INPUT'], enableOnContentEditable: true },
    [checkFocused],
  );

  useHotkeys(
    'a',
    (e) => {
      if (!checkFocused() || !activeLine || activeLine.approved_at) return;
      handleApproveLine(activeLine.id);
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, activeLine, handleApproveLine],
  );

  useHotkeys(
    's',
    (e) => {
      if (!checkFocused() || !activeLine.id) return;
      dispatch(
        soloOrUnsoloRowId({ id: activeLine.character_id, multi: false }),
      );
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, activeLine],
  );

  useHotkeys(
    'm',
    (e) => {
      if (!checkFocused() || !activeLine.id) return;
      handleUpdateLine({
        id: activeLine.id,
        is_disabled: !activeLine.is_disabled,
      });
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, activeLine, handleUpdateLine],
  );

  useHotkeys(
    'mod+left',
    (e) => {
      if (!checkFocused()) return;
      if (timelineEngine.current.isPlaying) {
        timelineEngine.current.pause();
      }

      timelineEngine.current.setTime(0);
      // timelineEngine.current.setScrollLeft(0);
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused],
  );

  useHotkeys(
    'mod+right',
    (e) => {
      if (!checkFocused()) return;
      if (timelineEngine.current.isPlaying) {
        timelineEngine.current.pause();
      }

      timelineEngine.current.setTime(durationRef.current);
      // timelineState.current.setScrollLeft(durationRef.current);
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused],
  );

  useHotkeys(
    'up,left',
    (e) => {
      if (!activeLine) return;
      handlePrevLine();
      e.preventDefault();
      e.stopPropagation();
    },
    [handlePrevLine, activeLine],
  );

  useHotkeys(
    'down,right',
    (e) => {
      if (!activeLine) return;
      handleNextLine();
      e.preventDefault();
      e.stopPropagation();
    },
    [handleNextLine, activeLine],
  );

  useHotkeys(
    'mod+k',
    (e) => {
      if (!checkFocused() || !activeLine) return;
      handleSplitLineAtPlayhead(activeLine);
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, activeLine, handleSplitLineAtPlayhead],
  );

  useHotkeys(
    'u',
    (e) => {
      if (!checkFocused() || !activeLine) return;
      handleUnwarpLine(activeLine.id);
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, activeLine, handleUnwarpLine],
  );

  useHotkeys(
    'mod+h',
    (e) => {
      if (!checkFocused() || !activeLine) return;
      dispatch(setHistoryModalId(activeLine.id));
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, activeLine],
  );

  useHotkeys(
    'mod+shift+j',
    (e) => {
      if (!checkFocused() || !activeLine) return;
      handleMergeLine(activeLine.id, 'prev');
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, activeLine, handleMergeLine],
  );

  useHotkeys(
    'mod+j',
    (e) => {
      if (!checkFocused() || !activeLine) return;
      handleMergeLine(activeLine.id, 'next');
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, activeLine, handleMergeLine],
  );

  useHotkeys(
    'mod+b',
    (e) => {
      if (!checkFocused() || !activeLine) return;
      handleRetranslateLine(activeLine.id);
      e.preventDefault();
      e.stopPropagation();
    },
    [checkFocused, activeLine, handleRetranslateLine],
  );
};

type EditorHandlerProps = {
  timelineEngine: React.MutableRefObject<TimelineEngine>;
  keepPlaybackOnPause?: boolean;
};

export const useEditorHandler = ({
  timelineEngine,
  keepPlaybackOnPause,
}: EditorHandlerProps) => {
  const dispatch = useDispatch();
  const seekTime = useSelector(getEditorSeekTime);

  const handlePlayPause = () => {
    if (timelineEngine.current.isPlaying) {
      timelineEngine.current.pause();
      if (keepPlaybackOnPause) {
        dispatch(setEditorSeekTime(timelineEngine.current.getTime()));
      } else {
        timelineEngine.current.setTime(seekTime);
      }
    } else timelineEngine.current.play({});
  };

  const handleToggleDub = () => {
    handlePlayPause();
    dispatch(soloOrUnsoloRowId({ id: 'original' }));
  };

  return { handleToggleDub, handlePlayPause };
};
