import { LineType } from 'lib/Types';
import api from 'lib/api';
import { upsertLines } from 'lib/redux/slices/lines';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  lines: LineType[];
};

export const useLoadingStatusPing = ({ lines }: Props) => {
  const dispatch = useDispatch();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const pingStatusEndpoint = async () => {
      const loadingLines = lines.filter((line) => line.is_loading);

      if (loadingLines.length > 0) {
        const loadingLineIds = loadingLines.map((line) => line.id);
        try {
          const { data } = await api.lines.status({ line_ids: loadingLineIds });
          dispatch(upsertLines(data.lines));
        } catch (error) {
          console.error('Error pinging status endpoint:', error);
        }
      }
    };

    if (lines.some((line) => line.is_loading)) {
      intervalRef.current = setInterval(pingStatusEndpoint, 3000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [lines]);
};
