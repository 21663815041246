import axios from './axios';

export const Voices = {
  get: () => axios.get('/voices'),
  create: ({
    name,
    description,
    workspace_id,
    files,
  }: {
    name: string;
    description: string;
    workspace_id?: string;
    files: File[];
  }) => {
    const formData = new FormData();
    formData.append('name', name);
    if (description) formData.append('description', description);
    if (workspace_id) formData.append('workspace_id', workspace_id);
    files.forEach((f) => formData.append('files', f));
    return axios.post('/voices', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  update: ({
    id,
    files,
    data_urls,
  }: {
    id: string;
    files: File[];
    data_urls: string[];
  }) => {
    const formData = new FormData();
    files.forEach((f) => formData.append('files', f));
    data_urls.forEach((url) => formData.append('data_urls', url));
    return axios.put(`/voices/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  delete: (id: string) => axios.delete(`/voices/${id}`),
};
