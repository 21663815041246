import { ProjectType, ProjectUserRole } from 'lib/Types';
import { useSelector } from 'react-redux';
import { getProjectUsers } from 'lib/redux/selectors/projectUsers';
import { getUsersDict } from 'lib/redux/selectors/users';

import { Subtitle } from '../../ui';

type Props = {
  project: ProjectType;
};

export const ProjectOwner = ({ project }: Props) => {
  const usersDict = useSelector(getUsersDict);
  const projectUser = useSelector(getProjectUsers).find(
    (p) => p.project_id === project.id && p.role === ProjectUserRole.OWNER,
  );

  return (
    <Subtitle>{usersDict[projectUser?.user_id || '']?.name || 'None'}</Subtitle>
  );
};
