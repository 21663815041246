import { ActivityType, ActivityTypeEnum } from 'lib/Types';
import api from 'lib/api';
import { getHistoryModalId } from 'lib/redux/selectors/editor';
import { setHistoryModalId } from 'lib/redux/slices/editor';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
} from 'modules/radix/Dialog';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getLinesDict } from 'lib/redux/selectors/lines';
import { HistoryRow } from './components/history-row';

export const HistoryModal = () => {
  const dispatch = useDispatch();
  const lineId = useSelector(getHistoryModalId);
  const line = useSelector(getLinesDict)[lineId || ''];
  const [activities, setActivities] = useState<ActivityType[]>([]);

  const setOpen = (open: boolean) => {
    dispatch(setHistoryModalId(open ? lineId : null));
  };

  useEffect(() => {
    if (!lineId) {
      setActivities([]);
      return;
    }

    const getActivities = async () => {
      if (lineId) {
        try {
          const { data } = await api.lines.history(lineId);
          setActivities(data.activities);
        } catch (error) {
          alert(`Error getting history ${error}`);
        }
      }
    };
    getActivities();
  }, [lineId]);

  return (
    <Dialog open={!!lineId} onOpenChange={setOpen}>
      <DialogContent
        style={{
          maxWidth: 800,
          maxHeight: 700,
          padding: 0,
          height: '100%',
        }}
      >
        <Container>
          <DialogTitle style={{ marginBottom: 16 }}>
            Previous generations
          </DialogTitle>
          <RowContainer>
            {activities.map(
              (a) =>
                a.type === ActivityTypeEnum.INFERENCE_LINE && (
                  <HistoryRow
                    activity={a}
                    key={a.id}
                    isActive={line?.raw_src === a.data?.raw_src}
                  />
                ),
            )}
          </RowContainer>
        </Container>
        <DialogClose asChild />
      </DialogContent>
    </Dialog>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
`;

const RowContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  flex: 1;
`;
